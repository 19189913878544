import React from "react";
import { Modal } from "antd";
import successIcon from "../../../assets/access-manager/success.svg";
import CustomButtonNext from "../../../layouts/component/CustomButton";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
export default function SetPasswordSuccessModal({ open, handleModalClose, responseMessage, handleParentModalClose, finalAPiCall }: any) {
  const handleCloseModal = async () => {
    try {
      if (typeof finalAPiCall === "function") {
        finalAPiCall();
      }
      handleModalClose();
      handleParentModalClose();
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  return (
    <Modal className="setPasswordSuccessModal rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="timerSuccessModal-content flex justify-center items-center flex-wrap">
        <div className="success my-4">
          <img src={successIcon} alt="success icon" />
        </div>
        <p className="w-full">{responseMessage}</p>
      </div>
      <div className="modal-footer mb-5 mt-5">
        <div className="w-full flex justify-center">
          <CustomButtonNext onClick={handleCloseModal} text={"Okay"} />
        </div>
      </div>
    </Modal>
  );
}
