import React, { useState } from "react";
import BasicDetails from "./basic-details/BasicDetails";
import { Switch } from "antd";
import { retrieveData } from "../../../../services/storage/Storage";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { ErrorMessages } from "../../../../const/Messages";
import ReactiveUserModal from "../user-dashboard/adduser/usertable/ReactiveUserModal";
import InActiveUserModal from "./basic-details/DeactivateUserModal";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../layouts/component/Loader";
import "./editUserManagement.scss";

export default function EditUserManagement() {
  const editUserStatus = useGetApiRequests("editUser", "PUT");
  const currentSelectedRow = retrieveData("currentSelectedRow", true);
  const realmId = retrieveData("realmId", true);
  const [userEnabled, setUserEnabled] = useState(false);
  const [activeUser, setActivateUser] = useState(false);
  const [deActiveUser, setDeActivateUser] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [enable, setEnable] = useState(false);
  const navigate = useNavigate();
  const onChangeUserStatus = (checked: boolean) => {
    setUserEnabled(checked);
    if (checked === true) {
      setActivateUser(true);
    } else {
      setDeActivateUser(true);
    }
  };
  const editUserStatusFunction = async () => {
    setLoader(true);
    const pathParams = {
      id: `${currentSelectedRow?.key}/status`,
    };
    const queryParams: any = {
      isEnabled: userEnabled,
      realmId: realmId,
    };
    try {
      setActivateUser(false);
      setDeActivateUser(false);
      const response: any = await editUserStatus("", queryParams, pathParams);
      const status = response.status;

      if (status === 200) {
        const responseData = response?.data;
        triggerNotification("success", "", responseData?.message, "topRight");
        setEnable(true);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleActivateUser = () => {
    editUserStatusFunction();
  };
  const handleCancelActivateUser = () => {
    setUserEnabled(!userEnabled);
    setActivateUser(false);
  };
  const handleCloseInActiveModal = () => {
    setUserEnabled(!userEnabled);
    setDeActivateUser(false);
  };
  const handleNavigate = () => {
    navigate("/user");
  };
  const handleCloseLoader = () => {
    setLoader(false);
  };
  return (
    <div className="akku-container">
      <div className="main-container editUser">
        <div className="dashboard-container _set-height rounded">
          <div>
            <div className="editUser-path mb-6 flex">
              <p onClick={handleNavigate} className=" cursor-pointer">
                User management /
              </p>{" "}
              <span>Edit Information</span>
            </div>
          </div>
          <div className="flex justify-between">
            <h1 className="editUser-title mb-6">Basic Details</h1>
            <div className="w-1/2 flex justify-end items-center">
              <p className={`${userEnabled ? "  text-[#ABABAB]" : "text-[#111]"} pr-3  lg:text-[20px] text-[16px] font-Inter font-medium`}>Disable</p>
              <Switch disabled={disableToggle} data-testid="subscription-switch" checked={userEnabled} onChange={onChangeUserStatus} />
              <p className={`${userEnabled ? "  text-[#111]" : "text-[#ABABAB]"} pl-3  lg:text-[20px] text-[16px] font-Inter font-medium`}>Enable</p>
            </div>
          </div>

          <div className="basicDetails" data-testid="basic-details-component">
            <BasicDetails setUserEnabled={setUserEnabled} setDisableToggle={setDisableToggle} enable={enable} handleCloseLoader={handleCloseLoader} />
          </div>
        </div>
      </div>
      {deActiveUser && (
        <InActiveUserModal openModal={deActiveUser} handleProceedFunction={handleActivateUser} handleCancelFunction={handleCloseInActiveModal} displayMessageContent={ErrorMessages?.inActiveUser} />
      )}
      {activeUser && <ReactiveUserModal active={activeUser} handleProceed={handleActivateUser} handleCancel={handleCancelActivateUser} displayContent={ErrorMessages?.activeUser} />}
      {loader && <Loader />}
    </div>
  );
}
