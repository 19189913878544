import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  updateDashboard: boolean;
  groupsFilterReload?: boolean;
  listOfRestrictionModal: boolean;
}

const initialState: AuthState = {
  updateDashboard: false,
  groupsFilterReload: false,
  listOfRestrictionModal: false,
};

const courseTabSlice = createSlice({
  name: "navigateCourseTab",
  initialState,
  reducers: {
    setUpdateDashboard: (state, action: PayloadAction<boolean>) => {
      state.updateDashboard = action.payload;
    },
    clearUpdateDashboard: (state) => {
      state.updateDashboard = false;
    },
    setReloadGroupFilter: (state, action: PayloadAction<boolean>) => {
      state.groupsFilterReload = action.payload;
    },
    setListOfRestrictionModal: (state, action: PayloadAction<boolean>) => {
      state.listOfRestrictionModal = action.payload;
    },
  },
});

export const { setUpdateDashboard, clearUpdateDashboard, setReloadGroupFilter, setListOfRestrictionModal } = courseTabSlice.actions;
export default courseTabSlice.reducer;
