import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Tooltip } from "antd";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { getCurrentDayDateMonthYear } from "../../../hooks/commonHooks";
import { retrieveData, retrieveDeviceData, storeData } from "../../../services/storage/Storage";
import UserHeaderMoadal from "./UserHeaderMoadal";
import { handleLogout } from "./LogoutHelperFile";
import Loader from "../../../layouts/component/Loader";
import LogoutModal from "../../../layouts/modal/logout/LogoutModal";
import { staticThemeUrl, themeImages } from "../../../User/theme";
import { setProfileDetails } from "../../../redux/slice/profile/ProfileDetailsSlice";
import PopupBlockModal from "../../../layouts/modal/popup-block/PopupBlockModal";
import { useLocation } from "react-router-dom";
import PasswordAlertModal from "../password-modal/PasswordAlertModal";
import ScreenLoader from "../../../layouts/loader/ScreenLoader";
const UserHeader = () => {
  const dispatch = useDispatch();
  const keycloak = useSelector((state: any) => state?.keycloak);
  const role = useSelector((state: any) => state?.auth?.authentication?.role);
  const profileDetails = useGetApiRequests("userProfileDetails", "GET");
  const gettingLogoutUrls = useGetApiRequests("getLogoutUrls", "GET");
  const userSessionKilling = useGetApiRequests("logoutSessionKill", "POST");
  const userId: string = keycloak?.KeycloakInfo?.subject || "";
  const [image, setImage] = useState("");
  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
  const [open, isOpen] = useState<boolean>(false);
  const [openLogout, isOpenLogout] = useState<boolean>(false);
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false);
  const [profileUpdate, setProfileUpdate] = useState<boolean>(false);
  const [userName, setUserName] = useState<any>({});
  const [isActivePopup, setIsActivePopup] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const deviceUrl = retrieveDeviceData("device");
  const location = useLocation();
  const isVisitPopup = retrieveData("isVisitPopup", true);
  const [isPassword, setIsPassword] = useState<boolean>(false);
  const [forceSetPassword, setForceSetPassword] = useState<boolean>(false);

  const theme = useSelector((state: any) => state?.theme?.themeDetails);
  const userDashbordLogoUrl = theme?.themeImagesUrl ? `${theme.themeImagesUrl}${themeImages.userDashbordLogo}` : `${staticThemeUrl.imagesUrl}${themeImages.userDashbordLogo}`;
  useEffect(() => {
    userId && getDetails();
  }, [profileUpdate, keycloak]);

  const checkPopupBlocker = () => {
    const popup = window.open("about:blank", "_blank");
    storeData("isVisitPopup", true, true);
    if (!popup || popup.closed || typeof popup.closed === "undefined") {
      setIsActivePopup(true);
    } else {
      popup?.close();
    }
  };

  useEffect(() => {
    const currentUrl = location.pathname;
    if ((!isVisitPopup && currentUrl === "/dashboard") || (!isVisitPopup && currentUrl === "/admin-dashboard")) {
      checkPopupBlocker();
    }
    storeData("isVisitPopup", true, true);
  }, [isVisitPopup]);

  const getDetails = async () => {
    const payload = {
      id: `${userId}/profile`,
    };
    try {
      const response: any = await profileDetails("", {}, payload);
      const status = response.status;
      if (status === 200) {
        const data = response.data.data;
        setUserName(response.data.data);
        setIsPassword(!data.passwordStatus);
        setForceSetPassword(data?.passwordLogin);
        dispatch(setProfileDetails(response.data.data));
        const profileImage = data.attributes.find((attribute: any) => attribute.name === "profileImage");
        setImage(profileImage?.value);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  const handleModalOpen = () => {
    isOpen(true);
  };
  const handleModalClose = () => {
    isOpen(false);
  };
  const handleLogoutModal = () => {
    isOpenLogout(true);
  };
  const logout = () => {
    handleLogoutModalClose();
    setLoader(true);
    handleLogout(dispatch, gettingLogoutUrls, userName, userSessionKilling, setLoader, deviceUrl, setLogoutLoader);
  };
  const handleLogoutModalClose = () => {
    isOpenLogout(false);
  };
  const redirectDashbaord = () => {
    storeData("key", 1, false);
    const url = "/admin-dashboard";
    const newTab: any = window.open(url, "_blank");
    newTab.focus();
  };

  const getUserInitial = (name: any) => {
    return name?.[0];
  };
  const handleClose = () => {
    setIsActivePopup(false);
  };

  return (
    <div className="akkuHeader-row">
      {isActivePopup && <PopupBlockModal open={true} handleModalClose={handleClose} />}

      <div className="logo">
        <img src={userDashbordLogoUrl} alt="logo" />
      </div>
      <div className="userInfo">
        <div className="userInfo-text">
          <p className="name">
            Welcome <strong>{(userName && userName?.firstName + " " + userName?.lastName) || "Guest"}</strong>
          </p>
          <p className="italic">
            Last Login : <strong>{getCurrentDayDateMonthYear()}</strong>
          </p>
        </div>

        <div className={`logout profile`}>
          <div className="header-profile clientHeader flex items-center">
            <div className="header-icon cursor-pointer" onClick={handleLogoutModal}>
              <Tooltip title="Logout">
                <span className="material-symbols-outlined profile-hover">power_settings_new</span>
              </Tooltip>
            </div>
            {role === "admin" && (
              <div className="header-icon cursor-pointer  " onClick={redirectDashbaord}>
                <Tooltip title="Admin Dashboard">
                  <span className="material-symbols-outlined profile-hover">switch_account</span>
                </Tooltip>
              </div>
            )}

            <div className="header-user">
              <Tooltip title="Profile">
                <Avatar
                  onClick={handleModalOpen}
                  className={`"bg-[#5441da] ${image ? "cursor-pointer " : "bg-[#5441da]"}`}
                  size="large"
                  src={image ? imageBaseUrl + image : <p className="text-[#fff] font-semibold font-Inter capitalize initial">{getUserInitial(userName?.firstName)}</p>}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {loader && <ScreenLoader />}
      {logoutLoader && <Loader />}
      <LogoutModal handleModalClose={handleLogoutModalClose} open={openLogout} logout={logout} />
      <UserHeaderMoadal
        getDetails={getDetails}
        setFullPageLoader={setLoader}
        handleModalClose={handleModalClose}
        setProfileUpdate={setProfileUpdate}
        profileUpdate={profileUpdate}
        open={open}
        userHavePassword={isPassword}
      />
      {isPassword && forceSetPassword && (
        <PasswordAlertModal setFullPageLoader={setLoader} isPassword={isPassword} forceSetPassword={forceSetPassword} handleClose={handleClose} getDetails={getDetails} />
      )}
    </div>
  );
};
export default UserHeader;
