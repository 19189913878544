import { TableProps } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import generateTitle from "../../../../../layouts/Table/generateTitle";


export const addGroupColumns: TableProps["columns"] = [
  {
    title: ({ sortColumns }: any) =>
      generateTitle(
        "Name",
        sortColumns?.find(({ column }: any) => column.key === "name"),
      ),
    dataIndex: "name",
    key: "name",
    width: "30%",
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
    render: (text: any, record: any) => (
      <div>
        <p className={`${text?.toLowerCase()} name cursor-pointer  font-Inter text-[#5441da] text-[16px] `}>{text || "-"}</p>
      </div>
    ),
  },
  {
    title: ({ sortColumns }: any) =>
      generateTitle(
        "Email",
        sortColumns?.find(({ column }: any) => column.key === "Email"),
      ),
    dataIndex: "email",
    key: "Email",
    width: "70%",
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
    render: (text: any, record: any) => (
      <div>
        <p className={`${text?.toLowerCase()} name cursor-pointer  font-Inter text-[16px] text-[#3A3A3A] `}>{text || "-"}</p>
      </div>
    ),
  },
];
