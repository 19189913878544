import React from "react";
import { Modal } from "antd";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import reactiveImg from "../../../../../../assets/images/user/reactivate.png";
export default function ReactiveUserModal(props: any) {
  const { active, handleProceed, handleCancel, displayContent } = props;

  return (
    <Modal centered open={active} footer={false} onCancel={handleCancel} closable={false} maskClosable={false} className="p-10 show-notification rounded-lg  _suspended">
      <div className="pt-5 modal-body">
        <div className="w-full flex-col flex justify-center items-center">
          <img className="h-20 w-20" src={reactiveImg} alt="warningIcon" />
          <p className=" font-semibold pt-3 text-[20px] text-center">{displayContent}</p>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCancel} text={"Cancel"} />
          <CustomButtonNext onClick={handleProceed} text={"Proceed"} />
        </div>
      </div>
    </Modal>
  );
}
