import React, { useEffect, useState } from "react";
import Keycloak from "keycloak-js";
import "material-icons/iconfont/material-icons.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initialPayload } from "./KeycloakHelper";
import { setKeycloakInfo, setKeycloakUserDetails } from "../../redux/slice/Users/KeyCloakSlice";
import { storeData, storeDeviceData, retrieveDeviceData } from "../../services/storage/Storage";
import { extractSubdomain } from "../../hooks/commonHooks";
import { Button, Input } from "antd";
import UserLoginTheme from "../user-authentication/LoginTheme/LoginTheme";
import ScreenLoader from "../../layouts/loader/ScreenLoader";
import { setAuthentication } from "../../redux/slice/AuthSlice";
import { triggerNotification } from "../../layouts/toast/ToastBar";
import { ErrorMessages } from "../../const/Messages";
import { setRealmId } from "../../redux/slice/CustomerSlice";
import { setPermissions } from "../../redux/slice/permissions/permissions";
import { useRoleDecoder } from "./roleDecoder";
import { clearStore } from "../../User/UserDashboard/user-header/LogoutHelperFile";
import UnauthorizedModal from "../../layouts/modal/unauthorized/UnauthorizedModal";

function Login() {
  const roleDecoder = useRoleDecoder();

  const auth: any = useSelector((state: any) => state?.auth);
  // Select Keycloak information from the Redux store
  const keycloakInfo: any = useSelector((state: any) => state?.keycloakDetails?.keycloakInfo);
  const redirectUrl = process.env.REACT_APP_KEYCLOAKREDIRCTURL;
  const deviceRestrictionUrl: any = process.env.REACT_APP_DEVICE_DECRYPTION;
  const localhostRealmName: any = process.env.REACT_APP_LOCALHOST_REALM_NAME;
  //const getIpApiUrl: any = process.env.REACT_APP_GET_IP_URL;
  const getIpApiUrl = "https://api.ipify.org?format=json";
  const dispatch = useDispatch();
  const getLocation = window.location;
  const domain = getLocation.hostname;

  const navigate = useNavigate();
  const [keycloak, setKeycloak] = useState(null);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const [unauthorized, setUnauthorized] = useState(false);
  const [deviceRestriction, setDeviceRestriction] = useState("");
  let apiUrl;
  const urlParamsGlobal = new URLSearchParams(window.location.search);
  //const deviceParamGlobal = urlParamsGlobal.get("device");
  const deviceParamGlobal = urlParamsGlobal ? urlParamsGlobal.get("device") : null;

  //this code is applicable for Device based restriction where the launching client url will contain request paraemter device with value
  const handleDeviceQueryParam = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const deviceParam = urlParams.get("device");

    if (deviceParam) {
      storeDeviceData("device", deviceParam, true);
      return true; // Device param exists
    }
    return false; // No device param
  };

  const deviceParamExists = handleDeviceQueryParam();

  /*const getIpInformation = async () => {
    try {
      const response = await fetch(getIpApiUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const ip = data?.ip; // Safe access with optional chaining
      console.log("IP Address:", ip); // Log the IP address
      return ip; // Return the IP address as a string
    } catch (error) {
      console.error("Error in fetching IP address:", error);
    }
  };*/

  /*const getIpInformation = async () => {
  try {
    const response = await fetch(getIpApiUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    const ip = data?.ip; // Safe access with optional chaining
    console.log("IP Address:", ip); // Log the IP address
    return ip; // Return the IP address as a string
  } catch (error) {
    console.error("Error in fetching IP address:", error);
  }
};*/

  useEffect(() => {
    localStorage.initLoginPageUrl = window.location.origin;
    storeData("isVisitPopup", false, true);

    async function initializeKeycloak() {
      setLoader(true);

      apiUrl = `${redirectUrl}${encodeURIComponent(domain)}`;
      if (deviceParamExists) {
        apiUrl += `&device=${deviceParamGlobal}`;
      }
      let cleanUrl = apiUrl.replace(/`/g, "");
      try {
        const response = await fetch(cleanUrl);
        if (response.status === 400) {
          const errorResponse = await response.json();
          triggerNotification("error", "", errorResponse?.error, "topRight");
          setUnauthorized(true);
        } else if (response.status === 403) {
          /**
           * Handler condition to check if an IP is Blacklisted.
           * Check if the 403 status is due to a blacklisted IP.
           */
          const errorResponse = await response.json();
          if (errorResponse.error === "IP address not allowed") {
            setOpen(true);
          }
        }
        if (!response.ok) throw new Error("Failed to fetch Keycloak configuration");

        // Call setupKeycloak with the fetched configuration and initial payload
        await setupKeycloak(response);
      } catch (error) {
        setLoader(false);
        setUnauthorized(true);
        console.error("Initializing Keycloak failed", error);
      }
    }
    if (!keycloak && keycloakInfo !== "true") {
      initializeKeycloak();
    }
    setRealName();
  }, [keycloak]);
  const handleModalClose = () => {
    setOpen(false);
  };

  /**
   * Function to set up Keycloak with the given configuration and initial payload.
   */
  // const setupKeycloak = async (response: any) => {
  //   let userDetails: any = {};
  //   const config = await response.json();
  //   storeData("keycloakUrl", config.url, false);
  //   const kc: any = new Keycloak(config);
  //   await kc.init({ ...initialPayload });

  //   storeData("kcConfig", config, false);

  //   const deviceManagerEnabled = config.isDeviceManagerEnabled;
  //   storeData("deviceManagerEnabled", deviceManagerEnabled, false);

  //   if (kc.authenticated) {
  //     setupAutomaticRefresh(kc);
  //     userDetails = kc.tokenParsed;

  //     //The below code used only if the client have Device based restriction
  //     if (deviceManagerEnabled) {
  //       await postAuthentication(kc, userDetails);
  //     } else {
  //       navigateToDashboard(kc, userDetails);
  //     }
  //   } else {
  //     console.warn("Not authenticated");
  //     setLoader(false);
  //   }
  //   setKeycloak(kc);
  // };
  const setupKeycloak = async (response: any) => {
    let userDetails: any = {};
    const config = await response.json();
    storeData("keycloakUrl", config.url, false);
    const kc: any = new Keycloak(config);
    const ipRestrictionEnabled = config.isIpRestrictionEnabled ?? false;
    const isTimeRestricitonEnabled = config.isTimeRestricitonEnabled ?? false;
    const isDeviceManagerEnabled = config.isDeviceManagerEnabled ?? false;
    const isDeviceManagerServiceEnabled = config.isDeviceManagerServiceEnabled ?? false;
    let combinedValue = `${ipRestrictionEnabled}||${isTimeRestricitonEnabled}||${isDeviceManagerEnabled}||${deviceParamExists}||${deviceParamGlobal}||${isDeviceManagerServiceEnabled}`;

    /*if (isDeviceManagerServiceEnabled) {
      const deviceInfo = await fetchDeviceInfo(); // Fetch device information if service is enabled

      if (deviceInfo) {
        
        combinedValue += `||${deviceInfo.serial_number}||${deviceInfo.device_uuid}`;
      }
    }*/

    await kc.init({ ...initialPayload, acrValues: combinedValue });

    //const combinedValue = `${ipRestrictionEnabled}||${isTimeRestricitonEnabled}||${isDeviceManagerEnabled}||${deviceParamExists}||${deviceParamGlobal}||${isDeviceManagerServiceEnabled}`;

    //await kc.init({ ...initialPayload, acrValues: combinedValue });

    storeData("kcConfig", config, false);
    const deviceManagerEnabled = config.isDeviceManagerEnabled;
    storeData("deviceManagerEnabled", deviceManagerEnabled, false);
    if (kc.authenticated) {
      setupAutomaticRefresh(kc);
      userDetails = kc.tokenParsed;
      //The below code used only if the client have Device based restriction
      /*if (deviceManagerEnabled) {
        await postAuthentication(kc, userDetails);
      } else {*/
      navigateToDashboard(kc, userDetails);
      //}
    } else {
      console.warn("Not authenticated");
      setLoader(false);
    }
    setKeycloak(kc);
  };

  const setupAutomaticRefresh = (kc: Keycloak) => {
    const refreshToken = () => {
      kc.updateToken(70) // Refresh if token expires within the next 70 seconds
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token was successfully refreshed");
            // Here you can access the new token
            const newAccessToken = kc.token;
            console.log("New Access Token:", newAccessToken);
            // Perform operations with the new token, e.g., store it or set headers for API calls
            storeData("authToken", newAccessToken, true);
            // Optionally, you can also store the ID token and refresh token
            storeData("idToken", kc.idToken, true);
            storeData("refreshToken", kc.refreshToken, true);
          } else {
            console.log("Token is still valid");
          }
        })
        .catch((err) => {
          clearStore(dispatch);
          navigate("/login");
          console.error("Failed to refresh the token", err);
          kc?.login(); // Redirect to login if refreshing fails
        });
    };

    // Refresh every minute
    setInterval(refreshToken, 30000);
  };

  const postAuthentication = async (kc: any, userDetails: any) => {
    const encryptedString = retrieveDeviceData("device");
    const requestBody = {
      realmName: kc.realm,
      encryptedString: !encryptedString ? null : encryptedString,
      userId: userDetails?.sub,
    };

    try {
      const response = await fetch(deviceRestrictionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) throw new Error("API call failed");
      const deviceApiResponseText: any = await response.text();
      const parsedText = JSON.parse(deviceApiResponseText);
      console.info("Device Authentication::::::::::::::::::::::::::" + deviceApiResponseText);
      console.info("deviceApiResponseText" + deviceApiResponseText.isDeviceAuthorized);

      if (parsedText.isDeviceAuthorized !== true) {
        setLoader(false);
        setDeviceRestriction(parsedText?.message);
        storeData("deviceManagerEnabled", true, false);

        triggerNotification("error", ErrorMessages?.accessDenied, parsedText?.message, "topRight");
      } else {
        storeData("deviceManagerEnabled", false, false);

        navigateToDashboard(kc, userDetails);
      }
    } catch (error) {
      console.error("Error calling the API or access denied", error);
    }
  };
  const navigateToDashboard = (kc: any, userDetails: any) => {
    tokenDecoder(kc.idToken);
    const storePermissionsObject = roleDecoder(kc.token);
    dispatch(setPermissions(storePermissionsObject));
    storeData("authToken", kc.token, true);
    const realmManagement = userDetails?.resource_access?.["realm-management"];
    let role: string = "";
    if (!realmManagement) {
      const realmManagementRoles = kc?.realmAccess?.roles;
      const isRealmAdmin = realmManagementRoles.includes("admin-role");
      role = isRealmAdmin ? "admin" : "user";
    } else {
      const realmManagementRoles = userDetails?.resource_access["realm-management"].roles;
      const isRealmAdmin = realmManagementRoles.includes("realm-admin");
      role = isRealmAdmin ? "admin" : "user";
    }
    dispatch(
      setAuthentication({
        ...auth.authentication,
        role: role,
      }),
    );

    // Dispatch an action to set Keycloak information in the Redux store
    dispatch(setKeycloakInfo(kc));
    // Dispatch an action to set Keycloak user details in the Redux store
    dispatch(setKeycloakUserDetails(userDetails));
    storeData("currentRole", role, true);
    role === "admin" ? navigate("/admin-dashboard") : navigate("/dashboard");
    storeData("key", "1", false);
    storeData("role", role, false);
  };
  // jwt decode
  function tokenDecoder(token: string) {
    let decodedToken;
    try {
      const parts = token.split(".");
      if (parts.length === 3) {
        decodedToken = base64UrlDecode(parts[1]);
        dispatch(setRealmId(decodedToken.realm_id));
        storeData("realmId", decodedToken.realm_id, true);
      }
    } catch (error) {
      console.error("Failed to decode JWT:", error);
    }
  }

  function base64UrlDecode(str: any) {
    let base64 = str.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  }
  const setRealName = () => {
    if (deviceRestriction !== "true" && deviceRestriction !== "") {
      storeDeviceData("device", "", true);
      sessionStorage.clear();
      localStorage.clear();
    } else {
      if (getLocation.hostname === "localhost" || getLocation.hostname === "development" || getLocation.hostname === "sso-demo2") {
        storeData("realmName", localhostRealmName, true);
      } else {
        const realmName = extractSubdomain(getLocation.href);
        storeData("realmName", realmName, true);
      }
    }
  };

  /*const fetchDeviceInfo = async () => {
  
    try {
     // const response = await fetch("http://localhost:8089/device-info");
      
      const response = await fetch("http://localhost:8089/device-info", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default'
    });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
     
      console.log("Device Info:", data); // Log the device information
      return data;
    } catch (error) {
      console.error("Error fetching device information:", error);
      return null;
    }
  };*/

  return (
    <div className="w-full">
      {!unauthorized && deviceParamExists && deviceRestriction !== "" && deviceRestriction !== "true" ? (
        <div className="login-title">
          {/* <h2>Your device is not authorized to access the application.</h2>
          <h2>Please contact the administrator.</h2> */}
          <h2>{deviceRestriction}</h2>
        </div>
      ) : unauthorized && deviceParamExists ? (
        <div className="login-title">
          <h2>Unauthorized access detected. Please log in from an authorized device for security purposes.</h2>
        </div>
      ) : (
        <>
          {loader && <ScreenLoader />}
          <div className="login-title">
            <h2>Login</h2>
          </div>
          <div className="login-form">
            <form>
              <div className="formGroup login-userName">
                <label className="label">Username</label>
                <Input placeholder="Enter Username" name="userName" />
              </div>
              <div className="login-forgetUsername">
                <span className="hoverUnderLine">Forgot your username?</span>
              </div>
              <div className="btn">
                <Button type="primary" className="login-btn" htmlType="submit">
                  Next
                </Button>
              </div>
            </form>
          </div>
        </>
      )}

      {open && <UnauthorizedModal loader={loader} open={open} handleModalClose={handleModalClose} isActiveBackButton={false} />}
    </div>
  );
}
export default UserLoginTheme(Login);
