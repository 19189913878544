import * as Yup from "yup";

export const handleKerberosNavigate = (stepper: any) => {
  const newStepper = stepper.map((step: any, index: number) => {
    if (index === 0) {
      return {
      title:"General Details",
        status: "check",
        description: '',
        active: true,
        icon: "check",
        
      };
    }
  });
 
  const filteredArray = newStepper.filter((item:any) => item !== null && item !== undefined);

  return filteredArray;
};

export  const kerberosInitialValues = {
    kerberosName: "",
    kerberosRealmName: "",
    serverPrincipalName: "",
    keytabPath: "",
  };


  export  const kerberosValidationSchema = Yup.object().shape({
  kerberosName: Yup.string()
    .required("UI display name is required")
    .min(3, "UI display name must be at least 3 characters"),
  kerberosRealmName: Yup.string()
    .required("Kerberos realm name is required")
    .min(3, "Kerberos realm name must be at least 3 characters"),
  serverPrincipalName: Yup.string()
    .required("Server principal is required")
    .min(3, "Server principal must be at least 3 characters"),
  keytabPath: Yup.string().required("Key tab is required")
    
});
