import { TableProps } from "antd";
import generateTitle from "../../../../../layouts/Table/generateTitle";
import { SortOrder } from "antd/lib/table/interface";

export const deviceRestrictionColumns: TableProps["columns"] = [
  {
    title: ({ sortColumns }: any) =>
      generateTitle(
        "Name",
        sortColumns?.find(({ column }: any) => column.key === "name"),
      ),
    dataIndex: "name",
    key: "name",
    width: "30%",
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
    render: (text: any, record: any) => (
      <div>
        <p className={`${text?.toLowerCase()} name cursor-pointer  font-Inter text-[#5441da] text-[16px] `}>
          <span>{text || "-"}</span>
        </p>
      </div>
    ),
  },
  {
    title: ({ Columns }: any) =>
      generateTitle(
        "Email",
        Columns?.find(({ column }: any) => column.key === "email"),
      ),
    dataIndex: "email",
    key: "email",
    width: "70%",
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
    render: (text: any, record: any) => (
      <div>
        <p className={`${text?.toLowerCase()} name cursor-pointer  font-Inter text-[16px] text-[#3A3A3A] `}>
          <span> {text || "-"}</span>
        </p>
      </div>
    ),
  },
];

export const deviceRestrictionColumnsUpdate: TableProps["columns"] = [
  {
    key: "name",
    title: ({ columns }: any) =>
      generateTitle(
        "Name",
        columns?.find(({ column }: any) => column.key === "name"),
      ),
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (dataFirst: any, dataSecond: any) => dataFirst?.name?.localeCompare(dataSecond?.name),
    render: (data: any) => (
      <div>
        <p className={`${data?.toLowerCase()} name text-[16px] font-Inter text-[#5441da] cursor-pointer`}>
          <span>{data || "-"}</span>
        </p>
      </div>
    ),
    width: "30%",
    dataIndex: "name",
  },
  {
    dataIndex: "email",
    key: "email",
    width: "50%",
    defaultSortOrder: "ascend" as SortOrder,
    title: ({ sortColumns }: any) =>
      generateTitle(
        "Email",
        sortColumns?.find(({ column }: any) => column.key === "email"),
      ),
    sorter: (firstData: any, secondData: any) => firstData?.email?.localeCompare(secondData?.email),
    render: (text: any, record: any) => (
      <div>
        <p className={`${text?.toLowerCase()} name text-[#3A3A3A] font-Inter cursor-pointer text-[16px]`}>
          <span> {text || "-"}</span>
        </p>
      </div>
    ),
  },
  {
    title: "Status",
    render: (text: any) => {
      return (
        <div>
          <p className={`${text}-color user-status status`}>
            <span></span>
            {text ?? "-"}
          </p>
        </div>
      );
    },
    dataIndex: "status",
    width: "20%",
  },
];
