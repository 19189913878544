import React from "react";
import { UserProvider } from "../dashboard/user/user-dashboard/userContext/UserContext";
import { AddUserProvider } from "../dashboard/user/user-dashboard/add-user-context/AddUserContext";
import ConfigureDirectoryConnection from "./ConfigureDirectoryConnection";

const ConnectionDirectory = () => {
  return (
    <>
      <UserProvider>
        <AddUserProvider>
          <ConfigureDirectoryConnection />
        </AddUserProvider>
      </UserProvider>
    </>
  );
};

export default ConnectionDirectory;
