import React, { useEffect, useState } from "react";
import { TimePicker, Table, Button, Input, Select, Radio } from "antd";
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik } from "formik";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import { useLocation, useNavigate } from "react-router-dom";
import { retrieveData } from "../../../../services/storage/Storage";
import { useDispatch, useSelector } from "react-redux";
import { setIpPayload, setPayloadForTimeAndIp } from "../../../../redux/slice/IPSlice";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { getUsersList } from "../../../dashboard/user/user-dashboard/groups/modal/ListOfUserHelperFile";
import UsersSearchDropdown from "../../../dashboard/user/user-dashboard/groups/UserSearchModal";
import { getUserGroup } from "../select-ip/IpRestrictionUtils";
import { columns, getZones, groupColumns } from "./TimeRestrictionTableData";
import { validationSchemaForTimeRestriction } from "./TimeRestrictionValidationSchema";
import IpTimeSuccessModal from "../IpTimeSuccessModal";
import CloseConfirmationModal from "../Model/CloseConfirmationModal";
import GroupAndUserNecessaryModal from "../Model/GroupAndUserNecessaryModal";
import { setListOfRestrictionModal } from "../../../../redux/slice/Users/UserDashboardUpdateSlice";
import AccessManagerBackNavigationArrow from "../AccessManagerBackNavigationArrow";
import dayjs from "dayjs";
import "./timeParameters.scss";

const { Option } = Select;

const TimeParameters = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isEditFromUrl = params.get("isEdit");
  const type = params.get("type");
  const navigatedFrom = params.get("from");

  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createTimeRestrictionApi = useGetApiRequests("createTimeBasedRestriction", "POST");
  const getUserListApi = useGetApiRequests("userManagement", "POST");
  const getGroupsListApi = useGetApiRequests("getGroupsList", "GET");
  const updateTimeRestrictionApi = useGetApiRequests("updateTimeBasedRestriction", "PUT");
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    description: "",
    assignTo: navigatedFrom ? "users" : "",
    timeParameter: [{ timeTo: "", timeFrom: "", timezone: "", id: "" }],
  });
  const [isActiveEdit, setIsActiveEdit] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [zonesList, setZonesList] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [usersSearchListForTime, setUsersSearchListForTime] = useState<any>([]);
  const [selectedObjects, setSelectedObjects] = useState<any>([]);
  const [listOfUsersForTime, setListOfUsersForTime] = useState<any>([]);
  const [dropdownVisibleForTime, setDropdownVisibleForTime] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [updatedUserList, setUpdatedUserList] = useState<any>([]);
  const [addedUsersForTime, setAddedUsersForTime] = useState<any>([]);
  const [groupDataForTime, setGroupDataForTime] = useState<any>([]);
  const [deletedUsersForTime, setDeletedUsersForTime] = useState<any>([]);
  const [userSearchGroupDataForTime, setUserSearchGroupDataForTime] = useState<any>([]);
  const [editRecordData, setEditRecordData] = useState<any>([]);
  const [timerSuccessModal, setTimerSuccessModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState<any>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [atLeastOneModalForTime, setAtLeastOneModalForTime] = useState(false);
  const [deleteIds, setDeleteIds] = useState<any>([]);
  const [newRowAdded, setIsNewRowAdded] = useState(false);
  const [key, setKey] = useState<number>(1);
  const [newForm, setNewForm] = useState<any>([{ accessTo: "", accessFrom: "", timezone: "", userTimeBasedAuthorizationId: "" }]);
  const editIpDetailsForTime = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const savedPayloadForTimeParam = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);
  const deviceRestrictionList: any = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);

  const returnToTheAccessManager = () => {
    navigate("/access-manager");
  };
  const returnToTheUserManagement = () => {
    dispatch(setListOfRestrictionModal(true));
    navigate("/user");
  };
  const returnToBack = () => {
    navigate(-1);
  };
  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };
  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    getZones(setLoader, setZonesList);

    if (isEditFromUrl === "true") {
      setIsActiveEdit(true);
      const timeParamDetailFromApi = editIpDetailsForTime?.userTimeBasedAuthorizationRestrictionKvDtoList?.map((item: any) => ({
        timezone: item?.timezone,
        accessFrom: item?.accessFrom,
        accessTo: item?.accessTo,
        userTimeBasedAuthorizationId: item?.userTimeBasedAuthorizationId,
      }));
      setNewForm(timeParamDetailFromApi);
      const timeParamDetailInTimeFormat = editIpDetailsForTime?.userTimeBasedAuthorizationRestrictionKvDtoList?.map((item: any) => ({
        timezone: item?.timezone,
        timeFrom: dayjs(item?.accessFrom, "hh:mm A"),
        timeTo: dayjs(item?.accessTo, "hh:mm A"),
        id: item?.userTimeBasedAuthorizationId,
      }));

      setInitialValues({
        name: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.name,
        description: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.description,
        assignTo: type,
        timeParameter: timeParamDetailInTimeFormat,
      });
      if (type === "users") {
        const editUserList: any = editIpDetailsForTime?.userTimeBasedAuthorizationWithUserKvDtoList?.map((item: any) => ({
          key: item?.userDto?.id,
        }));

        getUsersList(0, 10, "", false, setLoader, realmId, getUserListApi, setUsersSearchListForTime, true, editUserList, "", setListOfUsersForTime, true, setEditRecordData);
      } else if (type === "groups") {
        const editGroupList: any = editIpDetailsForTime?.userTimeBasedAuthorizationWithGroupKvDtoList?.map((item: any) => item?.groupKvDto?.id);
        getUserGroup(setLoader, getGroupsListApi, setGroupDataForTime, setUserSearchGroupDataForTime, editGroupList, true, setEditRecordData);
      }
    } else if (savedPayloadForTimeParam && Object.keys(savedPayloadForTimeParam)?.length > 0) {
      const timeParamDetailInTimeFormat = savedPayloadForTimeParam?.userTimeBasedAuthorizationRestrictionKvDto?.map((item: any) => ({
        timezone: item?.timezone,
        timeFrom: dayjs(item?.accessFrom, "hh:mm A"),
        timeTo: dayjs(item?.accessTo, "hh:mm A"),
        id: item?.userTimeBasedAuthorizationId,
      }));
      const timeParamDetailFromApi = savedPayloadForTimeParam?.userTimeBasedAuthorizationRestrictionKvDto?.map((item: any) => ({
        timezone: item?.timezone,
        accessFrom: item?.accessFrom,
        accessTo: item?.accessTo,
        userTimeBasedAuthorizationId: item?.userTimeBasedAuthorizationId,
      }));
      setNewForm(timeParamDetailFromApi);
      setInitialValues({
        name: savedPayloadForTimeParam?.userTimeBasedAuthorizationDto?.name,
        description: savedPayloadForTimeParam?.userTimeBasedAuthorizationDto?.description,
        assignTo: savedPayloadForTimeParam?.assignTo,
        timeParameter: timeParamDetailInTimeFormat,
      });
      setKey(key + 1);
    }
  }, []);
  const constructPayload = (payload: any) => {
    if (type === "users") {
      payload.addUserTimeRestriction = listOfUsersForTime.map((item: any) => item?.key);
    } else if (type === "groups") {
      payload.addGroupTimeRestriction = groupDataForTime?.map((item: any) => item?.key);
    } else if (type === "organization") {
      payload.organisationTimeZoneCreation = true;
    }
    return payload;
  };
  const constructDeletePayload = (payload: any) => {
    if (deletedUsersForTime?.length > 0 && deleteIds?.length > 0) {
      payload.removeTimeRestriction = Array.from(new Set([...deletedUsersForTime, ...deleteIds]));
    } else if (deletedUsersForTime?.length > 0) {
      payload.removeTimeRestriction = deletedUsersForTime;
    } else {
      payload.removeTimeRestriction = deleteIds;
    }
    return payload;
  };
  const newlyAddedRowPayload = (object: any) => {
    if (type === "groups") {
      object.addGroupTimeRestriction = groupDataForTime?.map((item: any) => item?.key);
    } else if (type === "users") {
      object.addUserTimeRestriction = listOfUsersForTime?.map((item: any) => item?.key);
    } else if (type === "organization") {
      object.organisationTimeZoneCreation = true;
    }
    return object;
  };
  const showAtLeastOneModal = (condition: boolean) => {
    if (condition) {
      setAtLeastOneModalForTime(true);
      return true;
    }
    return false;
  };
  const addUserAndGroupListInPayload = (payload: any) => {
    if (addedUsersForTime?.length > 0 && type === "groups") {
      payload.addGroupTimeRestriction = addedUsersForTime;
      payload.organisationTimeZoneCreation = false;
    } else if (addedUsersForTime?.length > 0 && type === "users") {
      payload.addUserTimeRestriction = addedUsersForTime;
      payload.organisationTimeZoneCreation = false;
    }
    return payload;
  };
  const handleApiSuccessCall = (response: any) => {
    setLoader(false);
    setTimerSuccessModal(true);
    setResponseMessage(response?.data?.message);
    setTimeout(() => {
      handleCloseSuccessModal();
      navigate("/access-manager");
    }, 3000);
  };
  const updateTimeRestriction = async (values: any) => {
    const isUserTypeAndEmpty = type === "users" && listOfUsersForTime?.length === 0;
    const isGroupTypeAndEmpty = type === "groups" && groupDataForTime?.length === 0;

    if (showAtLeastOneModal(isUserTypeAndEmpty) || showAtLeastOneModal(isGroupTypeAndEmpty)) {
      return;
    }
    const pathParams: any = {
      id: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.akkuUserTimeBasedAuthorizationId,
    };

    try {
      const payload: any = {
        userTimeBasedAuthorizationDto: {
          akkuUserTimeBasedAuthorizationId: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.akkuUserTimeBasedAuthorizationId,
          realmId: realmId,
          name: values?.name,
          description: values?.description,
          isActive: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.isActive,
        },
        userTimeBasedAuthorizationRestrictionKvDto: newForm,
      };

      addUserAndGroupListInPayload(payload);

      const differentLength = editIpDetailsForTime?.userTimeBasedAuthorizationRestrictionKvDtoList.length !== values.timeParameter.length;

      if (differentLength && newRowAdded) {
        constructPayload(payload);
      } else if (!differentLength && newRowAdded) {
        newlyAddedRowPayload(payload);
      }
      if (deletedUsersForTime?.length > 0 || deleteIds?.length > 0) {
        constructDeletePayload(payload);
      }
      try {
        const response: any = await updateTimeRestrictionApi(payload, {}, pathParams);
        const status = response.status;
        if (status === 200) {
          handleApiSuccessCall(response);
        }
      } catch (err: any) {
        handleRequestError(err);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  const addRestrictionForParticularUser = async (payload: any) => {
    const timeRestrictedUser = deviceRestrictionList?.map((item: any) => item?.key);
    payload.userTimeBasedAuthorizationKeyValueDto = {
      userIdList: timeRestrictedUser,
    };

    try {
      const response: any = await createTimeRestrictionApi(payload);
      const status = response.status;
      if (status === 200) {
        handleApiSuccessCall(response);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const onSubmit = async (values: any) => {
    if (!isActiveEdit) {
      const payload: any = {};

      payload.userTimeBasedAuthorizationDto = {
        realmId: realmId,
        name: values?.name,
        description: values?.description,
      };
      const transformedArray = newForm?.map((item: any) => ({
        timezone: item?.timezone,
        accessFrom: item?.accessFrom,
        accessTo: item?.accessTo,
      }));
      payload.userTimeBasedAuthorizationRestrictionKvDto = transformedArray;
      const savedPayload: any = { ...payload };
      savedPayload.assignTo = values?.assignTo;

      dispatch(setPayloadForTimeAndIp(savedPayload));
      if (!navigatedFrom) {
        if (values?.assignTo === "groups") {
          dispatch(setIpPayload(payload));
          navigate("/access-manager/groups?parameter=time");
        } else if (values?.assignTo === "users") {
          dispatch(setIpPayload(payload));
          navigate("/access-manager/assignUsers?parameter=time");
        } else if (values?.assignTo === "organization") {
          try {
            const response: any = await createTimeRestrictionApi(payload);
            const status = response.status;
            if (status === 200) {
              handleApiSuccessCall(response);
            }
          } catch (err: any) {
            handleRequestError(err);
            setLoader(false);
          }
        }
      } else {
        addRestrictionForParticularUser(payload);
      }
    } else {
      updateTimeRestriction(values);
    }
  };
  const handleCloseNecessaryModalForTime = () => {
    setAtLeastOneModalForTime(false);
  };
  const onSelectChangeForTime = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChangeForTime,
  };
  const unassignUserForTime = () => {
    if (type === "groups") {
      const shallowGroupsData = [...groupDataForTime];
      const reformedGroupList = shallowGroupsData?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationGroupIds = editIpDetailsForTime?.userTimeBasedAuthorizationWithGroupKvDtoList
        ?.filter((item: any) => selectedRowKeys.includes(item?.groupKvDto?.id))
        .flatMap((item: any) => item?.userTimeBasedAuthorizationId.split(",").map((id: any) => Number(id.trim())));
      const mergeDeleteUserArray = [...deletedUsersForTime, ...selectedAuthorizationGroupIds];
      setDeletedUsersForTime(mergeDeleteUserArray);
      setGroupDataForTime(reformedGroupList);
    } else {
      const shallowListOfUsers = [...listOfUsersForTime];
      const filteredReformedListNew = shallowListOfUsers?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationIds = editIpDetailsForTime?.userTimeBasedAuthorizationWithUserKvDtoList
        .filter((item: any) => selectedRowKeys.includes(item?.userDto?.id))
        .flatMap((item: any) => item?.userTimeBasedAuthorizationId.split(",").map((id: any) => Number(id.trim())));

      const mergeDeleteUserArray = [...deletedUsersForTime, ...selectedAuthorizationIds];
      setDeletedUsersForTime(mergeDeleteUserArray);
      setListOfUsersForTime(filteredReformedListNew);
    }
    setSelectedRowKeys([]);
  };

  const timeParamAssigningTo = (value: any, setFieldValue: any) => {
    setFieldValue("assignTo", value);
  };

  const onChangingZone = (value: any, setFieldValue: any, index?: number) => {
    const fieldName = `timeParameter[${index}].timezone`;
    setFieldValue(fieldName, value);

    if (index !== undefined) {
      setNewForm((prevState: any) => {
        const newState: any = [...prevState];
        newState[index] = { ...newState[index], timezone: value };
        return newState;
      });
    }
  };

  const handleAddUsersInTime = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = usersSearchListForTime.filter((item: any) => selectedValues.includes(item.value));
      setAddedUsersForTime(selectedValues);
      setSelectedObjects(selectedObjects);
      const combinedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(combinedArray?.map((obj) => obj?.value)))?.map((value) => {
        return combinedArray?.find((obj) => obj?.value === value);
      });
      setListOfUsersForTime(uniqueMergedArray);

      setDropdownVisibleForTime(false);
    } else {
      setDropdownVisibleForTime(false);
      setListOfUsersForTime(editRecordData);
    }
  };
  const handleChangeUsersForTime = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const removingIds = previousValue?.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfUsersDetails = [...listOfUsersForTime];
    const needToDeleteForTime = listOfUsersDetails?.filter((item) => !removingIds.includes(item.value));
    setUpdatedUserList(needToDeleteForTime);
  };
  const handleAddGroupsInTime = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = userSearchGroupDataForTime?.filter((item: any) => selectedValues?.includes(item.value));
      setAddedUsersForTime(selectedValues);
      setSelectedObjects(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value)))?.map((value) => {
        return mergedArray?.find((obj) => obj.value === value);
      });
      setGroupDataForTime(uniqueMergedArray);
      setDropdownVisibleForTime(false);
    } else {
      setDropdownVisibleForTime(false);
      setGroupDataForTime(editRecordData);
    }
  };
  const renderingUserSearch = (menu: any) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={type === "users" ? handleAddUsersInTime : handleAddGroupsInTime} setDropdownVisible={setDropdownVisibleForTime} />
      </div>
    );
  };
  const selectFilterProcessFunction = (input: string, option?: { label: string; value: string }) => (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());

  const handleLabelClick = (e: any, value: any) => {
    e.stopPropagation();
    const shallowSelectedValues: any = [...selectedValues];
    if (shallowSelectedValues?.includes(value)) {
      shallowSelectedValues?.splice(shallowSelectedValues?.indexOf(value), 1);
    } else {
      shallowSelectedValues.push(value);
    }
    setSelectedValues(shallowSelectedValues);
  };
  const handleChangeGroups = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const missingIdsForGroups = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfGroupDetails = [...groupDataForTime];
    const needToDeleteForTime = listOfGroupDetails.filter((item) => !missingIdsForGroups.includes(item.value));
    setUpdatedUserList(needToDeleteForTime);
  };
  const handleCloseSuccessModal = () => {
    setTimerSuccessModal(false);
  };

  const handleOnChange = (type: any, date: any, setFieldValue: any, value: any, index?: number) => {
    const fieldName = `timeParameter[${index}].${type}`;
    setFieldValue(fieldName, value);
    let name = type === "timeFrom" ? "accessFrom" : type === "timeTo" ? "accessTo" : "";
    if (index !== undefined) {
      setNewForm((prevState: any) => {
        const newState: any = [...prevState];
        newState[index] = { ...newState[index], [name]: date };
        return newState;
      });
    }
  };
  const handleRemoveList = (index: any, remove: any, values: any) => {
    const removedTimeZonesList = {
      accessFrom: dayjs(values?.timeFrom).format("hh:mm A"),
      accessTo: dayjs(values?.timeTo).format("hh:mm A"),
      timezone: values?.timezone,
      userTimeBasedAuthorizationId: values?.id,
    };

    const shallowNewForm = [...newForm];
    const updatedForm = shallowNewForm?.filter(
      (item: any) =>
        item.timezone !== removedTimeZonesList?.timezone ||
        item.accessFrom !== removedTimeZonesList?.accessFrom ||
        item.accessTo !== removedTimeZonesList?.accessTo ||
        item.userTimeBasedAuthorizationId !== removedTimeZonesList?.userTimeBasedAuthorizationId,
    );
    if (updatedForm && updatedForm.length > 0) {
      setNewForm(updatedForm);
    } else if (updatedForm && updatedForm.length === 0) {
      setNewForm([{ accessTo: "", accessFrom: "", timezone: "" }]);
    }
    if (values?.id && values?.id !== "") {
      const newIdValues = values?.id?.split(",").map(Number);

      setDeleteIds((prevDeleteIds: any) => {
        const flatPrevDeleteIds = prevDeleteIds.flat();
        const combinedIds = [...flatPrevDeleteIds, ...newIdValues];
        const uniqueIds = Array.from(new Set(combinedIds));
        return uniqueIds;
      });
    }
    remove(index);
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="h-full dashboard-container">
          <ul className="breadcrumb flex">
            {!navigatedFrom ? (
              <li className="font-Inter cursor-pointer pr-1" onClick={returnToTheAccessManager}>
                Access Manager /
              </li>
            ) : (
              <li className="cursor-pointer font-Inter pr-1" onClick={returnToTheUserManagement}>
                User Management /
              </li>
            )}
            <li className="font-Inter pr-1 active">Time Parameters</li>
          </ul>
          <div className={`mb-14 mt-4 ${isActiveEdit ? "selectIpEdit-title" : ""}`}>
            <AccessManagerBackNavigationArrow title={!isActiveEdit ? "Time Parameters" : "Edit - Time Parameters"} />
          </div>
          {!isActiveEdit ? (
            <div className=" mt-5 time-parameter ">
              <Formik key={key} initialValues={initialValues} validationSchema={validationSchemaForTimeRestriction} onSubmit={onSubmit} enableReinitialize={false}>
                {({ handleSubmit, values, setFieldValue, dirty, errors }) => {
                  return (
                    <Form
                      className=" h-full"
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <div className="w-full flex selectIP-form pt-0 flex-wrap ">
                        <div className="flex flex-wrap  mx-auto justify-between w-[80%]">
                          <div className="mb-8 lg:mb-11 w-[100%] lg:w-[48%] relative">
                            <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Name*</p>
                            <Field as={Input} type="text" className="font-medium h-[48px] text-[18px]" maxLength={30} name="name" data-testid="timeRestriction-name" />
                            <ErrorMessage name="name" component="div" className="error-message" />
                          </div>
                          <div className="mb-8 lg:mb-11 w-[100%] lg:w-[48%] relative">
                            <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Descriptions*</p>
                            <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" maxLength={60} name="description" data-testid="timeRestriction-description" />
                            <ErrorMessage component="div" className="error-message" name="description" />
                          </div>

                          {/* new design */}
                          <FieldArray name="timeParameter">
                            {({ push, remove }) => {
                              return (
                                <>
                                  {values?.timeParameter?.map((field: any, index: any) => (
                                    <div className="w-full relative" key={field.id}>
                                      <div className="w-full flex justify-between relative flex-wrap">
                                        <div className="mb-4 lg:mb-11 w-[100%] lg:w-[48%] md:w-[100%] zonesList">
                                          <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>Select Zone*</p>
                                          <Field
                                            as={Select}
                                            showSearch
                                            onChange={(value: string) => onChangingZone(value, setFieldValue, index)}
                                            name={`timeParameter[${index}].timezone`}
                                            placeholder="Select Zone"
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                            filterSort={(optionA: any, optionB: any) => optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())}
                                            className="w-full h-[48px] font-medium text-[18px]"
                                          >
                                            {zonesList?.map((option: any) => (
                                              <Select.Option key={option?.label} value={option?.label}>
                                                {option?.label}
                                              </Select.Option>
                                            ))}
                                          </Field>
                                          <ErrorMessage name={`timeParameter[${index}].timezone`} component="div" className="error-message" />
                                        </div>
                                        <div className="mb-4 lg:mb-11 w-[100%] lg:w-[48%]">
                                          <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>
                                            Select Range* (Start Time & End Time)
                                          </p>
                                          <div className="rangePicker">
                                            {savedPayloadForTimeParam && Object?.keys(savedPayloadForTimeParam)?.length > 0 ? (
                                              <div className="w-full flex">
                                                <div className="pr-2 w-1/2">
                                                  <Field name={`timeParameter[${index}].timeFrom`} className=" mr-2">
                                                    {({ field }: FieldProps<any>) => (
                                                      <TimePicker
                                                        use12Hours
                                                        onChange={(value, dateString) => {
                                                          handleOnChange("timeFrom", dateString, setFieldValue, value, index);
                                                        }}
                                                        value={field?.value}
                                                        format="hh:mm A"
                                                        suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                      />
                                                    )}
                                                  </Field>

                                                  <ErrorMessage name={`timeParameter[${index}].timeFrom`} component="div" className="error-message" />
                                                </div>
                                                <div className="w-[50%]">
                                                  <Field name={`timeParameter[${index}].timeTo`}>
                                                    {({ field }: FieldProps<any>) => (
                                                      <TimePicker
                                                        use12Hours
                                                        onChange={(value, dateString) => {
                                                          handleOnChange("timeTo", dateString, setFieldValue, value, index);
                                                        }}
                                                        value={field?.value}
                                                        format="hh:mm A"
                                                        suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                      />
                                                    )}
                                                  </Field>
                                                  <ErrorMessage name={`timeParameter[${index}].timeTo`} component="div" className="error-message" />
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="flex w-full">
                                                  <div className="mr-2 w-1/2">
                                                    <Field name={`timeParameter[${index}].timeFrom`} className=" mr-2">
                                                      {({ field }: FieldProps<any>) => {
                                                        return (
                                                          <TimePicker
                                                            onChange={(value, dateString) => {
                                                              handleOnChange("timeFrom", dateString, setFieldValue, value, index);
                                                            }}
                                                            use12Hours
                                                            format="hh:mm A"
                                                            suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                            value={field?.value}
                                                          />
                                                        );
                                                      }}
                                                    </Field>

                                                    <ErrorMessage name={`timeParameter[${index}].timeFrom`} component="div" className="error-message" />
                                                  </div>
                                                  <div className="w-1/2">
                                                    <Field name={`timeParameter[${index}].timeTo`}>
                                                      {({ field }: FieldProps<any>) => (
                                                        <TimePicker
                                                          onChange={(value, dateString) => {
                                                            handleOnChange("timeTo", dateString, setFieldValue, value, index);
                                                          }}
                                                          suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                          use12Hours
                                                          value={field?.value}
                                                          format="hh:mm A"
                                                        />
                                                      )}
                                                    </Field>
                                                    <ErrorMessage name={`timeParameter[${index}].timeTo`} component="div" className="error-message" />
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex absolute right-[-70px] top-7 justify-center items-center">
                                        {index !== values.timeParameter.length - 1 && (
                                          <span
                                            className={`${values.timeParameter.length === 1 ? "pointer-events-none " : ""} material-symbols-outlined add-custom ip remove cursor-pointer`}
                                            onClick={() => handleRemoveList(index, remove, values.timeParameter[index])}
                                          >
                                            remove
                                          </span>
                                        )}

                                        {index === values.timeParameter.length - 1 && (
                                          <span className="material-symbols-outlined cursor-pointer add-custom ip" onClick={() => push({ timeTo: "", timeFrom: "", timezone: "", index: index })}>
                                            add
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </>
                              );
                            }}
                          </FieldArray>
                          {/* end */}
                          {!navigatedFrom && (
                            <div className="lg:mb-11 lg:w-[98%] w-[100%] relative">
                              <div className="lg:w-[46%] md:w-[100%]">
                                <p className="font-Inter text-[#1D1D24] font-medium text-[18px]">Assign to*</p>
                                <Radio.Group value={values?.assignTo} className="pt-3 flex" name="assignTo" onChange={(e: any) => timeParamAssigningTo(e.target.value, setFieldValue)}>
                                  <Radio value={"organization"}>
                                    <div className="flex flex-col mr-5">
                                      <p className="user-type ">Organization</p>
                                    </div>
                                  </Radio>
                                  <Radio value="groups">
                                    <div className="flex  flex-col mr-5">
                                      <p className="user-type ">Groups</p>
                                    </div>
                                  </Radio>
                                  <Radio value="users">
                                    <div className="flex  flex-col">
                                      <p className="user-type ">Users</p>
                                    </div>
                                  </Radio>
                                </Radio.Group>
                              </div>
                              <ErrorMessage name="assignTo" component="div" className="error-message" />
                            </div>
                          )}
                        </div>
                        <div className="mt-[7px] pb-[13px] flex justify-end w-full foot mr-4">
                          <CustomButtonBack text={"Cancel"} onClick={handleOpenConfirmationModal} />
                          <Button htmlType="submit" type="primary" className="bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px] btn ">
                            {values?.assignTo === "organization" || navigatedFrom ? "Save" : "Next"}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          ) : (
            <>
              <div className="ip-container">
                <div className="mx-auto selectIpEdit">
                  <Formik initialValues={initialValues} validationSchema={validationSchemaForTimeRestriction} onSubmit={onSubmit} enableReinitialize={true}>
                    {({ handleSubmit, setFieldValue, values }) => {
                      return (
                        <Form
                          onSubmit={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          <div className="w-full flex pt-0 relative">
                            <div className="flex w-full  flex-col">
                              <div className="flex w-[70%] ml-8 justify-between mb-8">
                                <div className="relative w-[48%]">
                                  <p className="text-[#1D1D24] font-medium text-[18px] font-Inter">Name*</p>
                                  <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="name" maxLength={30} />
                                  <ErrorMessage component="div" name="name" className="error-message" />
                                </div>
                                <div className="relative w-[48%]">
                                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Descriptions*</p>
                                  <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="description" maxLength={60} />
                                  <ErrorMessage component="div" className="error-message" name="description" />
                                </div>
                              </div>

                              {/* start */}
                              <div className=" w-[70%] ml-8">
                                <FieldArray name="timeParameter">
                                  {({ remove, push }) => {
                                    return (
                                      <>
                                        {values?.timeParameter?.map((field: any, index: any) => (
                                          <div className="w-full relative" key={index}>
                                            <div className="w-full flex justify-between relative flex-wrap">
                                              <div className="mb-4 lg:mb-11 w-[100%] lg:w-[48%] md:w-[100%] zonesList">
                                                <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>Select Zone*</p>
                                                <Field
                                                  name={`timeParameter[${index}].timezone`}
                                                  showSearch
                                                  as={Select}
                                                  onChange={(value: string) => onChangingZone(value, setFieldValue, index)}
                                                  placeholder="Select Zone"
                                                  optionFilterProp="children"
                                                  className="w-full h-[48px] font-medium text-[18px]"
                                                  filterOption={(input: any, option: any) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                                  filterSort={(optionA: any, optionB: any) => optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())}
                                                >
                                                  {zonesList?.map((option: any) => (
                                                    <Select.Option value={option?.label} key={option?.label}>
                                                      {option?.label}
                                                    </Select.Option>
                                                  ))}
                                                </Field>
                                                <ErrorMessage name={`timeParameter[${index}].timezone`} className="error-message" component="div" />
                                              </div>
                                              <div className="mb-4 lg:mb-11 w-[100%] lg:w-[48%]">
                                                <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>
                                                  Select Range* (Start Time & End Time)
                                                </p>
                                                <div className="rangePicker">
                                                  {Object?.keys(savedPayloadForTimeParam)?.length > 0 && savedPayloadForTimeParam ? (
                                                    <div className="w-full flex">
                                                      <div className="w-1/2 pr-2">
                                                        <Field name={`timeParameter[${index}].timeFrom`} className="mr-2">
                                                          {({ field }: FieldProps<any>) => (
                                                            <TimePicker
                                                              format="hh:mm A"
                                                              use12Hours
                                                              onChange={(value, dateString) => {
                                                                handleOnChange("timeFrom", dateString, setFieldValue, value, index);
                                                              }}
                                                              suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                              value={field?.value}
                                                            />
                                                          )}
                                                        </Field>
                                                        <ErrorMessage name={`timeParameter[${index}].timeFrom`} className="error-message" component="div" />
                                                      </div>
                                                      <div className="w-[50%]">
                                                        <Field name={`timeParameter[${index}].timeTo`}>
                                                          {({ field }: FieldProps<any>) => (
                                                            <TimePicker
                                                              format="hh:mm A"
                                                              use12Hours
                                                              onChange={(value, dateString) => {
                                                                handleOnChange("timeTo", dateString, setFieldValue, value, index);
                                                              }}
                                                              suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                              value={field?.value}
                                                            />
                                                          )}
                                                        </Field>
                                                        <ErrorMessage name={`timeParameter[${index}].timeTo`} className="error-message" component="div" />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <>
                                                      <div className="w-full flex">
                                                        <div className="w-1/2 mr-2">
                                                          <Field name={`timeParameter[${index}].timeFrom`} className="mr-2">
                                                            {({ field }: FieldProps<any>) => {
                                                              return (
                                                                <TimePicker
                                                                  use12Hours
                                                                  format="hh:mm A"
                                                                  suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                                  value={field?.value}
                                                                  onChange={(value, dateString) => {
                                                                    handleOnChange("timeFrom", dateString, setFieldValue, value, index);
                                                                  }}
                                                                />
                                                              );
                                                            }}
                                                          </Field>

                                                          <ErrorMessage name={`timeParameter[${index}].timeFrom`} component="div" className="error-message" />
                                                        </div>
                                                        <div className="w-1/2">
                                                          <Field name={`timeParameter[${index}].timeTo`}>
                                                            {({ field }: FieldProps<any>) => (
                                                              <TimePicker
                                                                value={field?.value}
                                                                onChange={(value, dateString) => {
                                                                  handleOnChange("timeTo", dateString, setFieldValue, value, index);
                                                                }}
                                                                suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                                format="hh:mm A"
                                                                use12Hours
                                                              />
                                                            )}
                                                          </Field>
                                                          <ErrorMessage name={`timeParameter[${index}].timeTo`} className="error-message" component="div" />
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex justify-center items-center absolute right-[-130px] top-7">
                                              <span
                                                className={`${values.timeParameter.length === 1 ? "pointer-events-none " : ""}cursor-pointer material-symbols-outlined add-custom ip remove`}
                                                onClick={() => handleRemoveList(index, remove, values.timeParameter[index])}
                                              >
                                                remove
                                              </span>
                                              {index === values.timeParameter.length - 1 && (
                                                <span
                                                  className="ip material-symbols-outlined add-custom cursor-pointer"
                                                  onClick={() => {
                                                    setIsNewRowAdded(true);
                                                    push({ timeTo: "", timeFrom: "", timezone: "", index: "" });
                                                  }}
                                                >
                                                  add
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                              {/* end */}

                              <div className="col-end-4 selectIP relative  flex justify-end mt-8">
                                <Button htmlType="submit" className="btn bg-[#5441DA] w-[400px] h-[55px] text-[20px]" type="primary">
                                  {isActiveEdit ? "Update" : "Save"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                {type !== "organization" && (
                  <div className="assignedTable-container">
                    <h3>Assigned to </h3>
                    <div className="table-search">
                      <div className="w-[35%]">
                        {type === "groups" ? (
                          <Select
                            className="h-14 mb-5 w-full custom-dropdown"
                            placeholder={"Search Groups"}
                            tokenSeparators={[","]}
                            onChange={handleChangeGroups}
                            onDropdownVisibleChange={(canVisible) => setDropdownVisibleForTime(canVisible)}
                            open={dropdownVisibleForTime}
                            optionLabelProp="label"
                            filterOption={selectFilterProcessFunction}
                            dropdownRender={renderingUserSearch}
                            mode="tags"
                          >
                            {userSearchGroupDataForTime?.map((element: any) => (
                              <Option key={element?.value} value={element?.value} label={element?.name}>
                                <input
                                  name={element?.name}
                                  id={element?.value}
                                  checked={selectedValues.includes(element?.value)}
                                  onChange={(e) => handleLabelClick(e, element?.value)}
                                  type="checkbox"
                                  className="w-5 dropdown-list"
                                />
                                <label htmlFor={element?.value} className="flex flex-col h-14 justify-center pointer-events-none">
                                  {element?.name} <span className="absolute text-[#747577]  top-[32px]">{element?.description}</span>
                                </label>
                              </Option>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            className="h-14 w-full mb-5 custom-dropdown"
                            mode="tags"
                            placeholder={"Search Users"}
                            tokenSeparators={[","]}
                            optionLabelProp="label"
                            open={dropdownVisibleForTime}
                            dropdownRender={renderingUserSearch}
                            onDropdownVisibleChange={(visible) => setDropdownVisibleForTime(visible)}
                            onChange={handleChangeUsersForTime}
                            filterOption={selectFilterProcessFunction}
                          >
                            {usersSearchListForTime?.map((item: any) => (
                              <Option key={item?.value} value={item?.value} label={item?.name}>
                                <input
                                  name={item?.name}
                                  id={item?.value}
                                  checked={selectedValues?.includes(item?.value)}
                                  onChange={(e) => handleLabelClick(e, item?.value)}
                                  type="checkbox"
                                  className="dropdown-list w-5"
                                />
                                <label htmlFor={item?.name} className="h-14  flex flex-col justify-center pointer-events-none">
                                  {item?.name} <span className="top-[32px] absolute text-[#747577]">{item?.email}</span>
                                </label>
                              </Option>
                            ))}
                          </Select>
                        )}
                      </div>
                    </div>
                    <div className="assignedTable ">
                      <div className="device-restriction flex">
                        <p className="assignedTable-totalGroup">{`Total No. of ${type} ${type === "groups" ? groupDataForTime?.length : listOfUsersForTime?.length} `}</p>
                        {selectedRowKeys?.length > 0 && (
                          <p className={`ml-[55px] users-clear `} onClick={unassignUserForTime}>
                            {type === "groups" ? "Unassign Group(s)" : "Unassign user(s)"}
                          </p>
                        )}
                      </div>
                      <Table
                        loading={loader}
                        rowSelection={rowSelection}
                        columns={type === "groups" ? groupColumns : columns}
                        dataSource={type === "groups" ? groupDataForTime : listOfUsersForTime}
                        pagination={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {openConfirmationModal && <CloseConfirmationModal closeModalOpen={openConfirmationModal} handleProceed={returnToBack} handleCancel={closeConfirmationModal} />}
      {atLeastOneModalForTime && (
        <GroupAndUserNecessaryModal handleCloseNecessaryModal={handleCloseNecessaryModalForTime} openNecessaryModal={atLeastOneModalForTime} type={type === "groups" ? "group" : "user"} />
      )}
      {timerSuccessModal && <IpTimeSuccessModal open={timerSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
};

export default TimeParameters;
