import { APIConfigURLs } from "../apiInterfaceType/apiInterfaceType";

const DELETE_API_URL: APIConfigURLs = {
  deleteUser: {
    url: "/v1/user",
  },
  deleteAppList: {
    url: "/v1/client",
  },
  deleteGroupList: {
    url: "/v1/group/",
  },
  deleteLDAPConnection: {
    url: "/v1/component",
  },
  deletePermission: {
    url: "/v1/role",
  },
  deleteIpRestriction: {
    url: "/v1/ip-restriction",
  },
  deleteTimeRestriction: {
    url: "/v1/user/time-based-restriction",
  },
  deleteAttribute:{
    url:'v1/component/mappers'
  }
};

export default DELETE_API_URL;
