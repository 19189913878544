import React, { useState, useEffect } from "react";
import { notification, Upload, Modal } from "antd";
import { UploadFile, RcFile } from "antd/lib/upload/interface";
import ImageView from "./ImageView";
import { images } from "../../assets/images/ImagePaths";

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ImageUpload = ({
  imageUrl,
  onImageUpload,
  onImageDelete,
  maxFileSize, // Maximum file size in bytes
  allowedTypes, // An array of allowed file types, e.g., ['image/jpeg', 'image/png', 'application/pdf']
  multiple = false,
  className,
}: {
  imageUrl?: string;
  onImageUpload: (file: File) => void;
  onImageDelete: (file: File) => void;
  maxFileSize: number;
  allowedTypes: string[];
  multiple?: boolean;
  className: any;
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      const parts = imageUrl?.split("/"); // This splits the string into an array of parts
      const fileName = parts[parts?.length - 1]; // This selects the last part of the array

      setFileList([
        {
          uid: "-1",
          name: fileName,
          status: "done",
          url: imageUrl,
        },
      ]);
    }
  }, [imageUrl]);

  const handleCancel = () => setPreviewOpen(false);

  const handleRemove = (file: UploadFile) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
    onImageDelete(file.originFileObj as File);
  };
  return (
    <>
      <Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture-card"
        fileList={fileList}
        className={className}
        onPreview={(e) => handlePreview(e, setPreviewImage, setPreviewOpen, setPreviewTitle)}
        onChange={(e) => handleChange(e, maxFileSize, setFileSizeExceeded, allowedTypes, setFileList, onImageUpload, multiple)}
        onRemove={handleRemove}
        multiple={multiple}
      >
        {!multiple && fileList.length >= 1 ? null : (
          <div className="image-upload justify-center items-center flex-wrap p-4">
            <div className="upload-document-image flex justify-center">
              <p className="  leading-[0px]">
                <ImageView src={images.uploadImg} className="w-10 h-10 img-upload" />
                <span className="ml-2"> </span>
              </p>
            </div>
            {allowedTypes[0] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <p> Only xlsx files.</p>
            ) : (
              <p className="text-[12px]"> Only {`${allowedTypes.map((type) => type.split("/")[1].toUpperCase())}`} files. </p>
            )}
          </div>
        )}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} maskClosable={false}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      {fileSizeExceeded && (
        <div className="file-size-warning">
          <p className="error-message">File size exceeds the maximum allowed size of {maxFileSize / (1024 * 1024)} MB.</p>
        </div>
      )}
    </>
  );
};
export const handlePreview = async (file: UploadFile, setPreviewImage: any, setPreviewOpen: any, setPreviewTitle: any) => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj as RcFile);
  }
  setPreviewImage(file.url ?? (file.preview as string));
  setPreviewOpen(true);
  setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
};
export const handleChange = (
  { fileList: newFileList }: { fileList: UploadFile[] },
  maxFileSize: any,
  setFileSizeExceeded: any,
  allowedTypes: any,
  setFileList: any,
  onImageUpload: any,
  multiple: any,
) => {
  const filteredFileList: any = newFileList.filter((file: any) => {
    const fileSize = file?.originFileObj?.size;
    if (fileSize === null) return false;
    if (fileSize > maxFileSize) {
      setFileSizeExceeded(true);
      return false;
    } else setFileSizeExceeded(false);

    if (!allowedTypes.includes(file?.type)) {
      let allowedFileTypesString;
      if (allowedTypes[0] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        allowedFileTypesString = `Only xlsx files are allowed.`;
      } else {
        const typeNames = allowedTypes.map((type: any) => type.split("/")[1].toUpperCase());
        allowedFileTypesString = `Only ${typeNames.join(", ")} files are allowed.`;
      }
      notification.error({
        message: "Invalid File Type",
        description: allowedFileTypesString,
      });
      return false;
    }
    return true;
  });
  setFileList(filteredFileList);
  if (filteredFileList.length > 0) {
    if (multiple) onImageUpload(filteredFileList as File);
    else onImageUpload(filteredFileList[0]?.originFileObj as File);
  }
};
export default ImageUpload;
