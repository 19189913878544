import { Table, TableProps } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../layouts/component/SearchField";
import { debounce } from "lodash";
import AddEditDeviceRestriction from "./AddEditDeviceRestriction";
import "./deviceRestriction.scss";

export default function DeviceRestriction() {
  const navigate = useNavigate();
  const [search, setSearch] = useState<any>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const commonRender = (text: any) => (
    <div>
      <p className={`${text?.toLowerCase()} user-status text-[#545263] `}>{text || "-"}</p>
    </div>
  );
  const columns: TableProps["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "20%",
      render: commonRender,
    },
  ];

  const data: any[] = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      name: `Edward King ${i}`,
      department: "Sales",
      group: "A",
      status: "Active",
    });
  }

  const handleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    setSearch(searchValue);
  }, 100);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-auto rounded device-restriction groups">
          <ul className="flex breadcrumb">
            <li
              className="font-Inter pr-1 cursor-pointer"
              onClick={() => {
                navigate("/user");
              }}
            >
              Access Manager /
            </li>
            <li className="font-Inter pr-1 active">Device Restriction </li>
          </ul>
          <div className="w-full flex justify-between">
            <p className="text-[#171717] text-2xl font-Inter font-bold pb-3 pt-2 flex items-center">Edit - Device Restriction</p>
            {/* <Tooltip title={"Create Group"}>
                            <Button type="primary" className="w-20 h-[56px] bg-[#5441DA] groups-btn" >
                                <span className="material-symbols-outlined">add </span>
                            </Button>
                        </Tooltip> */}
            <p className="highlightText">Assigned to: Groups</p>
          </div>
          <AddEditDeviceRestriction />
          <p className="text-[#171717] text-2xl font-Inter font-bold pb-3 flex items-center mb-6">Assigned ( # of users)</p>
          <SearchInput search={search} placeholder="Search" onChange={handleSearch} className="deviceRestriction-search" />
          <div className="w-full pt-10 deviceRestriction">
            <Table
              // pagination={{

              //     showSizeChanger: true,
              //     pageSizeOptions: prerequisitesTable.pageSizeOptions,
              //     defaultPageSize: sizeChanger,
              //     pageSize: sizeChanger,
              //     onChange: handlePageSizeChange,
              // }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
