import { Modal } from "antd";
import CustomButtonBack from "../../component/CustomButtonBack";
import CustomButtonNext from "../../component/CustomButton";
import logoutIcon from "./../../../assets/images/icons/logoutIcon.png";
import ImageView from "../../component/ImageView";

const LogoutModal = (props: any) => {
  const { handleModalClose, open, logout } = props;

  const handleRequestApi = () => {
    logout();
  };
  return (
    <Modal className="p-10  show-notification  logoutModal rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleModalClose}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <div className="w-20 h-20">
            <ImageView alt="notificationDone" src={logoutIcon} />
          </div>
          <h5 className=" font-Inter text-[24px] pt-3 font-semibold"> Logout</h5>
          <p className="text-[20px] text-center font-Inter font-medium text-[#475467]">Are you sure you want to logout?</p>
        </div>
      </div>
      <div className="modal-footer pt-9">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleModalClose} text={"Cancel"} />
          <CustomButtonNext onClick={handleRequestApi} text={"Yes"} />
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
