import { Input, Select } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../../../../../../layouts/component/CustomButtonBack";
import SubmitButton from "../../../../../../../../layouts/component/SubmitButton";
import { validationSchema } from "../../../../adduser/form/validationSchema/basicFormValidationSchema";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../../../../services/storage/Storage";
import Loader from "../../../../../../../../layouts/component/Loader";
import { getRolesList } from "../../../../adduser/usertable/CustomTableUtils";

const BasicDetailsForm = (props: any) => {
  const { setCurrentStep, initialValues, setInitialValues, handleCloseModal, isEdit } = props;
  const roleList = useGetApiRequests("getRolesList", "GET");
  const [isFormDirty, setFormDirty] = useState(false);
  const [loader, setLoader] = useState(false);
  const realmId = retrieveData("realmId", true);
  const [roleDropDownList, setRoleDropDownList] = useState<any>([]);
  const [selectedRole, setSelectedRole] = useState("User");
  const getRoles = async () => {
    setLoader(true);
    const reformedRoleList: any = await getRolesList(realmId, roleList);
    const user = { label: "User", value: "User" };
    reformedRoleList?.push(user);
    setRoleDropDownList(reformedRoleList);
    setLoader(false);
    const rolesInInitialValue = reformedRoleList?.find((item: any) => item?.value === initialValues?.userType);
    if (rolesInInitialValue && Object.keys(rolesInInitialValue).length > 0) {
      setSelectedRole(rolesInInitialValue?.value);
    }
  };
  useEffect(() => {
    getRoles();
  }, []);
  const onSubmit = (values: any) => {
    setInitialValues({
      ...initialValues,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      userType: values.userType,
    });
    setCurrentStep(2);
  };
  const handleToBack = () => {
    handleCloseModal();
  };
  const onChange = (value: string, setFieldValue: any) => {
    setSelectedRole(value);
    setFieldValue("userType", value);
  };
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize={true} isInitialValid={isFormDirty}>
        {({ handleSubmit, errors, setFieldValue, dirty }) => (
          <Form
            onSubmit={(e) => {
              setFormDirty(true);
              handleSubmit(e);
            }}
          >
            <div className="w-full flex pt-[63px] flex-col edit-basic single-user pt-7 mb-6">
              <div className="w-[80%]  flex mx-auto justify-between flex-wrap _edit">
                <div className="mb-10 w-[40%] input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">First Name*</p>
                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="firstName" maxLength={30} data-testid='fnameId' />
                  <ErrorMessage name="firstName" component="div" className="error-message" />
                </div>
                <div className="mb-10 w-[40%] -form input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Last Name*</p>
                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="lastName" maxLength={30} data-testid='lnameId' />
                  <ErrorMessage name="lastName" component="div" className="error-message" />
                </div>
                <div className="mb-10 w-[40%] input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Work Email ID*</p>
                  <Field as={Input} type="email" readOnly={isEdit || ""} className={`${isEdit ? "cursor-not-allowed" : ""} h-[56px] font-medium text-[18px]`} name="email" data-testid='emailId'/>
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>
                <div className="mb-10 w-[40%] -form input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium" data-testid='uTypeId'>User Type*</p>
                  <Field
                    value={selectedRole}
                    as={Select}
                    showSearch
                    onChange={(value: string) => onChange(value, setFieldValue)}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    className=" w-[100%] h-[56px]"
                    name="userType"
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {roleDropDownList?.map((option: any) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name="userType" component="div" className="error-message" />
                </div>
              </div>
            </div>

            <div className={` bg-[#F5F5FA] modal-footer mt-[5rem] h-[100px] flex items-center absolute bottom-0 pr-[30px] w-[100%] mx-auto`}>
              <div className="w-full flex justify-end">
                <CustomButtonBack onClick={handleToBack} text={"Cancel"} />
                <SubmitButton text={isEdit ? "Update" : "Next"} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {loader && <Loader />}
    </>
  );
};

export default BasicDetailsForm;
