import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import "./SearchAppModal.scss";
import { useDispatch } from "react-redux";
import { setGroupAppList } from "../../../../../../redux/slice/GroupSlice";

const { Option } = Select;

const SearchAppsModal = (props: any) => {
  const { handelClose, isOpen, setListOfApps, listOfApps, setSelectedValues, selectedValues, appList, tempSelectedApps, setTempSelectedApps, setDisableButton } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [appsDeleted, setAppsDeleted] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleCloseModal = () => {
    handelClose();
    if (listOfApps?.length === 0) {
      setSelectedValues([]);
    }
  };
  const handleChange = (value: any) => {
    setTempSelectedApps(value);

    if (value.length < listOfApps?.length) {
      setAppsDeleted(true);
    }
  };

  const handleAddApps = () => {
    setSelectedValues(tempSelectedApps);
    const selectedObjects = appList.filter((item: any) => tempSelectedApps.includes(item.value));
    if (appsDeleted) {
      setListOfApps(selectedObjects);
      dispatch(setGroupAppList(selectedObjects));
    } else {
      const storeListOfApps = listOfApps && listOfApps.length > 0 ? listOfApps : [];
      const mergedArray = [...storeListOfApps, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value))).map((value) => {
        return mergedArray.find((obj) => obj.value === value);
      });
      setListOfApps(uniqueMergedArray);
      dispatch(setGroupAppList(selectedObjects));
    }
    setDisableButton(false);
    setDropdownVisible(false);
    handelClose();
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Modal className=" searchApps add-apps rounded-lg " maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className=" py-[25px] edit-user-modal ">
        <h5 className=" text-center font-Inter font-semibold pb-[10px] text-[#171717] text-[22px]">Select apps to add in the groups</h5>
      </div>
      <div className=" pb-2  modal-body px-8 pt-8 ">
        <Select
          className={`${selectedValues?.length > 5 ? "h-28" : "h-14"} w-full`}
          mode="tags"
          tokenSeparators={[","]}
          optionLabelProp="label"
          open={dropdownVisible}
          placeholder="Search"
          onChange={handleChange}
          onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
          filterOption={filterOption}
          defaultValue={selectedValues}
        >
          {
            appList?.map((option: any) => (
              <Option key={option.value} label={option.label} value={option.value} >
                <label htmlFor={option.label}>{option.label}</label>
              </Option>
            ))}
        </Select>
      </div>
      <div className="modal-footer px-8 pt-10">
        <div className="w-full flex justify-center ">
          <CustomButtonBack text={"Cancel"} onClick={handleCloseModal}  />
          <CustomButtonNext text={"Add"} onClick={handleAddApps}  />
        </div>
      </div>
    </Modal>
  );
};

export default SearchAppsModal;
