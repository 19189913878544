import { APIConfigURLs } from "../apiInterfaceType/apiInterfaceType";

const POST_API_URL: APIConfigURLs = {
  login: {
    url: "/v1/auth/login",
    baseURL: "/keycloak-integration-api",
  },
  dashboardAll: {
    url: "/v1/dashboard/widgets/all",
  },
  dashboardCustomer: {
    url: "/v1/dashboard/widgets/customer",
  },
  dashboardRiskAssessment: {
    url: "v1/dashboard/widgets/risk-assessment",
  },
  singleUsers: {
    url: "v1/user",
  },
  masterAvailableApps: {
    url: "/v1/master-client/list",
  },
  createAppConfiguration: {
    url: "/v1/client",
  },
  tokenValidation: {
    url: "/v1/admin/token",
  },
  userLogin: {
    url: "v1/user/login",
  },
  userManagement: {
    url: "/v1/user-management/list",
  },
  createLDAP: {
    url: "/v1/component",
  },
  testConnection: {
    url: "/v1/component/realm",
  },
  syncDetails: {
    url: "/v1/user/sync",
  },
  singleUserSync: {
    url: "v1/user-management/sync-accounts",
  },
  reGenerateKey: {
    url: "v1/client/secret-key",
  },
  createCustomer: {
    url: "v1/customer",
  },
  validateOtp: {
    url: "v1/user/validate-otp",
  },
  validateForgetPasswordOtp: {
    url: "v1/user/validate-otp",
  },

  resendOtp: {
    url: "/v1/otp/send",
  },
  LDAPMapper: {
    url: "v1/component/realm",
  },
  logoutSessionKill: {
    url: "v1/user/logout",
  },
  deviceBasedRestrictionForSingleUser: {
    url: "/v1/user/device-restriction/send-email",
  },
  deviceBasedMultipleUser: {
    url: "/v1/user/device-restriction/send-email-multi-user",
  },
  deviceRestriction: {
    url: "/v1/user-device-restriction",
  },
  groupCreation: {
    url: "v1/group",
  },
  assignUsersToGroup: {
    url: "/v1/group/user/assign",
  },
  unAssignUsersFromGroup: {
    url: "/v1/group/user",
  },
  customerOnBoardingCreation: {
    url: "/v1/customer/detail",
  },
  customerOnBoardingDomainVerifiaction: {
    url: "/v1/customer/domain/verify",
  },
  createIpBasedRestriction: {
    url: "/v1/ip-restriction",
  },
  getParticularIpRestriction: {
    url: "/v1/user/access-manager/ip-restrict/details",
  },
  createTimeBasedRestriction: {
    url: "/v1/user/time-based-restriction",
  },
  groupSync: {
    url: "/v1/component/user/group/sync",
  },
  //user post api urls
  sendOtp: {
    url: "v1/user/send-otp",
  },
  getUserName: {
    url: "v1/user/get-username",
  },
  setPassword: {
    url: "v1/user/set-password",
  },
  forgotUserName: {
    url: "v1/user/forgot-username",
  },
  getMapperAttributes: {
    url: "v1/component/attribute",
  },
  addMappers: {
    url: "v1/component/realm",
  },
  syncMapperAttributes: {
    url: "v1/component/user/sync",
  },
  getAvailableRolesList: {
    url: "v1/role/realm",
  },
  savePermissions: {
    url: "v1/role/realm",
  },
  userCSVImport: {
    url: "v1/user/csv/import",
  },
  generateJarFile:{
    url: "/v1/customer/device-restriction/jar-file",

  },
  createKerberosDirectory:{
    url: "v1/component/kerberos",

  }

};
export default POST_API_URL;
