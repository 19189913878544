import { MANAGER_PASSWORD } from "./ModulePermissionConst";

export const menuList = {
  smartAnalytics: "Smart Analytics",
  userManagement: "User Management",
  appManagement: "App Management",
  roleManagement: "Role Management",
  accessManager: "Access Manager",
  credentialManagement: "Credential Management",
  passwordManager: MANAGER_PASSWORD,
};
