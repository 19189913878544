import React from "react";
import "./Register.scss";
import RegisterForm from "./new-register/RegisterForm";
const NewRegister = () => {
  return (
    <>
      <div className="w-full flex new">
        <div className="w-[60%]" data-testid="register-form">
          <div className="new-registration"></div>
        </div>
        <div className="w-[40%]">
          <RegisterForm />
        </div>
      </div>
    </>
  );
};

export default NewRegister;
