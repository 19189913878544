import { Modal } from "antd";
import React from "react";
import ImageView from "../../component/ImageView";
import popupBlocked from "./../../../assets/images/popupBlock.png";
import "./popupBlockModal.scss";

export default function PopupBlockModal({ open, handleModalClose }: any) {
  return (
    <Modal className="rounded-lg popupBlockModal" maskClosable={false} centered open={open} footer={false} onCancel={handleModalClose}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <div className="w-[50%]">
            <ImageView alt="notificationDone" src={popupBlocked} />
          </div>
          <h2 className="text-[24px] font-Inter  pt-3 font-semibold">Pop-up has been blocked.</h2>
          <p className="content">Please allow pop-ups to log out. </p>
        </div>
      </div>
    </Modal>
  );
}
