import React from "react";
import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";
import { Tooltip } from "antd";

const ConnectorCard = () => {
  return (
    <>
      <div className="w-[33%]">
        <div className="connector-card">
          <div className="connector-header ">
            <div className="w-full h-full flex items-center justify-center">
              <div className="w-11 ">
                <ImageView src={images.link} alt='Akku_ConnectorImage' className="pt-1" />
              </div>
              <p className="card-title">Akku_Connector</p>
            </div>
          </div>
          <Tooltip title={"dd"}>
            <p className="px-[14px] pt-[14px] description">
              Lorem xdssdcff dsd cfsd cfds csd sdcsd dsc fsd csdc sd csd c sfd sdfs dfffd sfdsfdsfsd ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.
            </p>
          </Tooltip>

          <p className="flex items-center px-[14px]">
            <ImageView src={images.greenCheck} alt='connectedImage' className="pt-1 pr-2" /> Connected
          </p>
        </div>
      </div>
    </>
  );
};

export default ConnectorCard;
