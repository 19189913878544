import React from "react";
import { useAccessManager } from "../context/AccessManagerContext";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import DeviceAuthorizationModal from "../../dashboard/user/user-dashboard/adduser/device-authorization/DeviceAuthorizationModal";
import { useNavigate } from "react-router-dom";

interface ListItem {
  id: string;
  name: string;
  description: string;
}
export default function CreateLists() {
  const navigate = useNavigate();
  const { closeCreateAccessManager, openWhiteListing, closeWhiteListing, isActiveWhiteListModal } = useAccessManager();
  const list: ListItem[] = [
    {
      id: "1",
      name: "Select IP",
      description: "Specify what IP can access your data",
    },
    {
      id: "2",
      name: "Whitelist Device/s",
      description: "Bind your device/s to your data",
    },
    {
      id: "3",
      name: "Set Time Parameters",
      description: "Stipulate what time your data can be accessed",
    },
  ];

  const handleOpenList = (el: ListItem) => {
    closeCreateAccessManager();
    if (el.name === "Select IP") {
      navigate("/access-manager/select-ip");
    } else if (el.name === "Set Time Parameters") {
      navigate("/access-manager/time-parameters");
    } else {
      openWhiteListing();
    }
  };

  const handelClose = () => {
    closeWhiteListing();
  };
  return (
    <>
      <div className="accessManagerModalContent">
        <div className="create-list pt-12 pb-7">
          {list.map((item) => (
            <div className="list-items flex justify-between items-center" key={item.id} onClick={() => handleOpenList(item)}>
              <div className="list-item--content">
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
              <div className="list-item--arrow">
                <span className="material-icons-outlined  text-[#1C1B1F]">expand_more</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end">
          <CustomButtonBack onClick={closeCreateAccessManager} text={"Cancel"} />
        </div>
      </div>
      {isActiveWhiteListModal && <DeviceAuthorizationModal handelClose={handelClose} from={"access-manager"} />}
    </>
  );
}
