import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../../../layouts/breadcrumb/Breadcrumb.scss";

const AccessManagerBreadcrumbs = ({ isEdit }: any) => {
  const navigate = useNavigate();

  const handleNavigateAccessManager = () => {
    navigate("/access-manager");
  };
  return (
    <>
      {!isEdit ? (
        <ul className="breadcrumb flex">
          <li className="cursor-pointer font-Inter pr-1" onClick={handleNavigateAccessManager}>
            Access Manager /
          </li>
          <li className="font-Inter active">Device level</li>
        </ul>
      ) : (
        <ul className="breadcrumb flex">
          <li className="cursor-pointer font-Inter pr-1" onClick={handleNavigateAccessManager}>
            Access Manager /
          </li>
          <li className="font-Inter active">Device Restriction</li>
        </ul>
      )}
    </>
  );
};

export default AccessManagerBreadcrumbs;
