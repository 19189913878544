import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const AdminHeaderHeader = () => {
  const navigate = useNavigate();

  const handleEditAdminDetails = () => {
    navigate("/edit-tenant");
  };
  return (
    <div className="flex w-full edit-admin absolute  z-10 hidden">
      <p>If you want to edit the details please click the button to edit.</p>

      <Button
        type="primary"
        className=" ml-4 flex items-center justify-evenly font-semibold text-[18px] font-Inter w-[115px] h-[40px] bg-[#5441DA] text-white rounded-full"
        size="large"
        onClick={handleEditAdminDetails}
      >
        Details
      </Button>
    </div>
  );
};

export default AdminHeaderHeader;
