import { Modal } from "antd";
import { ErrorMessages } from "../../const/Messages";
import { apiResponseMessage } from "../../constant/apiResponseMessage";
import { triggerNotification } from "./ToastBar";

export const handleNotFoundError = (errorMessage: string): void => {
  triggerNotification("error", "", errorMessage, "topRight");
};
export const handleDefaultError = (): void => {
  triggerNotification("error", "", "Unexpected error", "topRight");
};
export const handleRequestError = (err: any): void => {
  switch (err?.response?.status) {
    case 404:
    case 400:
    case 409:
      handleNotFoundError(err?.response?.data?.message || apiResponseMessage.tryAging);
      break;
    case 401:
      Modal.error({
        title: "Session Expired",
        content: ErrorMessages?.sessionExpired,
        centered: true,
        maskClosable: false,
        onOk: () => {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = "/";
        },
      });
      break;
    default:
      handleNotFoundError(apiResponseMessage.tryAging);
      break;
  }
};
