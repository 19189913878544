import React from "react";
import "./accessManager.scss";
import { AccessManagerContextProvider } from "./context/AccessManagerContext";
import AccessManagerContainer from "./layout/AccessManagerContainer";

export default function AccessManager() {
  return (
    <AccessManagerContextProvider>
      <AccessManagerContainer />
      {/* <DeviceRestriction /> */}
    </AccessManagerContextProvider>
  );
}
