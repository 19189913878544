import { Modal, Radio, Space, RadioChangeEvent } from "antd";
import React, { useEffect, useState } from "react";
import "./DeviceAuthorization.scss";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import ImageView from "../../../../../../layouts/component/ImageView";
import { images } from "../../../../../../assets/images/ImagePaths";
import { useNavigate } from "react-router-dom";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../../../../../layouts/component/Loader";
import { useDispatch } from "react-redux";
import { useAccessManager } from "../../../../../access-manager/context/AccessManagerContext";
import { retrieveData } from "../../../../../../services/storage/Storage";
import { setDeviceRestrictionList } from "../../../../../../redux/slice/DeviceRestrictionSlice";

const DeviceAuthorizationModal = (props: any) => {
  const { isActiveWhiteListModal, closeWhiteListing } = useAccessManager();
  const { deviceAuth, handelClose, from } = props;

  const getFilepaths = useGetApiRequests("getFilePaths", "GET");
  const deviceRestrictionPost = useGetApiRequests("deviceRestriction", "POST");
  const getDeviceRestrictionList = useGetApiRequests("getAccessManagerDetails", "GET");
  const getUserList = useGetApiRequests("userManagement", "POST");

  const realmId = retrieveData("realmId", true);
  const realmName = retrieveData("realmName", true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState(1);
  const [currentTab, setCurrentTab] = useState(1);
  const [filePaths, setFilePaths] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [userList, setUserList] = useState<any>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const osData = [
    {
      osName: "Windows",
      imageUrl: images.windows,
    },
    {
      osName: "macOS",
      imageUrl: images.mac,
    },
    {
      osName: "Linux",
      imageUrl: images.linux,
    },
  ];
  useEffect(() => {
    if (deviceAuth || isActiveWhiteListModal) {
      setIsOpen(deviceAuth || isActiveWhiteListModal);
    }
  }, [deviceAuth, isActiveWhiteListModal]);

  useEffect(() => {
    if (from === "access-manager") {
      getDeviceRestrictionUsersList();
    }
  }, []);
  const getDeviceRestrictionUsersList = async () => {
    setLoader(true);
    const queryParams = {
      realmId: realmId,
    };
    try {
      const response: any = await getDeviceRestrictionList("", queryParams);
      if (response.status === 200) {
        const unMappedUerList = response?.data?.data?.accessManagerDeviceRestrictionKvDto?.unMappedUserDtoList;
        const reformedUserList = unMappedUerList?.map((item: any) => ({
          key: item?.id,
        }));

        const payload = {
          realmId: realmId,
          sorting: "name_asc",
          statusFilter: "active",
          searchFilter: "",
          startValue: 0,
          limitValue: retrieveData("totalUserRecords", true),
        };
        try {
          const response: any = await getUserList(payload);
          const status = response.status;

          if (status === 200) {
            const responseData = response?.data;
            const userListFromApi = responseData?.data?.map((item: any) => ({
              name: item?.userData?.name,
              userId: item?.userData?.userId,
              email: item?.userData?.email,
              key: item?.userData?.userId,
            }));
            const reformedListKeys = reformedUserList?.map((item: any) => item.key);
            const finalUserList = userListFromApi?.filter((item: any) => reformedListKeys?.includes(item.key));
            setUserList(finalUserList);
            dispatch(setDeviceRestrictionList(finalUserList));
            setLoader(false);
          }
        } catch (err: any) {
          setLoader(false);
          handleRequestError(err);
        }
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const handleCloseModal = () => {
    if (isActiveWhiteListModal) {
      closeWhiteListing();
    } else {
      setIsOpen(false);
      handelClose();
    }
    setCurrentTab(1);
  };
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const handleNextTab = async () => {
    setLoader(true);

    if (value === 1) {
      const queryParams: object = { realmName: realmName };
      try {
        const response: any = await getFilepaths("", queryParams);
        const status = response.status;
        if (status === 200) {
          const filePaths = response?.data?.data?.userDeviceRestrictionAgentFilePath;
          const updatedOsData = osData.map((data: any) => ({
            ...data,
            storagePath: filePaths[data?.osName],
          }));
          setLoader(false);
          setFilePaths(updatedOsData);
        }
      } catch (err: any) {
        setLoader(false);

        handleRequestError(err);
      }
      setCurrentTab(2);
    } else {
      navigate("/access-manager/device-restriction");
    }
  };
  const handleFileDownload = (path: any) => {
    const onlyUserKeys = userList?.map((item: any) => item?.key);
    const commaSeparatedString: string = onlyUserKeys?.join(",");
    const payload: object = {
      userId: commaSeparatedString,
    };
    deviceRestrictionPost(payload)
      .then((response: any) => {})
      .catch((err: any) => {
        handleRequestError(err);
      });
    const anchor = document.createElement("a");
    anchor.href = path;
    anchor.download = "";
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };
  return (
    <Modal className="device-auth" maskClosable={false} centered open={isOpen} footer={false} onCancel={handleCloseModal}>
      <div className="pt-5 px-10 w-full">
        {currentTab === 1 ? (
          <>
            <p className="modal-title font-Inter">Would you like to whitelist your devices on:</p>
            <div className="flex justify-center pt-11 pb-10 ">
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value={1}>Policy Level</Radio>
                  <Radio value={2}>Device Level</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="btn-group flex justify-center pb-5">
              <CustomButtonBack onClick={handleCloseModal} text={"Cancel"} data-testid="custom-button-back" />
              <CustomButtonNext onClick={handleNextTab} text={"Next"} />
            </div>
          </>
        ) : (
          <>
            <p className="modal-title font-Inter" onClick={() => handleFileDownload(from)}>
              Please download the Akku Device Manager
            </p>
            <div className="w-full pt-10 pb-10 flex justify-center flex-col items-center">
              <p className="mb-5 text-[12px] text-[#696969]">Desktop apps</p>
              <div className="w-full justify-center flex">
                {filePaths?.map((item: any) => (
                  <div key={item?.osName} onClick={() => handleFileDownload(item?.storagePath)}>
                    <div className="device-list mr-5">
                      <div className="w-8">
                        <ImageView src={item?.imageUrl} />
                      </div>
                      <p className="text-[#696969] text-[10px] font-medium font-Inter">{item?.osName}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <p className="text-center download font-Inter">Once downloaded, please add Akku Device Manager to your policy engine</p>
          </>
        )}
        {loader && <Loader />}
      </div>
    </Modal>
  );
};

export default DeviceAuthorizationModal;
