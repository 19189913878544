const generateTitle = (columnName: string, sortedColumn: any): React.JSX.Element => {
  let sortOrderIndicator = null;
  if (sortedColumn?.order === "ascend") {
    sortOrderIndicator = (
      <p className="flex">
        <span className="material-symbols-outlined text-[15px]">arrow_upward</span>
      </p>
    );
  } else if (sortedColumn?.order === "descend") {
    sortOrderIndicator = (
      <p className="flex">
        <span className="material-symbols-outlined text-[15px]">arrow_downward</span>
      </p>
    );
  }

  return (
    <div className={`flex justify-start items-center custom-title ${columnName}`}>
      <p className=" text-[20px] font-Inter text-[#1D1D24] font-medium">{columnName}</p>
      {sortOrderIndicator}
    </div>
  );
};

export default generateTitle;
