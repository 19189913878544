import React, { useEffect, useState } from "react";
import { Button, Modal, Select } from "antd";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import DropdownMenu from "./DropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../layouts/component/Loader";
import { handleConnectionList, handleIssueCredentialApi } from "../helper/credentialHelper";
const { Option } = Select;

const AddCredentialsModal = (props: any) => {
  const { handleClose, isCredential, userSearchGroupDataForTime } = props;
  const [open, setOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownUser, setDropdownUser] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [userGroupData, setUserGroupData] = useState([]);
  const credentialData = useSelector((state: any) => state?.credentialsSlice?.credentialInitialData);
  const [userDropDownData, setUserDropDownData] = useState<any>([]);
  const [disableGroups, setDisableGroups] = useState(false);
  const [disableUsers, setDisableUsers] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [key, setKey] = useState<number>(1);
  const isUpdate = useSelector((state: any) => state?.credentialsSlice?.isUpdate);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(isCredential);
    setKey(key + 1);
    setUserGroupData(userSearchGroupDataForTime);
    if (credentialData && Object.keys(credentialData).length !== 0) {
      const transformedArray = handleConnectionList(credentialData?.credentialManagerConnectionDetailsKvDtoList);
      const credentialsNotIssueUsers = transformedArray.filter(
        (item: any) => item?.connectionStatus?.toLowerCase() === "active" && item?.connectionExchangeStatus?.toLowerCase() !== "credential_acked",
      );
      setUserDropDownData(credentialsNotIssueUsers);
    }
    return () => {
      setDisableGroups(false);
      setDisableUsers(false);
    };
  }, [isCredential]);

  const handleCreateConnection = () => {
    if (selectedValues.length === 0 && selectedUsers.length === 0) {
      setErrorMessage("Please select at least one user");
    } else {
      handleIssueCredential();
    }
  };
  const handleIssueCredential = async () => {
    const selectedId = selectedValues.length > 0 ? selectedValues : selectedUsers;
    handleIssueCredentialApi(setErrorMessage, setLoader, selectedId, handleClosePopup, isUpdate, userDropDownData, dispatch);
  };

  const filterOption = (input: any, option: any) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleCancel = () => {
    setDropdownVisible(false);
    setDropdownUser(false);
  };

  const handleUserClick = (event: any, value: any) => {
    setErrorMessage("");
    event.stopPropagation();
    const newValues: any = [...selectedValues];
    if (newValues.includes(value)) {
      newValues.splice(newValues.indexOf(value), 1);
    } else {
      newValues.push(value);
    }
    setDisableGroups(newValues.length > 0);
    setSelectedValues(newValues);
  };

  const handleUserSelection = (event: any, value: any) => {
    setErrorMessage("");
    event.stopPropagation();
    const newVal: any = [...selectedUsers];
    if (newVal.includes(value)) {
      newVal.splice(newVal.indexOf(value), 1);
    } else {
      newVal.push(value);
    }
    setSelectedUsers(newVal);
    setDisableUsers(newVal.length > 0);
  };

  const handleDeselect = (value: any) => {
    setSelectedValues((prevValue: any) => {
      const newValue = prevValue.includes(value) ? prevValue.filter((val: any) => val !== value) : [...prevValue, value];
      setDisableGroups(newValue.length > 0);
      return newValue;
    });
  };
  const handleDeselectUser = (userValue: any) => {
    setSelectedUsers((prevValues: any) => {
      const selectedVal = prevValues.includes(userValue) ? prevValues.filter((val: any) => val !== userValue) : [...prevValues, userValue];
      setDisableUsers(selectedVal.length > 0);
      return selectedVal;
    });
  };
  const handleClosePopup = () => {
    setSelectedUsers([]);
    setSelectedValues([]);
    handleClose();
    setErrorMessage("");
  };
  return (
    <Modal key={key} className="rounded-lg add-credentials" maskClosable={false} centered open={open} footer={false} onCancel={handleClosePopup}>
      {loader && <Loader />}
      <div className="modal-title pt-3 px-4">
        <h3>Issue Credentials</h3>
      </div>
      <div className="modal-body pt-4 px-12">
        <div className="w-full pt-10">
          <p>You can select individual users to issue credentials</p>
          <div className="w-full flex issue-credentials">
            <div className="w-[45%] select-group hidden">
              <p className="connection-title">Select groups </p>
              <Select
                className="h-14 w-full  custom-dropdown"
                mode="tags"
                placeholder="Search"
                onDropdownVisibleChange={setDropdownVisible}
                open={dropdownVisible}
                tokenSeparators={[","]}
                optionLabelProp="label"
                filterOption={filterOption}
                dropdownRender={(menu: any) => <DropdownMenu menu={menu} handleCancel={handleCancel} />}
                disabled={disableUsers}
                onDeselect={handleDeselect}
                suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
              >
                {userGroupData?.map((option: any) => (
                  <Option key={option.id} value={option.value} label={option.name}>
                    <input
                      type="checkbox"
                      className="dropdown-list w-5"
                      name={option.firstName}
                      id={option.id}
                      checked={selectedValues.includes(option.value)}
                      onChange={(e) => handleUserClick(e, option.value)}
                    />
                    <label htmlFor={option.value} className="flex flex-col h-14 pointer-events-none justify-center">
                      {option.firstName}
                    </label>
                  </Option>
                ))}
              </Select>
            </div>
            <div className="w-[10%] pt-[90px] select-option hidden">
              <p className="text-center">or</p>
            </div>
            <div className="w-full max-w-[460px] select-user">
              <p className="connection-title">Select users</p>
              <Select
                className="h-[56px] w-full custom-dropdown"
                mode="tags"
                placeholder="Search"
                onDropdownVisibleChange={setDropdownUser}
                open={dropdownUser}
                tokenSeparators={[","]}
                optionLabelProp="label"
                filterOption={filterOption}
                dropdownRender={(menuItem: any) => <DropdownMenu menu={menuItem} handleCancel={handleCancel} />}
                disabled={disableGroups}
                onDeselect={handleDeselectUser}
                suffixIcon={<span className=" material-symbols-outlined">expand_more</span>}
              >
                {userDropDownData?.map((options: any) => (
                  <Option key={options.id} value={options.value} label={options.name}>
                    <input
                      type="checkbox"
                      className="dropdown-list w-5"
                      name={options.name}
                      id={options.id}
                      checked={selectedUsers.includes(options.value)}
                      onChange={(e) => handleUserSelection(e, options.value)}
                    />
                    <label htmlFor={options.value} className="flex flex-col h-14 pointer-events-none justify-center">
                      {options.name}
                    </label>
                  </Option>
                ))}
              </Select>
              {errorMessage && <div className="error-message text-red-600">{errorMessage}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer pt-20">
        <div className="w-full flex justify-end">
          <CustomButtonBack onClick={handleClosePopup} text="Cancel" />
          <Button onClick={handleCreateConnection} className="font-semibold text-[20px] h-[56px] bg-[#5441DA] text-white" size="large">
            Send issue credentials
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddCredentialsModal;
