import * as Yup from 'yup';

// Define the SAML and OpenID Connect schemas separately
export const samlSchema:any = Yup.object({
    
    saml: Yup.array().of(
        Yup.object({
           clientId: Yup.string().required('Client ID is required'),
      // masterSamlProcessingUrl: Yup.string().required('Master SAML Processing URL is required').url("Invalid URL"),
      // validRedirectLogoutUrl: Yup.array().of(Yup.string().required('Valid Redirect Logout URL is required').url("Invalid URL")),
      assertionConsumerUrlRedirect: Yup.string().required('Assertion Consumer Service Redirect Binding URL is required'),
      assertionConsumerUrlPost: Yup.string().required('Assertion Consumer Service POST Binding URL'),
      baseUrl: Yup.string().required('Base URL is required').url("Invalid URL"),
      validRedirectUrl: Yup.array().of(Yup.string().required('Valid Redirect URL is required').url("Invalid URL")),
      // rootUrl: Yup.string().required('Root URL is required').url("Invalid URL"),

    })
  ),
});

export const openIdSchema:any = Yup.object({
  openId: Yup.array().of(
    Yup.object({
     clientId: Yup.string().required('Client ID is required'),
    //   clientAuthentication: Yup.string().required('Client Authentication is required'),
    //   serviceAccountsEnabled: Yup.string().required('Service Accounts Enabled is required'),
    //   authorization: Yup.string().required('Authorization is required'),
    //   directAccessGrants: Yup.string().required('Direct Access Grants is required'),
    //   standardFlow: Yup.string().required('Standard Flow is required'),
    //   implicitFlow: Yup.string().required('Implicit Flow is required'),
    //   deviceAuthorizationGrantEnabled: Yup.string().required('Device Authorization Grant Enabled is required'),
    //   oidcCibaGrantEnabled: Yup.string().required('OIDC CIBA Grant Enabled is required'),
      // validRedirectLogoutUrl: Yup.array().of(Yup.string().required('Valid Redirect Logout URL is required').url("Invalid URL")),
      // rootUrl: Yup.string().required('Root URL is required').url("Invalid URL"),
      baseUrl: Yup.string().required('Base URL is required').url("Invalid URL"),
      validRedirectUrl: Yup.array().of(Yup.string().required('Valid Redirect URL is required').url("Invalid URL")),
      // webOrigins: Yup.array().of(Yup.string().required('Web Origins is required').url("Invalid URL")),
      frontChannelLogoutUrl:Yup.string().required('Front-channel logout URL is required').url("Invalid URL"),
    })
  ),
});

export const jiraValidationSchema:any= Yup.object({
  jira: Yup.array().of(
    Yup.object({
     apiKey: Yup.string().required('Api key  is required'),
     apiSecret: Yup.string().required('Api secret key is required'),
    })
  ),
  mappingGroups: Yup.array().of(
    Yup.object({
       source: Yup.string().required('Source is required'),
     target:Yup.string().required('Target role is required'),
    }))
});
