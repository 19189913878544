interface PermissionsData {
  [moduleName: string]: string[];
}
interface ModulePermissions {
  [moduleName: string]: {
    [action: string]: boolean;
  };
}

export const parsePermissions = (permissionsData: PermissionsData): { [moduleName: string]: ModulePermissions } => {
  const parsedPermissions: { [moduleName: string]: ModulePermissions } = {};
  for (const module in permissionsData) {
    const permissionsStr = permissionsData[module][0];
    parsedPermissions[module] = JSON.parse(permissionsStr);
  }
  return parsedPermissions;
};

export const hasPermission = (permissions: ModulePermissions | undefined, module: string, action: string): boolean => {
  return permissions?.[module]?.[action] ?? false;
};

export const getPermissionStyle = (permission: string | undefined | boolean, style = "permission-disabled") => {
  return !permission ? style : "";
};
