import React, { useEffect, useState } from "react";
import ImageView from "../../../layouts/component/ImageView";
import { Modal } from "antd";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../layouts/component/CustomButton";
import { images } from "../../../assets/images/ImagePaths";

const DisableConnection = (props: any) => {
  const { disableLDAP, isOpenPopup, handleClose, handleDisable } = props;
  const [open, isOpen] = useState<boolean>(false);

  useEffect(() => {
    isOpen(isOpenPopup);
  }, [isOpenPopup]);

  const handleDisableLDAP = () => {
    handleDisable();
  };
  const handleCloseModal = () => {
    handleClose();
  };
  return (
    <Modal className="  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={disableLDAP ? images.notificationSuspend : images.notificationDone} alt="notificationDone" className="delete-apps" />
          {!disableLDAP && (
            <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#101828]">
              Disabling Active Directory will result in all user accounts being disabled. Are you sure you want to proceed?
            </p>
          )}
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={"No"} />
          <CustomButtonNext onClick={handleDisableLDAP} text={"Yes"} />
        </div>
      </div>
    </Modal>
  );
};

export default DisableConnection;
