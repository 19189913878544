export const helpMessage = {
  SSOSessionIdle: "Time a session is allowed to be idle before it expires. Tokens and browser sessions are invalidated when a session is expired.",
  SSOSessionMax: "Max time before a session is expired. Tokens and browser sessions are invalidated when a session is expired.",
  rememberSSOSessionIdle:
    "Time a remember me session is allowed to be idle before it expires. Tokens and browser sessions are invalidated when a session is expired. If not set it uses the standard SSO Session Idle value.",
  rememberSSOSessionMax:
    "Max time before a session is expired when a user has set the remember me option. Tokens and browser sessions are invalidated when a session is expired. If not set it uses the standard SSO Session Max value.",
  loginTimeout: "Max time a user has to complete a login. This is recommended to be relatively long, such as 30 minutes or more.",
  loginActionTimeout: "Max time a user has to complete login related actions like update password or configure totp. This is recommended to be relatively long, such as 5 minutes or more.",
  setPasswordLogin:"Force user to set password on first login",
};
