import React, { useEffect, useState } from "react";
import { Dropdown, MenuProps, Button, Tooltip } from "antd";
import SingleUserModal from "./SingleUserModal";
import { retrieveData, storeData } from "../../../../../services/storage/Storage";
import "./UserHeader.scss";
import { getPermissionStyle, hasPermission } from "../../../../../redux/slice/permissions/permissionUtils";
import { useDispatch, useSelector } from "react-redux";
import { modulePermissions } from "../../../../../const/RolePermissions";
import PermissionsModal from "../../../../../layouts/permissionsModal/PermissionsModal";
import { useNavigate } from "react-router-dom";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import Loader from "../../../../../layouts/component/Loader";
import { clearAddUserSlice } from "../../../../../redux/slice/AddUserSlice";

const ClientHeader = () => {
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const myAppList = useGetApiRequests("myAppsList", "GET");
  const realmId = retrieveData("realmId", true);
  const [myApps, setMyApps] = useState<any>([]);
  const [isActiveUserRestriction, setIsActiveUserRestriction] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [open, setIsOpen] = useState<boolean>(false);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getMyAppList();
  }, []);

  const handelAddUser = () => {
    dispatch(clearAddUserSlice());
    navigate("/directory/configure-directory");
    storeData("getComponentDetails", null, true);
  };

  const handelAddSingleUser = () => {
    setIsOpen(true);
  };
  const handelClose = () => {
    setIsOpen(false);
    setPermissionsModal(false);
  };

  const getMyAppList = async () => {
    setLoader(true);
    let params = {
      realmId: realmId,
    };
    try {
      const response: any = await myAppList("", params);
      const status = response.status;
      if (status === 200) {
        setMyApps(response?.data?.data || []);
        setLoader(false);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className=" w-[230px] h-[170px] py-3 custom-dropdown">
          <div className="flex w-full flex-col">
            <button
              onClick={() => {
                handelAddSingleUser();
              }}
              className=" add-directory"
            >
              <span className="material-icons  mr-3 ">person</span>Users
            </button>
            <button
              onClick={() => {
                handelAddUser();
              }}
              className="add-directory"
            >
              <span className="material-icons  mr-3">account_tree</span>Directory
            </button>
            <button
              className="add-directory"
              onClick={() => {
                navigate("/user/csv-upload");
              }}
            >
              <span className="material-icons  mr-3">text_snippet</span>CSV
            </button>
          </div>
        </div>
      ),
    },
  ];
  const hasCreatePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "create");
  const handleDropdownClick = () => {
    if (!permissions || !hasPermission(permissions, modulePermissions.userManagement, "create")) {
      setPermissionsModal(true);
    }
  };
  const handleUserRestrictionModal = () => {
    setIsActiveUserRestriction(true);
  };
  const handleUserRestrictionModalClose = () => {
    setIsActiveUserRestriction(false);
  };

  return (
    <>
      {loader && <Loader />}
      <div className="header-content flex justify-between">
        <h2>User Management</h2>
        {myApps?.length === 0 ? (
          <Button type="primary" className=" add-btn opacity-85" onClick={handleUserRestrictionModal}>
            <span className="material-symbols-outlined">add </span>
          </Button>
        ) : (
          <Dropdown menu={{ items }} trigger={["click"]} disabled={!hasCreatePermission} className={getPermissionStyle(hasCreatePermission)}>
            <Tooltip title={myApps.length === 0 ? "No apps found" : "Add user"}>
              <Button type="primary" className=" add-btn" onClick={handleDropdownClick}>
                <span className="material-symbols-outlined">add </span>
              </Button>
            </Tooltip>
          </Dropdown>
        )}
      </div>

      {isActiveUserRestriction && <PermissionsModal open={isActiveUserRestriction} close={handleUserRestrictionModalClose} userCreateNotAllowed={true} />}
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handelClose} />}
      {open && <SingleUserModal open={open} handelClose={handelClose} />}
    </>
  );
};

export default ClientHeader;
