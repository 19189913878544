import React, { useEffect } from "react";
import Assesments from "./components/Assesments";
import "./Dashboard.scss";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardFilter } from "../../redux/slice/DashboardFilterSlice";
import useDashboardService from "./useDashboardService";
import { customerTable } from "../../constant/prerequisites/prerequisites";
import { retrieveData } from "../../services/storage/Storage";
import AdminHeaderHeader from "./components/AdminHeaderHeader";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";

const Dashboard: React.FC = () => {
  const currentRole = retrieveData("currentRole", true);
  const reloadCustomerDashboard = useSelector((state: any) => state?.CustomerSlice?.reloadCustomerDashboard);
  const customerTableCurrentPage = useSelector((state: any) => state?.CustomerSlice?.customerTableCurrentPageNumber);
  const restriction = useSelector((state: any) => state?.restriction?.restrictionDetails);

  const requestDashboardFilter = useGetApiRequests("dashboardFilter", "GET");

  const realmIdFromRedux = useSelector((state: any) => state?.CustomerSlice?.realmId);
  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();

  const { getDashboardDetails } = useDashboardService();

  const requestPayload = {
    realmId: realmId,
    riskAssessmentDto: { customerType: "all" },
    recoveryAttemptsDto: {
      recoveryType: "Password",
    },
    topSellerDto: {
      year: 2024,
      orderBy: "product",
    },
  };

  if (currentRole === "user") {
    window.location.href = "/dashboard";
  }

  useEffect(() => {
    !restriction.isActive && init();
  }, [reloadCustomerDashboard, realmIdFromRedux]);

  const init = () => {
    const customerTableInitialFilterData: any = { ...customerTable?.initialFilterData, pageNumber: customerTableCurrentPage };
    getDashboardDetails(customerTableInitialFilterData, "dashboardAll", requestPayload);
    getFilterData();
  };
  const getFilterData = async () => {
    try {
      const response: any = await requestDashboardFilter();
      const status = response.status;
      if (status === 200) {
        const data = response.data;
        dispatch(setDashboardFilter(data));
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  return (
    <div className="akku-container">
      <AdminHeaderHeader />
      {!restriction.isActive && (
        <div className="main-container admin">
          <Assesments data-testid="assessments-component" />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
