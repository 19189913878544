import React from "react";
import { Button, Input, Switch, Tooltip } from "antd";
import { ErrorMessage, Field, Form } from "formik";
import { getPermissionStyle } from "../../redux/slice/permissions/permissionUtils";

const PasswordManagerForm = (props: any) => {
  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    isSubmitting,
    passwordExpiry,
    setPasswordExpiry,
    passwordLength,
    setPasswordLength,
    passwordLengthAction,
    setPasswordLengthAction,
    lowercasePassword,
    uppercasePassword,
    specialCharactersPassword,
    digitsPassword,
    recentlyUsedPassword,
    values,
    hasUpdatePermission,
  } = props;

  const getStrengthMessage = (value: number) => {
    switch (value) {
      case 9:
        return "Good";
      case 10:
        return "Strong";
      case 11:
        return "Recommended";
      case 12:
        return "Super Strong";
      case 13:
        return "Unnecessarily Strong";
      default:
        return "";
    }
  };
  return (
    <Form onSubmit={handleSubmit} className="w-full">
      <div className="w-full flex password-page">
        <div className="w-1/2 password-left">
          <div className="flex w-full  justify-between relative mb-10">
            <p className=" font-Inter w-1/2">
              Password Expiry
              <Tooltip title="The number of days the password is valid before a new password is required">
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-1/2 flex justify-end">
              <div className="increment">
                <Field
                  as={Input}
                  type="number"
                  className="input-field pointer-events-none"
                  name="passwordExpiry"
                  value={passwordExpiry}
                  onChange={(e: any) => setFieldValue("passwordExpiry", e.target.value)}
                  data-testid="passwordExpiry-input"
                />
                <span
                  className={`material-symbols-outlined remove ${passwordExpiry === 0 && "decrement-gray  pointer-events-none"}`}
                  onClick={() => {
                    setPasswordExpiry(passwordExpiry - 15);
                    setFieldValue("passwordExpiry", Math.max(0, values.passwordExpiry - 15));
                  }}
                  data-testid="remove-count"
                >
                  remove
                </span>
                <span
                  className={`material-symbols-outlined add  ${passwordExpiry === 90 && "pointer-events-none decrement-gray"}`}
                  onClick={() => {
                    setPasswordExpiry(passwordExpiry + 15);
                    setFieldValue("passwordExpiry", values.passwordExpiry + 15);
                  }}
                  data-testid="add-count"
                >
                  add
                </span>
              </div>
              <ErrorMessage name="passwordExpiry" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-8">
            <p className=" font-Inter w-1/2">
              Password Minimum Length
              <Tooltip title="The minimum number of characters required for the password">
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-1/2 flex justify-end">
              <div className="increment">
                <Field
                  as={Input}
                  type="number"
                  className="input-field pointer-events-none"
                  name="passwordLength"
                  value={passwordLength}
                  onChange={(e: any) => setFieldValue("passwordLength", e.target.value)}
                  data-testid="passwordLength-input"
                />
                <span
                  className={`material-symbols-outlined remove   ${passwordLength > 8 && "length"} ${passwordLength === 8 && "pointer-events-none decrement-gray"}`}
                  onClick={() => {
                    setPasswordLength(passwordLength - 1);
                    setFieldValue("passwordLength", Math.max(0, values.passwordLength - 1));
                    setPasswordLengthAction(true);
                  }}
                >
                  remove
                </span>
                <span
                  className={`material-symbols-outlined add  ${passwordLength > 8 && "length"} ${passwordLength === 13 && "pointer-events-none decrement-gray"}`}
                  onClick={() => {
                    setPasswordLength(passwordLength + 1);
                    setFieldValue("passwordLength", values.passwordLength + 1);
                    setPasswordLengthAction(true);
                  }}
                >
                  add
                </span>
                {passwordLengthAction ? (
                  <h6 className={`mt-1 strength-${getStrengthMessage(passwordLength)}`}>{getStrengthMessage(passwordLength)}</h6>
                ) : (
                  <h6 className={`mt-2 opacity-0 strength-${getStrengthMessage(passwordLength)}`}>{getStrengthMessage(passwordLength) !== "" ? getStrengthMessage(passwordLength) : ""}</h6>
                )}
              </div>
              <ErrorMessage name="passwordLength" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-8">
            <p className=" font-Inter w-[70%]">
              Uppercase Characters
              <Tooltip title=" At least one uppercase letter required in the password">
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-[30%] flex justify-end">
              <Field
                onChange={(e: any) => handleChange(e, "uppercase", setFieldValue)}
                as={Switch}
                checked={uppercasePassword}
                type="text"
                className=""
                name="uppercase"
                data-testid="uppercase-input"
              />
              <ErrorMessage name="uppercase" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-8">
            <p className=" font-Inter w-[70%]">
              Lowercase Characters
              <Tooltip title=" At least one lowercase letter required in the password">
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-[30%] flex justify-end">
              <Field
                checked={lowercasePassword}
                onChange={(e: any) => handleChange(e, "lowercase", setFieldValue)}
                as={Switch}
                type="text"
                className=""
                name="lowercase"
                data-testid="lowercase-input"
              />
              <ErrorMessage name="lowercase" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-8">
            <p className=" font-Inter w-[70%]">
              Special Characters
              <Tooltip title="Atleast one special character required in the password">
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-[30%] flex justify-end">
              <Field
                checked={specialCharactersPassword}
                onChange={(e: any) => handleChange(e, "specialCharacter", setFieldValue)}
                as={Switch}
                type="text"
                className=""
                name="specialCharacter"
                data-testid="specialCharacter-input"
              />
              <ErrorMessage name="specialCharacter" component="div" className="error-message" />
            </div>
          </div>
        </div>
        <div className="w-1/2 password-left">
          <div className="flex w-full  justify-between relative mb-8">
            <p className=" font-Inter w-[70%]">
              Digits
              <Tooltip title="Atleast one numerical digit required in the password">
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-1/2 flex justify-end">
              <Field checked={digitsPassword} onChange={(e: any) => handleChange(e, "digit", setFieldValue)} as={Switch} type="text" className="" name="digit" data-testid="digit-input" />
              <ErrorMessage name="digit" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-10">
            <p className=" font-Inter  flex-col note">
              <span className="not"> Not Recently used</span>
              <span>
                <strong>Note:</strong> Last 3 recently used password cannot reused.
              </span>
            </p>
            <div className="note-switch flex justify-end">
              <Field
                checked={recentlyUsedPassword}
                onChange={(e: any) => handleChange(e, "recentlyUsed", setFieldValue)}
                as={Switch}
                type="text"
                className=""
                name="recentlyUsed"
                data-testid="recentlyUsed-input"
              />
              <ErrorMessage name="recentlyUsed" component="div" className="error-message" />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end pb-10 ">
        <Button type="primary" onClick={isSubmitting} className={`btn btn-secondary ${getPermissionStyle(hasUpdatePermission)}`}>
          Restore default
        </Button>
        <Button type="primary" htmlType="submit" className={`btn mr-12 ${getPermissionStyle(hasUpdatePermission)}`}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default PasswordManagerForm;
