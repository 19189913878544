import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Input } from "antd";
import { manageConnectionSchema, initAppConnection } from "../helper/connectorHelper";
import ConnectionSourceAndTarget from "./ConnectionSourceAndTarget";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
const ManageConnection = (props: any) => {
  const { enableTest } = props;
  const dispatch = useDispatch();

  const initialValues = initAppConnection;

  const handleSubmit = async (values: any) => {
    let step = {
      activeStep: 2,
      childStepper: "",
    };
    dispatch(setCurrentStep(step));
  };
  const handleBackToUser = () => {
    let step = {
      activeStep: 0,
      childStepper: "",
    };
    dispatch(setCurrentStep(step));
  };

  return (
    <div className="w-full px-10 pb-20 ">
      <p className="app-header pt-8"> Manage Connection </p>
      <div className="w-full connection-container">
        <div className="w-full">
          <ConnectionSourceAndTarget />
        </div>
        <div className="w-full mx-auto pb-10">
          <Formik initialValues={initialValues} validationSchema={manageConnectionSchema} onSubmit={handleSubmit}>
            {({ values, dirty, setFieldValue }) => {
              return (
                <Form>
                  <div className="w-full pt-20 px-[30px] app-connector manage flex-wrap ">
                    <div className="mb-10 w-[45%] input-container relative">
                      <p>Connector Name*</p>
                      <Field as={Input} type="text" className="form-type-box" name="manageConnectorName" data-testid="usersMcn-input" />
                      <ErrorMessage name="manageConnectorName" component="div" className="error-message" />
                    </div>
                    <div className="mb-10 w-[45%] input-container relative">
                      <p>Description*</p>
                      <Field as={Input} type="text" className="form-type-box" name="manageDescription" data-testid="usersMd-input" />
                      <ErrorMessage name="manageDescription" component="div" className="error-message" />
                    </div>
                    <div className="mb-10 w-[45%] input-container relative">
                      <p>Number of parallel threads</p>
                      <Field as={Input} type="text" className="form-type-box" name="noOfParallelThreads" data-testid="noOfParallelThreads-input" />
                      <ErrorMessage name="noOfParallelThreads" component="div" className="error-message" />
                    </div>

                    <div className="footer ">
                      <div className="modal-footer w-full mx-auto ">
                        <div className="w-full flex justify-end pr-5">
                          {!enableTest ? (
                            <>
                              <Button onClick={handleBackToUser} className=" back-btn">
                                Back
                              </Button>
                              <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn ">
                                Next
                              </Button>
                            </>
                          ) : (
                            <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                              Test
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ManageConnection;
