import { Radio } from "antd";
import React, { useState } from "react";

const MappingUserStatus = () => {
  const customerTablePlanValue = [
    { label: "Save in Jira as Active", value: "JiraActive" },
    { label: "Save in Jira as Suspend", value: "JiraSuspend" },
    { label: "Delete from Jira", value: "JiraDelete" },
  ];
  const [filterValue, setFilterValue] = useState("");

  return (
    <>
      <div className="w-full px-10 pb-5 ">
        <p className="app-header pt-8">Mapping - User status</p>
      </div>
      <div className="w-full user-mapping  flex justify-center items-center">
        <div className="user-card">
          <p className="px-10">When a user status is marked as disabled in the Akku, what would you like to do?</p>
          <div className="w-full flex justify-center">
            <Radio.Group
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
              value={filterValue}
            >
              {customerTablePlanValue?.map((filter: any) => (
                <div key={filter?.value} className=" mt-7">
                  <Radio value={filter?.value}>{filter?.label}</Radio>
                </div>
              ))}
            </Radio.Group>
          </div>
        </div>
      </div>
    </>
  );
};

export default MappingUserStatus;
