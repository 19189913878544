import { Modal } from "antd";
import React from "react";
import ImageView from "../../../../../../layouts/component/ImageView";
import { images } from "../../../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import { useUserContext } from "../../userContext/UserContext";

export default function DeleteRestrictionModal({ deleteModal, handleModalClose, selectedRowObject, deviceInfoId, handleToBack }: any) {
  const deleteDeviceRestriction = useGetApiRequests("deleteUser", "DELETE");
  const { getFilterData } = useUserContext();

  const deleteRestrictionApiCall = () => {
    const pathParams: any = {
      id: `${selectedRowObject?.key}/device-information/${deviceInfoId}`,
    };
    deleteDeviceRestriction("", {}, pathParams)
      .then((response: any) => {
        triggerNotification("success", "", response?.data?.message, "topRight");
        getFilterData(0, 10);
        handleToBack();
      })
      .catch((err: any) => {
        handleRequestError(err);
      });
  };
  return (
    <Modal className="p-10 rounded-lg  show-notification  _suspended " maskClosable={false} footer={false} centered open={deleteModal} onCancel={handleModalClose}>
      <div className="modal-body pt-4 ">
        <div className="flex w-full justify-center flex-col items-center ">
          <ImageView src={images.notificationSuspend} alt="notificationDone" className=" h-20 w-20 " />
          <h5 className=" pt-3 text-[24px] font-Inter  font-semibold">{""}</h5>
          <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">Are you sure want to delete the restrictions ?</p>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className=" flex w-full  justify-center">
          <CustomButtonBack text={"No"} onClick={handleModalClose} />
          <CustomButtonNext onClick={deleteRestrictionApiCall} text={"Yes"} />
        </div>
      </div>
    </Modal>
  );
}
