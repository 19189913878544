import React from "react";
import { retrieveData } from "../../services/storage/Storage";

interface ApiResponse {
  isIpAllowed: boolean;
  // Add other properties as needed
}

const checkWhitelistIP = async (realmName: string, userId: string, whiteListUrl: string, setLoader: React.Dispatch<React.SetStateAction<boolean>>) => {
  const keycloakUrl: string = retrieveData("keycloakUrl", false);

  const payload = { realmName, userId, keycloakUrl };
  const token: string = retrieveData("authToken", true);
  try {
    const response = await fetch(whiteListUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer  ${token}` },
      body: JSON.stringify(payload),
    });
    const responseText: string = await response.text();
    const apiResponse: ApiResponse = JSON.parse(responseText);
    if (response.status === 200) {
      setLoader(false);
      return !apiResponse.isIpAllowed;
    } else {
      setLoader(false);
      return false;
    }
  } catch (error) {
    setLoader(false);
    console.error("Error calling the API or access denied", error);
    return false;
  }
};

export default checkWhitelistIP;

function convertTo12Hour(time24: any) {
  let [hours, minutes] = time24.split(":").map(Number);

  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert hour to 12-hour format and handle the midnight (0 hour) case

  return `${hours}:${minutes.toString().padStart(2, "0")} ${period}`;
}

export const timeBasedRestriction = async (realmName: string, userId: string, timeBasedUrl: string, setLoader: any) => {
  const currentDate = new Date();
  const currentDateStr = currentDate.toLocaleDateString();
  const keycloakUrl: string = retrieveData("keycloakUrl", false);

  // Get the time zone abbreviation
  const timeZoneAbbr = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneString = timeZoneAbbr === "Asia/Calcutta" ? "Asia/Kolkata" : timeZoneAbbr;

  const options: Intl.DateTimeFormatOptions = { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };
  const currentTime = currentDate.toLocaleTimeString("en-GB", options); // Use 'en-GB' to enforce 24-hour format
  const time12 = convertTo12Hour(currentTime);
  const token: string = retrieveData("authToken", true);

  const payload = {
    realmName,
    userId,
    requstDate: currentDateStr,
    timezone: timeZoneString,
    requestTime: time12,
    keycloakUrl,
  };
  try {
    const response = await fetch(timeBasedUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer  ${token}` },
      body: JSON.stringify(payload),
    });

    const responseText: string = await response.text();
    const apiResponse: any = JSON.parse(responseText);
    if (response.status === 200) {
      return !apiResponse.isTimeBasedAccess;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error calling the API or access denied", error);
    return false;
  } finally {
    setLoader(false);
  }
};
