import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  customerValues: any;
  customerId: any;
  getCustomerDetails: object;
  reloadCustomerDashboard: boolean;
  realmId: any;
  customerTableCurrentPageNumber: number;
}

const initialState: AuthState = {
  customerValues: {},
  customerId: "",
  getCustomerDetails: {
    generalDetails: {},
    emailDetails: {},
    ssoDetails: {},
    plansDetails: {},
  },
  reloadCustomerDashboard: false,
  realmId: "",
  customerTableCurrentPageNumber: 1,
};

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    setCustomerData: (state, action: PayloadAction<any>) => {
      state.customerValues = action.payload;
    },
    clearCustomerData: (state) => {
      state.customerValues = {};
    },
    setCustomerId: (state, action: PayloadAction<any>) => {
      state.customerId = action.payload;
    },
    setCustomerDetailFromApi: (state, action: PayloadAction<any>) => {
      state.getCustomerDetails = action.payload;
    },
    clearCustomerDetailsFromApi: (state) => {
      state.getCustomerDetails = {
        generalDetails: {},
        emailDetails: {},
        ssoDetails: {},
        plansDetails: {},
      };
    },
    setReloadCustomerDashboard: (state) => {
      state.reloadCustomerDashboard = !state.reloadCustomerDashboard;
    },
    setRealmId: (state, action: PayloadAction<any>) => {
      state.realmId = action.payload;
    },
    setCustomerTableCurrentPageNumber: (state, action: PayloadAction<any>) => {
      state.customerTableCurrentPageNumber = action.payload;
    },
  },
});

export const { setCustomerData, clearCustomerData, setCustomerId, setCustomerDetailFromApi, clearCustomerDetailsFromApi, setReloadCustomerDashboard, setRealmId, setCustomerTableCurrentPageNumber } =
  customerSlice.actions;
export default customerSlice.reducer;
