import { Button, Tooltip } from "antd";
import React, { useState } from "react";
import { getPermissionStyle } from "../../../../../redux/slice/permissions/permissionUtils";
import { retrieveData } from "../../../../../services/storage/Storage";
import Loader from "../../../../../layouts/component/Loader";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { apiResponseMessage } from "../../../../../constant/apiResponseMessage";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import ConfirmationModalForDelete from "../../../../../Utils/ConfirmationModalForDelete";
import { ErrorMessages } from "../../../../../const/Messages";

const DeviceRestrictionTableHeader = ({ userList, selectedRows, setSelectedRow, setSelectedRowKeys, fromAccessManager, updateDeviceBasedRestriction, getDeviceRestrictionUsersList }: any) => {
  const [loader, setLoader] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_BASEURL;
  const clearSection = () => {
    setSelectedRow([]);
    setSelectedRowKeys([]);
  };
  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };
  const deleteUsersFromDeviceRestriction = async () => {
    setLoader(true);
    const baseUrl = `${apiBaseUrl}/v1/user/device-information`;
    const queryParams = selectedRows.map((id: any) => `userId=${encodeURIComponent(id)}`).join("&");
    const fullUrl = `${baseUrl}?${queryParams}`;
    const token = retrieveData("authToken", true);
    try {
      const response = await fetch(fullUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      handleCloseModal();
      triggerNotification("success", "", data?.message, "topRight");
      setLoader(false);
      clearSection();
      getDeviceRestrictionUsersList();
    } catch (error: any) {
      setLoader(false);
      handleRequestError(error);
    }
  };
  const openConfirmation = () => {
    setOpenDeleteModal(true);
  };

  return (
    <>
      {userList && (
        <div className="w-full flex mb-5">
          {fromAccessManager && <p className="users-total pr-5">Total user count: {`${userList?.length}`} </p>}
          {fromAccessManager && selectedRows && selectedRows.length > 0 && (
            <>
              <p className="users-total pr-5">{selectedRows.length === 1 ? `${selectedRows?.length} user` : `${selectedRows?.length} users`} selected</p>
              <p className="users-clear pr-5" onClick={clearSection}>
                Clear selection
              </p>
              {updateDeviceBasedRestriction && (
                <Tooltip title={"Delete Users"}>
                  <Button
                    onClick={openConfirmation}
                    className={`blockAndDeleteButton  ml-6 sync-btn  ${getPermissionStyle(true)}`}
                    icon={<span className="material-icons-outlined mt-[2px] text-[#667085] text-[16px]">delete</span>}
                  />
                </Tooltip>
              )}
            </>
          )}
        </div>
      )}
      {loader && <Loader />}
      {openDeleteModal && (
        <ConfirmationModalForDelete
          openModal={openDeleteModal}
          closeModal={handleCloseModal}
          contentToDisplay={ErrorMessages?.deleteDisplayContent}
          handleYesButton={deleteUsersFromDeviceRestriction}
        />
      )}
    </>
  );
};

export default DeviceRestrictionTableHeader;
