import React, { useEffect, useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import "./MapAttribute.scss";
import { staticMessage } from "../../../../../../../../constant/StaticContent";
import { Formik, Form } from "formik";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../../../../services/storage/Storage";
import MappersFinalReview from "../map-final/MappersFinalReview";
import Loader from "../../../../../../../../layouts/component/Loader";
import MapAttributeForm from "./MapAttributeForm";
import { validationSchema } from "./validationSchema";
import { useSelector } from "react-redux";
const AttributeMapping = (props: any) => {
  const { editAttribute, setCurrentTab } = props;
  const [formInitialValues, setFormInitial] = useState({ forms: [{ source: "", target: "", userType: "", isCheck: false, id: "" }] });
  const stepper = useSelector((state: any) => state.addUserSlice.steeper);
  const getMapperType = useGetApiRequests("getMapperType", "GET");
  const getMapperLists = useGetApiRequests("getMapperLists", "GET");
  const [formKey, setKormKey] = useState<number>(1);
  const realmName = retrieveData("realmName", true);
  const componentId = retrieveData("componentId", true);
  const [loader, setLoader] = useState<boolean>(false);
  const [finalReview, setFinalReview] = useState<boolean>(false);
  const [formList, setFormList] = useState<any>();
  const [transformList, setTransformList] = useState<any>([]);
  const [transformPayload, setTransformPayload] = useState<any>();
  let getComponentId = retrieveData("getComponentDetails", true);
  const [isAttributeDelete, setIsAttributeDelete] = useState<boolean>(false);

  useEffect(() => {
    getAttributeList();
    getComponentList();
  }, [isAttributeDelete]);
  const getComponentList = async () => {
    setLoader(true);

    const payload = {
      componentId: editAttribute ? getComponentId : componentId?.data?.componentId,
      realmName: realmName,
    };

    try {
      const response: any = await getMapperLists(payload, payload);
      const status = response?.status;
      if (status === 200) {
        let transformedArray = response?.data.map((elm: any) => {
          let isPredefined = getPredefinedAttributes(elm?.config);

          if (elm.name != "MSAD account controls") {
            return {
              source: elm.config["ldap.attribute"][0],
              target: elm.config["user.model.attribute"][0],
              userType: elm.providerId,
              isCheck: isPredefined,
              id: elm.id,
            };
          } else {
            return {
              source: "Default",
              target: "Default",
              userType: elm.providerId,
              isCheck: true,
              id: elm.id,
            };
          }
        });
        let v = {
          forms: [...transformedArray],
        };
        setFormInitial(v);
        setKormKey(formKey + 1);
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const getPredefinedAttributes = (elm: any) => {
    return elm.hasOwnProperty("is.mandatory.in.ldap");
  };
  const getAttributeList = async () => {
    setLoader(false);

    const payload = {
      componentId: editAttribute ? getComponentId : componentId?.data?.componentId,
      realmName: realmName,
    };

    try {
      const response: any = await getMapperType(payload, payload);
      const status = response.status;
      if (status === 200) {
        setTransformList(response.data);
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const transformFormValues = (values: any) => {
    let spiltedArray: any = [];
    values.map((item: any) => {
      if (item?.userType === "group-ldap-mapper") {
        spiltedArray.push({
          id: item?.id,
          name: item?.source + "Mapper",
          parentId: componentId?.data?.componentId || getComponentId,
          providerId: item?.userType,
          config: {
            "groups.dn": [componentId?.data?.usersDn],
            "group.name.ldap.attribute": [componentId?.data?.usernameLDAPAttribute],
            mode: [staticMessage?.groupMapping?.mode],
            "user.roles.retrieve.strategy": [staticMessage?.groupMapping?.retrieve],
            "drop.non.existing.groups.during.sync": ["true"],
            "preserve.group.inheritance": ["false"],
          },
        });
      } else if (item?.userType === "user-attribute-ldap-mapper") {
        spiltedArray.push({
          id: item?.id,
          name: item?.source + "Mapper",
          parentId: componentId?.data?.componentId || getComponentId,
          providerId: item?.userType,
          config: {
            "user.model.attribute": [item?.target],
            "ldap.attribute": [item?.source],
          },
        });
      }
    });
    return spiltedArray;
  };

  const onSubmit = async (values: any) => {
    const transformedValues = transformFormValues(values?.forms);
    setTransformPayload(transformedValues);
    setFormList(values.forms);
    setFinalReview(true);
  };

  const handleClose = () => {
    setFinalReview(false);
  };

  const handleMapperBack = () => {
    setCurrentTab(2);
  };
  return (
    <div className="w-full px-12 relative map-attribute">
      {loader && <Loader />}
      {editAttribute !== true && <CommonHeader text={staticMessage?.MappingAttribute?.mappingAttributeHeading} className={"hidden"} onClick={null} />}
      <div className={`${stepper[4]?.active ? "mapper-responsive" : ""} `}>
        <div className={`${editAttribute ? "edit" : ""} w-full pt-[40px] attribute-container`}>
          <ul className="flex w-[85%]">
            <li>{staticMessage?.MappingAttribute?.sourceLdap}</li>
            <li>{staticMessage?.MappingAttribute?.targetAkku}</li>
            <li>{staticMessage?.MappingAttribute?.transfromation}</li>
          </ul>
          <div className="w-full flex"></div>
          <Formik initialValues={formInitialValues} onSubmit={onSubmit} key={formKey} validationSchema={validationSchema}>
            {({ values, setFieldValue }) => (
              <Form>
                <MapAttributeForm
                  handleMapperBack={handleMapperBack}
                  values={values}
                  setFieldValue={setFieldValue}
                  transformList={transformList}
                  setFormInitial={setFormInitial}
                  editAttribute={editAttribute}
                  setIsAttributeDelete={setIsAttributeDelete}
                  isAttributeDelete={isAttributeDelete}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {finalReview && (
        <MappersFinalReview setCurrentTab={setCurrentTab} finalReview={finalReview} handleClose={handleClose} transformPayload={transformPayload} formList={formList} editAttribute={editAttribute} />
      )}
    </div>
  );
};

export default AttributeMapping;
