import { Input } from "antd";
import { ErrorMessage, Field } from "formik";
import React from "react";

const RestApiForm = () => {
  return (
    <>
      <div className="mb-10 w-[45%] input-container relative">
        <p>URL*</p>
        <Field as={Input} type="text" className="form-type-box" name="connectorURL" data-testid="connectorURL-input" />
        <ErrorMessage name="connectorURL" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[45%] input-container relative">
        <p>Username*</p>
        <Field as={Input} type="text" className="form-type-box" name="userName" data-testid="userName-input" />
        <ErrorMessage name="userName" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[45%] input-container relative">
        <p>Password*</p>
        <Field as={Input} type="text" className="form-type-box" name="password" data-testid="password-input" />
        <ErrorMessage name="password" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[45%] input-container relative">
        <p>Provisioning URL*</p>
        <Field as={Input} type="text" className="form-type-box" name="provisionURL" data-testid="provisionURL-input" />
        <ErrorMessage name="provisionURL" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[45%] input-container relative">
        <p>Deprovisioning URL*</p>
        <Field as={Input} type="text" className="form-type-box" name="deprovisionURL" data-testid="deprovisionURL-input" />
        <ErrorMessage name="deprovisionURL" component="div" className="error-message" />
      </div>
    </>
  );
};

export default RestApiForm;
