import React, { useState } from "react";
import { Select, Switch } from "antd";
import { ErrorMessage, Field } from "formik";
import "./MappingNotification.scss";
import { staticMessage } from "../../../../../../../../constant/StaticContent";

const EmailNotification = () => {
  const mailDropDownList: any = [{ label: "User", value: "user" }];
  const groupDropDownList: any = [{ label: "Group", value: "Group" }];
  const templateDropDownList: any = [{ label: "template", value: "template" }];
  const deliverDropDownList: any = [{ label: "Deliver", value: "Deliver" }];

  const [isToggled, setToggle] = useState<boolean>(false);

  const handleToggle = (e: any) => {
    setToggle(e);
  };

  return (
    <div className=" mail-container">
      <div className="w-full flex items-center mb-10">
        <span className="font-Inter text-[22px]  mr-4 text-[#121D24] font-semibold ">{staticMessage?.MappingNotification?.email}</span>
        <Switch checked={isToggled} onChange={(e) => handleToggle(e)} />
      </div>
      <div className="mb-10 w-[33%] -form">
        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.MappingNotification?.fromEmail}</p>
        <Field
          as={Select}
          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
          className=" w-[90%] h-[56px]"
          name="fromMail"
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {mailDropDownList?.map((option: any) => (
            <Select.Option key={option.value} value={option.label}>
              {option.label}
            </Select.Option>
          ))}
        </Field>
        <ErrorMessage name="fromMail" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[33%] -form">
        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.MappingNotification?.selectGroup}</p>
        <Field
          as={Select}
          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
          className=" w-[90%] h-[56px]"
          name="fromMail"
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {groupDropDownList?.map((option: any) => (
            <Select.Option key={option.value} value={option.label}>
              {option.label}
            </Select.Option>
          ))}
        </Field>
        <ErrorMessage name="fromMail" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[33%] -form">
        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.MappingNotification?.selectTemplate}</p>
        <Field
          as={Select}
          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
          className=" w-[90%] h-[56px]"
          name="fromMail"
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {templateDropDownList?.map((option: any) => (
            <Select.Option key={option.value} value={option.label}>
              {option.label}
            </Select.Option>
          ))}
        </Field>
        <ErrorMessage name="fromMail" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[33%] -form">
        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.MappingNotification?.whenToDeliver}</p>
        <Field
          as={Select}
          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
          className=" w-[90%] h-[56px]"
          name="fromMail"
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {deliverDropDownList?.map((option: any) => (
            <Select.Option key={option.value} value={option.label}>
              {option.label}
            </Select.Option>
          ))}
        </Field>
        <ErrorMessage name="fromMail" component="div" className="error-message" />
      </div>
    </div>
  );
};

export default EmailNotification;
