import React, { useRef, useState, useEffect } from "react";
import { FieldArray, Field, ErrorMessage } from "formik";
import { Input, Tooltip } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./CustomField.scss";

const CustomFieldArray = ({ values, pushRef, removeRef, setFieldValue, errors }: any) => {
  const [editableField, setEditableField] = useState<any>({});

  const inputRef = useRef<any>([]);

  useEffect(() => {
    Object.keys(editableField).forEach((index: any) => {
      if (editableField[index] && inputRef.current[index]) {
        inputRef.current[index]?.focus();
      }
    });
  }, [editableField]);

  const handleEdit = (index: any) => {
    setEditableField((prev: any) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleResetField = (index: any) => {
    setFieldValue(`customField[${index}].fieldLabel`, "Untitled");
    setEditableField((prev: any) => ({ ...prev, [index]: false }));
  };

  const isAllFieldsFilled = values?.customField?.every((field: any) => field?.fieldLabel && field.newField);
  const getClassNames = (editableField: any, index: any, errors: any) => {
    const editable = editableField[index];
    const hasError = errors?.customField?.[index]?.fieldLabel;
    return `${!editable ? "no-edit" : "allow-edit"} font-medium text-[18px] edit-field ${hasError ? "error-field" : ""}`;
  };
  return (
    <div className="flex flex-wrap w-[90%] mx-auto pb-10 justify-between">
      <div className="mb-10 w-[45%] flex relative">
        <div className="w-[90%]" data-testid="mobileId">
          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Mobile Number *</p>
          <PhoneInput inputClass="ant-input h-[56px] w-[100%]" country={"us"} value={values?.phone} onChange={(value) => setFieldValue("phone", value)} />
          <ErrorMessage name="phone" component="div" className="error-message" />
        </div>
      </div>
      <div className="mb-10 w-[45%] flex relative">
        <div className="w-[90%]">
          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Email ID *</p>
          <Input type="email" className="h-[56px] font-medium" name="verifyEmail" onChange={(e) => setFieldValue("verifyEmail", e.target.value)} value={values?.verifyEmail} />
          <ErrorMessage name="verifyEmail" component="div" className="error-message" />
        </div>
      </div>
      <div className="custom-field w-full mx-auto mb-10">
        <div className="flex w-full justify-between items-center ">
          <div className="text w-[75%] mt-5 ">
            <p className="custom font-Inter">Please enter the following details or add custom field</p>
          </div>
        </div>
      </div>
      <div className="w-full flex mx-auto">
        <FieldArray name="customField">
          {({ push, remove }) => {
            pushRef.current = push;
            removeRef.current = remove;

            return (
              <div className="w-full flex-wrap justify-between">
                {values?.customField?.map((field: any, index: any) => (
                  <div className="w-[65%]" key={field?.id}>
                    <div className="flex w-full relative">
                      <div className="w-full">
                        <div>
                          <Tooltip
                            className="error-tooltip"
                            placement="topLeft"
                            title={errors?.customField?.[index]?.fieldLabel && <ErrorMessage name={`customField[${index}].fieldLabel`} component="span" className="text-[#cf3b30]" />}
                          >
                            <div className="flex items-center relative h-4 mb-2">
                              <Field name={`customField[${index}].fieldLabel`}>
                                {({ field }: any) => (
                                  <Input
                                    {...field}
                                    type="text"
                                    disabled={!editableField[index]}
                                    maxLength={15}
                                    ref={(el) => (inputRef.current[index] = el)}
                                    className={`mb-2 ${getClassNames(editableField, index, errors)}`}
                                  />
                                )}
                              </Field>
                              <div className="actions">
                                <span className="material-symbols-outlined text-[18px] ml-3 cursor-pointer" onClick={() => handleEdit(index)}>
                                  {editableField[index] ? "done" : "edit"}
                                </span>
                                <span className="material-symbols-outlined text-[18px] ml-3 cursor-pointer" onClick={() => handleResetField(index)}>
                                  {editableField[index] ? "close" : ""}
                                </span>
                              </div>
                            </div>
                          </Tooltip>
                          <div className="flex items-center relative mb-8">
                            <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" placeholder="Enter" name={`customField[${index}].newField`} />
                            <ErrorMessage name={`customField[${index}].newField`} component="div" className="error-message personal" />
                            <div className="w-[270px] flex">
                              {index !== 0 && (
                                <span className="material-symbols-outlined add-custom remove cursor-pointer" onClick={() => remove(index)}>
                                  remove
                                </span>
                              )}
                              {index === values.customField.length - 1 && (
                                <span
                                  className={`material-symbols-outlined cursor-pointer add-custom ${!isAllFieldsFilled ? "disabled" : ""}`}
                                  onClick={() => isAllFieldsFilled && push({ newField: "", fieldLabel: index === 0 ? "Untitled1" : `Untitled${index + 1}`, id: index })}
                                >
                                  add
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

export default CustomFieldArray;
