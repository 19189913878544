import { Button, Radio, Space } from "antd";
import React, { useEffect } from "react";
import "./channel.scss";
import { useAddUserContext } from "../../../add-user-context/AddUserContext";
import { images } from "../../../../../../../assets/images/ImagePaths";
import { staticMessage } from "../../../../../../../constant/StaticContent";
export default function Channel() {
  const { setAddUserType, addUserType, closeModal, handleNext, setHandleRedirect } = useAddUserContext();

  const onChange = (e: any) => {
    setAddUserType(e.target.value);
  };

  const handleRedirectNext = () => {
    if (addUserType === "singleUser") {
      setHandleRedirect(true);
    } else {
      handleNext(0, 1);
    }
  };
  useEffect(() => {
    handleNext(1, 2);
  }, []);
  return (
    <div className="modal-content">
      <div className="modal-component">
        <div className="flex justify-center items-center h-full flex-col    ">
          <p className="user-title">Add Users</p>
          <div className="cart channel flex justify-center flex-wrap">
            <div className="pt-16">
              <img src={images.channel} alt="channel icon" />
            </div>
            <div className="w-full pl-14">
              <p className="text-center title">Select Channel</p>
              <h6 className=" pb-12 text-center">{staticMessage?.SelectChannel?.importUsers}</h6>
              <Radio.Group className="pl-20" onChange={onChange} value={addUserType}>
                <Space direction="vertical">
                  <Radio value="singleUser" data-testid="singleUserId">{staticMessage?.SelectChannel?.akkuDirectory}</Radio>
                  <Radio value="sync" data-testid="syncId">{staticMessage?.SelectChannel?.syncDirectory}</Radio>
                </Space>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>
      <div className="button-group bg-white flex justify-end items-center pr-8">
        <Button className=" mr-5 flex items-center justify-evenly font-semibold text-[20px] font-Inter  w-[186px] h-[56px]" size="large" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          data-testid="submit-button"
          type="primary"
          htmlType="submit"
          className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
          onClick={handleRedirectNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
