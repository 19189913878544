import React from "react";

const PermissionHeader = () => {
  return (
    <div className="w-full flex add-permission-container bg-[#F9FAFB] ">
      <div className="w-1/2">
        <h3 className=" font-Inter">Products</h3>
      </div>
      <div className="w-1/2">
        <div className="w-full flex action">
          <p>Create</p>
          <p>Edit</p>
          <p>View</p>
          <p>Delete</p>
        </div>
      </div>
    </div>
  );
};

export default PermissionHeader;
