import React from 'react'
import "./CSVUpload.scss"
import CSVUploadHeader from './CSVUploadHeader'
import DragAndDrop from './DragAndDrop'

export default function CSVUpload() {
    return (
        <div className="akku-container">
            <div className="main-container">
                <div className="dashboard-container _set-height rounded CSVUpload">
                    <CSVUploadHeader />
                    <div className='dragAndDrop mt-8'>
                        <DragAndDrop />
                    </div>
                </div>
            </div>
        </div>
    )
}
