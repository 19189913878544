import React from "react";
import "./MappingNotification.scss";
import { DatePicker, DatePickerProps, Select } from "antd";
import type { Dayjs } from "dayjs";
import { ErrorMessage, Field } from "formik";
import { staticMessage } from "../../../../../../../../constant/StaticContent";

const NotificationFrequency = (props: any) => {
  const dropDownList1: any = [{ label: "User", value: "user" }];

  const onChange: DatePickerProps<Dayjs[]>["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <div className="frequency pb-20">
      <div className="w-full flex justify-between">
        <div className=" w-full relative">
          <div className="w-full flex flex-col justify-between">
            <div className="w-full flex items-center justify-between pb-5">
              <p className="w-[60%] frequency-question">{staticMessage?.MappingNotification?.selectDate}</p>
              <div className="w-[40%]">
                <DatePicker className="h-14 w-[260px]" onChange={onChange} showTime needConfirm={false} />
              </div>
            </div>
            <div className="w-full flex items-center justify-between">
              <p className="w-[60%] frequency-question">{staticMessage?.MappingNotification?.periodicRun}</p>
              <div className=" w-[40%] -form">
                <Field
                  as={Select}
                  suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                  className=" w-[260px] h-[56px]"
                  name="fromMail"
                  filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {dropDownList1?.map((option: any) => (
                    <Select.Option key={option.value} value={option.label}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Field>
                <ErrorMessage name="fromMail" component="div" className="error-message" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationFrequency;
