import { Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import SuspendUserModal from "./SuspendUserModal";
import { UserObject } from "./usertableTypes";
import { retrieveData } from "../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../layouts/toast/ToastBar";
import { useUserContext } from "../../userContext/UserContext";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import UserTableHeader from "./UserTableHeader";
import { useSelector } from "react-redux";
import { modulePermissions } from "../../../../../../const/RolePermissions";
import { getPermissionStyle, hasPermission } from "../../../../../../redux/slice/permissions/permissionUtils";
import PermissionsModal from "../../../../../../layouts/permissionsModal/PermissionsModal";
import ReactiveUser from "../../../../../../assets/images/icons/reactive.png";
import ReactiveUserModal from "./ReactiveUserModal";
import { ErrorMessages } from "../../../../../../const/Messages";
import Loader from "../../../../../../layouts/component/Loader";
const SyncUserDetails = (props: any) => {
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasDeletePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "delete");
  const hasUpdatePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "update");
  const userName = retrieveData("userName", true);

  const realmName = retrieveData("realmName", true);
  const syncDetails = useGetApiRequests("syncDetails", "POST");
  const editUser = useGetApiRequests("editUser", "PUT");
  const { getFilterData, customerOriginalTableData, totalRecords, handleSearch, statusFilter } = useUserContext();

  const { selectedRowKeys, rowObject, setSelectedRow, clearSelection } = props;
  const selectedRowLength = selectedRowKeys.length;
  const [loadings, setLoadings] = useState<boolean>();
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationType, setNotificationType] = useState(false);
  const [requestPayload, setRequestPayload] = useState<any>([]);
  const [getToken, setGetToken] = useState<any>();
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const [activateUser, setActivateUser] = useState<boolean>(false);
  const [suspendedUsers, setSuspendedUser] = useState([]);
  const [isActiveDisabled, setIsActiveDisabled] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);

  const token = retrieveData("authToken", true);

  const tableLength = customerOriginalTableData?.length || 0;

  useEffect(() => {
    tokenDecoder();
  }, [props]);

  useEffect(() => {
    const filteredArray = rowObject.filter((obj: any) => selectedRowKeys?.includes(obj?.key) && obj?.status === "suspended");
    if (filteredArray?.length > 0) {
      setSuspendedUser(filteredArray);
    }
    let filtered: boolean = false;
    rowObject.forEach((item: UserObject) => {
      if (item.email === userName && selectedRowKeys?.includes(item?.key)) {
        filtered = true;
      }
    });
    if (filtered && selectedRowLength === 1) {
      setIsActiveDisabled(true);
    } else {
      setIsActiveDisabled(false);
    }
  }, [selectedRowKeys]);

  const handelNotificationClose = () => {
    setNotificationOpen(false);
    clearSelection();
    handleSearch("");
  };

  const handleRequestSuspend = (el: string) => {
    if (!rowObject) {
      return;
    }
    const requestDtoList: any = [];

    rowObject.forEach((item: UserObject) => {
      if (item.email !== userName) {
        if (selectedRowKeys.includes(item?.key)) {
          let storeObject = {
            id: item?.userId,
            username: item?.email,
            firstName: item?.name?.split(" ")[0],
            lastName: item?.name?.split(" ")[1],
            email: item?.email,
            enabled: false,
          };
          requestDtoList.push(storeObject);
        }
      }
    });
    let requestPayload = {
      realmName: realmName,
      userEntityKeyValueDtoList: [...requestDtoList],
      userStatus: el,
    };
    setRequestPayload(requestPayload);

    setNotificationType(false);
    if (el === "suspended") {
      setNotificationOpen(true);
      setNotificationType(true);
    } else {
      setNotificationOpen(true);
    }
  };
  function base64UrlDecode(str: any) {
    // Replace URL-safe base64 encoding characters (- and _) with the standard base64 characters (+ and /)
    let base64 = str?.replace(/-/g, "+").replace(/_/g, "/");
    // Decode the base64 string into a binary string
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  }

  function tokenDecoder() {
    let decodedToken;
    try {
      const parts = token?.split(".");
      if (parts?.length === 3) {
        decodedToken = base64UrlDecode(parts[1]);
        setGetToken(decodedToken);
      }
    } catch (error) {
      console.error("Failed to decode JWT:", error);
    }
  }

  const handleSync = async () => {
    let payload = {
      emailId: getToken?.email,
      realmName: realmName,
    };
    try {
      const response: any = await syncDetails(payload);
      const status = response.status;
      if (status === 200) {
        triggerNotification("success", response?.data?.message, "", "topRight");
        getFilterData(0, 10);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  const handleOpenHelpModal = () => {
    setPermissionsModal(true);
  };
  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };
  const handleReactiveUser = async () => {
    setLoadings(true);
    const userEntityKeyValueDtoList = suspendedUsers?.map((user: any) => {
      const [firstName, lastName] = user.name.split(" ");
      return {
        id: user.key,
        username: user.email,
        firstName: firstName || "",
        lastName: lastName || "",
        email: user.email,
        enabled: true,
      };
    });

    const payload: any = {
      realmName: realmName,
      userEntityKeyValueDtoList: userEntityKeyValueDtoList,
      userStatus: "reactivated",
    };
    try {
      const response: any = await editUser(payload);
      const status = response.status;
      if (status === 200) {
        setLoadings(false);
        handleCancelReactiveUser();
        clearSelection();
        setSuspendedUser([]);
        triggerNotification("success", response?.data?.message, "", "topRight");
        getFilterData(0, 10);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  const handleCancelReactiveUser = () => {
    setActivateUser(false);
  };
  const openReactiveUser = () => {
    setActivateUser(true);
  };
  return (
    <div className="w-full flex justify-between items-center  pb-6" data-testid="syncUser-id">
      <div className="flex w-full items-center ">
        <UserTableHeader selectedRowLength={selectedRowLength} totalRecords={totalRecords} clearSelection={clearSelection} tableLength={tableLength} handleSync={handleSync} loadings={loadings} />
        {selectedRowLength > 0 && (
          <>
            {suspendedUsers?.length > 0 && (
              <Tooltip title={"Reactivate User"}>
                <Button
                  onClick={() => {
                    hasUpdatePermission ? openReactiveUser() : handleOpenHelpModal();
                  }}
                  className={`blockAndDeleteButton ml-6 sync-btn ${getPermissionStyle(hasDeletePermission)}`}
                  icon={<img src={ReactiveUser} alt="ReactiveUser" />}
                />
              </Tooltip>
            )}

            {!isActiveDisabled && (
              <>
                {statusFilter !== "suspended" && (
                  <Tooltip title={"Suspend"}>
                    <Button
                      onClick={() => {
                        hasDeletePermission ? handleRequestSuspend("suspended") : handleOpenHelpModal();
                      }}
                      className={`blockAndDeleteButton ml-6 sync-btn ${getPermissionStyle(hasDeletePermission)}`}
                      icon={<span className="material-icons-outlined text-[#667085] text-[15px]">block</span>}
                    />
                  </Tooltip>
                )}

                <Tooltip title={"Delete"}>
                  <Button
                    onClick={() => {
                      hasDeletePermission ? handleRequestSuspend("deleted") : handleOpenHelpModal();
                    }}
                    className={`blockAndDeleteButton  ml-6 sync-btn  ${getPermissionStyle(hasDeletePermission)}`}
                    icon={<span className="material-icons-outlined text-[#667085] text-[15px]">delete</span>}
                  />
                </Tooltip>
              </>
            )}
          </>
        )}
      </div>
      {notificationOpen && (
        <SuspendUserModal
          notificationOpen={notificationOpen}
          handelNotificationClose={handelNotificationClose}
          notificationType={notificationType}
          payload={requestPayload}
          setSelectedRow={setSelectedRow}
          setLoader={setLoader}
        />
      )}
      {loader && <Loader />}
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
      {activateUser && <ReactiveUserModal active={activateUser} handleProceed={handleReactiveUser} handleCancel={handleCancelReactiveUser} displayContent={ErrorMessages?.activateUser} />}
    </div>
  );
};

export default SyncUserDetails;
