import React, { useEffect, useState } from "react";
import generateTitle from "../../../../layouts/Table/generateTitle";
import { SortOrder } from "antd/lib/table/interface";
import { Table, Tooltip } from "antd";
import ConnectionManagementHeader from "./ConnectionManagementHeader";
import { useSelector } from "react-redux";
import { prerequisitesTable } from "../../../../constant/prerequisites/prerequisites";
import CustomFilterDropdown from "./CustomFilterDropdown";

const ConnectionManagementTable = (props: any) => {
  const { setCurrentTabStatus } = props;
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filterVisiblity, setFilterVisiblity] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const setTableData = useSelector((state: any) => state?.credentialsSlice?.setTableData);

  const [connectionTableData, setConnectionTableData] = useState<[]>([]);

  useEffect(() => {
    handleGetConnectionList();
  }, [setTableData]);
  const handleGetConnectionList = () => {
    setSelectedRow([]);
    setSelectedRowKeys([]);
    const transformedArray = setTableData?.map((item: any, index: any) => ({
      key: item?.userId,
      name: `${item?.firstName} ${item?.lastName}`,
      connectionId: item?.connectionId,
      credentialExchangeId: item?.connectionExchangeId || "-",
      createdOn: item?.connectionCreatedDate,
      status: getStatus(item?.connectionExchangeStatus),
    }));
    setConnectionTableData(transformedArray);
  };
  const getStatus = (status: string) => {
    if (status === "offer_sent") {
      return "offered";
    } else if (status === "credential_acked") {
      return "accepted";
    } else {
      return "pending";
    }
  };
  const groupFilter = (filtered: any) => (
    <Tooltip title={"Filter"}>
      <p onClick={() => setFilterVisiblity(!filterVisiblity)} className="flex text-[20px] items-center justify-start font-Inter text-[#1D1D24] font-medium ">
        <span className="material-symbols-outlined  group-filter ml-2">filter_list</span>
      </p>
    </Tooltip>
  );

  const columns = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Name",
          sortColumns?.find(({ column }: any) => column.key === "name"),
        ),
      key: "name",
      dataIndex: "name",
      defaultSortOrder: "ascend" as SortOrder,
      width: "20%",
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      render: (value: any) => (
        <div>
          <p className={`${value?.toLowerCase()} credentials-data text-[#5441da] `}>
            <span>{value || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: () => generateTitle("Connection ID", ""),
      dataIndex: "connectionId",
      key: "connectionId",
      defaultSortOrder: "ascend" as SortOrder,
      width: "25%",
      render: (value: any) => (
        <div>
          <p className={` credentials-data `}>
            <span>{value || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: () => generateTitle("Credential Exchange ID ", ""),
      dataIndex: "credentialExchangeId",
      key: "credentialExchangeId",
      defaultSortOrder: "ascend" as SortOrder,
      render: (value: any) => (
        <div>
          <p className={` credentials-data `}>
            <span>{value || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: () => generateTitle("Created on", ""),
      dataIndex: "createdOn",
      key: "createdOn",
      render: (value: any) => (
        <div>
          <p className={`credentials-data `}>
            <span>{value || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: () => generateTitle("Status", ""),
      dataIndex: "status",
      key: "status",
      filterIcon: groupFilter,
      filterDropdown: CustomFilterDropdown,
      onFilter: (value: any, record: any) => record.status === value,
      render: (value: any) => (
        <div>
          <p className={`${value?.toLowerCase()} credentials-data status capitalize`}>
            <span>{value || "-"}</span>
          </p>
        </div>
      ),
    },
  ];
  const onSelectChange = (newSelectedKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedKeys);
    setSelectedRow(newSelectedKeys);
    setLoader(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: getRowClassName(record),
    }),
  };
  const getRowClassName = (record: any) => {
    return record.status !== "accepted";
  };

  return (
    <>
      <ConnectionManagementHeader setCurrentTabStatus={setCurrentTabStatus} selectedRow={selectedRow} connectionTableData={connectionTableData} />
      <div className="mt-5 w-full credentials-table">
        <Table
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: prerequisitesTable.pageSizeOptions,
          }}
          loading={loader}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={connectionTableData}
        />
      </div>
    </>
  );
};

export default ConnectionManagementTable;
