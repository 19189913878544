import React, { useState } from "react";
import "./RoleManagement.scss";
import RolePermissionHeader from "./layout/RolePermissionHeader";
import SearchInput from "../../layouts/component/SearchField";
import RoleManagementTable from "./layout/RoleManagementTable";

const RoleManagement = () => {
  const [search, setSearch] = useState<string>("");
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    setSearch(searchValue);
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full rounded role-management">
          <RolePermissionHeader />
          <div className="mt-6">
            <SearchInput search={search} placeholder="Search" onChange={handleSearch} />
          </div>
          <div className="w-full">
            <RoleManagementTable search={search} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;
