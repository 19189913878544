import React, { useState } from "react";
import "./ManageConnection.scss";
import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";
import SelectConnector from "../modal/SelectConnector";

const ConnectionSourceAndTarget = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSelectConnector = () => {
    setOpenModal(true);
  };
  return (
    <>
      <div className="flex w-full px-[30px] manage-connector">
        <div className="w-[40%]">
          <p className="source">Select Source</p>
          <div className="browse-card">
            <div
              className="browse  h-full flex flex-col"
              onClick={() => {
                handleSelectConnector();
              }}
            >
              <div className="w-11">
                <ImageView src={images.link} />
              </div>
              <p>Browse from connectors</p>
            </div>
            <div className="selected-source hidden ">
              <p className="flex items-center  name">
                <div className="w-11 mr-3">
                  <ImageView src={images.link} />
                </div>
                Akku_Connector
              </p>
              <p className="query">query DetectEnginePerformanceProblem</p>
            </div>
          </div>
        </div>
        <div className="w-[20%] flex justify-center items-center">
          <div className="w-20">
            <ImageView src={images.rightArrow} />
          </div>
        </div>
        <div className="w-[40%]">
          <p className="source">Select Target</p>
          <div className="browse-card">
            <div className="selected-source ">
              <p className="flex items-center  name">
                <div className="w-11 mr-3">
                  <ImageView src={images.link} />
                </div>
                Jira_Connector
              </p>
              <p className="query">query DetectEnginePProblems</p>
            </div>
          </div>
        </div>
      </div>

      {openModal && <SelectConnector openModal={openModal} handleClose={handleClose} />}
    </>
  );
};

export default ConnectionSourceAndTarget;
