
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import { setCredentialUpdate,setSuccessMessage } from "../../../redux/slice/credential-management/credentialsSlice";
import { retrieveData } from "../../../services/storage/Storage";
 const baseUrl = process.env.REACT_APP_BLOCKCHAIN_BASEURL;
 

 export const handleConnectionList = (credentialData:any) => {
    const transformedArray =
    
      credentialData?.map((item: any) => ({
        id: item.userId,
        name: item.username,
        value: item.userId,
        firstName: item.firstName,
        lastName: item.lastName,
        connectionId: item.connectionId,
        connectionCreatedDate: item.connectionCreatedDate,
        connectionStatus: item.connectionStatus,
        connectionExchangeId: item.connectionExchangeId,
        connectionExchangeStatus: item.connectionExchangeStatus,
      }));
      return transformedArray;
  };


  export const handleIssueCredentialApi = async (setErrorMessage:any,setLoader:any,selectedId:any,handleClosePopup:any,isUpdate:boolean,userDropDownData:any,dispatch:any) => {
    const token: string = retrieveData("authToken", true);
    setErrorMessage("");
    setLoader(true);
    const url = `${baseUrl + "v1/user/issue-credential/send"}`;
    const payload = {
        userId: [...selectedId  ] ,
    
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData?.message;
        const parts = errorMessage?.split(":");
        const connectionId = parts[1]?.trim();
        const error = parts[0].trim();
        const foundObject: any = userDropDownData?.filter((obj: any) => obj.connectionId === connectionId);
        const errorMsg = `${error} for ${foundObject.length !== 0 && foundObject[0]?.name}`;
        triggerNotification("error", "", foundObject?.length !== 0 ? errorMsg : errorMessage, "topRight");
        throw new Error(errorMessage);
      }
      const data = await response.json();
      setTimeout(() => {
        setLoader(false);
        dispatch(setCredentialUpdate(!isUpdate));
        dispatch(setSuccessMessage(data?.message));

        handleClosePopup();
      }, 1000);
    } catch (error: any) {
      setTimeout(() => {
         handleRequestError(error);
        setLoader(false);
      }, 1000);
    }
  };

 export const connectionFilter =[
   { text: "Accepted", value: "accepted" },
  { text: "Offered", value: "offered" },
  { text: "Pending", value: "Pending" },
 
 ]
 export const credentialFilter=[  
  { text: "Active", value: "active" },
  { text: "Waiting", value: "Waiting" },
 ]