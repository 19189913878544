import React from "react";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";

const EmptyDataCard = () => {
  return (
    <>
      <div className="h-full rounded-lg w-full  flex justify-center items-center">
        <div className="w-[92%] h-[90%]  flex justify-center items-center flex-col rounded-lg bg-[#F5F5FA] ">
          <ImageView src={images.addIcon} className="w-10 my-apps-add-icon" alt="add-icon" />
          <p className="text-[20px]  text-[#000] font-medium font-Inter">Add new app</p>
        </div>
      </div>
    </>
  );
};

export default EmptyDataCard;
