import React from "react";
import "./Breadcrumb.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setListOfRestrictionModal } from "../../redux/slice/Users/UserDashboardUpdateSlice";

const Breadcrumb = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(setListOfRestrictionModal(true));

    navigate("/user");
  };
  return (
    <ul className="flex breadcrumb">
      <li className="font-Inter pr-1 cursor-pointer" onClick={handleNavigate}>
        User management /
      </li>
      <li className="active font-Inter">Device level</li>
    </ul>
  );
};

export default Breadcrumb;
