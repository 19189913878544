import React from "react";
import { ErrorMessage, Field } from "formik";
import { Input, Select, Tooltip } from "antd";
import { helpMessage } from "./sessionIdleFormhelper";
const LoginSettingForm = (props: any) => {
  const { handleOnChange, setFieldTouched, errors, handleBlurEvent, setFieldValue } = props;
  const userAttributes = ["Min"];
  return (
    <div className="w-full ">
      <p className="font-Inter font-semibold text-[24px] pb-7">Login Settings</p>
      <div className="sso-login w-full ">
        <div className="w-[50%] flex pt-[10px] flex-col sso-container">
          <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
            <div className="mb-10 w-[65%] mr-5 flex  items-center sso-container-field idle relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium w-[200px]">
                Login Timeout*
                <Tooltip title={helpMessage.loginTimeout}>
                  {" "}
                  <span className="material-symbols-outlined  relative ml-1 cursor-pointer top-1"> help</span>
                </Tooltip>
              </p>
              <div className="sso-input">
                <Field
                  onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                  as={Input}
                  name="loginTimeout"
                  className="h-[48px] font-medium text-[18px] w-[190px]"
                  type="number"
                  data-testid="loginTimeout-input"
                />
                <ErrorMessage name="loginTimeout" component="div" className="error-message" />
              </div>
            </div>
            <div className="mb-10 w-[30%] minute relative">
              <Field
                onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                as={Select}
                name={`min`}
                value="Min"
                suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                className={" w-[90px] h-[48px]  pointer-events-none "}
                onChange={(value: any) => handleOnChange(setFieldValue, "target", value)}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {userAttributes?.map((option: any) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Field>
            </div>
          </div>
        </div>
        <div className="w-[50%] flex pt-[10px] flex-col sso-container">
          <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
            <div className="mb-10 w-[65%] mr-5 flex  items-center sso-container-field idle relative">
              <p className="text-[#1D1D24] w-[200px] text-[18px] font-Inter font-medium">
                Login Action Timeout*
                <Tooltip title={helpMessage.loginActionTimeout}>
                  <span className="material-symbols-outlined  relative ml-1 cursor-pointer top-1"> help</span>
                </Tooltip>
              </p>
              <div className="sso-input">
                <Field
                  onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                  as={Input}
                  type="number"
                  className="h-[48px] font-medium text-[18px] w-[190px]"
                  name="loginActionTimeout"
                  data-testid="loginAction-input"
                />
                <ErrorMessage name="loginActionTimeout" component="div" className="error-message" />
              </div>
            </div>
            <div className="mb-10  minute relative w-[30%]">
              <Field
                onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                as={Select}
                suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                name={`min`}
                className={" w-[90px] h-[48px] pointer-events-none"}
                value="Min"
                onChange={(value: any) => handleOnChange(setFieldValue, value, "target")}
                filterOption={(option: React.ReactElement, input: string) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {userAttributes?.map((option: any) => (
                  <Select.Option value={option} key={option}>
                    {option}
                  </Select.Option>
                ))}
              </Field>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSettingForm;
