import { Button, Select } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import React, { useState } from "react";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
interface AddAttribute {}
interface UserAttribute {}

const MappingConnectorAttributes = (props: any) => {
  const dispatch = useDispatch();

  const { values,  setFormInitial, transformList } = props;

  const [adAttributeList, setAdAttributeList] = useState<AddAttribute[]>([]);
  const [userAttributeList, setUserAttributeList] = useState<UserAttribute[]>([]);
  const handleOnChange = ( field: string,  index: number,val: any, values: any) => {
    const fields = field;
    const setFields = values?.forms.map((elm: any, ind: number) => {
      if (index === ind) {
        elm[fields] = val;
      }
    });
    setFormInitial(setFields);
    if (field === "target") {
      const filteredUserAttributes = userAttributeList.filter((attribute: any) => attribute !== val);
      setUserAttributeList(filteredUserAttributes);
    } else {
      const filteredAttributes = adAttributeList.filter((attribute: any) => attribute !== val);
      setAdAttributeList(filteredAttributes);
    }
  };

  const handleRemove = (remove: any, index: number, value: any) => {
    remove(index);
    setAdAttributeList((prevAttributes: any) => [...prevAttributes, value?.source].filter((attribute) => attribute.trim() !== ""));
    setUserAttributeList((prevUsersList: any) => [...prevUsersList, value?.target].filter((attribute) => attribute.trim() !== ""));
  };

  const handleBack = () => {
    let step = {
      childStepper: "",
      activeStep: 1,
      
    };
    dispatch(setCurrentStep(step));
  };
  return (
    <div>
      <FieldArray name="forms">
        {({ push, remove }) => (
          <div className="mapper pl-10 pt-20">
            {values.forms.map((formItem: any, index: any) => (
              <div key={formItem.userType} className="w-full flex mapper-attributes">
                <div className=" w-[33%] pb-6 mr-10 source relative">
                  {index === 0 && <p className="text-center absolute top-[-50px] w-full "> SOURCE - Akku</p>}

                  <Field
                    as={Select}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    className={values.forms[index]?.isCheck ? " w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : " w-[100%] h-[56px] "}
                    name={`forms.${index}.source`}
                    values={values.forms[index]?.source}
                    onChange={(value: any) => handleOnChange("source", value, index, values)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    data-testid="usersIt-input"
                  >
                    {adAttributeList?.map((option: any) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name={`forms.${index}.source`} component="div" className="error-message custom-error" />
                </div>
                <div className=" w-[33%] pb-6 mr-10 source relative">
                  {index === 0 && <p className="text-center absolute top-[-50px] w-full"> TARGET - Jira</p>}

                  <Field
                    as={Select}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    className={values.forms[index]?.isCheck ? " w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : " w-[100%] h-[56px] "}
                    name={`forms.${index}.target`}
                    values={values.forms[index]?.target}
                    onChange={(value: any) => handleOnChange( "target", value, index, values)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    data-testid="usersIe-input"
                  >
                    {userAttributeList?.map((option: any) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name={`forms.${index}.target`} component="div" className="error-message custom-error" />
                </div>
                <div className=" w-[33%] pb-6  relative ">
                  {index === 0 && <p className="text-center absolute top-[-50px] w-full"> TRANSFORMATION</p>}

                  <Field
                    as={Select}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    className={values.forms[index]?.isCheck ? " w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : " w-[100%] h-[56px] "}
                    name={`forms.${index}.userType`}
                    values={values.forms[index]?.userType}
                    onChange={(value: any) => handleOnChange( "userType", value, index, values)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    data-testid="usersIt-input"
                  >
                    {transformList.map((option: any) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.id}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name={`forms.${index}.userType`} component="div" className="error-message custom-error" />
                </div>

                <div className="w-[270px] flex justify-start ml-5 ">
                  {index === 0 && <br />}

                  <span
                    className={` add material-symbols-outlined cursor-pointer ml-5 ${values.forms.length - 1 === index ? "add-map-block" : "add-map-hidden"}`}
                    onClick={() => push({ source: "", target: "", userType: "", isCheck: false, id: "" })}
                  >
                    add
                  </span>
                  <span
                    className={` add material-symbols-outlined cursor-pointer  ml-5 ${values.forms.length - 1 === index && index != 0 ? "" : "add-map-hidden"}`}
                    onClick={() => handleRemove(remove, index, values.forms[index])}
                  >
                    remove
                  </span>
                </div>
              </div>
            ))}
            <div className="footer">
              <div className="modal-footer w-[95%] mx-auto ">
                <div className="w-full flex justify-end">
                  <>
                    <CustomButtonBack onClick={handleBack} text={"Back"} />

                    <Button type="primary" htmlType="submit" className="bg-[#5441DA] ml-5  submit-btn">
                      Next
                    </Button>
                  </>
                </div>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default MappingConnectorAttributes;
