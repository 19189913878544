import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";

export interface UserData {
  userId: string;
  name: string;
  email: string;
  status: string;
  clientDetailsList: any[];
  groupNames: string;
  deviceRestrictionStatus: string;
}

export const createTableObject = (response: any[] | null | undefined) => {
  if (!response || response.length === 0) {
    // Handle the case when response is null or empty
    return [];
  }

  let deformedVal = response.map((item: any, index: number) => {
    const clientDetailsList = item?.clientDetailDtos?.map((clientItem: any) => ({
      userId: item?.userId || null,
      email: item?.email || null,
      ...clientItem,
    }));
    return {
      key: item?.userData?.userId,
      name: item?.userData?.name,
      email: item?.userData?.email,
      status: item?.userData?.status,
      service: clientDetailsList,
      userId: item?.userData?.userId,
      groups: item?.userData?.groupNames,
      restrictions: item?.userData?.deviceRestrictionStatus,
    };
  });

  return deformedVal;
};

export const getRolesList = async (realmId: string, roleList: any) => {
  const pathParams = {
    realmId: realmId,
  };
  try {
    const response: any = await roleList("", "", pathParams);
    const status = response.status;
    if (status === 200) {
      const reformedRoleList = response?.data?.data?.map((item: any) => ({
        label: item?.name,
        key: item?.id,
        value: item?.id,
      }));
      return reformedRoleList;
    }
  } catch (err: any) {
    handleRequestError(err);
  }
};
