import React, { useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import "./MappingUserStatus.scss";
import { Button, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAddUserStepper } from "../../../../../../../../redux/slice/AddUserSlice";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../../../../services/storage/Storage";
import Loader from "../../../../../../../../layouts/component/Loader";
import { useAddUserContext } from "../../../../add-user-context/AddUserContext";
import { setUpdateDashboard } from "../../../../../../../../redux/slice/Users/UserDashboardUpdateSlice";
import { useNavigate } from "react-router-dom";
const MappingUserStatus = () => {
  const { closeModal } = useAddUserContext();
  const [addUserType, setAddUserType] = useState("triggerChangedUsersSync");
  const syncMapperAttributes = useGetApiRequests("syncMapperAttributes", "POST");
  const [loader, setLoader] = useState<boolean>(false);
  const componentId = retrieveData("componentId", true);
  const realmName = retrieveData("realmName", true);
  const toggleTable = useSelector((state: any) => state?.UserDashboardUpdateSlice?.updateDashboard);
  const navigate = useNavigate();

  const userStatus: any = [
    { type: "Sync changed users", checked: false, val: "triggerChangedUsersSync" },
    { type: "Sync all users", checked: true, val: "triggerFullSync" },
  ];
  const dispatch = useDispatch();
  const stepper = useSelector((state: any) => state.addUserSlice.steeper);
  const handelAddUser = (event: any) => {
    setAddUserType(event.target.value);
  };
  const handleBack = () => {
    const newStepper = stepper.map((step: any, index: number) => {
      if (index === 2) {
        return {
          ...step,
          active: false,
          status: "check",
          icon: "check",
          
        };
      } else if (index === 3) {
        return {
          ...step,
          active: false,
          status: "check",
          icon: "check",
          children: [
            {
              description: "Enter child step 1 details",
              active: false,
              title: "Authentication",
            },
            {
              description: "Enter child step 2 details",
              active: false,
              title: "Search & Update",
            },
          ],
        };
      } else if (index === 4) {
        return {
          ...step,
          active: true,
          status: "inprogress",
          icon: "",
          children: [
            {
              description: "Enter child step 1 details",
              active: true,
              title: "Attributes",
            },
            {
              description: "Enter child step 2 details",
              active: false,
              title: "User Status",
            },
            {
              description: "Enter child step 2 details",
              active: false,
              title: "Notifications",
            },
          ],
        };
      } else {
        return {
          ...step,
          active: false,
        };
      }
    });
    dispatch(setAddUserStepper(newStepper));
  };

  const handleUserSync = async () => {
    setLoader(true);
    const payload = {
      componentId: componentId.data.componentId,
      realmName: realmName,
      action: addUserType,
    };
    try {
      const response: any = await syncMapperAttributes(payload);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        navigate("/user");
        closeModal();

        triggerNotification("success", response?.data?.message, "", "topRight");
        dispatch(setUpdateDashboard(!toggleTable));
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  return (
    <div className="w-full px-12 user status relative">
      {loader && <Loader />}
      {/* <CommonHeader text={staticMessage?.MappingUserStatus?.mappingUserStatusHeading} onClick={handleBack} /> */}
      <CommonHeader text={"Mapper Sync"} onClick={handleBack} className="cursor-pointer" />

      <div className="w-full pt-20 px-12">
        {/* <p className="user-info pb-7">{staticMessage?.MappingUserStatus?.mappingUserStatusSubheading}</p> */}
        <p className="user-info pb-7">Action</p>

        <div className="w-full flex ">
          <Radio.Group
            className=" w-1/2 user-action  pb-5"
            onChange={(e) => {
              handelAddUser(e);
            }}
            defaultValue={userStatus.find((item: any) => item.checked).val}
          >
            {userStatus.map((elm: any, index: any) => (
              <Radio value={elm.val}>
                <div className="flex min-w-[200px] flex-col">
                  <p className="user-type "> {elm.type}</p>
                </div>
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>
      <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[95px]">
        <div className="modal-footer w-[95%] mx-auto ">
          <div className="w-full flex justify-end">
            <Button
              type="primary"
              // onClick={handleUserAction}
              onClick={handleUserSync}
              className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
            >
              Sync
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingUserStatus;
