import React, { createContext, useState, useContext, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAddUserSlice, setAddUserStepper } from "../../../../../redux/slice/AddUserSlice";

interface UserData {
  // Define your user data structure here
}

interface UserContextType {
  userData: UserData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
  isOpen: boolean;
  setAddUserType: any;
  openModal: () => void;
  closeModal: () => void;
  handleNext: (value: number, val: number) => void; // Add handleNext to the UserContextType
  handlePrevious: () => void; // Add handlePrevious to the UserContextType
  activeStep: number;
  addUserType: string;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  redirect: string;
  handleRedirect: boolean;
  setHandleRedirect: any;
}

const initialUserData: UserData = {
  // Initialize your user data here
};

const initialModalState = false;

export const AddUserContext = createContext<UserContextType | undefined>(undefined);

export const AddUserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const redirect = "";
  const dispatch = useDispatch();
  const stepper = useSelector((state: any) => state.addUserSlice.steeper);
  const [userData, setUserData] = useState<UserData | null>(initialUserData);
  const [addUserType, setAddUserType] = useState<string>("sync");
  const [handleRedirect, setHandleRedirect] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(initialModalState);
  const [activeStep, setActiveStep] = useState(0);

  const openModal = () => {
    setIsOpen(true);
    dispatch(clearAddUserSlice());
  };

  const closeModal = () => {
    setIsOpen(false);
    setAddUserType("sync");
    dispatch(clearAddUserSlice());
  };

  const handleNext = (currentIndex: number, nextIndex: number) => {
    const updatedStepper = updateStepperOnNext(stepper, currentIndex, nextIndex);
    dispatch(setAddUserStepper(updatedStepper));
  };

  const updateStepperOnNext = (currentStepper: any[], currentIndex: number, nextIndex: number) => {
    return currentStepper.map((step, index) => {
      if (index === currentIndex) {
        return updateFirstStep(step);
      } else if (index === nextIndex) {
        return updateSecondStep(step);
      } else {
        return updateRestSteps(step);
      }
    });
  };

  const updateFirstStep = (step: any) => ({
    ...step,
    status: "check",
    icon: "check",
    active: false,
  });

  const updateSecondStep = (step: any) => ({
    ...step,
    status: "inprogress",
    icon: "",
    active: true,
  });

  const updateRestSteps = (step: any) => ({
    ...step,
    active: false,
  });

  const handlePrevious = () => {
    const newStepper = stepper.map((step: any, index: number) => ({
      ...step,
      active: index === getActiveStepIndex() ? false : index === getActiveStepIndex() - 1,
    }));
    dispatch(setAddUserStepper(newStepper));
  };

  const getActiveStepIndex = () => {
    return stepper.findIndex((step: any) => step.active);
  };

  return (
    <AddUserContext.Provider
      value={{
        userData,
        setUserData,
        isOpen,
        openModal,
        closeModal,
        handleNext,
        handlePrevious,
        activeStep,
        setActiveStep,
        setAddUserType,
        addUserType,
        redirect,
        handleRedirect,
        setHandleRedirect,
      }}
    >
      {children}
    </AddUserContext.Provider>
  );
};

export const useAddUserContext = () => {
  const context = useContext(AddUserContext);

  if (!context) {
    throw new Error("useAddUserContext must be used within a UserProvider");
  }

  return context;
};
