import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  appRender: boolean;
}

const initialState: AuthState = {
    appRender: false,
};

const courseTabSlice = createSlice({
  name: 'navigateCourseTab',
  initialState,
  reducers: {
    setAppRender: (state, action: PayloadAction<boolean>) => {
      state.appRender = action.payload;
    },
    clearSetAppRender: state => {
      state.appRender = false;
    },
  },
});

export const { setAppRender, clearSetAppRender } = courseTabSlice.actions;
export default courseTabSlice.reducer;
