  
import * as Yup from "yup";
  
  export const LDAPvalidationSchema = Yup.object().shape({
    connectionUrl: Yup.string().required("Connection URL is required"),
    ldapName: Yup.string().required("Directory name is required"),
    bindDn: Yup.string().required("Bind DN is required"),
    bindCredentials: Yup.string().required("Bind credentials is required"),
  });

  export const LDAPAuthenticationSchema = Yup.object().shape({
    editMode: Yup.string().required("Edit Mode is required"),
    usersDn: Yup.string().required("Users DN is required"),
    usernameLDAPAttribute: Yup.string().required("Username LDAP attributes is required"),
    rdnLdapAttribute: Yup.string().required("RDN LDAP attributes is required"),
    uuidLdapAttribute: Yup.string().required("UUID LDAP attributes required"),
    userObjectClasses: Yup.string().required("User object classes is required"),
    syncUpdateInterval: Yup.string().required("Sync update interval is required"),
    certificate: Yup.string().required("Certificate is required"),
  });