import React from "react";
import LicenseCard from "../../../layouts/dashboard-module/license-card/LicenseCard";
import "./Assessment.scss";
import RiskManagementCard from "../../../layouts/dashboard-module/risk-management/RiskManagementCard";
import Application from "../../../layouts/dashboard-module/application/Application";
import CustomerTable from "../../../layouts/dashboard-module/customer-table/CustomerTable";
import MFAChart from "../../../layouts/dashboard-module/mfa-chart/MFAChart";
import RecoveryAttempts from "../../../layouts/dashboard-module/attempts/RecoveryAttempts";
import LicenseManagement from "../../../layouts/dashboard-module/license/LicenseManagement";
import ProductAdoption from "../../../layouts/dashboard-module/product-adoption/ProductAdoption";
import { useSelector } from "react-redux";
const Assesments = () => {
  const toggleTable = useSelector((state: any) => state?.TableExpand?.expanedTab);
  const userProfileDetails = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);

  return (
    <div className={!toggleTable ? "dashboard-container" : "dashboard-container table-container"} data-testid="Application">
      {!toggleTable && (
        <div className="license-container dashboard-left flex mb-5">
          <div className="dashboard-right w-[60%] mr-4">
            <LicenseManagement />
            <div className="mt-5">
              <Application />
            </div>
            <div className="mt-5">
              <RecoveryAttempts />
            </div>
          </div>
          <div className="w-[40%] dashboard-right assessment">
            <ProductAdoption />
            <div className="risk-assessment mt-5">
              <RiskManagementCard />
            </div>
            <div className="mt-5">
              <MFAChart />
            </div>
          </div>
        </div>
      )}

      {!toggleTable && (
        <div className="hidden">
          <div className="_card-container-1 hidden">
            <div className="_card-license">
              <LicenseCard />
            </div>
            <div className="_card-risk_management">
              <ProductAdoption />
            </div>
          </div>
        </div>
      )}
      {userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP" ? (
        <div className={toggleTable ? "flex w-full mt-4" : " flex w-full mt-8 expand"}>
          <CustomerTable />
        </div>
      ) : null}
    </div>
  );
};
export default Assesments;
