import { Radio, Tooltip } from "antd";
import { customFilters } from "./tableHelper";
import { useUserContext } from "../../userContext/UserContext";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setReloadGroupFilter } from "../../../../../../redux/slice/Users/UserDashboardUpdateSlice";

const CustomFilter = (props: any) => {
  const { type, handleFilterClose } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const [groupValue, setGroupValue] = useState("");
  const dispatch = useDispatch();
  const { getFilterData, groupList, setStatusFilter, statusFilter } = useUserContext();

  const handleRadioChange = (e: any) => {
    const val = e.target.value;
    handleFilterClose();
    if (type === "status") {
      setStatusFilter(val);
      setGroupValue(val);
      getFilterData(0, 10, val);
    } else {
      setGroupValue(val);
      getFilterData(0, 10, "", val);
    }
  };

  const handleScroll = () => {
    if (divRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = divRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        dispatch(setReloadGroupFilter(true));
      }
    }
  };
  return (
    <div className="px-2 pb-3 pt-1 custom-radio w-[200px]" ref={divRef} onScroll={handleScroll}>
      {type === "status" ? (
        <Radio.Group
          onChange={(e) => {
            handleRadioChange(e);
          }}
          value={statusFilter}
        >
          {customFilters.map((filter: any) => (
            <div key={filter.value} className="pt-2">
              <Radio value={filter.value}>{filter.text}</Radio>
            </div>
          ))}
        </Radio.Group>
      ) : (
        <Radio.Group
          onChange={(e) => {
            handleRadioChange(e);
          }}
          value={groupValue}
          data-testId="customFilter-id"
        >
          {groupList?.map((filter: any) => (
            <div key={filter?.value} className="pt-2">
              {filter?.label?.length >= 16 ? (
                <Tooltip title={filter?.label}>
                  <Radio value={filter?.value}>{filter?.label}</Radio>
                </Tooltip>
              ) : (
                <Radio value={filter?.value}>{filter?.label}</Radio>
              )}
            </div>
          ))}
        </Radio.Group>
      )}
    </div>
  );
};

export default CustomFilter;
