import React from "react";
import { Select } from "antd";
import ImageView from "./ImageView"; // Adjust this import as needed

const CustomSelect = ({ suffixIconSrc, className, value, options, onChange }: any) => {
  return (
    <Select suffixIcon={<ImageView className="custom-select" src={suffixIconSrc} alt="chevronDown" />} className={className} showSearch onChange={onChange} placeholder={"Category"} value={value}>
      {options.map((option: any) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
