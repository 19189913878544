import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../layouts/component/CustomButton";
import { Button, Modal, Radio, Select, Tooltip } from "antd";
import { retrieveData } from "../../../services/storage/Storage";
import Loader from "../../../layouts/component/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setCredentialUpdate, setSuccessMessage } from "../../../redux/slice/credential-management/credentialsSlice";
const { Option } = Select;

const AddConnectionModal = (props: any) => {
  const { handleClose, isActive } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [key, setKey] = useState<number>(1);
  const [selectValues, setSelectValues] = useState<any>([]);
  const [userSearchGroupData, setUserSearchGroupData] = useState<any>([]);
  const [selectValue, setSelectValue] = useState("email");
  const [errorMsg, setErrorMessage] = useState<string>("");
  const baseURL = process.env.REACT_APP_BLOCKCHAIN_BASEURL;
  const authToken: string = retrieveData("authToken", true);
  const [loader, setLoader] = useState<boolean>(false);
  const isUpdateState = useSelector((state: any) => state?.credentialsSlice?.isUpdate);
  const credentialData = useSelector((state: any) => state?.credentialsSlice?.credentialInitialData);

  const customerTablePlanValue = [
    { label: "Email address", value: "email" },
    { label: "QR code", value: "qrCode" },
  ];
  const dispatch = useDispatch();
  // Handle change event for radio buttons
  const handleRadioChange = (e: any) => {
    setSelectValue(e.target.value);
  };
  const handleCloseModal = () => {
    handleClose();
    setSelectValues([]);
    setErrorMessage("");
  };
  const handleConnectionUserList = (userData: any) => {
    let userArray = userData?.map((item: any) => ({
      id: item.id,
      value: item.id,
      name: `${item?.firstName} ${item?.lastName}`,
      email: item.email,
    }));
    return userArray;
  };
  useEffect(() => {
    if (credentialData?.userDtoList?.length !== 0) {
      let credentiaUserDtoList = credentialData?.userDtoList;
      const transformedArray = handleConnectionUserList(credentiaUserDtoList);
      setUserSearchGroupData(transformedArray);
    } else {
      setUserSearchGroupData([]);
    }
    setIsOpen(isActive);
    setKey(key + 1);
  }, [isActive, credentialData]);

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const dropdownRender = (menu: any) => {
    return (
      <div>
        <div>{menu}</div>
        <div>
          <div style={{ padding: "8px", textAlign: "center" }}>
            <div className="w-full flex justify-end ">
              <Button className="w-[144px] h-[48px]  mr-2 font-semibold text-sm" onClick={() => handleCancel()}>
                Cancel
              </Button>
              <Button type="primary" className="bg-[#5441DA] w-[144px] h-[48px] font-semibold text-sm" onClick={() => handleCancel()}>
                Add
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleCancel = () => {
    setDropdownShow(false);
  };

  const handleUserLabelClick = (event: any, value: any) => {
    event.stopPropagation();
    setErrorMessage("");
    const newValue: any = [...selectValues];
    if (newValue.includes(value)) {
      newValue.splice(newValue.indexOf(value), 1);
    } else {
      newValue.push(value);
    }
    setSelectValues(newValue);
  };

  const handleCreateConnection = async () => {
    if (selectValues.length === 0) {
      setErrorMessage("Please select at least one user");
      return;
    }
    setErrorMessage("");
    setLoader(true);
    const url = `${baseURL + "v1/user/connection"}`;
    const payload = {
      userId: [...selectValues],
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      setLoader(false);
      dispatch(setCredentialUpdate(!isUpdateState));
      dispatch(setSuccessMessage(data?.message));

      handleCloseModal();
    } catch (error) {
      setLoader(false);
    }
  };

  const handleDeselect = (value: any) => {
    setSelectValues((prevValues: any) => prevValues.filter((val: any) => val !== value));
  };
  return (
    <Modal key={key} className="rounded-lg add-credential" maskClosable={false} centered open={isOpen} footer={false} onCancel={handleCloseModal}>
      {loader && <Loader />}
      <div className="modal-title pt-4 px-4">
        <h3>Add Connections</h3>
      </div>
      <div className="modal-body pt-4 px-12">
        <div className="w-full pt-10">
          <p>Select the appropriate action for creating connection(s)</p>
          <div className="w-full flex pt-7">
            <Radio.Group onChange={handleRadioChange} value={selectValue} className="flex">
              {customerTablePlanValue?.map((filter: any) => (
                <div key={filter?.value} className="connection-label">
                  <Tooltip title={filter?.value === "qrCode" ? "This functionality is under development" : ""}>
                    <Radio value={filter?.value} disabled={filter?.value === "qrCode"}>
                      {filter?.label}
                    </Radio>
                  </Tooltip>
                </div>
              ))}
            </Radio.Group>
          </div>
          <div className="w-full">
            <p className="connection-title">Select users</p>

            <Select
              className="h-14 max-w-[460px] w-full  custom-dropdown"
              mode="tags"
              placeholder="Search"
              onDropdownVisibleChange={setDropdownShow}
              open={dropdownShow}
              tokenSeparators={[","]}
              optionLabelProp="label"
              filterOption={filterOption}
              dropdownRender={dropdownRender}
              onDeselect={handleDeselect}
              suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
            >
              {userSearchGroupData?.map((option: any) => (
                <Option key={option.id} value={option.value} label={option.name}>
                  <input
                    type="checkbox"
                    className="dropdown-list w-5"
                    name={option.name}
                    id={option.id}
                    checked={selectValues.includes(option.value)}
                    onChange={(e) => handleUserLabelClick(e, option.value)}
                  />
                  <label htmlFor={option.value} className="flex flex-col h-14 pointer-events-none justify-center">
                    {option.name}
                  </label>
                </Option>
              ))}
            </Select>
            {errorMsg && <div className="error-message text-red-600">{errorMsg}</div>}
          </div>
        </div>
      </div>
      <div className="modal-footer pt-20 mt-5">
        <div className="w-full flex justify-end">
          <CustomButtonBack onClick={handleCloseModal} text={"Cancel"} />
          <CustomButtonNext onClick={handleCreateConnection} text={"Create"} />
        </div>
      </div>
    </Modal>
  );
};

export default AddConnectionModal;
