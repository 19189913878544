import * as Yup from "yup";

export   const validationSchema = Yup.object({
    forms: Yup.array().of(
      Yup.object({
        source: Yup.string().required("Source LDAP is required"),
        target: Yup.string().required("Target LDAP is required"),
        userType: Yup.string().required("Transformation type is required"),
      }),
    ),
  });