import React from "react";
import { Modal } from "antd";
import CreateAccessManagerModalHeader from "../../../../../access-manager/create/CreateAccessManagerModalHeader";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearPayloadForTimeAndIp } from "../../../../../../redux/slice/IPSlice";

export default function AccessManagerListModal(props: any) {
  const { activate, closeModal } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const restrictionList: any[] = [
    {
      name: "Select IP",
      description: "Specify what IP can access your data",
      id: "1",
    },
    {
      name: "Whitelist Device/s",
      description: "Bind your device/s to your data",
      id: "2",
    },
    {
      name: "Set Time Parameters",
      description: "Stipulate what time your data can be accessed",
      id: "3",
    },
  ];

  const handleOpenRestrictionList = (item: any) => {
    closeModal();
    if (item?.name === "Select IP") {
      dispatch(clearPayloadForTimeAndIp());

      navigate("/user/select-ip?from=userList");
    } else if (item?.name === "Set Time Parameters") {
      dispatch(clearPayloadForTimeAndIp());

      navigate("/user/time-parameters?from=userList");
    } else {
      navigate("/user/device-restriction");
    }
  };

  const handelCloseModal = () => {
    closeModal();
  };
  return (
    <Modal className="createAccessMangerModal" centered open={activate} footer={false} onCancel={closeModal} maskClosable={false}>
      <CreateAccessManagerModalHeader />
      <div className="accessManagerModalContent">
        <div className="pt-12 pb-7 create-list">
          {restrictionList?.map((item: any) => (
            <div className="flex justify-between items-center list-items" key={item?.id} onClick={() => handleOpenRestrictionList(item)}>
              <div className="list-item--content">
                <h4>{item?.name}</h4>
                <p>{item?.description}</p>
              </div>
              <div className="list-item--arrow">
                <span className="text-[#1C1B1F] material-icons-outlined">expand_more</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end">
          <CustomButtonBack text={"Cancel"} onClick={handelCloseModal} />
        </div>
      </div>
    </Modal>
  );
}
