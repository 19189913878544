import React, { useState } from "react";
import { Select, Switch } from "antd";
import { ErrorMessage, Field } from "formik";
import "./MappingNotification.scss";

const SMSNotification = () => {
  const dropDownList: any = [{ label: "User", value: "user" }];
  const [isToggle, setToggle] = useState<boolean>(false);

  const handleToggle = (e: any) => {
    setToggle(e);
  };
  return (
    <div className=" mail-container">
      <div className="w-full flex items-center mb-10">
        <span className="font-Inter text-[22px]  mr-4 text-[#121D24] font-semibold ">SMS</span>
        <Switch checked={isToggle} onChange={(e) => handleToggle(e)} />
      </div>
      <div className="mb-10 w-[33%] -form">
        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">From Email*</p>
        <Field
          as={Select}
          name="fromMail"
          className="h-[56px] w-[90%] "
          suffixIcon={<span className="material-symbols-outlined text-[#000] ">expand_more</span>}
          
          filterOption={(option: React.ReactElement,input: string ) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {dropDownList?.map((option: any) => (
            <Select.Option key={option.value} value={option.label}>
              {option.label}
            </Select.Option>
          ))}
        </Field>
        <ErrorMessage className="error-message" name="fromMail" component="div"  />
      </div>
      <div className="mb-10 w-[33%] -form">
        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Select Group</p>
        <Field
          as={Select}
          className=" w-[90%] h-[56px]"
          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
          name="fromMail"
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {dropDownList?.map((option: any) => (
            <Select.Option value={option.label} key={option.value} >
              {option.label}
            </Select.Option>
          ))}
        </Field>
        <ErrorMessage name="fromMail"  className="error-message" component="div" />
      </div>
      <div className="mb-10 w-[33%] -form">
        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Select Template</p>
        <Field
          as={Select}
          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
          className=" w-[90%] h-[56px]"
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          name="fromMail"
        >
          {dropDownList?.map((option: any) => (
            <Select.Option key={option.value} value={option.label}>
              {option.label}
            </Select.Option>
          ))}
        </Field>
        <ErrorMessage name="fromMail" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[33%] -form">
        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">When to deliver</p>
        <Field
          name="fromMail"
          as={Select}
          className=" w-[90%] h-[56px]"
          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {dropDownList?.map((option: any) => (
            <Select.Option key={option.value} value={option.label}>
              {option.label}
            </Select.Option>
          ))}
        </Field>
        <ErrorMessage name="fromMail" component="div" className="error-message" />
      </div>
    </div>
  );
};

export default SMSNotification;
