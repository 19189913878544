import { Button, Input } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
});
export default function AddEditDeviceRestriction() {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    profileImage: "",
    zone: "",
    dateRange: [null, null],
  };

  const onSubmit = async (values: any) => {};
  return (
    <div className="addEditDeviceRestriction-form mb-11">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize={true}>
        {({ handleSubmit }) => (
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="grid grid-cols-3 gap-6">
              <div className="mb-2">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Enter type of device</p>
                <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="firstName" maxLength={30} data-testid="fname" />
                <ErrorMessage name="firstName" component="div" className="error-message" />
              </div>
              <div className="mb-2">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">IMEI number</p>
                <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="lastName" maxLength={30} data-testid="lname" />
                <ErrorMessage name="lastName" component="div" className="error-message" />
              </div>
              <div className="mb-2">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Name of rule</p>
                <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="firstName" maxLength={30} data-testid="rule" />
                <ErrorMessage name="firstName" component="div" className="error-message" />
              </div>
              <div className="">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Enter Description</p>
                <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="lastName" maxLength={30} data-testid="description" />
                <ErrorMessage name="lastName" component="div" className="error-message" />
              </div>
              <div className="mt-6 col-span-2 flex justify-end">
                <Button htmlType="submit" className="btn bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px]" type="primary">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
