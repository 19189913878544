import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import SearchInput from "../../../../../layouts/component/SearchField";
import ConnectorCard from "./ConnectorCard";

const SelectConnector = (props: any) => {
  const { openModal, handleClose } = props;
  const [open, isOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    isOpen(openModal);
  }, [openModal]);

  const handleCloseModal = () => {
    handleClose();
  };

  const handleAddConnection = () => {
    handleClose();
  };
  const handelSearch = (value: string) => {
    setSearchValue(value);
  };
  return (
    <>
      <Modal className="modal-selector rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
        <div className="modal-body  pt-4 ">
          <p className="modal-title pl-[30px]">Select Connectors</p>
          <div className="w-full pb-10 pt-8 pl-[30px]">
            <SearchInput placeholder={"Search"} onChange={handelSearch} search={searchValue} className="w-[300px]" />
          </div>
          <div className="modal-cards flex flex-wrap w-full">
            <ConnectorCard />
          </div>
        </div>
        <div className="modal-footer pt-8">
          <div className="w-full flex justify-end">
            <CustomButtonBack onClick={handleCloseModal} text={"Cancel"} />
            <CustomButtonNext onClick={handleAddConnection} text={"Add"} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectConnector;
