import React from "react";
import { Modal } from "antd";
import ImageView from "../../../../layouts/component/ImageView";
import warningImg from "../../../../assets/images/user/warning.svg";
import CustomOkayButton from "../../../../layouts/component/CustomButton";

export default function GroupAndUserNecessaryModal({ handleCloseNecessaryModal, openNecessaryModal, type }: any) {
  return (
    <Modal className="_suspended p-10 rounded-lg show-notification" closable={false} centered open={openNecessaryModal} footer={false} maskClosable={false}>
      <div className=" modal-body necessaryModal pt-4">
        <div className="flex justify-center items-center w-full flex-col">
          <ImageView className="h-15 w-15" src={warningImg} alt="warning-icon" />
          <p className="text-[20px] pt-4 font-semibold text-center">It's mandatory to assign at least one {type} to the restriction.</p>
        </div>
      </div>
      <div className="modal-footer necessaryModal pt-8">
        <div className="justify-center flex w-full">
          <CustomOkayButton onClick={handleCloseNecessaryModal} text={"Ok"} />
        </div>
      </div>
    </Modal>
  );
}
