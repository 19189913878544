import { APIConfigURLs } from "../apiInterfaceType/apiInterfaceType";

const PUT_API_URL: APIConfigURLs = {
  clientDisplayConsole: {
    url: "/v1/client/display-in-console",
  },
  editUser: {
    url: "/v1/user",
  },
  editConfiguration: {
    url: "/v1/client",
  },
  uploadLogo: {
    url: "/v1/customer/upload-logo",
  },
  updateProfile: {
    url: "user/profile",
    baseURL: "v1/",
  },
  updateClientProfile: {
    url: "",
    baseURL: "v1/user/profile",
  },
  updateClientPassword: {
    url: "",
    baseURL: "v1/user/set-password",
  },
  updateGroup: {
    url: "/v1/group",
  },
  updateLDAP: {
    url: "/v1/component",
  },
  disableLDAPConnection: {
    url: "/v1/component",
  },
  basicDetails: {
    url: "v1/user/basic-details",
  },
  editPermissions: {
    url: "/v1/role",
  },
  updateIpRestriction: {
    url: "/v1/user/access-manager/ip-restrict/details",
  },
  updateTimeBasedRestriction: {
    url: "/v1/user/time-based-restriction",
  },
  updatePasswordManager: {
    url: "/v1/policy/password-policy",
  },
  updateKerberosDirectory: {
    url: "v1/component/kerberos",

  },
  updatePassword: {
    url: 'v1/user/login/set-password'
  }
};

export default PUT_API_URL;
