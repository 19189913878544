import React, { useState } from "react";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { apiResponseMessage } from "../../../../../constant/apiResponseMessage";
import ViewCertificateModal from "./ViewCertificateModal";
import { retrieveData } from "../../../../../services/storage/Storage";

const CertificateView = () => {
  const realmName = retrieveData("realmName", true);
  const viewCertificate = useGetApiRequests("viewCertificate", "GET");
  const [certificateDetail, setCertificateDetail] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const realmBaseUrl = process.env.REACT_APP_KEYCLOAKBASEURL;

  const handleClose = () => {
    setOpenModal(false);
  };
  const handelViewCertificate = () => {
    let param = {
      "realm-name": realmName,
    };
    viewCertificate("", param)
      .then((response: any) => {
        const responseData = response?.data?.data?.keys;
        responseData.map((elm: any) => {
          if (elm?.type === "RSA" && elm?.algorithm === "RS256") {
            setCertificateDetail(elm?.certificate);
            setOpenModal(true);
          }
        });
      })
      .catch((err: any) => {
        triggerNotification("error", "", apiResponseMessage?.failedToFetchCertificateDetails, "topRight");
      });
  };

  return (
    <>
      <div className="w-full flex mb-10">
        <div className="w-[75%] flex lg:flex-row flex-col items-start lg:items-center configuration-container">
          <p className="text-[#5441DA] flex items-center cursor-pointer text-[16px] font-Inter font-medium">
            <a target="_blank" href={`${realmBaseUrl}/realms/${realmName}/.well-known/openid-configuration`} rel="noreferrer">
              OpenId Endpoint Configuration
            </a>
            <span className="material-symbols-outlined ml-2">open_in_new</span>
          </p>
          <p className="text-[#5441DA] flex items-center pl-8 cursor-pointer text-[16px] font-Inter font-medium">
            <a target="_blank" href={`${realmBaseUrl}/realms/${realmName}/protocol/saml/descriptor`} rel="noreferrer">
              SAML 2.0 Identity Provider Metadata
            </a>
            <span className="material-symbols-outlined ml-2">open_in_new</span>
          </p>
        </div>
        <div className="w-1/4 flex justify-end">
          <CustomButtonNext className="h-12 cursor-not-allowed" text={"View Certificate"} onClick={handelViewCertificate} />
        </div>
      </div>
      {openModal && <ViewCertificateModal certificateDetail={certificateDetail} openModal={openModal} handleClose={handleClose} />}
    </>
  );
};

export default CertificateView;
