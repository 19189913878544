import { ErrorMessages } from "../../../../const/Messages";
import { timeZones } from "./TimeZoneList";

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text: any, record: any) => record.name,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text: any, record: any) => record.email,
  },
];
export const groupColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text: any, record: any) => record.name,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text: any, record: any) => record.description,
  },
];

export const getTimeDifferenceFromGMT = (timeZone: any) => {
  const date = new Date();
  const offsetInMinutes: any = date.toLocaleString("en-US", { timeZone, timeZoneName: "short" }).split(" ")[3];
  const offset = offsetInMinutes > 0 ? "+" + offsetInMinutes : offsetInMinutes;
  return offset;
};

export const getZones = async (setLoader: any, setZonesList: any) => {
  setLoader(true);

  try {
    const listOfTimeZones = timeZones;
    const timeDifferences = listOfTimeZones?.map((timeZone: any) => {
      return { label: timeZone + ` (${getTimeDifferenceFromGMT(timeZone)})` };
    });

    setZonesList(timeDifferences);
  } catch (error) {
    console.error(ErrorMessages?.timeZoneFetchingError, error);
  } finally {
    setLoader(false);
  }
};
