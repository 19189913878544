import React, { useEffect, useState } from "react";
import SearchInput from "../../../../layouts/component/SearchField";
import CustomSelect from "../../../../layouts/component/CustomSelect";
import { images } from "../../../../assets/images/ImagePaths";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { setSearchValue } from "../../../../redux/slice/AppSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";

const MyAppsHeader = () => {
  const [filterData, setFilterData] = useState<any>({ categoryId: "" });
  const getCategoryList = useGetApiRequests("getCategoryList", "GET");
  const [dropdownList, setDropdownList] = useState<any>([]);
  const [selectedCategery, setSelectedCategery] = useState<any>("");

  const searchValue = useSelector((state: any) => state);
  const searchUserDetails = (event: any) => {
    const { value } = event.target;
    let searchParam = [{ name: value, ...filterData }];

    dispatch(setSearchValue(searchParam));
  };
  const dispatch = useDispatch();

  const handleOnChangeFilter = (key: any, value: any) => {
    let searchApp = searchValue?.appSearchSlice?.searchApps[0];
    setSelectedCategery(value);
    setFilterData({ ...filterData, [key]: value });
    const searchParam = [{ ...filterData, categoryId: value, name: searchApp?.name || "" }];
    dispatch(setSearchValue(searchParam));
  };

  useEffect(() => {
    getAppCategoryList();
    return () => {
      dispatch(setSearchValue({}));
    };
  }, []);
  const sortAppsByName = (items: any) => {
    const sortedArray = items?.sort((a: any, b: any) => {
      const firstElement = a?.label?.toUpperCase();
      const secondElement = b?.label?.toUpperCase();
      if (firstElement < secondElement) {
        return -1;
      }
      if (firstElement > secondElement) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return sortedArray;
  };
  const getAppCategoryList = () => {
    getCategoryList()
      .then((response: any) => {
        let originalArray = response?.data?.data;
        const convertedArray = originalArray.map((item: any) => ({
          akkuCategoryId: item.akkuCategoryId,
          label: item.name,
          value: item.akkuCategoryId,
        }));
        const sortedArray = sortAppsByName(convertedArray);
        sortedArray?.unshift({ akkuCategoryId: "", label: "All", value: "" });
        setDropdownList(sortedArray);
      })
      .catch((err: any) => {
        handleRequestError(err);
      });
  };

  return (
    <div className="w-full flex justify-between items-center overflow-x-hidden">
      <div className="w-1/2 md:w-1/4">
        <p className="text-[#171717] text-[28px] font-Inter font-semibold pl-5 md:text-[24px]">My Apps </p>
      </div>
      <div className="w-1/2 md:w-3/4 flex justify-end pr-4">
        <CustomSelect
          suffixIconSrc={images.chevronDown}
          placeholder="Catagory"
          value={selectedCategery}
          className="w-[248px] mr-4 h-[48px]"
          options={dropdownList}
          onChange={(e: any) => {
            handleOnChangeFilter("categoryId", e);
          }}
        />
        <SearchInput placeholder="Search" onChange={searchUserDetails} />
      </div>
    </div>
  );
};

export default MyAppsHeader;
