import React, { useEffect, useState } from "react";
import CardTitle from "../../component/CardTitle";
import batch from "../../../assets/images/icons/risk.png";

import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import { cardData } from "./riskManagementCardHelper";
import ImageView from "../../component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import { useNavigate } from "react-router-dom";
import { storeData } from "../../../services/storage/Storage";

const RiskManagementCard = () => {
  const [riskAssessmentResult, setRiskAssessmentResult] = useState<any>(null);
  const [cardDetails, setCardDetails] = useState<any>(null);

  const userDetails = useSelector((state: any) => state?.dashboardDetails?.riskAssessmentData);

  useEffect(() => {
    setRiskAssessmentResult(userDetails);
    handleCardDetails(userDetails);
  }, [userDetails]);

  const handleCardDetails = (data: any) => {
    const mapping: any = {
      "Suspicious Login": "suspiciousLogin",
      "Failed Login": "failedLogins",
      "Unused Licenses": "unusedLicenses",
      "Locked Accounts": "lockedUsers",
    };

    for (const item of cardData) {
      const dataKey: any = mapping[item.label];
      item.count = data?.[dataKey] ?? 0;
    }

    setCardDetails(cardData);
  };

  const onChange = (date: any, dateString: any) => {
    console.log(date, dateString);
  };
  const navigate = useNavigate();
  const handleNavigate = (label: any, type: string) => {
    if (label !== 2) {
      navigate("/risk-assessment");
      storeData("riskAssessmentTabKey", label, true);
    }
  };

  return (
    <div className="_card   risk-management">
      <div className="_card-header --no-border">
        <div className="title">
          <CardTitle title="Risk Assessment" src={batch} />
        </div>
        <div className="flex hidden">
          <div className="_custum-date-picker relative w-[125px]">
            <DatePicker
              className="__common w-full h-[48px] pl-10 __placeholder"
              placeholder="Today"
              onChange={(date, dateString) => {
                onChange(date, dateString);
              }}
              suffixIcon={null}
            />
            <span className="material-symbols-outlined text-[#667085] absolute left-3 top-3 pr-3">calendar_today</span>
          </div>
        </div>
      </div>
      <div className="px-10">
        <div className="flex w-full  pt-3 pb-6">
          <div className="flex justify-center items-center">
            <ImageView className="w-7 h-7" src={images.location} alt="chevronDown" />
            <p className="active-users font-Inter text-[18px]">Active Users : {riskAssessmentResult?.activeUsers}</p>
          </div>
        </div>
        <div className="grid 2xl:gap-x-10 xl:gap-x-8 gap-x-6  gap-y-4 grid-cols-2">
          {cardDetails?.map((card: any, index: number) => (
            <div key={card?.id} className={`flex justify-center ${card.label === "Unused Licenses" ? "" : "cursor-pointer"}`} role="none" onClick={() => handleNavigate(index, card?.label)}>
              {/* __border */}
              <div
                className={`w-[100%] card-count ${card.label === "Suspicious Login" ? "hover:bg-[#FFCFCF]" : card.label !== "Unused Licenses" ? "hover:bg-[#DEDEFF]" : ""} ${card.label === "Suspicious Login" ? "active" : ""}`}
              >
                <h4 className="font-Inter font-semibold text-[36px] text-[#000] leading-[32px]  text-rwd26">{card?.count}</h4>
                <p className="risks-type flex items-center">
                  <span className="material-symbols-outlined pr-2">{card.icon}</span>
                  <span className="riskAssesment-label"> {card.label}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RiskManagementCard;
