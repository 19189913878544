import React from "react";
import "./PageNotFound.scss";
import { Button } from "antd";
import { retrieveData } from "../../services/storage/Storage";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const navigate = useNavigate();
  const userRole = retrieveData("role", false);
  const handleNavigateDashboard = () => {
    if (userRole === "admin") {
      navigate("/admin-dashboard");
    } else {
      navigate("/dashboard");
    }
  };
  return (
    <div className="page-not-found">
      <div className="page-content">
        <div className="text-center">
          <h1>404</h1>
          <h2>Page not found!</h2>
          <p>
            You might have typed in the wrong address or the
            <br />
            page has moved. in the meantime, try again.
          </p>
          <Button className="bg-[#5441DA] btn" type="primary" onClick={handleNavigateDashboard}>
            Go to dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
