import { useDispatch, useSelector } from "react-redux";
import { setDashboardDetails, setCustomerTableData, setRiskAssessmentData, setApplication, setMFA, setRecoveryAttempts, setTotalLicenses } from "../../redux/slice/DashboardSlice";
import useApiRequests from "../../services/axios/useApiRequests";
import { useState } from "react";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";

const useDashboardService = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const realm_id = useSelector((state: any) => state?.CustomerSlice?.realmId);
  const userId: string = useSelector((state: any) => state?.keycloak?.KeycloakInfo?.subject);

  // Define a mapping between widget names and API requests
  const apiRequestsMap: Record<string, any> = {
    dashboardAll: useApiRequests("dashboardAll", "POST"),
    dashboardCustomer: useApiRequests("dashboardCustomer", "POST"),
    topSeller: useApiRequests("topSeller", "POST"),
    // Add more entries for other widgets as needed
  };

  const getDashboardDetails = async (filterData: any, widgetName: string = "", partialPayload: any = {}) => {
    setLoading(true); // Set loading state to true when starting the API request

    const payload = {
      customerDashboardPayloadDto: {
        ...filterData,
        realmId: realm_id,
      },
      ...partialPayload,
    };
    payload.userId = userId;

    try {
      // Use the API request based on the widget name

      const response: any = await apiRequestsMap[widgetName](payload);
      const status = response.status;

      if (status === 200) {
        if (widgetName === "dashboardAll") {
          dispatch(setDashboardDetails(response.data.data)); // Dispatch data to Redux store
          dispatch(setCustomerTableData(response.data.data.customerInformation));
          dispatch(setRiskAssessmentData(response.data.data.riskAssessmentResult));
          dispatch(setApplication(response.data.data.applicatoinStatisticsResultClass));
          dispatch(setMFA(response.data.data.multiFactorAuthorizationRepresentation));
          dispatch(setRecoveryAttempts(response.data.data.recoveryAttemptsRepresentation));
          dispatch(setTotalLicenses(response.data.data.totalLicenses));
        } else if (widgetName === "dashboardCustomer") {
          dispatch(setCustomerTableData(response.data.data.customerInformation));
        }
      }
    } catch (err: any) {
      handleRequestError(err);
    } finally {
      setLoading(false); // Set loading state to false after API request completes (either success or error)
    }
  };

  return { getDashboardDetails, loading };
};

export default useDashboardService;
