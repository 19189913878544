import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { LDAPvalidationSchema } from "../../dashboard/user/user-dashboard/add-user/layouts/modal/configure-authentication/LDAPvalidationSchema";
import TestingProcessModal from "../../dashboard/user/user-dashboard/add-user/layouts/modal/configure-authentication/TestingProcessModal";
import ConfigureAuthenticationForm from "../../dashboard/user/user-dashboard/add-user/layouts/modal/configure-authentication/ConfigureAuthenticationForm";
import LDAPTitleHeader from "../common/LDAPTitleHeader";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../services/storage/Storage";
import { setLDAPUpdateInitialValues } from "../../../redux/slice/LDAPSlice";
import { setBindcredential } from "../../../redux/slice/edit-ldap/BindCredentialSlice";
import Loader from "../../../layouts/component/Loader";
const EditAuthentication = (props: any) => {
  const { responseData, setCurrentTab } = props;

  const configureInitialValue = {
    connectionUrl: "",
    bindType: "Simple",
    bindDn: "",
    bindCredentials: "",
    ldapName: "",
  };
  const dispatch = useDispatch();
  const [initialValue, setInitialValues] = useState(configureInitialValue);
  const realmName: string = retrieveData("realmName", true);
  const storedValues = useSelector((state: any) => state?.LDAPFormDetails?.LDAPInitialConfigurations);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const createLDAP = useGetApiRequests("testConnection", "POST");
  const [loader, setLoader] = useState<boolean>(false);
  const [enableTest, setEnableTest] = useState<boolean>(true);

  const initialValues = storedValues && Object.keys(storedValues).length > 0 ? storedValues : initialValue;
  // setBindcredential;
  useEffect(() => {
    appendDataToForm();
  }, [responseData]);
  const appendDataToForm = () => {
    const mergedInitialValue = {
      ...initialValue,
      connectionUrl: responseData?.config?.connectionUrl[0],
      bindDn: responseData?.config?.bindDn[0],
      ldapName: responseData?.name,
    };
    setInitialValues(mergedInitialValue);
  };

  const handleSubmit = async (values: any) => {
    const pathParams = {
      realmName: realmName,
      testConnection: "test-connection",
    };
    const payload = {
      connectionUrl: values?.connectionUrl,
      action: "testConnection",
    };

    try {
      const response: any = await createLDAP(payload, {}, pathParams);
      const status = response.status;
      if (status === 200) {
        testAuthentication(pathParams, values);
        dispatch(setLDAPUpdateInitialValues(values));
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      setOpenModal(false);
      handleRequestError(err);
    }
  };

  const testAuthentication = async (pathParams: any, values: any) => {
    setOpenModal(true);
    dispatch(setBindcredential(values?.bindCredentials));
    const testAuthPayload: object = {
      connectionUrl: values?.connectionUrl,
      bindDn: values?.bindDn,
      bindCredential: values?.bindCredentials,
      action: "testAuthentication",
    };
    try {
      const response: any = await createLDAP(testAuthPayload, {}, pathParams);
      const status = response?.status;
      if (status === 200) {
        setEnableTest(false);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      setOpenModal(false);
      handleRequestError(err);
    }
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleNextTab = () => {
    setCurrentTab(2);
  };
  return (
    <>
      {loader && <Loader />}
      <LDAPTitleHeader title={"Edit- Configure(Authentication)"} enableTest={enableTest} />
      <Formik initialValues={initialValues} validationSchema={LDAPvalidationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ values, dirty, setFieldValue }) => {
          return (
            <Form>
              <div className="w-full edit-ldap relative">
                <ConfigureAuthenticationForm setFieldValue={setFieldValue} enableTest={enableTest} className={true} handleNextTab={handleNextTab} setEnableTest={setEnableTest} />
              </div>
            </Form>
          );
        }}
      </Formik>
      {openModal && <TestingProcessModal openModal={openModal} handleClose={handleClose} enableTest={enableTest} isLDAP={true} />}
    </>
  );
};

export default EditAuthentication;
