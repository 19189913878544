import React, { useEffect, useState } from "react";
import { Tabs, TabsProps } from "antd";
import RiskAssessmentTable from "./RiskAssessmentTable";
import { retrieveData } from "../../../services/storage/Storage";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { formatDateTime } from "../RiskAssessmentHelperFile";

const RiskAssessmentTabs = () => {
  const tabKeyFromDashboard = retrieveData("riskAssessmentTabKey", true)?.toString();
  const realmId = retrieveData("realmId", true);
  const getRiskAssessmentDetails = useGetApiRequests("getRiskAssessmentDetails", "POST");
  const [selectedTabKey, setSelectedTabKey] = useState(tabKeyFromDashboard);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sizeChanger, setSizeChanger] = useState<any>(10);
  
  useEffect(() => {
    gettingRiskAssessmentDetails(selectedTabKey, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeChanger, currentPage]);

  const gettingRiskAssessmentDetails = async (tabKey: string, fromTabChange: boolean) => {
    setLoader(true);
    setDataSource([]);
    const payload: any = {
      realmId: realmId,
      start: fromTabChange ? 0 : currentPage,
      limit: fromTabChange ? 10 : sizeChanger,
    };
    if (tabKey === "1") {
      payload.widgetType = "FAILED_LOGIN";
    } else if (tabKey === "3") {
      payload.widgetType = "LOCKED_USERS";
    } else {
      payload.widgetType = "SUSPICIOUS_LOGIN";
    }
    try {
      const response: any = await getRiskAssessmentDetails(payload);
      const status = response?.status;
      if (status === 200) {
        const reformedData = response?.data?.data?.map((item: any, index: number) => {
          const commonData = {
            name: item?.name,
            email: item?.email,
            lastLogin: item?.lastActiveLogin ? formatDateTime(item?.lastActiveLogin) : "-",
            reason: item?.userRiskAssessmentData?.eventType ? item?.userRiskAssessmentData?.eventType : "-",
            userId: item?.userRiskAssessmentData?.userId,
            key: index + 1,
          };
          switch (tabKey) {
            case "0":
              return { ...commonData, suspiciousLoginAttempt: item?.userRiskAssessmentData?.eventTime ? formatDateTime(item?.userRiskAssessmentData?.eventTime) : "-" };
            case "1":
              return { ...commonData, failedLoginAttempt: item?.userRiskAssessmentData?.eventTime ? formatDateTime(item?.userRiskAssessmentData?.eventTime) : "-" };
            case "3":
              return { ...commonData, lockedLoginAttempt: item?.userRiskAssessmentData?.eventTime ? formatDateTime(item?.userRiskAssessmentData?.eventTime) : "-" };
            default:
              return commonData;
          }
        });
        setDataSource(reformedData);
        if (response?.data?.meta?.totalRecords) {
          setTotalCount(response?.data?.meta?.totalRecords);
        } else {
          setTotalCount(0);
        }
        setLoader(false);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const itemsTabs: TabsProps["items"] = [
    {
      key: "0",
      label: "Suspicious Login",
      children: (
        <RiskAssessmentTable
          tabKey={selectedTabKey}
          riskAssessmentData={dataSource}
          parentLoader={loader}
          totalCount={totalCount}
          currentPage={currentPage}
          sizeChanger={sizeChanger}
          setSizeChanger={setSizeChanger}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
    {
      key: "3",
      label: "Locked Accounts",
      children: (
        <RiskAssessmentTable
          tabKey={selectedTabKey}
          riskAssessmentData={dataSource}
          parentLoader={loader}
          totalCount={totalCount}
          currentPage={currentPage}
          sizeChanger={sizeChanger}
          setSizeChanger={setSizeChanger}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
    {
      key: "1",
      label: "Failed Login",
      children: (
        <RiskAssessmentTable
          tabKey={selectedTabKey}
          riskAssessmentData={dataSource}
          parentLoader={loader}
          totalCount={totalCount}
          currentPage={currentPage}
          sizeChanger={sizeChanger}
          setSizeChanger={setSizeChanger}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
  ];

  const handleTabChange = (key: string) => {
    setCurrentPage(0);
    setSizeChanger(10);
    setSelectedTabKey(key);
    gettingRiskAssessmentDetails(key, true);
  };

  return (
    <div className="risk-assessment-tabs">
      <Tabs activeKey={selectedTabKey} size="large" className="bg-[#fff]" type="card" items={itemsTabs} onChange={handleTabChange} data-testid="risk-tabs" />
    </div>
  );
};
export default RiskAssessmentTabs;
