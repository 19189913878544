import MyAppsHeader from "./layout/my-apps/MyAppsHeader";
import MyApps from "./layout/my-apps/MyApps";
import AvailableApps from "./layout/my-apps/AvailableApps";
import "./AppStore.scss";
const AppStore = () => {
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full overflow-y-auto">
          <MyAppsHeader />
          <div className="h-full ">
            <MyApps />
            <AvailableApps />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStore;
