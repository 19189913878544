export const ignoredRoutes = [
  "/register",
  "/user-register",
  "/login",
  "/",
  "/verify-email",
  "/upload-logo",
  "/setpassword",
  "/forgot-password",
  "/forgot-username",
  "/multi-factor-authentication",
  "/sent-otp-mobile",
  "/sent-otp-email",
  "/validate-otp",
  "/reset-password",
  "/set-password",
  "/dashboard",
  "/device-logout",
];
