import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData, storeData } from "../../../services/storage/Storage";

export const getUserGroupCount = async (getGroups: any) => {
  const queryParams: any = {
    realmName: retrieveData("realmName", true),
    limit: 10,
    start: 0,
  };

  try {
    const response: any = await getGroups("", queryParams);
    const status = response.status;
    if (status === 200) {
      storeData("totalGroupsCount", response?.data?.meta?.totalRecords, true);
    }
  } catch (err: any) {
    handleRequestError(err);
  }
};
export const getUserGroupList = async (getGroups: any) => {
  const totalGroupsCount = retrieveData("totalGroupsCount", true);
  const limit = totalGroupsCount;
  const queryParams: any = {
    realmName: retrieveData("realmName", true),
    limit: limit,
    start: 0,
  };

  try {
    const response: any = await getGroups("", queryParams);
    const status = response.status;
    if (status === 200) {
      return response?.data;
    }
  } catch (err: any) {
    handleRequestError(err);
  }
};
export const getUsersCountForGroups = async (getUserListCount: any) => {
  const realmId = retrieveData("realmId", true);

  try {
    const payloadForUserCount = {
      realmId: realmId,
      sorting: "name_asc",
      statusFilter: "active,inactive",
      searchFilter: "",
      startValue: 0,
      limitValue: 10,
    };
    const response: any = await getUserListCount(payloadForUserCount);
    const statusCode = response?.status;
    if (statusCode === 200) {
      storeData("totalUserRecords", response?.data?.meta?.totalRecords, true);
    }
  } catch (err: any) {
    handleRequestError(err);
  }
};
