import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
  customerId: Yup.string()
    // .required("Customer Id is required")
    // .matches(/^\S*$/, "Customer Id cannot contain spaces")
    // .matches(/^[^0-9\s]+$/, "Customer Id should not contain digits")
    // .max(30, "Customer ID cannot exceed 30 characters"),
    .required("Customer Id is required")
    .matches(/^\S*$/, "Customer Id cannot contain spaces")
    .matches(/^[a-z0-9_.]+$/, "Customer Id should not contain uppercase and special characters")
    .max(30, "Customer ID cannot exceed 30 characters"),
  displayName: Yup.string().required("Display name is required").max(30, "Display name must be at most 30 characters"),
  customerEmailId: Yup.string().email("Please enter a valid email address for the customer").required("Customer email Id is required"),
  noOfLicense: Yup.number().required("No. of license is required").typeError("No. of license must be a number").max(999999, "No. of license cannot exceeds 999999"),
  phone: Yup.string().required("Mobile Number is required"),
  smsServiceProvider: Yup.string().required("Region is required"),
});
export const emailValidationSchema = Yup.object().shape({
  senderEmail: Yup.string().email("Invalid email").required("Sender email ID is required"),
  displaySenderEmail: Yup.string().required("Display name for sender email address is required").max(20, "Display name cannot exceed 20 characters"),
  displayReplyEmail: Yup.string().required("Reply to display name is required").max(20, "Display name cannot exceed 20 characters"),
  replyEmail: Yup.string()
    .email("Please enter a valid email address")
    .required("Reply to email address is required")
    .notOneOf([Yup.ref("senderEmail")], "Reply email cannot be the same as From email address"),
});

export const sessionValidationSchema = Yup.object().shape({
  ssoSessionIdleTimeout: Yup.string().required("Time is required"),
  ssoSessionMaxLifespan: Yup.string().required("Time is required"),
  // ssoSessionIdleTimeoutRememberMe: Yup.string().required("SSO Session remember me time is required"),
  // ssoSessionMaxLifespanRememberMe: Yup.string().required("SSO Session max remember me time is required"),
  loginTimeout: Yup.string().required("Time is required"),
  loginActionTimeout: Yup.string().required("Timeout is required"),
});
