import React, { useCallback, useEffect, useState } from "react";
import MyAppCard from "../card/MyAppCard";
import MyAppsEmpty from "./MyAppsEmpty";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { useSelector } from "react-redux";
import Loader from "../../../../layouts/component/Loader";

import { retrieveData } from "../../../../services/storage/Storage";
import { debounce } from "lodash";
const MyApps = () => {
  const [myAppDetails, setMyAppDetails] = useState<any>([]);
  const [myApps, setMyApps] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);
  const searchValue = useSelector((state: any) => state?.appSearchSlice?.searchApps?.[0]);
  const realmId = retrieveData("realmId", true);

  const myAppList = useGetApiRequests("myAppsList", "GET");

  const searchMyApps = (searchApp: any) => {
    let name = searchApp?.name.toLowerCase();
    let akkuCategoryId = searchApp?.categoryId;
    let appData: any = [];
    myApps?.map((elm: any) => {
      if (akkuCategoryId) {
        if (elm.akkuMasterClient?.name.toLowerCase().includes(name) && akkuCategoryId === elm.akkuCategory.akkuCategoryId) {
          appData.push(elm);
        } else {
          return;
        }
      } else if (elm.akkuMasterClient?.name.toLowerCase().includes(name)) {
        appData.push(elm);
      }
    });

    setMyAppDetails(appData);
  };

  // Debounced API call function
  const debouncedSearchMyApps = useCallback(debounce((searchPayload) => {
    searchMyApps(searchPayload);
  }, 800), []);

  useEffect(() => {
    const searchApp = searchValue;
    if (searchApp?.name) {
      debouncedSearchMyApps(searchApp);
    } else {
      getMyAppList();
    }
  }, [reInitiateApps, searchValue]);

  const sortMyAppsDetails = (items: any) => {
    const sortedArrays = items?.sort((a: any, b: any) => {
      const firstElements = a?.client?.name?.toUpperCase();
      const secondElements = b?.client?.name?.toUpperCase();
      if (firstElements < secondElements) {
        return -1;
      }
      if (firstElements > secondElements) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return sortedArrays;
  };
  const getMyAppList = async () => {
    setLoader(true);
    let params = {
      realmId: realmId,
    };
    try {
      const response: any = await myAppList("", params);
      const status = response.status;
      if (status === 200) {
        const myAppDetails: any = sortMyAppsDetails(response?.data?.data);
        setMyAppDetails(myAppDetails || []);
        setMyApps(response?.data?.data);
        setLoader(false);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div data-testid="my-apps-empty" className="pt-6 flex flex-wrap">
        {myAppDetails?.length !== 0 ? <MyAppCard myAppDetails={myAppDetails} available={false} /> : <MyAppsEmpty />}
      </div>
    </>
  );
};

export default MyApps;
