import React, { useState } from "react";
import { Button, Input, Select } from "antd";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { staticMessage } from "../../../../../../../../constant/StaticContent";
import { handleAuthRedirectNext } from "./stepperHelper";
import { setAddUserStepper } from "../../../../../../../../redux/slice/AddUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ConfigureAuthenticationForm = (props: any) => {
  const { enableTest, className, handleNextTab, setEnableTest } = props;
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepper = useSelector((state: any) => state.addUserSlice.steeper);
  const { handleChange } = useFormikContext();

  const toggleVisibility = () => {
    setVisible(!visible);
  };
  const handleNext = () => {
    if (className) {
      handleNextTab();
    } else {
      const stepperActive = handleAuthRedirectNext(stepper);
      dispatch(setAddUserStepper(stepperActive));
    }
  };
  const handleBackToUser = () => {
    navigate("/user");
  };
  const handleFieldChange = (e: any) => {
    setEnableTest(true);
    handleChange(e);
  };
  return (
    <div className="w-full  search-update configure-authentication">
      <div className="mb-10 w-[92%] mx-auto ldap-container pb-10">
        <div className="w-[90%] flex pt-[40px] flex-col edit-basic">
          <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
            <div className="mb-10 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Directory Name*</p>
              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="ldapName" onChange={handleFieldChange} />
              <ErrorMessage name="ldapName" component="div" className="error-message" />
            </div>
            <div className="mb-10 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureAuthentication?.connectionUrl}</p>
              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="connectionUrl" onChange={handleFieldChange} data-testid="connectionUrl-input" />
              <ErrorMessage name="connectionUrl" component="div" className="error-message" />
            </div>
            <div className="mb-8 w-[45%] input-container ">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureAuthentication?.bindtype}</p>
              <Select placeholder="Simple" disabled suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>} className=" w-[100%] h-[56px]">
                <Select.Option value={"Simple"}>Simple</Select.Option>
              </Select>
            </div>

            <div className="mb-8 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureAuthentication?.bindDn}</p>
              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="bindDn" onChange={handleFieldChange} />
              <ErrorMessage name="bindDn" component="div" className="error-message" />
            </div>
            <div className="mb-8 w-[45%] relative input-container ">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureAuthentication?.bindCredentials}</p>
              <Field
                as={Input}
                type={visible ? "text" : "password"}
                className="h-[56px] font-medium text-[18px]"
                name="bindCredentials"
                data-testid="bind-credentials-input"
                onChange={handleFieldChange}
              />
              {!visible ? (
                <span className="material-icons-outlined absolute right-[10px] top-[50%] cursor-pointer" onClick={toggleVisibility}>
                  visibility_off
                </span>
              ) : (
                <span className="material-icons-outlined absolute right-[10px] top-[50%] cursor-pointer" onClick={toggleVisibility}>
                  visibility
                </span>
              )}
              <ErrorMessage name="bindCredentials" component="div" className="error-message" />
            </div>
          </div>
        </div>
      </div>
      <div className={`${className ? "edit-ldap-footer" : "w-full"} footer flex items-center absolute bottom-0 right-0  bg-[#fff] h-[90px]`}>
        <div className="modal-footer w-full mx-auto ">
          <div className="w-full flex justify-end pr-5">
            {className && (
              <Button onClick={handleBackToUser} className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] text-[#5441DA] mr-10 ">
                Back
              </Button>
            )}
            {!enableTest ? (
              <Button onClick={handleNext} type="primary" className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                Next
              </Button>
            ) : (
              <Button type="primary" htmlType="submit" className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                Test
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureAuthenticationForm;
