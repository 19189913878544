import React from "react";
import "./ConnectedApps.scss";
import { ErrorMessage, Field } from "formik";
import { Input } from "antd";

const GroupDetails = (props: any) => {
  const { setDisableButton, setFieldValue } = props;
  const handleChange = (e: any) => {
    setFieldValue("groupName", e.target.value);
    setDisableButton(false);
  };
  const handleChangeDescription = (e: any) => {
    setFieldValue("groupDescription", e.target.value);
    setDisableButton(false);
  };

  return (
    <div className="w-full rounded-lg bg-[#FAFAFC] h-[140px] mt-4 group-details">
      <div className="w-full  flex h-full justify-start items-center flex-wrap _edit px-10 py-5">
        <div className="mb-10 w-[30%] pr-4 relative input-container">
          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Group Name*</p>
          <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="groupName" onChange={handleChange} maxLength={50} />
          <ErrorMessage name="groupName" component="div" className="error-message custom-err" />
        </div>
        <div className="mb-10 w-[30%] relative input-container">
          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Description*</p>
          <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="groupDescription" onChange={handleChangeDescription} />
          <ErrorMessage name="groupDescription" component="div" className="error-message custom-err" />
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;
