import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import MappingConnectorAttributes from "./MappingConnectorAttributes";
import "./ProvisionMapping.scss";
import { setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { useDispatch } from "react-redux";
const ProvisionMappingAttributes = () => {
  const [formInitialValues, setFormInitial] = useState({ forms: [{ source: "", target: "", userType: "", id: "" }] });
  const formKey = 1;
  const dispatch = useDispatch();

  const transformList: any = [];
  const validationSchema = Yup.object({
    forms: Yup.array().of(
      Yup.object({
        source: Yup.string().required("Source LDAP is required"),
        target: Yup.string().required("Target LDAP is required"),
        userType: Yup.string().required("Transformation type is required"),
      }),
    ),
  });

  const onSubmit = async (values: any) => {
    let step = {
      activeStep: 3,
      childStepper: "",
    };
    dispatch(setCurrentStep(step));
  };
  return (
    <>
      <div className="w-full px-10 pb-5 ">
        <p className="app-header pt-8">Mapping - Attributes</p>
      </div>

      <div className="w-full mapping-attributes">
        <Formik initialValues={formInitialValues} onSubmit={onSubmit} key={formKey} validationSchema={validationSchema}>
          {({ values, setFieldValue }) => (
            <Form>
              <MappingConnectorAttributes values={values} setFieldValue={setFieldValue} transformList={transformList} setFormInitial={setFormInitial} />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ProvisionMappingAttributes;
