
import { ErrorMessage, Field } from "formik";
import React from "react";
import { Input, Select } from "antd";
const { TextArea } = Input;

const DBConnectorForm = (props: any) => {
  const { initialValues, setFieldValue } = props;
  const editModeList = [{ label: "MySql", value: "MySql" }];
  return (
    <>
      <div className="mb-10 w-[45%] input-container relative">
        <p>Database type*</p>
        <Field
          as={Select}
          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
          className=" w-[100%] h-[56px]"
          name="databaseType"
          value={initialValues.connectorType}
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => setFieldValue("databaseType", value)}
          data-testid="usersI-input"
        >
          {editModeList?.map((option: any) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Field>
        <ErrorMessage name="databaseType" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[45%] input-container relative">
        <p>Hostname</p>
        <Field as={Input} type="text" className="form-type-box" name="connectorName" data-testid="usersCn-input" />
        <ErrorMessage name="connectorName" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[45%] input-container relative">
        <p>User name</p>
        <Field as={Input} type="text" className="form-type-box" name="hostName" data-testid="usersHn-input" />
        <ErrorMessage name="hostName" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[45%] input-container relative">
        <p>Password</p>
        <Field as={Input} type="text" className="form-type-box" name="connectorUserName" data-testid="usersCun-input" />
        <ErrorMessage name="connectorUserName" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-[45%] input-container relative">
        <p>Schema name</p>
        <Field as={Input} type="text" className="form-type-box" name="schemaName" data-testid="usersSn-input" />
        <ErrorMessage name="schemaName" component="div" className="error-message" />
      </div>
      <div className="mb-10 w-full h-[150px] input-container relative">
        <p>Enter Query</p>
        <Field as={TextArea} type="text" className="form-textarea h-full" name="query" data-testid="usersQ-input" />
        <ErrorMessage name="query" component="div" className="error-message" />
      </div>
    </>
  );
};

export default DBConnectorForm;
