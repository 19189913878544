import ImageView from "../../../component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import { Steps } from "antd";

const AddCustomerStepper = (props: any) => {
  const { currentTab } = props;

  return (
    <div className="w-full ">
      <div className="head flex justify-center items-center flex-col">
        <div className="w-[70px] pt-16">
          <ImageView className="w-7 h-7" src={images.group} alt="chevronDown" />
        </div>
        <p className="add-title">Add Tenant</p>
      </div>
      <div className="w-full 2xl:mt-[70px] mt-[40px] pl-10">
        <Steps
          current={currentTab - 1}
          direction="vertical"
          items={[
            {
              title: "General Details",
            },
            {
              title: "Email Configuration",
            },
            {
              title: "SSO session settings",
            },
            {
              title: "Products or Plan",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AddCustomerStepper;
