import React from "react";
import LDAPTitleHeader from "../common/LDAPTitleHeader";
import AttributeMapping from "../../dashboard/user/user-dashboard/add-user/layouts/modal/map-attributes/AttributeMapping";

const EditMappingAttributes = (props: any) => {
  const { responseData, setCurrentTab } = props;
  return (
    <>
      <LDAPTitleHeader title={"Edit- Mapping(Attributes)"} active />
      <>{responseData && <AttributeMapping editAttribute={true} setCurrentTab={setCurrentTab} />}</>
    </>
  );
};

export default EditMappingAttributes;
