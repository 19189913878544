import React from "react";
import { Modal } from "antd";
import ImageView from "../../../../layouts/component/ImageView";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../layouts/component/CustomButton";
import { images } from "../../../../assets/images/ImagePaths";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";

export default function DeleteRestrictionModal({ editRecord, deleteRestrictionModalOpen, setDeleteRestrictionModalOpen, setToReload, toReload, setLoader }: any) {
  const deleteIp = useGetApiRequests("deleteIpRestriction", "DELETE");

  const handleCloseDeleteRestriction = () => {
    setDeleteRestrictionModalOpen(false);
  };
  const deleteApiForIp = async (payload: any) => {
    try {
      const response: any = await deleteIp(payload);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        setToReload(!toReload);
        triggerNotification("success", "", response?.data?.message, "topRight");
        handleCloseDeleteRestriction();
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleDeleteRestriction = () => {
    setLoader(true);
    if (editRecord?.classification === "a") {
      const payload = {
        akkuUserIpBasedAuthorizationIds: editRecord?.akkuUserIpBasedAuthorizationIds,
      };
      deleteApiForIp(payload);
    } else if (editRecord?.classification === "c") {
      const timePayload = {
        akkuUserTimeBasedRestrictionIdList: editRecord?.akkuUserTimeBasedAuthorizationIdList,
      };
      deleteApiForIp(timePayload);
    }
  };
  return (
    <div>
      <Modal className="_suspended rounded-lg show-notification" maskClosable={false} centered open={deleteRestrictionModalOpen} footer={false} onCancel={handleCloseDeleteRestriction}>
        <div className="pt-4 modal-body">
          <div className=" w-full flex flex-col items-center justify-center">
            <ImageView className="delete-apps" src={images.notificationDelete} alt="notificationDone" />
            <h5 className="font-Inter text-[18px] font-semibold pt-3"> Are you sure you want to delete this restriction? </h5>
          </div>
        </div>
        <div className="pt-10 modal-footer">
          <div className="flex justify-center w-full">
            <CustomButtonBack onClick={handleCloseDeleteRestriction} text={"No"} />
            <CustomButtonNext onClick={handleDeleteRestriction} text={"Yes"} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
