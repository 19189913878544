import { Radio } from "antd";
import React from "react";

const RadioGroup = (props: any) => {
  const { onChange, value } = props;

  return (
    <>
      <div className="w-full flex pt-[32px] app-configure ">
        <div className="w-full ">
          <p className="text-[#1D1D24] text-[20px] font-Inter pb-4 font-medium">Application Type</p>
          <Radio.Group onChange={onChange} value={value} className="pb-5">
            <Radio value={"saml"}>SAML</Radio>
            <Radio value={"openid-connect"}>OpenID</Radio>
            <Radio value={"CAS"} disabled>
              CAS
            </Radio>
          </Radio.Group>
        </div>
      </div>
    </>
  );
};

export default RadioGroup;
