export const groupColumnsIp = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text: any, record: any) => record.name,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text: any, record: any) => record.description,
  },
];
export const columnsIp = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text: any, record: any) => record.name,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text: any, record: any) => record.email,
  },
];
