import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { images } from "../../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import ImageView from "../../../../../layouts/component/ImageView";
import "./NewAppConfiguration.scss";

const NewAppConfiguration = (props: any) => {
  const { openPopUp, handleModalClose } = props;
  const [open, isOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(true);
  const [adChecked, setAdChecked] = useState(false);

  useEffect(() => {
    isOpen(openPopUp);
  }, [openPopUp]);

  const handleCloseModal = () => {
    handleModalClose();
  };

  const handleNavigate = () => {
    // Handle navigation logic here
  };

  const handleCheckboxChange = (e: any) => {
    setAdChecked(false);
    setIsChecked(!isChecked);
  };

  const handleChangeAdCheckBox = (e: any) => {
    setAdChecked(!adChecked);
    setIsChecked(false);
  };

  return (
    <Modal className="new-app rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <p className="title font-Inter">Which one do you like to configure?</p>
        <div className="flex w-full justify-center items-center mt-8 mb-5 ">
          <div className={`${isChecked ? "bordered" : ""} w-1/2  img-container flex flex-col`}>
            <input type="checkbox" checked={isChecked} onChange={(e) => handleCheckboxChange(e)} className="block z-20 w-full h-full absolute opacity-0 cursor-pointer top-0 left-0" />
            <ImageView src={images.provisionIcon} alt="notificationDone" className="delete-apps z-10" />
            <p>SSO</p>
          </div>
          <div className={`${adChecked ? "bordered" : ""} w-1/2 flex flex-col img-container `}>
            <input type="checkbox" checked={adChecked} onChange={(e) => handleChangeAdCheckBox(e)} className="block- z-20 w-full h-full absolute opacity-0 cursor-pointer top-0 left-0" />
            <ImageView src={images.ssoIcon} alt="notificationDone" className="delete-apps z-10" />
            <p>Provisioning</p>
          </div>
        </div>
      </div>
      <div className="modal-footer py-6">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={"Cancel"} />
          <Button onClick={handleNavigate} className="next-btn  bg-[#5441DA]  lg:h-[56px] md:h-[48px] text-white" size="large" disabled={!adChecked && !isChecked}>
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewAppConfiguration;
