import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./sideBarStyle.scss";
import { Layout, Menu as AntMenu } from "antd";
import { images } from "../../assets/images/ImagePaths";
import ImageView from "../component/ImageView";
import { retrieveData, storeData } from "../../services/storage/Storage";
import { modulePermissions } from "../../const/RolePermissions";
import { hasPermission } from "../../redux/slice/permissions/permissionUtils";
import { useSelector } from "react-redux";
import { ignoredRoutes } from "./ignoredRoutes";
import PermissionsModal from "../permissionsModal/PermissionsModal";
import { menuList } from "../../const/Menu";
import { availableRoutes } from "../header/headerHelper";

const { Sider } = Layout;

type MenuItem = {
  key: React.Key;
  label: React.ReactNode;
  icon?: React.ReactNode;
  to?: string;
  children?: MenuItem[];
};

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, to?: string, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    to,
  };
}

const items: MenuItem[] = [
  getItem(menuList.smartAnalytics, "1", <span className="material-icons text=[#fff] --menu">home</span>, "/admin-dashboard"),
  getItem(menuList.userManagement, "2", <span className="material-icons --menu">groups</span>, "/user"),
  getItem(menuList.appManagement, "3", <span className="material-icons --menu">app_registration</span>, "/app-store"),
  getItem(menuList.roleManagement, "4", <span className="material-symbols-outlined --menu">clinical_notes</span>, "/role-management"),
  getItem(menuList.accessManager, "5", <span className="material-symbols-outlined --menu">key</span>, "/access-manager"),
  getItem(menuList?.passwordManager, "6", <span className="material-symbols-outlined --menu">encrypted</span>, "/password-management"),
  // getItem(menuList.credentialManagement, "7", <span className="material-symbols-outlined --menu">badge</span>, "/credential-manager"),
];
const SideBar = (props: any) => {
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const permission = useSelector((state: any) => state?.permissionsSlice);

  const hasViewPermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "view");
  const hasViewAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "view");
  const hasViewAccessMangerPermission = permissions && hasPermission(permissions, modulePermissions.accessManger, "view");
  const hasViewPasswordMangerPermission = permissions && hasPermission(permissions, modulePermissions.passwordManager, "view");

  const { setCollapse } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [key, setKey] = useState<any>("1");
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const currentVersion = process.env.REACT_APP_VERSION;
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const activeBar = retrieveData("key", false);
    handleResize();
    window.addEventListener("resize", handleResize);

    const matchingItem = items.find((item: any) => location.pathname === item.to || location.pathname.includes(item.to));
    if (matchingItem) {
      setKey(matchingItem.key.toString());
    } else if (activeBar) {
      setKey(activeBar.toString());
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]);

  const handleResize = () => {
    let deviceWidth = window.innerWidth <= 1380;
    setCollapsed(deviceWidth);
    setCollapse(deviceWidth);
  };

  if (ignoredRoutes.includes(location.pathname)) {
    return null;
  }
  if (availableRoutes.includes(location.pathname) == false) {
    return null;
  }
  const handelNavigate = (key: any, name: string) => {
    setKey(key);
    storeData("key", key, false);
  };
  const handleCollapse = (value: boolean) => {
    setCollapsed(value);
  };

  const MenuItem = (item: any) => {
    let isView: any = null;
    if (item.label === menuList.userManagement) {
      if (hasViewPermission) {
        isView = true;
      } else {
        isView = false;
      }
    } else if (item.label === menuList.appManagement) {
      if (hasViewAppStorePermission) {
        isView = true;
      } else {
        isView = false;
      }
    } else if (item.label === menuList.accessManager) {
      if (hasViewAccessMangerPermission) {
        isView = true;
      } else {
        isView = false;
      }
    } else if (item.label === menuList.passwordManager) {
      if (hasViewPasswordMangerPermission) {
        isView = true;
      } else {
        isView = false;
      }
    } else if (item.label === menuList.roleManagement) {
      isView = permission.admin;
    } else {
      isView = true;
    }

    return (
      <>
        {isView ? (
          <Link
            to={item.to}
            onClick={() => {
              handelNavigate(item.key, item.label);
            }}
          >
            {item.label}
          </Link>
        ) : (
          <span
            onClick={() => {
              setPermissionsModal(true);
            }}
          >
            {item.label}
          </span>
        )}
      </>
    );
  };
  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };
  const menuPermissionsStyle = (item: any) => {
    if (item.label === menuList.userManagement) {
      return !hasViewPermission ? "permission-disabled-menu" : "";
    } else if (item.label === menuList.appManagement) {
      return !hasViewAppStorePermission ? "permission-disabled-menu" : "";
    }
    if (item.label === menuList.roleManagement) {
      return !permission.admin ? "permission-remove-menu" : "";
    }
    if (item.label === menuList.accessManager) {
      return !hasViewAccessMangerPermission ? "permission-disabled-menu" : "";
    }
    if (item.label === menuList.passwordManager) {
      return !hasViewPasswordMangerPermission ? "permission-disabled-menu" : "";
    }
  };
  const handleClickNavigation = () => {
    storeData("key", "1", false);
    navigate("/admin-dashboard");
  };

  const handleClickToggle = () => {
    const toggle: any = document.getElementsByClassName("ant-layout-sider-trigger")[0];
    toggle.click();
  };
  return (
    <>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => handleCollapse(value)} className={!collapsed ? "isNotActiveCollapsed" : "activeCollapsed"}>
        <div className="demo-logo-vertical" />
        <img
          onClick={() => {
            handleClickToggle();
          }}
          className="h-8 mt-6 menu-icon cursor-pointer"
          src={images.menuIcon}
          alt="brand logo"
          data-testid="menuId"
        />

        <div className="branLogo flex justify-center pt-10 xl:pt-16  pb-8 xl:pb-10 cursor-pointer" onClick={() => handleClickNavigation()}>
          <ImageView className="" src={images.brandLogo} alt="brand logo" />
        </div>
        <AntMenu theme="dark" selectedKeys={[key || "1"]} mode="inline">
          {items.map((item) => {
            if (item.children) {
              return (
                <AntMenu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                  {item.children.map((child) => (
                    <AntMenu.Item key={child.key}>{child.to ? <Link to={child.to}>{child.label}</Link> : child.label}</AntMenu.Item>
                  ))}
                </AntMenu.SubMenu>
              );
            }
            return (
              <AntMenu.Item key={item.key} icon={item.icon} className={menuPermissionsStyle(item)}>
                {MenuItem(item)}
              </AntMenu.Item>
            );
          })}
        </AntMenu>
        {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
        {!collapsed && <p className="version">Current version-{currentVersion}</p>}
      </Sider>
    </>
  );
};

export default SideBar;
