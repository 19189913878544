import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CardTitle from "../../component/CardTitle";
import batch from "../../../assets/images/icons/licenseManagement.svg";
import { Button } from "antd";
import "./License.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LicenseManagement = () => {
  const licenseData = useSelector((state: any) => state?.dashboardDetails?.data?.licenseManagementDto);
  const data: any = [{ type: "Unused" }, { type: "Used" }];
  const chartOptions1: any = {
    series: [20, 30],
    options: {
      labels: data?.map((row: any) => row?.type),
      colors: ["#FAF1EC", "#FC9D5A"],
      chart: {
        width: 300,
        type: "pie",
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: "100%",
            labels: {
              show: false,
            },
          },
          animate: false,
        },
      },
      stroke: {
        width: 0,
      },
      dataLabels: {
        enabled: false,
        style: {},
      },
      legend: {
        show: false,
      },
      events: {},
      tooltip: {},
      states: {
        hover: {
          filter: {
            type: "none", // Disables the hover effect
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none", // Optionally, disables the active (click) effect as well
          },
        },
      },
      responsive: [
        {
          breakpoint: 767,
          options: {
            chart: {
              width: 150,
            },
          },
        },
      ],
    },
  };
  const navigate = useNavigate();
  const [chartOptions, setChartOptions] = useState<any>(chartOptions1);
  useEffect(() => {
    if (licenseData) {
      const newSeries = [licenseData.totalLicenseCount - licenseData.userUsedLicenseCount, licenseData.userUsedLicenseCount];
      setChartOptions((prevOptions: any) => ({
        ...prevOptions,
        series: newSeries,
      }));
    }
  }, [licenseData]);

  const userManagementNavigation = () => {
    navigate("/user?isActive=true");
  };

  return (
    <>
      <div className="_card _border rounded-lg license w-full h-full min-h-[370px] max-h-[380px] __padding">
        <div className="_card-header --pad-border">
          <div className="title pt-[6px]">
            <CardTitle title="License Management" src={batch} />
          </div>
          <p className="text-[#5441DA] text-[20px] font-Inter font-medium hidden">View in Detail</p>
        </div>
        <div className="flex w-full pt-4 pb-10">
          <div className="w-[55%] flex justify-center items-center">
            <div className="chartWidth">
              <Chart options={chartOptions?.options} series={chartOptions?.series} type="pie" width="225" />
            </div>
            <div className="w-[85%] flex justify-start items-center flex-col">
              <p className="font-Inter text-[20px] font-medium text-[#303030] pb-1 text-rwd16">Used License</p>
              <h3 className="font-Inter text-[44px] text-[#5441DA] leading-[32px] font-semibold cursor-pointer" onClick={userManagementNavigation}>
                {licenseData?.userUsedLicenseCount}
              </h3>
              <p className="font-Inter text-[20px] font-medium text-[#303030] pt-1 text-rwd16">Out of {licenseData?.totalLicenseCount}</p>
            </div>
          </div>
          <div className="w-[45%] flex flex-col justify-center items-center basicPlanConatiner ">
            <div className="license-plan flex">
              <h6 className="text-[22px] text-[#000] font-medium font-Inter text-rwd20">{licenseData?.planName} Plan </h6>
              {licenseData?.planStatus ? (
                <p className="license-status ml-4">
                  <span className="mr-2"> </span> Active
                </p>
              ) : (
                <p className="license-inactive ml-4">
                  <span className="mr-2"> </span> Inactive
                </p>
              )}
            </div>
            <Button disabled={true} className="btn bg-[#5441DA] w-[223px] h-[55px] text-[20px]" type="primary">
              Upgrade
            </Button>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="contribution-percentage w-full   justify-between  pt-5 flex items-center ">
            <p className="font-Inter text-[18px] text-[#747577] italic">
              Recent Purchase : {licenseData?.planPurchasedDate}
              {/* Recent Purchase : <span>1000</span> License on <span>29/11/23</span> , 9.30 AM */}
            </p>
            {/* <p className="font-Inter text-[18px] text-[#747577] italic">
              Order ID - <span>19301-31</span> License on{" "}
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default LicenseManagement;
