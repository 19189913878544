import React, { useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";

interface App {
    app: string;
    description: string;
    baseUrl: string;
    logoUrl: string;
    akkuClientId: number;
}

interface GroupedApps {
    [groupName: string]: App[];
}

interface GroupAppList {
    data: GroupedApps;
    setCheckedState: any;
    checkedState: any
}

const GroupAppList: React.FC<GroupAppList> = ({ data, setCheckedState, checkedState }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [currentValue, setCurrentValue] = useState(null)
    const [groupName, setGroupName] = useState("")
    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleCheckboxChange = (e: any, groupName: string) => {
        const { checked } = e.target;
        setGroupName(groupName)
        setCurrentValue(checked)
        if (checked) {
            setCheckedState({ ...checkedState, [groupName]: checked });
        } else {
            setOpen(true);
        }
    };
    const assignValue = () => {
        setCheckedState({ ...checkedState, [groupName]: currentValue });
        setOpen(false);
    }
    return (
        <div className="app-list user-groupAppList">

            {
                data && Object.entries(data).map(([groupName, apps]) => (
                    <div key={groupName} className="mb-6">
                        <h3>
                            <Checkbox className="mr-2"
                                checked={checkedState[groupName] || false}
                                onChange={(e) => {
                                    handleCheckboxChange(e, groupName);
                                }}

                            /> {groupName}</h3>
                        <div className="app-group user-groupApp mt-3 flex">

                            {apps.map((app) => (
                                <div className="user-groupApp-item" key={app.logoUrl}>
                                    <img src={app.logoUrl} alt="logo" />
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            }



            <Modal className="add-apps rounded-lg groupAddDelete" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>

                <div className="modal-body px-8 pt-8 pb-2 ">
                    <div className="flex justify-center mb-4">
                        <ImageView src={images.notificationDelete} alt="notificationDone" className="delete-apps" />
                    </div>
                    <h3 className="text-center deleteTitle">Are you sure you want to deprovision this user from the group?</h3>
                </div>
                <div className="modal-footer px-8 pt-10">
                    <div className="w-full flex justify-center ">
                        <Button
                            className=" mr-5 flex items-center justify-evenly font-semibold text-[20px] font-Inter  w-[186px] h-[56px]"
                            size="large"
                            onClick={handleCloseModal}
                        >
                            No
                        </Button>
                        <Button
                            data-testid="submit-button"
                            type="primary"
                            htmlType="submit"
                            className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
                            onClick={assignValue}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>

        </div>
    );
};

export default GroupAppList;
