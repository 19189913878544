import * as Yup from "yup";
import { validationErrorMessages } from "../../../../../../../constant/validationMessage";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(validationErrorMessages.firstName.required)
    .matches(/^[a-zA-Z\s]*$/, validationErrorMessages.firstName.letterAndSpace)
    .max(16, validationErrorMessages.firstName.maxLength)
    .min(4, validationErrorMessages.firstName.minLength),
  lastName: Yup.string()
    .required(validationErrorMessages.lastName.required)
    .matches(/^[a-zA-Z\s]*$/, validationErrorMessages.lastName.letterAndSpace)
    .max(16, validationErrorMessages.lastName.maxLength)
    .min(1, validationErrorMessages.lastName.minLength),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  userType: Yup.string().required("User Type is required"),
});
