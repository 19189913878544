import ChartOptions from "../license-card/ChartOptions";
interface ChatType {
  options: ChartOptions;
}
const data = [{ type: "Failure" }, { type: " Successful" }];
export const chartOptions: any = {
  options: {
    labels: data.map((row: any) => row?.type),
    colors: ["#F64D75", "#95DABE"],
    chart: {
      width: 300,
      type: "pie",
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
        expandOnClick: false,
        startAngle: 0,
        endAngle: 360,
        donut: {
          size: "100%",
          labels: {
            show: false,
          },
        },
        animate: false,
      },
    },
    stroke: {
      width: 0,
    },
    tooltip: {
      y: {
        formatter: function (val: any, { series }: any) {
          if (!series) {
            return `${val} %`;
          }
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "13px",
        offsetX: 40,
        offsetY: -10,
      },
    },
    legend: {
      show: false,
    },
    events: {},
    states: {
      hover: {
        filter: {
          type: "none", // Disables the hover effect
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none", // Optionally, disables the active (click) effect as well
        },
      },
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          chart: {
            width: 150,
          },
          legend: {},
        },
      },
    ],
  },
};
