import React, { useState } from "react";
import Chart from "react-apexcharts";
import ChartOptions from "../license-card/ChartOptions";
import { MFAChartData, processData } from "./MFAHelper";
import { images } from "../../../assets/images/ImagePaths";
import ApplicationDetailsModal from "../application/ApplicationDetailsModal";
interface ChatType {
  options: ChartOptions;
}
const ChartMFA = ({ data }: any) => {
  const seriesFromProcessData = processData(data);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedApplication, setSelectedApplication] = useState<any>();

  const chartOptions: ChatType = {
    options: {
      labels: MFAChartData.map((row: any) => row?.type),
      colors: ["#1984A3", "#46D39A", "#6E4F7B", "#867FDA", "#EBD588", "#FC9D5A"],
      chart: {
        width: 300,
        type: "pie",
        events: {
          dataPointSelection: function (event: any, chartContext: any, config: any) {
            const seriesIndex = config.seriesIndex;
            const dataPointIndex = config.dataPointIndex;
            const selectedData = MFAChartData[dataPointIndex];
            console.log(`Clicked on series index: ${seriesIndex}, data point index: ${dataPointIndex}`);
            console.log(`Selected data:`, selectedData);
            setSelectedApplication(selectedData.type);
            setIsActive(true);
            // Add your custom logic here
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: "100%",
            labels: {
              show: false,
            },
          },
          animate: false,
        },
      },
      stroke: {
        width: 0,
      },

      tooltip: {
        y: {
          formatter: function (value: any, { series, seriesIndex, dataPointIndex, w }: any) {
            if (!series) {
              return `${value} %`;
            }
            const total = series?.reduce((acc: any, val: any) => acc + val, 0);
            const percentage = ((value / total) * 100).toFixed(2);
            return `${value} (${percentage}%)`;
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 100,
        offsetY: 100,
        textAnchor: "middle",
        distributed: false,
        style: {
          fontSize: "13px",
        },
      },
      legend: {
        show: true,
        fontSize: "20px",
        color: "#545263",
        formatter: function (seriesName: any, opts: any) {
          const additionalText: any = {
            "Touch ID": `<img src= ${images.thumbIcon} className="material-symbols-outlined">`,
            Authenticator: `<img src= ${images.qrCodeIcon} className="material-symbols-outlined">`,
            "Face ID": `<img src= ${images.faceIcon} className="material-symbols-outlined">`,
            Email: `<img src= ${images.mailIcon} className="material-symbols-outlined">`,
            "Mobile Push": `<img src= ${images.phoneIcon} className="material-symbols-outlined">`,
            SMS: `<img src= ${images.smsIcon} className="material-symbols-outlined">`,
          };
          return (additionalText[seriesName] || "") + seriesName;
        },
      },
      events: {},
      responsive: [
        {
          breakpoint: 1700,
          options: {
            chart: {
              width: 380,
            },
            legend: {},
          },
        },
        {
          breakpoint: 1600,
          options: {
            chart: {
              width: 380,
            },
            legend: {},
          },
        },
        {
          breakpoint: 1440,
          options: {
            chart: {
              width: 350,
            },
            legend: {},
          },
        },
        {
          breakpoint: 1380,
          options: {
            chart: {
              width: 450,
            },

            legend: {},
          },
        },
        {
          breakpoint: 767,
          options: {
            chart: {
              width: 350,
            },
            legend: {},
          },
        },
      ],
    },
  };
  const handleClose = () => {
    setIsActive(false);
  };
  return (
    <>
      <Chart options={chartOptions?.options} series={seriesFromProcessData} type="pie" width="500" className="_chart-custom" />
      {isActive && <ApplicationDetailsModal isActive={isActive} handleClose={handleClose} selectedApplication={selectedApplication} active />}
    </>
  );
};

export default ChartMFA;
