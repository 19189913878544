import React from "react";
import "./CommonHeader.scss";
const CommonHeader = ({ onClick, text, className }: any) => {
  return (
    <div className=" pt-8  w-full flex">
      <p className={`${"title flex items-center "} `}>
        <span className={`${"material-symbols-outlined text-3xl mr-5 "} ${className} `} onClick={onClick}>
          arrow_back
        </span>
        {text}
      </p>
    </div>
  );
};

export default CommonHeader;
