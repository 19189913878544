import React, { useEffect, useRef, useState } from "react";
import AppIcon from "./AppIcon";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import { retrieveData } from "../../../../services/storage/Storage";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { Input } from "antd";
import "./Card.scss";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { useSelector } from "react-redux";
const AppConfigureCard = (props: any) => {
  const { TextArea } = Input;

  const realmName = retrieveData("realmName", true);
  const editAppConfiguration = useGetApiRequests("clientSecretKey", "GET");
  const reGenerateKey = useGetApiRequests("reGenerateKey", "POST");
  const { setInitialValues, responseData, value, setAppName, appName, setDescription, description, setImageDataFinal, logoUrlFromAPi } = props;
  const [editDetails, setEditDetails] = useState<any>({});
  const [appConfiguration, setAppConfiguration] = useState<any>();
  const [secretKey, setSecretKey] = useState<any>("");
  const [visible, setVisible] = useState(false);
  const configureApp = retrieveData("configureApp", true);
  const [editName, setEditName] = useState(true);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const containerRef: any = useRef(null);
  useEffect(() => {
    if (configureApp === "configure") {
      setAppConfiguration(appDetails);
      setDescription(appDetails?.description);
      setAppName(appDetails?.name);
    } else if (configureApp === "edit") {
      setAppConfiguration(appDetails?.akkuMasterClient);
      setEditDetails(appDetails?.akkuMasterClient);
      setAppName(appDetails?.client?.name);
      setDescription(appDetails?.client?.description);
    }
  }, []);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setEditName(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  }, []);
  const onChange = (e: any) => {
    const { name, value } = e.target;
    setInitialValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    setDescription(value);
  };
  useEffect(() => {
    if (value !== "saml" && configureApp === "edit") {
      updateClientSecret();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData, value]);
  const updateClientSecret = async () => {
    let param = {
      "client-id": responseData,
      "realm-name": realmName,
    };
    editAppConfiguration("", param)
      .then((response: any) => {
        setSecretKey(response?.data?.data);
      })
      .catch((err: any) => {
        handleRequestError(err);
      });
  };
  const handleScereatKey = (e: any) => {
    setSecretKey((prevValues: any) => ({
      ...prevValues,
      value: e.target.value,
    }));
  };
  const toggleVisibility = () => {
    setVisible(!visible);
  };
  const handleRegenerate = async () => {
    let param = {
      "client-id": responseData,
      "realm-name": realmName,
    };
    try {
      const response: any = await reGenerateKey(param);
      const status = response.status;
      if (status === 200) {
        triggerNotification("success", "", response?.data?.message, "topRight");
        updateClientSecret();
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  const handelCopyKey = async () => {
    try {
      await navigator.clipboard.writeText(secretKey.value);
    } catch (err) {
      handleRequestError(err);
    }
  };
  const handleEditIcon = () => {
    if (editName) {
      const inputfield = document.getElementById("inputField");
      inputfield?.focus();
    }

    setEditName(!editName);
  };
  const handleAppName = (e: any) => {
    setAppName(e.target.value);
  };
  return (
    <div className="configure-card">
      <div className="max-w-[450px] w-full h-[420px] _container ">
        <div className="flex justify-center items-center bg-[#F7F6FF] rounded-lg">
          {appConfiguration && <AppIcon logoUrlFromAPi={logoUrlFromAPi} setImageDataFinal={setImageDataFinal} singleAppDetails={appConfiguration} editDetails={editDetails} />}
        </div>
        <div ref={containerRef}>
          {configureApp === "edit" ? (
            <div className="flex pt-20 justify-center w-full">
              <div className="relative flex">
                <Input
                  id="inputField"
                  className={`text-center text-[20px] font-Inter font-semibold ${editName ? "pointer-events-none" : "pointer-events-auto"} `}
                  value={appName}
                  onChange={(e: any) => handleAppName(e)}
                  variant={!editName ? "outlined" : "borderless"}
                />
                <div className="absolute right-0  w-6 top-0 flex items-center cursor-pointer" onClick={() => handleEditIcon()}>
                  <ImageView src={images?.edit} alt="edit" className="pr-[5px] pt-[15px] pb-[5px]" />
                </div>
              </div>
            </div>
          ) : (
            <p className="pt-20 text-[20px] justify-center items-center  flex font-Inter font-semibold">{appName}</p>
          )}
          <div className="w-full p-1 ">
            <TextArea
              className={`myAppsTextArea font-Inter text-[16px] ${editName ? "pointer-events-none" : "pointer-events-auto"}`}
              value={description}
              showCount={!editName}
              maxLength={500}
              onChange={onChange}
              placeholder="Enter Description"
              variant={editName ? "borderless" : "outlined"}
            />
          </div>
        </div>
        {value !== "saml" && configureApp === "edit" ? (
          <div className="w-full pt-8 relative">
            <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">Client Secret</p>
            <Input
              addonAfter={
                <span className="material-symbols-outlined cursor-pointer" onClick={handelCopyKey}>
                  content_copy
                </span>
              }
              value={secretKey?.value}
              onChange={(e: any) => {
                handleScereatKey(e);
              }}
              type={visible ? "text" : "password"}
              className="secret-key font-medium text-[18px]"
              readOnly
              name="secretKey"
            />
            {!visible ? (
              <span className="material-icons-outlined absolute right-[115px] cursor-pointer top-[40%]" onClick={toggleVisibility}>
                visibility_off
              </span>
            ) : (
              <span className="material-icons-outlined absolute right-[115px] cursor-pointer top-[40%]" onClick={toggleVisibility}>
                visibility
              </span>
            )}
            <p className="text-lg font-normal py-6 text-[#5441DA] font-Inter flex justify-start items-center cursor-pointer mr-2 w-[100px] " onClick={handleRegenerate}>
              <span className="material-symbols-outlined mr-2">replay</span> Regenerate
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default AppConfigureCard;
