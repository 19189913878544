import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Input } from "antd";
import { emailValidationSchema } from "../../../layouts/dashboard-module/customer-table/modal/schema/ValidationScheme";
import UpdateModal from "../../../layouts/dashboard-module/customer-table/modal/forms/UpdateModal";

const EditEmailConfiguration = (props: any) => {
  const { emailDataChanges } = props;
  const handleSubmit = (values: any) => {
    console.log(values);
  };
  const [isOpenPopup, setIsOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    senderEmail: "",
    displaySenderEmail: "",
    replyEmail: "",
    displayReplyEmail: "",
  });
  useEffect(() => {
    const init = {
      senderEmail: emailDataChanges.senderEmail,
      displaySenderEmail: emailDataChanges.displaySenderEmail,
      replyEmail: emailDataChanges.replyEmail,
      displayReplyEmail: emailDataChanges.displayReplyEmail,
    };
    setInitialValues(init);
  }, [emailDataChanges]);
  const handleBlurEvent = async (e: any, setFieldTouched: any, errors: any) => {
    const fieldName = e.target.name;
    await setFieldTouched(fieldName, true);
    if (!errors[fieldName]) {
      setIsOpen(true);
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div className="w-full">
      <p className="title">Email Configuration</p>
      <Formik initialValues={initialValues} values={initialValues} validationSchema={emailValidationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ values, errors, setFieldTouched }) => {
          return (
            <Form>
              <div className="w-full">
                <div className="w-full">
                  <div className=" mx-auto">
                    <div className="w-[90%] flex pt-[20px] flex-col edit-basic">
                      <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
                        <div className="mb-10 w-[40%] input-container relative">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">From*</p>
                          <Field
                            onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                            as={Input}
                            type="text"
                            className="h-[56px] font-medium text-[18px]"
                            name="senderEmail"
                            data-testid="editSendMail-config"
                            placeholder="Sender email address"
                          />
                          <ErrorMessage name="senderEmail" component="div" className="error-message" />
                        </div>
                        <div className="mb-10 w-[40%] input-container relative">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">From display name*</p>
                          <Field
                            onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                            as={Input}
                            type="text"
                            data-testid="editMail-config"
                            className="h-[56px] font-medium text-[18px]"
                            name="displaySenderEmail"
                            placeholder="Display name for sender email address"
                          />
                          <ErrorMessage name="displaySenderEmail" component="div" className="error-message" />
                        </div>
                        <div className="mb-10 w-[40%] input-container relative">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Reply to*</p>
                          <Field
                            onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                            as={Input}
                            type="text"
                            className="h-[56px] font-medium text-[18px]"
                            name="replyEmail"
                            placeholder="Reply to email address"
                          />
                          <ErrorMessage name="replyEmail" component="div" className="error-message" />
                        </div>
                        <div className="mb-10 w-[40%] input-container relative">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Reply to display name*</p>
                          <Field
                            onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                            as={Input}
                            type="text"
                            className="h-[56px] font-medium text-[18px]"
                            name="displayReplyEmail"
                            placeholder="Display name for reply email address"
                          />
                          <ErrorMessage name="displayReplyEmail" component="div" className="error-message" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer hidden ">
                    <Button htmlType="submit" type="primary" className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {<UpdateModal handleClose={handleClose} isOpenPopup={isOpenPopup} />}
    </div>
  );
};

export default EditEmailConfiguration;
