import { Modal } from 'antd'
import CustomButtonBack from '../component/CustomButtonBack'
import { rolePermissionsMessage } from '../../const/RolePermissions'
import warningIcon from './../../assets/images/user/warning.svg';
import CustomButtonNext from '../component/CustomButton'
import ImageView from '../component/ImageView'

export default function PermissionsModal({ open, close, userCreateNotAllowed = false }: any) {


    return (
        <Modal className="p-10 pb-8 permission-modal  rounded-lg" closable={false} maskClosable={false} centered open={open} footer={false} onCancel={close}>
            <div className="modal-body  pt-1 ">
                <div className="flex w-full justify-center items-center flex-col">
                    <ImageView src={warningIcon} alt="warningIcon" className="w-20 h-20" />
                    <p className='mt-2'>{userCreateNotAllowed ? " Please configure at least one app to add users" : rolePermissionsMessage.contactAdmin}</p>
                </div>
            </div>
            <div className="modal-footer pt-8">
                <div className="w-full flex justify-center">
                    <CustomButtonBack onClick={close} text={userCreateNotAllowed ? "OK" : "Cancel"} />
                    {
                        !userCreateNotAllowed && <CustomButtonNext text={"Contact admin"} />
                    }

                </div>
            </div>
        </Modal>
    )
}
