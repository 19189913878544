import React from "react";
import { Modal } from "antd";
import success from "./../../../assets/access-manager/success.svg";

export default function IpTimeSuccessModal(props: any) {
  const { open, handleModalClose, responseMessage } = props;
  return (
    <div>
      <Modal className="timerSuccessModal rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleModalClose}>
        <div className="timerSuccessModal-content flex justify-center items-center flex-wrap">
          <div className="success my-4">
            <img src={success} alt="success icon" />
          </div>
          <p className="w-full">{responseMessage}</p>
        </div>
      </Modal>
    </div>
  );
}
