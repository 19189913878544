import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ImageView from "../../../../../../../../layouts/component/ImageView";
import { images } from "../../../../../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../../../layouts/component/CustomButton";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../../../layouts/toast/ToastBar";
import { retrieveData } from "../../../../../../../../services/storage/Storage";
import Loader from "../../../../../../../../layouts/component/Loader";
const DeleteAttributes = (props: any) => {
  const { handleClose, isDelete, attributeConfig, setIsAttributeDelete, isAttributeDelete } = props;
  const [open, isOpen] = useState<boolean>(false);
  const deleteAttribute = useGetApiRequests("deleteAttribute", "DELETE");
  const realmName = retrieveData("realmName", true);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    isOpen(isDelete);
  }, [isDelete]);
  const handleDeleteConnection = async () => {
    setLoader(true);
    const payload = {
      realmName: realmName,
    };
    const attributeId = attributeConfig.id;
    try {
      const response: any = await deleteAttribute("", payload, [attributeId]);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data;
        triggerNotification("success", "", data?.message, "topRight");
        handleClose();
        setIsAttributeDelete(!isAttributeDelete);
        setLoader(false);
      }
    } catch (err: any) {
      setLoader(false);
      triggerNotification("error", "", err?.response?.data?.message, "topRight");
    }
  };
  return (
    <Modal className="rounded-lg delete-credential" maskClosable={false} centered open={open} footer={false} onCancel={handleClose}>
      {loader && <Loader />}
      <div className="modal-body  justify-center  pt-4 px-12 ">
        <div className="w-full justify-center flex">
          <div className="w-20">
            <ImageView src={images.notificationDelete} />
          </div>
        </div>
        <div className="w-full ">
          <p className="text-center delete">Delete Attribute</p>
          <p className="text-center confirm">Are you sure want to delete?</p>
        </div>
      </div>
      <div className="modal-footer pt-10">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleClose} text={"No"} />
          <CustomButtonNext onClick={handleDeleteConnection} text={" Yes "} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAttributes;
