import React, { useState } from "react";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import icon from "./../../../../../assets/images/icons/dragIcon.svg";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { useSelector } from "react-redux";
import { retrieveData } from "../../../../../services/storage/Storage";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;

const DragAndDrop: React.FC = () => {
  const navigate = useNavigate();
  const keycloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";
  const realmId = retrieveData("realmId", true);
  const [file, setFile] = useState<any>(null);
  const [errorMessages, setErrorMessages] = useState("");

  const userCSVImportApi = useGetApiRequests("userCSVImport", "POST");

  // State to track if the upload is successful
  const [isActiveUploadContent, setIsActiveUploadContent] = useState(true);

  const props: UploadProps = {
    onChange(info: any) {
      const { status, size, type } = info.file;
      const isCSV = type === "text/csv";
      const isLt20M = size / 1024 / 1024 < 20;

      if (!isCSV) {
        setErrorMessages("You can only upload CSV files!");
        return;
      }
      if (!isLt20M) {
        setErrorMessages("CSV file must be smaller than 20MB!");
        return;
      }
      if (status === "removed") {
        setIsActiveUploadContent(true);
      }
      if (status === "uploading") {
        setIsActiveUploadContent(false);
      }
      if (status === "done" || status === "error") {
        setErrorMessages("");
        createPayload(info, false);
      }
    },
    onDrop(e) {
      const file = e?.dataTransfer?.files[0];
      const isCSV = file?.type === "text/csv";
      const isLt20M = file?.size / 1024 / 1024 < 20;

      if (!isCSV) {
        setErrorMessages("You can only upload CSV files!");
        setIsActiveUploadContent(true);
        return;
      }
      if (!isLt20M) {
        setErrorMessages("CSV file must be smaller than 20MB!");
        setIsActiveUploadContent(true);
        return;
      }

      setIsActiveUploadContent(false);
    },
  };

  const createPayload = (info: any, drag: boolean = false) => {
    if (info) {
      setFile(info.file);
      const formData = new FormData();
      formData.append("file", drag ? info : info?.file?.originFileObj);
      formData.append("name", drag ? info?.name : info?.file.name);
      requestUpload(formData);
    }
  };

  const requestUpload = async (formData: any) => {
    const payload = {
      userId: userId,
      realmId: realmId,
    };

    try {
      const headers = { "Content-Type": "multipart/form-data" };
      const response: any = await userCSVImportApi(formData === "" ? {} : formData, { UserCsvRequestDtoStr: payload }, {}, headers);
      const status = response.status;
      if (status === 200) {
        const data = response?.data;
        setTimeout(() => {
          triggerNotification("success", "", data?.message, "topRight");
        }, 2000);
        setTimeout(() => {
          setIsActiveUploadContent(true);
          navigate("/user");
        }, 3000);
      }
    } catch (err: any) {
      setIsActiveUploadContent(true);
    }
  };

  return (
    <div className={`dragger ${!isActiveUploadContent ? "active" : ""} ${errorMessages ? "error" : ""}`}>
      <Dragger {...props}>
        {isActiveUploadContent ? (
          <div>
            <div className="ant-upload-drag-icon w-full flex justify-center">
              <div>
                <img src={icon} alt="icon" />
              </div>
            </div>
            <p className="ant-upload-text">
              <span>Click to Upload or </span> Drag and Drop
            </p>
            <p className="ant-upload-hint">CSV file format (max 2MB)</p>
            {errorMessages && <p className="error-message text-center left-[50%] translate-x-[-50%]">{errorMessages}</p>}
          </div>
        ) : (
          <div className="processingData">
            <p className="processingDataText">Processing your data...</p>
            <div className="processingDataFile-container flex items-center justify-center">
              <div className="processingDataFile flex items-center">
                <span className="material-symbols-outlined">hourglass_bottom</span> <span className="fileName">{file?.name}</span>
              </div>
            </div>
            <p className="processingDataHint">Please wait as this might take some time if your data is huge</p>
          </div>
        )}
      </Dragger>
    </div>
  );
};

export default DragAndDrop;
