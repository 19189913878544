import React from "react";
import { useNavigate } from "react-router-dom";

const GroupsBreadcrumb = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ul className="flex breadcrumb">
        <li
          className="font-Inter pr-1 cursor-pointer"
          onClick={() => {
            navigate("/user");
          }}
        >
          User management /
        </li>
        <li className="font-Inter pr-1 active">Groups </li>
      </ul>
    </div>
  );
};

export default GroupsBreadcrumb;
