import React from "react";
import { staticMessage } from "../../../../../../../../constant/StaticContent";
import { Tooltip } from "antd";

const CommonHeader = (props: any) => {
  const { currentStep, handleToBack, className } = props;

  const handelNavigate = () => {
    handleToBack();
  };

  return (
    <>
      <div className="h-[160px] flex items-center px-20 bg-[#F0F0FA] rounded-lg form-stepper">
        <div className="flex w-full justify-between">
          <div className={`${className}  w-[5%] flex justify-center items-center`}>
            <Tooltip title={"Back"}>
              <span className="material-symbols-outlined cursor-pointer" onClick={handelNavigate}>
                arrow_back
              </span>
            </Tooltip>
          </div>
          <div className={`${className === "hidden" ? "pl-4" : ""} w-[95%] flex flex-col`}>
            <p className="user-type">{staticMessage.singleUserMessage.sbuTitle}</p>
            <h5 className=" user-title">{currentStep === 1 ? staticMessage.userTab.tabOne : staticMessage.userTab.tabTow}</h5>
            <>
              <p className="user-type">{currentStep === 1 ? staticMessage.singleUserMessage.basicDetailsMessage : staticMessage.singleUserMessage.personalDetailsMessage}</p>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonHeader;
