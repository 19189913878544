import React from "react";
import { useNavigate } from "react-router-dom";
import RiskAssessmentTabs from "./components/RiskAssessmentTabs";
import "./components/RiskManagement.scss";
import { useSelector } from "react-redux";
const RiskAssessment = () => {
  const navigate = useNavigate();

  const userDetails = useSelector((state: any) => state?.dashboardDetails?.riskAssessmentData);

  const handleNavigate = () => {
    navigate("/admin-dashboard");
  };
  return (
    <div className="akku-container">
      <div className="main-container bg-[#F5F5FA]">
        <ul className="flex breadcrumb">
          <li role="none" className="font-Inter  pr-1 cursor-pointer !text-[18px]" onClick={handleNavigate}>
            Smart Analytics {"/ "}
          </li>
          <li className="active font-Inter !text-[18px]" data-testid="risk-assessment">
            {" "}
            Risk Assessment{" "}
          </li>
        </ul>
        <div className="flex justify-between">
          <p className="title py-2">Risk Assessment</p>
          <div className="border flex justify-between px-5 py-7 items-center w-[275px] h-[50px] bg-[#F9FAFB] rounded">
            <span className="material-symbols-outlined">account_circle_off</span>
            <span className="font-Inter text-[17px] text-[#000] pr-3">Unused Licenses</span>
            <span className="font-Inter font-semibold text-[20px] text-[#3A3A3A] leading-[32px]  text-rwd26">{userDetails?.unusedLicenses}</span>
          </div>
        </div>
        <div>
          <RiskAssessmentTabs />
        </div>
      </div>
    </div>
  );
};

export default RiskAssessment;
