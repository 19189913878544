import * as Yup from "yup";

export   const validationSchema = Yup.object().shape({
    name: Yup.string().required("Role name is required")
     .max(50, "Role name must be at most 50 characters"),
    roleDescription: Yup.string()
    .required("Role description is required")
    .max(50, "Role description must be at most 50 characters"),
  });


   export  const permissionInitialValues: any = {
    name: "",
    roleDescription: "",
    attributeList: {},
  };