import { Modal } from "antd";
import React from "react";
import ImageView from "../../component/ImageView";
import CustomButtonNext from "../../component/CustomButton";
import unauthorized from "./../../../assets/images/icons/unauthorized.svg";

interface UnauthorizedModalProps {
  open: boolean;
  loader: boolean;
  isActiveBackButton: boolean;
  handleModalClose: () => void;
  logout?: () => void;
  modalType?: string;
}

const UnauthorizedModal: React.FC<UnauthorizedModalProps> = ({ open, loader, handleModalClose, logout, isActiveBackButton = false, modalType = "ip" }) => {
  return (
    <Modal className="p-10  show-notification unauthorized  logoutModal rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleModalClose}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <div className="w-20 h-20">
            <ImageView alt="notificationDone" src={unauthorized} />
          </div>
          <h5 className="text-[24px] font-Inter  pt-3 font-semibold"> {modalType === "ip" ? "Unauthorized Access!" : "Restrictions!"}</h5>
          <p className="text-[20px] text-center font-Inter font-medium text-[#475467]">
            {modalType === "ip"
              ? "Your administrator has restricted your access from this IP address. Please contact administrator for more information on this."
              : "The operation has been canceled due to time restrictions within the application. Please reach out to your system administrator for assistance."}
          </p>
        </div>
      </div>
      {isActiveBackButton && (
        <div className="modal-footer pt-9">
          <div className="w-full flex justify-center">{logout && <CustomButtonNext onClick={logout} text={modalType === "ip" ? "Go back" : "OK"} loader={loader} />}</div>
        </div>
      )}
    </Modal>
  );
};

export default UnauthorizedModal;
