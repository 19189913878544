import React, { useEffect, useState } from "react";
import { Button, Input, Select, Switch, Tooltip } from "antd";
import { ErrorMessage, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import CustomButtonBack from "../../../../component/CustomButtonBack";
import ImageView from "../../../../component/ImageView";
import GreenCheckIcon from "../../../../../assets/images/icons/greencheck.svg";
import { useDispatch } from "react-redux";
import { setCustomerData, setCustomerDetailFromApi } from "../../../../../redux/slice/CustomerSlice";
import { retrieveData } from "../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../toast/ErrorNotificationMessage";
import UpdateModal from "./UpdateModal";
import { helpMessage } from "./sessionIdleFormhelper";
import "react-phone-input-2/lib/bootstrap.css";

const GeneralDetailsForm = (props: any) => {
  const getThemeList = useGetApiRequests("getThemeList", "GET");
  const getSmsProvider = useGetApiRequests("getSmsServiceProviders", "GET");

  const {
    errors,
    enableTest,
    setFieldValue,
    setCurrentTab,
    values,
    setEnableTest,
    isEdit,
    handleCloseModal,
    availableButton,
    getCustomerDetails,
    setGetCustomerDetails,
    active,
    setFieldTouched,
    disableSmsProvider,
  } = props;

  const environment = process.env.REACT_APP_ENV;
  const dispatch = useDispatch();
  const realmName = retrieveData("realmName", true);
  const [loader, setLoader] = useState<boolean>(false);
  const [themeLists, setThemeLists] = useState<any>([]);
  const [isOpenPopup, setIsOpen] = useState<boolean>(false);
  const [smsProviderList, setSmsProviderList] = useState<any>([]);

  useEffect(() => {
    if (isEdit) {
      setEnableTest(false);
    }
  }, [isEdit, enableTest]);

  useEffect(() => {
    getServiceProviders();
    initThemeList();
  }, []);
  const getServiceProviders = async () => {
    try {
      const response: any = await getSmsProvider();
      const status = response.status;
      if (status === 200) {
        const list = response?.data?.data;
        const providersList = Object.entries(list)?.map(([label, value]) => ({
          label,
          value,
        }));
        setSmsProviderList(providersList);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const initThemeList = async () => {
    setLoader(true);
    try {
      const response: any = await getThemeList();
      const status = response.status;
      if (status === 200) {
        const options: any[] = [];

        const themes = response?.data?.loginCustomerThemes;
        Object.entries(themes).forEach(([theme, url]) => {
          options.push({
            value: url,
            label: theme,
          });
        });

        setThemeLists(options);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const handleLoginPasswordChange = (checked: boolean) => {
    setFieldValue("passwordLogin", checked);
  };
  const handleNext = () => {
    if (Object.keys(errors).length === 0) {
      const submittedValues: any = {
        customerId: values?.customerId,
        displayName: values?.displayName,
        primaryEmailId: values?.customerEmailId,
        noOfLicense: values?.noOfLicense,
        primaryMobileNumber: values?.phone,
        loginTheme: values?.loginTheme,
        parentCustomer: realmName,
        customerType: "CLIENT",
        passwordLogin: values?.passwordLogin,
        smsServiceProvider: values?.smsServiceProvider,
      };
      const customerGeneralDetail = {
        customerGeneralDetailsDto: submittedValues,
      };
      dispatch(setCustomerData(customerGeneralDetail));
      dispatch(
        setCustomerDetailFromApi({
          ...getCustomerDetails,
          generalDetails: submittedValues,
        }),
      );
      setGetCustomerDetails({
        ...getCustomerDetails,
        generalDetails: submittedValues,
      });
      setCurrentTab(2);
    }
  };
  const handleBackButton = () => {
    handleCloseModal();
  };

  const handleCustomerId = (e: any) => {
    setFieldValue("customerId", e.target.value);
    setEnableTest(true);
  };
  const handleBlurEvent = async (e: any) => {
    const fieldName = e.target.name;
    await setFieldTouched(fieldName, true);
    if (!errors[fieldName] && active === true) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={`w-full overflow-y-auto general-details-container ${active !== true ? "h-[80vh]" : ""}`}>
      <div className={` overflow-x-hidden mx-auto  ${active === true ? "mb-0 w-full" : "mb-10 w-[92%]"}`}>
        <div className={`w-[90%] flex flex-col edit-basic ${active === true ? "pt-0" : "pt-[50px] "}`}>
          <div className={`w-full  flex mx-auto justify-between flex-wrap ${active !== true ? "pb-10" : "pb-0"}`}>
            {!active && (
              <div className="mb-10 w-[42%] relative input-container ">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Customer ID*</p>
                <Field
                  data-testid="customerId-test"
                  as={Input}
                  disabled={isEdit}
                  type="text"
                  className="h-[56px] font-medium text-[18px]"
                  name="customerId"
                  maxLength={30}
                  onChange={(e: any) => {
                    handleCustomerId(e);
                  }}
                />
                {availableButton && values?.customerId && (
                  <p className="absolute right-3 top-[45px] flex items-center">
                    <ImageView src={GreenCheckIcon} /> <span className="px-2 text-[#5441DA] text-[16px] font-Inter">Available</span>
                  </p>
                )}
                {values?.customerId && (
                  <p className="text-[#1D1D24] text-[17px] font-Inter font-medium mt-1 pl-3">{`https://${values.customerId}${environment === "sandbox" || environment === "production" || environment === "uat" || environment === "staging" || environment === "development" ? ".akku.work" : ".cloudnowtech.com"}`}</p>
                )}
                <ErrorMessage name="customerId" component="div" className="error-message" />
              </div>
            )}
            <div className={`mb-10  input-container relative" ${active === true ? "w-[30%]" : "w-[42%]"}`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Display name*</p>
              <Field onBlur={handleBlurEvent} as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="displayName" data-testid="displayName-test" maxLength={30} />
              <ErrorMessage name="displayName" component="div" className="error-message" />
            </div>
            {!active && (
              <div className={`mb-10 w-[42%] input-container relative ${errors?.customerId && "pt-3"} `}>
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Customer email ID*</p>
                <Field as={Input} disabled={isEdit} type="text" className="h-[56px] font-medium text-[18px]" name="customerEmailId" data-testid="customerEmailId-test" />
                <ErrorMessage name="customerEmailId" component="div" className="error-message" />
              </div>
            )}
            <div className={`mb-10  input-container relative" ${active === true ? "w-[30%]" : "w-[42%]"}`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">No. of license*</p>
              <Field onBlur={handleBlurEvent} as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="noOfLicense" data-testid="noOfLicense-test" />
              <ErrorMessage name="noOfLicense" component="div" className="error-message" />
            </div>
            <div className={`mb-10  input-container relative" ${active === true ? "w-[30%]" : "w-[42%]"}`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Login theme</p>

              <Field
                onBlur={handleBlurEvent}
                as={Select}
                type="text"
                className="h-[56px] font-medium text-[18px] w-[100%] loginThemeText"
                name="loginTheme"
                value={values.loginTheme?values.loginTheme:undefined}
                loader={loader}
                onChange={(value: string) => setFieldValue("loginTheme", value)}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select the option"
              >
                {themeLists.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    <Tooltip title={option.label}>{option.label}</Tooltip>
                  </Select.Option>
                ))}
              </Field>
              <ErrorMessage name="loginTheme" component="div" className="error-message" />
            </div>
            <div className={`"mb-10  input-container relative" ${active === true ? "w-[30%]" : "w-[42%]"}`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Mobile Number*</p>
              <PhoneInput onBlur={handleBlurEvent} inputClass="ant-input h-[56px] w-[100%]" country={"us"} value={values.phone} onChange={(value) => setFieldValue("phone", value)} />
              <ErrorMessage name="phone" component="div" className="error-message" />
            </div>
            <div className="mt-6 xl:mt-4 w-[100%]  flex items-center xl:w-[42%] ">
              <span className="text-[18px] text-[#1D1D24] font-Inter font-medium">Set Password on first login</span>
              <Tooltip title={helpMessage.setPasswordLogin}>
                <span className="material-symbols-outlined   cursor-pointer "> help</span>
              </Tooltip>
              <Switch checked={values?.passwordLogin} className="ml-16" onChange={handleLoginPasswordChange} />
              <ErrorMessage name="passwordLogin" component="div" className="error-message" />
            </div>
            <div className={`input-container relative mt-4 xl:mt-0" ${active === true ? "w-[30%]" : "w-[42%]"}`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Region*</p>
              <Field
                as={Select}
                disabled={disableSmsProvider && isEdit}
                type="text"
                className="h-[56px] font-medium text-[18px] w-[100%] loginThemeText"
                name="smsServiceProvider"
                value={values.smsServiceProvider ? values.smsServiceProvider : undefined}
                loader={loader}
                onChange={(value: string) => setFieldValue("smsServiceProvider", value)}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select the option"
              >
                {smsProviderList?.map((option: any) => (
                  <Select.Option key={option?.value} value={option?.value}>
                    <Tooltip title={option?.label}>{option?.label}</Tooltip>
                  </Select.Option>
                ))}
              </Field>
              <ErrorMessage name="smsServiceProvider" component="div" className="error-message" />
            </div>
          </div>
        </div>
      </div>
      <div className={active !== true ? "footer" : " hidden"}>
        <div className="modal-footer w-[95%] mx-auto ">
          {!isEdit ? (
            <div className="w-full flex justify-end">
              <CustomButtonBack onClick={handleBackButton} text={"Cancel"} />

              {!enableTest ? (
                <Button
                  type="primary"
                  disabled={Object.keys(errors).length !== 0}
                  onClick={handleNext}
                  className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
                >
                  Next
                </Button>
              ) : (
                <Button htmlType="submit" type="primary" className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                  Test
                </Button>
              )}
            </div>
          ) : (
            <div className="w-full flex justify-end">
              <CustomButtonBack onClick={handleBackButton} text={"Cancel"} />
              <Button
                htmlType="submit"
                disabled={Object.keys(errors).length !== 0}
                type="primary"
                className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </div>
      {<UpdateModal handleClose={handleClose} isOpenPopup={isOpenPopup} />}
    </div>
  );
};

export default GeneralDetailsForm;
