import * as Yup from "yup";
export const validationSchemaForIp = Yup.object().shape({
  name: Yup.string().required("Name is required").max(20, "Name cannot exceed 20 characters"),
  description: Yup.string().required("Description is required").max(50, "Description cannot exceed 50 characters"),
  authorizationType: Yup.string().required("IP type is required"),
  assignTo: Yup.string().required("Please select any one"),
  ipDetails: Yup.array().of(
    Yup.object().shape({
      IPv6: Yup.lazy((value) => {
        if (value && value.length > 0) {
          return Yup.string()
            .matches(/^([0-9a-fA-F]{4}:){7}[0-9a-fA-F]{4}$/, "Invalid IPv6 address format")
            .test("unique-name", "IP address already exists", function (value) {
              const { path, options }: any = this;
              const fields = options.context.ipDetails;
              const currentField = parseInt(path.match(/\d+/)[0]);
              const isDuplicate = fields
                ?.filter((field: any, index: any) => index !== currentField)
                .some((field: any) => field.IPv6 === value);

              return !isDuplicate;
            });
        }
        return Yup.string().nullable();
      }),

      IPv4: Yup.string()
        .test("unique-name", "IP address already exists", function (value: any) {
          const { path, options }: any = this;
          const fields: any = options.context.ipDetails;
          const currentFieldIndex = parseInt(path.match(/\d+/)[0]);
          const isDuplicate = fields
            ?.filter((field: any, index: any) => index !== currentFieldIndex)
            .some((field: any) => {
              return field.IPv4 === value;
            });

          return !isDuplicate;
        })
        .test("ipSegmentRange", "Invalid IP address ", function (value) {
          if (!value) return true; // Skip this test if value is not provided
          const segments = value.split('.');
          return segments.every(segment => {
            const num = Number(segment);
            return num >= 0 && num <= 255;
          });
        })
        .test("ipValidation", "Invalid IP address format ", function (value: string | undefined) {
          const ipRange = this.parent.ipRange;
          if (ipRange === "exactIp") {
            return Yup.string()
              .matches(/^(?:\d{1,3}\.){3}\d{1,3}$/, "Invalid IP address format for exact IP")
              .isValidSync(value);
          } else if (ipRange === "range16") {
            return Yup.string()
              .matches(/^(\d{1,3}\.){1,2}\d{1,3}$/, "Invalid IP address format for Range 16")
              .test("range16-format", "Invalid IP address format for Range 16", (val: string | undefined) => {
                return /^(\d{1,3}\.){1,2}\d{1,3}$/.test(val ?? "");
              })
              .isValidSync(value);
          } else if (ipRange === "range24") {
            return Yup.string()
              .matches(/^(\d{1,3}\.){2}\d{1,3}$/, "Invalid IP address format for Range 24")
              .test("range24-format", "Invalid IP address format for Range 24", (val: string | undefined) => {
                return /^(\d{1,3}\.){2}\d{1,3}$/.test(val ?? "");
              })
              .isValidSync(value);
          }
          else {
            return Yup.string()
              .required("Invalid IP address format for Range 24")
              .isValidSync(value);
          }
        })
        .required("IP address is required"),
      ipRange: Yup.string().required("IP Range is required"),
    })),

});

export const ipRange = [
  { label: "Exact IP", value: "exactIp" },
  { label: "Range 16", value: "range16" },
  { label: "Range 24", value: "range24" },
];
