import React, { useEffect, useState } from "react";
import { Collapse, CollapseProps, Input } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import { staticMessage } from "../../../../../constant/StaticContent";
import ToolTipCustom from "../../../../../layouts/component/ToolTipCustom";

const OpenIdLogInSetting = (props: any) => {
  const { values, isError } = props;
  const [activeKey, setActiveKey] = useState<string>("");

  useEffect(() => {
    if (isError) {
      setActiveKey("1");
    }
  }, [isError]);
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const item: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[18px] font-normal">3 . Login Settings</p>,
      children: (
        <>
          <FieldArray name="openId">
            {({ insert, remove, push }) => (
              <div>
                {values?.openId?.length > 0 &&
                  values.openId.map((samlConfig: any, index: any) => (
                    <div className="flex w-full justify-between flex-col" key={samlConfig?.clientId}>
                      {/*  */}
                      <div className="w-full flex justify-between">
                        <div className="mb-10 w-[45%]">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium flex items-center">
                            Root URL
                            <ToolTipCustom title={staticMessage.app.root} />
                          </p>
                          <Field as={Input} type="text" name={`openId.${index}.rootUrl`} className="h-[56px] font-medium text-[18px]" />
                          <ErrorMessage name={`openId.${index}.rootUrl`} component="div" className="error-message custom-error" />
                        </div>
                        {/*  */}
                        <div className=" w-[45%]">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium flex items-center">
                            Home URL*
                            <ToolTipCustom title={staticMessage.app.home} />
                          </p>
                          <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.baseUrl`} />
                          <ErrorMessage name={`openId.${index}.baseUrl`} component="div" className="error-message custom-error" />
                        </div>
                      </div>
                      <div>
                        <FieldArray name={`openId.${index}.validRedirectUrl`}>
                          {({ push, remove }) => (
                            <div>
                              {samlConfig.validRedirectUrl.map((url: any, urlIndex: any) => (
                                <div key={samlConfig?.clientId} className="relative ">
                                  <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium ">
                                    Valid redirect URL* <ToolTipCustom title={staticMessage.app.openRedirect} />{" "}
                                  </p>
                                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.validRedirectUrl.${urlIndex}`} />
                                  <ErrorMessage name={`openId.${index}.validRedirectUrl.${urlIndex}`} component="div" className="error-message custom-error" />
                                  <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-11" onClick={() => remove(urlIndex)}>
                                    <span className="material-symbols-outlined">do_not_disturb_on</span>
                                  </button>
                                </div>
                              ))}
                              <button type="button" onClick={() => push("")}>
                                <p className="text-[#444] text-[18px] font-Inter flex pt-3">
                                  <span className="material-symbols-outlined">add_circle</span>
                                  Add Valid Redirect URLs
                                </p>
                              </button>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                      <FieldArray name={`openId.${index}.validRedirectLogoutUrl`}>
                        {({ push, remove }) => (
                          <div>
                            {samlConfig.validRedirectLogoutUrl.map((url: any, urlIndex: any) => (
                              <div key={samlConfig?.clientId} className="relative mt-8">
                                <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium">
                                  Valid Post Logout redirect URL
                                  <ToolTipCustom title={staticMessage.app.openIdLog} />
                                </p>
                                <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.validRedirectLogoutUrl.${urlIndex}`} />
                                <ErrorMessage name={`openId.${index}.validRedirectLogoutUrl.${urlIndex}`} component="div" className="error-message custom-error" />
                                <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-11" onClick={() => remove(urlIndex)}>
                                  <span className="material-symbols-outlined">do_not_disturb_on</span>
                                </button>
                              </div>
                            ))}
                            <button type="button" onClick={() => push("")}>
                              <p className="text-[#444] text-[18px] font-Inter  flex pt-3">
                                <span className="material-symbols-outlined">add_circle</span>
                                Add Valid Post Logout redirect URLs
                              </p>
                            </button>
                          </div>
                        )}
                      </FieldArray>
                      <FieldArray name={`openId.${index}.webOrigins`}>
                        {({ push, remove }) => (
                          <div className="mb-8">
                            {samlConfig.webOrigins.map((url: any, urlIndex: any) => (
                              <div key={samlConfig?.clientId} className="relative mt-8">
                                <p className="text-[#1D1D24] flex items-center text-[18px] font-Inter font-medium">
                                  Web Origin
                                  <ToolTipCustom title={staticMessage.app.webOrgin} />
                                </p>
                                <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.webOrigins.${urlIndex}`} />
                                <ErrorMessage name={`openId.${index}.webOrigins.${urlIndex}`} component="div" className="error-message custom-error" />
                                <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-11" onClick={() => remove(urlIndex)}>
                                  <span className="material-symbols-outlined">do_not_disturb_on</span>
                                </button>
                              </div>
                            ))}
                            <button type="button" onClick={() => push("")}>
                              <p className="text-[#444] text-[18px] font-Inter flex pt-3">
                                <span className="material-symbols-outlined">add_circle</span>
                                Web Origin
                              </p>
                            </button>
                          </div>
                        )}
                      </FieldArray>
                      <div className=" w-full">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium flex items-center">Front-channel logout URL*</p>
                        <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.frontChannelLogoutUrl`} />
                        <ErrorMessage name={`openId.${index}.frontChannelLogoutUrl`} component="div" className="error-message custom-error" />
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </FieldArray>
        </>
      ),
    },
  ];
  return (
    <>
      <div className={` w-full pt-5`}>
        <Collapse activeKey={activeKey} onChange={handleCollapseChange} accordion items={item} className="w-[80%]  mt-6 custom-header" expandIconPosition={"end"} />
      </div>
    </>
  );
};

export default OpenIdLogInSetting;
