import React, { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { storeData } from "../../services/storage/Storage";
import { useDispatch, useSelector } from "react-redux";
import { setFromUserName, setVerifyPayload } from "../../redux/slice/Users/MobileNumberSlice";
import { fromWhere } from "../../redux/slice/Users/UserDetailSlice";
import UserLoginTheme from "../../pages/user-authentication/LoginTheme/LoginTheme";

function MultiFactorAuthForgotUserName() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState("");
  const verifyPayload = useSelector((state: any) => state?.forgotUsernameMobileNumber?.verifyPayload);
  const handleCheckboxChange = (el: string) => {
    setIsChecked(el);
    dispatch(
      setVerifyPayload({
        ...verifyPayload,
        isActive: el,
      }),
    );
  };

  const redirect = () => {
    storeData("authForgotPassword", isChecked, true);
    dispatch(fromWhere("forgot-username"));
    storeData("fromSetPassword", true, true);
    dispatch(setFromUserName(true));
    if (isChecked === "phone") {
      navigate("/sent-otp-mobile");
    } else {
      navigate("/sent-otp-email");
    }
  };

  return (
    <div className="w-full multiFactorAuth">
      <div className="multiFactorAuth-title">
        <div className="w-full flex justify-center pb-2">{/* <img src={authIcon} alt="authIcon" /> */}</div>
        <h2 className="resetHeader">Username Recovery</h2>
        <p className="text-center mb-8 forgetUsername">To recover your username, verify yourself using your preferred method below</p>
      </div>
      <div className="multiFactorAuth-form">
        <div className="formGroup multiFactorAuth">
          <div className={isChecked === "email" ? "multiFactorAuth-email formGroup-item active" : "multiFactorAuth-email formGroup-item"} onClick={() => handleCheckboxChange("email")}>
            <span className="material-icons check_circle">check_circle</span>
            <div className="item flex">
              <div className="item-left mr-3">
                <span className="material-icons-outlined primary-color">mail</span>
              </div>
              <div className="item-right">
                <h6>Personal Email</h6>
                <p>Receive verification codes via email</p>
              </div>
            </div>
          </div>
          <div className={isChecked === "phone" ? "multiFactorAuth-email formGroup-item active" : "multiFactorAuth-email formGroup-item"} onClick={() => handleCheckboxChange("phone")}>
            <span className="material-icons check_circle">check_circle</span>
            <div className="item flex">
              <div className="item-left mr-3">
                <span className="material-icons-outlined primary-color">phone_android</span>
              </div>
              <div className="item-right">
                <h6>Mobile Number</h6>
                <p>Receive verification codes via mobile number</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn">
        <Button type="primary" className="bg-akku login-btn" disabled={isChecked === ""} onClick={redirect}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default UserLoginTheme(MultiFactorAuthForgotUserName);
