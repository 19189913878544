import React, { useState } from "react";
import "./ConnectedDirectory.scss";
import ImageView from "../../../../../../layouts/component/ImageView";
import { images } from "../../../../../../assets/images/ImagePaths";
import { useNavigate } from "react-router-dom";
import { retrieveData, storeData } from "../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionStyle, hasPermission } from "../../../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../../../const/RolePermissions";
import PermissionsModal from "../../../../../../layouts/permissionsModal/PermissionsModal";
import IpTimeSuccessModal from "../../../../../access-manager/create/IpTimeSuccessModal";
import { setKerberosInitialValue } from "../../../../../../redux/slice/GroupSlice";

const ConnectedDirectory = (props: any) => {
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasUpdatePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "update");

  const { connectionList } = props;
  const syncMapperAttributes = useGetApiRequests("syncMapperAttributes", "POST");
  const groupSyncApi = useGetApiRequests("groupSync", "POST");
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState<any>();
  const navigate = useNavigate();
  const getComponentId = retrieveData("getComponentDetails", true);
  const realmName: string = retrieveData("realmName", true);
  const [kerberosId, setKerberosId] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleEditLdapConnection = () => {
    console.log(kerberosId);
    if (kerberosId) {
      navigate("/edit-connected-directory");
      storeData("componentId", {}, true);
    } else {
      navigate("/edit-kerberos-directory");
    }
  };

  const [checkedItemId, setCheckedItemId] = useState<any>(null);

  const handleCheckboxChange = (elm: any) => {
    const selectedVal = connectionList.filter((item: any) => {
      return elm.id === item.id;
    });
    dispatch(setKerberosInitialValue(selectedVal));
    setKerberosId(elm.providerId !== "kerberos");
    if (hasUpdatePermission) {
      setCheckedItemId(elm.id === checkedItemId ? null : elm.id);
      storeData("getComponentDetails", elm.id, true);
    } else {
      setPermissionsModal(true);
    }
  };
  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleSyncLdapConnection = async (syncType: string) => {
    if (hasUpdatePermission) {
      const payload = {
        componentId: getComponentId,
        realmName: realmName,
        action: syncType,
      };

      try {
        const response: any = await syncMapperAttributes(payload);
        const status = response.status;
        if (status === 200) {
          triggerNotification("success", response?.data?.message, "", "topRight");
          setTimeout(() => {
            navigate("/user");
          }, 1000);
        }
      } catch (err: any) {
        handleRequestError(err);
      }
    } else {
      setPermissionsModal(true);
    }
  };
  const handleGroupSync = async () => {
    try {
      const payload = {
        componentId: getComponentId,
        realmId: retrieveData("realmId", true),
      };
      const response: any = await groupSyncApi(payload);
      const status = response.status;
      if (status === 200) {
        setSuccessModal(true);
        setSuccessMessage(response?.data?.message);
        setCheckedItemId(null);
        setTimeout(() => {
          handleCloseSuccessModal();
          setSuccessMessage("");
        }, 3000);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };
  return (
    <div className="connected-container ">
      <div className="w-full flex">
        <h5 className="font-Inter w-1/2">Connected Directory</h5>
        {checkedItemId && (
          <div className="w-1/2 flex justify-end ldap-action">
            {kerberosId && (
              <>
                <p className="flex items-center p">
                  Sync Group
                  <span
                    className="w-7 block mx-3 cursor-pointer"
                    onClick={() => {
                      handleGroupSync();
                    }}
                  >
                    <ImageView src={images.groupSync} />
                  </span>
                </p>
                <p className="flex items-center p">
                  Sync change users
                  <span
                    className="w-7 block mx-3 cursor-pointer"
                    onClick={() => {
                      handleSyncLdapConnection("triggerChangedUsersSync");
                    }}
                  >
                    <ImageView src={images.syncUser} />
                  </span>
                </p>
                <p className="flex items-center">
                  Sync All
                  <span
                    className="w-7 block mx-3 cursor-pointer"
                    onClick={() => {
                      handleSyncLdapConnection("triggerFullSync");
                    }}
                  >
                    <ImageView src={images.syncAll} />
                  </span>
                </p>
              </>
            )}
            <p className="flex items-center justify-center">
              <span
                className="w-7 block "
                onClick={() => {
                  handleEditLdapConnection();
                }}
              >
                <span className="material-symbols-outlined pencil">edit</span>
              </span>
            </p>
          </div>
        )}
      </div>
      <div className="directory pt-2 flex-wrap">
        {connectionList?.map((elm: any) => (
          <div className={`directory-card mt-5 mr-5 cursor-pointer ${getPermissionStyle(hasUpdatePermission)}`} key={elm?.id}>
            <div className={`flex cards relative ${elm?.id === checkedItemId ? "bordered" : ""}`}>
              <input type="checkbox" onChange={() => handleCheckboxChange(elm)} checked={elm?.id === checkedItemId} className="block w-full h-full absolute opacity-0 cursor-pointer top-0 left-0" />
              <div className="image h-12 w-12 mr-4">
                <ImageView src={elm?.config?.enabled?.[0] === "true" ? images.connectedDirectory : images.disableLdap} />
              </div>
              <p className="font-semibold text-[20px] font-Inter">{elm?.name}</p>
            </div>
          </div>
        ))}
      </div>
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
      {successModal && <IpTimeSuccessModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={successMessage} />}
    </div>
  );
};

export default ConnectedDirectory;
