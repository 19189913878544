import React from 'react'

export default function CreateAccessManagerModalHeader() {
    return (

        <div className='create-header flex items-center flex-wrap'>
            <div className='accessManagerModalContainer'>
                <h3 className='w-full'>How would you like to control access to your applications?</h3>
                <p>Select how you would like to restrict access, then assign users.</p>
            </div>
        </div>

    )
}
