import React from "react";
import { Modal } from "antd";
import ImageView from "../../component/ImageView";
import warningImg from "./../../../assets/images/user/warning.svg"
import CustomButtonBack from "../../component/CustomButtonBack";
import CustomButtonNext from "../../component/CustomButton";

export default function DeviceTokenPhoneCheck(props: any) {
    const { handleCancel, handleProceed, open } = props;
    return (
        <div className="closeConfirmationModal">
            <Modal className="p-10 rounded-lg  _suspended show-notification" closable={false} centered open={open} footer={false} onCancel={handleCancel} maskClosable={false}>
                <div className="pt-4 modal-body">
                    <div className="flex-col flex justify-center items-center w-full">
                        <ImageView src={warningImg} alt="warningIcon" className="h-20 w-20" />
                        <p className="pt-4 text-[18px] text-center font-Inter">Are you sure you want to update phone number since user has already register device to this number</p>
                    </div>
                </div>
                <div className="pt-8 modal-footer">
                    <div className="flex justify-center w-full">
                        <CustomButtonBack onClick={handleCancel} text={"Cancel"} />
                        <CustomButtonNext onClick={handleProceed} text={"Proceed"} />
                    </div>
                </div>
            </Modal>
        </div>
    );
}
