import React, { useState } from "react";
import { Switch } from "antd";
import UpdgradeConfirmationModal from "./UpdgradeConfirmationModal";
const PlansHeader = ({ onClick, text, className, yearlySubscription, setYearlySubscription, active }: any) => {
  const [isOpenPopup, setOpenPopUp] = useState<boolean>(false);
  const onChange = (checked: boolean) => {
    console.log(active);
    if (active) {
      setOpenPopUp(true);
    }

    setYearlySubscription(checked);
  };
  const handleClose = () => {
    setOpenPopUp(false);
  };
  const handleUpdate = () => {
    console.log("update");
  };
  return (
    <div className="  w-full flex justify-between">
      <div className="w-1/2">
        <p className={`${"title flex items-center "} `} onClick={onClick}>
          <span className={`${"material-symbols-outlined text-3xl mr-5 "} ${className} `}>arrow_back</span>
          Plan
        </p>
      </div>
      <div className="w-1/2 flex justify-end items-center">
        <p className={`${yearlySubscription ? "  text-[#ABABAB]" : "text-[#111]"} pr-3  lg:text-[22px] text-[16px] font-Inter font-medium`}>Monthly</p>
        <Switch data-testid="subscription-switch" checked={yearlySubscription} onChange={onChange} />
        <p className={`${yearlySubscription ? "  text-[#111]" : "text-[#ABABAB]"} pl-3  lg:text-[22px] text-[16px] font-Inter font-medium`}>Annual</p>
      </div>
      <UpdgradeConfirmationModal handleClose={handleClose} isOpenPopup={isOpenPopup} handleUpdate={handleUpdate} />
    </div>
  );
};

export default PlansHeader;
