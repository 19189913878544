import { Button, Tooltip } from "antd";
import React from "react";
import { getPermissionStyle } from "../../redux/slice/permissions/permissionUtils";

const SyncButton = (props: any) => {
  const { loadings, onClick, title, icon, disabled, record } = props;
  return (
    <Tooltip title={title}>
      <Button
        data-testid="sync-button"
        type="primary"
        className={` ${getPermissionStyle(disabled)} ${icon === "devices" ? "bg-white pointer-events-none" : "bg-[#5441DA]"}  ml-2   sync-btn `}
        icon={<span className={`${icon === "devices" ? "text-[#000]" : ""} selection material-icons text-[14px]`}>{icon}</span>}
        loading={loadings}
        onClick={onClick}
        disabled={record?.status !== "active"}
      />
    </Tooltip>
  );
};

export default SyncButton;
