import React from 'react';

const Breadcrumbs = ({ currentSection,onClick }:any) => {
  return (
    <div className="breadcrumb">
      <ul className="flex items-center">
        <li className='text-lg font-normal text-[#1D1D24] font-Inter cursor-pointer' onClick={onClick}>
          App Store {"> "}
        </li>
        <li className="pl-2 text-lg font-semibold text-[#1D1D24] font-Inter">{currentSection}</li>
      </ul>
    </div>
  );
};

export default Breadcrumbs;
