import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  appDetails: {},
  currentStep:{}
}

const initialState: AuthState = {
    appDetails: {},
  currentStep:{
    "activeStep": 0,
    "childStepper": ""
}

};

const courseTabSlice: any = createSlice({
  name: "navigateCourseTab",
  initialState,
  reducers: {
    setAppConfigDetails: (state, action: PayloadAction<boolean>) => {
      state.appDetails = action.payload;
    },
       setCurrentStep: (state, action: PayloadAction<boolean>) => {
      state.currentStep = action.payload;
    },
    clearAppDetails: (state) => {
      state.appDetails = false;
    },
     clearCurrentStep: (state) => {
      state.currentStep = {};
    },
  },
});

export const { setAppConfigDetails, clearAppDetails,setCurrentStep ,clearCurrentStep} = courseTabSlice.actions;
export default courseTabSlice.reducer;
