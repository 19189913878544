import React from "react";
import { ErrorMessage, Field, Form } from "formik";
import { Button, Input } from "antd";
const KerberosConnectionForm = (props: any) => {
  const { dirty, active, handleBack } = props;
  const handleBackToUser = () => {
    handleBack();
  };
  return (
    <>
      <Form>
        <div className="w-full kerberos-edit">
          <div className="mb-10 w-[92%] mx-auto ldap-container pb-10">
            <div className="w-[95%] mx-auto flex pt-[40px] flex-col edit-basic">
              <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
                <div className="mb-10 w-[45%] input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">UI display name*</p>
                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="kerberosName" data-testid="kerberosName" />
                  <ErrorMessage name="kerberosName" component="div" className="error-message" />
                </div>
                <div className="mb-10 w-[45%] input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Kerberos realm*</p>
                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="kerberosRealmName" />
                  <ErrorMessage name="kerberosRealmName" component="div" className="error-message" />
                </div>
                <div className="mb-10 w-[45%] input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Server principal*</p>
                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="serverPrincipalName" />
                  <ErrorMessage name="serverPrincipalName" component="div" className="error-message" />
                </div>
                <div className="mb-10 w-[45%] input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Key tab*</p>
                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="keytabPath" />
                  <ErrorMessage name="keytabPath" component="div" className="error-message" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full footer flex items-center absolute bottom-0 right-0  bg-[#fff] h-[90px]">
            <div className="modal-footer w-full mx-auto ">
              <div className="w-full flex justify-end pr-5">
                <Button onClick={handleBackToUser} className=" font-semibold text-[20px] font-Inter w-[186px] h-[56px] text-[#5441DA] mr-10 ">
                  Back
                </Button>
                {active ? (
                  <Button disabled={!dirty} type="primary" htmlType="submit" className=" font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                    {active ? "Update" : "Save"}
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit" className=" font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default KerberosConnectionForm;
