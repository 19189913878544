import React, { useEffect, useState } from "react";
import { Collapse, CollapseProps, Input, Tooltip } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import { staticMessage } from "../../../../constant/StaticContent";
import "./AppConfigure.scss";

const CapabilityConfigurationForm = (props: any) => {
  const { values, isError } = props;
  const [activeKey, setActiveKey] = useState<string>("");

  useEffect(() => {
    if (isError) {
      setActiveKey("1");
    }
  }, [isError]);
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const item: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[18px] font-normal">2 . Login Settings</p>,
      children: (
        <FieldArray name="saml">
          {({ insert, remove, push }) => (
            <div>
              {values.saml.length > 0 &&
                values.saml.map((samlConfig: any, index: any) => (
                  <div className="flex w-full justify-between flex-col" key={samlConfig?.clientId}>
                    <div className="w-full flex justify-between">
                      <div className="mb-2 w-[45%]">
                        <p className="text-[#1D1D24] text-[18px] flex items-center ml-2 font-Inter font-medium">
                          Root URL
                          <Tooltip title={staticMessage.app.root}>
                            <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                          </Tooltip>
                        </p>
                        <Field as={Input} type="text" name={`saml.${index}.rootUrl`} className="h-[56px] font-medium text-[18px]" />
                        <ErrorMessage name={`saml.${index}.rootUrl`} component="div" className="error-message custom-error" />
                      </div>
                      <div className="mb-2 w-[45%]">
                        <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium">
                          Home URL*
                          <Tooltip title={staticMessage.app.home}>
                            <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                          </Tooltip>
                        </p>
                        <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.baseUrl`} />
                        <ErrorMessage name={`saml.${index}.baseUrl`} component="div" className="error-message custom-error" />
                      </div>
                    </div>
                    <div>
                      <FieldArray name={`saml.${index}.validRedirectUrl`}>
                        {({ push, remove }) => (
                          <div>
                            {samlConfig.validRedirectUrl.map((url: any, urlIndex: any) => (
                              <div key={samlConfig?.clientId} className="relative mt-8">
                                <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium">
                                  Valid redirect URL*
                                  <Tooltip title={staticMessage.app.redirect}>
                                    <span className="material-symbols-outlined  cursor-pointer ml-2 rotate-180">error</span>
                                  </Tooltip>
                                </p>
                                <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.validRedirectUrl.${urlIndex}`} />
                                <ErrorMessage name={`saml.${index}.validRedirectUrl.${urlIndex}`} component="div" className="error-message custom-error" />

                                <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-11" onClick={() => remove(urlIndex)}>
                                  <span className="material-symbols-outlined">do_not_disturb_on</span>
                                </button>
                              </div>
                            ))}
                            <button type="button" onClick={() => push("")}>
                              <p className="text-[#444] text-[18px] font-Inter font-medium flex pt-3">
                                <span className="material-symbols-outlined">add_circle</span>
                                Add Valid Redirect URLs
                              </p>
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                    <FieldArray name={`saml.${index}.validRedirectLogoutUrl`}>
                      {({ push, remove }) => (
                        <div className="mb-8">
                          {samlConfig.validRedirectLogoutUrl.map((url: any, urlIndex: any) => (
                            <div key={samlConfig?.clientId} className="relative mt-8">
                              <p className="text-[#1D1D24] flex items-center text-[18px] font-Inter font-medium">
                                Valid redirect Logout URL{" "}
                                <Tooltip title={staticMessage.app.redirectLogout}>
                                  <span className="material-symbols-outlined  cursor-pointer rotate- ml-2">error</span>
                                </Tooltip>
                              </p>
                              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.validRedirectLogoutUrl.${urlIndex}`} />
                              <ErrorMessage name={`saml.${index}.validRedirectLogoutUrl.${urlIndex}`} component="div" className="error-message custom-error" />

                              <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-11" onClick={() => remove(urlIndex)}>
                                <span className="material-symbols-outlined">do_not_disturb_on</span>
                              </button>
                            </div>
                          ))}
                          <button type="button" onClick={() => push("")}>
                            <p className="text-[#444] text-[18px] font-Inter flex pt-3">
                              <span className="material-symbols-outlined">add_circle</span>
                              Add Valid Redirect URLs
                            </p>
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <div className="mb-10 w-[100%] hidden">
                      <p className="text-[#1D1D24] flex items-center text-[18px] font-Inter font-medium">
                        IDP-Initiated SSO URL Name*
                        <Tooltip title={staticMessage.app.idpurl}>
                          <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                        </Tooltip>
                      </p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.idpInitiatedSsoUrlName`} />
                      <ErrorMessage name={`saml.${index}.idpInitiatedSsoUrlName`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="mb-10 w-[100%] hidden">
                      <p className="text-[#1D1D24] flex items-center text-[18px] font-Inter font-medium">
                        IDP-Initiated SSO Relay State*
                        <Tooltip title={staticMessage.app.idpsso}>
                          <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                        </Tooltip>
                      </p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.idpInitiatedSsoRelayState`} />
                      <ErrorMessage name={`saml.${index}.idpInitiatedSsoRelayState`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="mb-5 w-[100%]">
                      <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium">
                        Master SAML Processing URL
                        <Tooltip title={staticMessage.app.mastersaml}>
                          <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                        </Tooltip>
                      </p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.masterSamlProcessingUrl`} />
                      <ErrorMessage name={`saml.${index}.masterSamlProcessingUrl`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </FieldArray>
      ),
    },
  ];
  return (
    <>
      <div className={` w-full pt-6`}>
        <Collapse activeKey={activeKey} onChange={handleCollapseChange} accordion items={item} className="w-[80%]  custom-header" expandIconPosition={"end"} />
      </div>
    </>
  );
};

export default CapabilityConfigurationForm;
