import React, { useState } from "react";
import "./ConnectedApps.scss";
import CheckboxImage from "../components/CheckboxImage";
import { images } from "../../../../../../assets/images/ImagePaths";
const ConnectedApps = () => {
  const [checkedVal,setCheckedVal]=useState()
  console.log(checkedVal);
  
  
  return (
    <>
      <div className="connected-apps">
        <p className="text-[#171717] text-[24px] font-semibold font-Inter pb-5">Apps Connected</p>
        <div className="flex  w-full flex-wrap">
          <CheckboxImage  images={images.salesforce} setCheckedVal={setCheckedVal} />
          <CheckboxImage  images={images.salesforce} setCheckedVal={setCheckedVal} />
        
        </div>
      </div>
    </>
  );
};

export default ConnectedApps;
