import { Button, Table, Tooltip, Switch, TableColumnsType } from "antd";
import { useAccessManager } from "../context/AccessManagerContext";
import CreateAccessMangerModal from "../create/CreateAccessMangerModal";
import { useEffect, useState } from "react";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { retrieveData, storeData } from "../../../services/storage/Storage";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../../layouts/component/Loader";
import ip from "../../../assets/images/icons/ip.png";
import timer from "../../../assets/images/icons/timer.png";
import devices from "../../../assets/images/icons/devices.png";
import { useDispatch, useSelector } from "react-redux";
import { setInformationFromIpGet, setIpEditRecord, setParticularIpInfo } from "../../../redux/slice/IPSlice";
import { useNavigate } from "react-router-dom";
import { getPermissionStyle, hasPermission } from "../../../redux/slice/permissions/permissionUtils";
import PermissionsModal from "../../../layouts/permissionsModal/PermissionsModal";
import { getUserGroupCount } from "./AccessManagerGetGroupCount";
import DeleteRestrictionModal from "../create/Model/DeleteRestrictionModal";
import { modulePermissions } from "../../../const/RolePermissions";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import { ErrorMessages } from "../../../const/Messages";
import DeviceRestrictionWarningModal from "../../dashboard/user/user-dashboard/adduser/device-authorization/DeviceRestrictionWarningModal";

export default function AccessManagerContainer() {
  const { openCreateAccessManager, warningModal, closeWarningModal } = useAccessManager();
  const [loader, setLoader] = useState(false);
  const [accessManagerList, setAccessManagerList] = useState<any>([]);
  const [permissionModal, setPermissionModal] = useState(false);
  const [editRecords, setEditRecords] = useState<any>({});
  const [deleteRestrictionModalOpen, setDeleteRestrictionModalOpen] = useState(false);
  const [toReload, setToReload] = useState(false);
  const getAccessManager = useGetApiRequests("getAccessManager", "GET");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getIPRestrictionDetails = useGetApiRequests("getParticularIpRestriction", "POST");
  const getUserListCount = useGetApiRequests("userManagement", "POST");
  const getGroupsListCount = useGetApiRequests("getGroupsList", "GET");
  const updateIpRestriction = useGetApiRequests("updateIpRestriction", "PUT");
  const updateTimeRestrictionApi = useGetApiRequests("updateTimeBasedRestriction", "PUT");

  const getParticularTimeRestriction = useGetApiRequests("getAccessManagerDetails", "GET");
  const realmId = retrieveData("realmId", true);
  const accessManagerPermissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const accessManagerCreate = accessManagerPermissions["Access Manager"]?.create;
  const accessManagerEdit = accessManagerPermissions["Access Manager"]?.update;
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);

  const hasDeletePermission = permissions && hasPermission(permissions, modulePermissions.accessManger, "delete");

  const handleNotification = (record: any, checked: boolean) => {
    let message = "";

    if (record === "IP") {
      message = checked ? ErrorMessages?.IPRestrictionEnabled : ErrorMessages?.IPRestrictionDisabled;
    } else {
      message = checked ? ErrorMessages?.TimeRestrictionEnabled : ErrorMessages?.TimeRestrictionDisabled;
    }

    triggerNotification("success", "", message, "topRight");
  };

  const onChange = async (checked: boolean, record: any) => {
    const transformedData = record?.ipInfo?.map((item: any) => ({
      ipRange: item.ipRange,
      newIpInfo: item.ipInfo,
      oldIpInfo: item.ipInfo,
      ipInfoV6: item.ipInfoV6,
    }));
    if (accessManagerEdit) {
      //IP restriction
      if (record?.classification === "a") {
        const payload: any = {
          akkuUserIpBasedAuthorizationDto: {
            name: record.name,
            description: record.description,
            authorizationType: record.authorizationType,
            ipInfos: transformedData,
            active: checked,
            realmId: realmId,
          },
          ipRestrictionDashboardDto: {
            realmId: realmId,
            authorizationType: record.authorizationType,
            status: checked,
            userRestricted: record.userRestricted,
            groupRestricted: record.groupRestricted,
            organisationRestriction: record.organisationRestriction,
          },
          addIpRestriction: [],
          addIpAddress: [],
          removeIpAddress: [],
          removeIpRestriction: [],
          updateIpInfos: transformedData,
        };
        if (record.userEntityIdOrGroupId[0]) {
          payload.akkuUserIpBasedAuthorizationDto[!record.groupRestricted ? "userEntityId" : "keycloakGroupId"] = record.userEntityIdOrGroupId;
        } else {
          payload.akkuUserIpBasedAuthorizationDto["keycloakGroupId"] = [];
        }
        payload.ipRestrictionDashboardDto.akkuUserIpBasedAuthorizationIds = record?.akkuUserIpBasedAuthorizationIds;
        try {
          const response: any = await updateIpRestriction(payload);
          const status = response.status;
          if (status === 200) {
            setLoader(false);
            handleNotification("IP", checked);
            getAccessManagerList();
          }
        } catch (err: any) {
          setLoader(false);
          handleRequestError(err);
        }
      } else if (record?.classification === "c") {
        //Time Restriction
        getTimeDetails(record, true, checked);
      }
    } else {
      setPermissionModal(true);
    }
  };
  const customSortByIp = function (a: any, b: any) {
    const classificationOrder: any = { a: 1, b: 2, c: 3 };
    return classificationOrder[a?.classification] - classificationOrder[b?.classification];
  };
  const getUsersCount = async () => {
    try {
      const payload = {
        realmId: realmId,
        sorting: "name_asc",
        statusFilter: "active,inactive",
        searchFilter: "",
        startValue: 0,
        limitValue: 10,
      };
      const response: any = await getUserListCount(payload);
      const status = response.status;

      if (status === 200) {
        storeData("totalUserRecords", response?.data?.meta?.totalRecords, true);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const getAccessManagerList = async () => {
    const pathParams: any = {
      realmId: realmId,
    };
    setLoader(true);
    try {
      const response: any = await getAccessManager("", {}, pathParams);
      const status = response.status;
      if (status === 200) {
        const mappingData = response?.data?.data;
        const reformedArray = [];
        getUsersCount();
        getUserGroupCount(getGroupsListCount);
        mappingData?.userTimeBasedAuthorizationKvDtoList?.forEach((item: any) => {
          reformedArray?.push({
            name: item?.name,
            description: item?.description,
            type: timer,
            status: item?.status,
            assigned: item?.assignedTo,
            classification: "c", //time
            akkuUserTimeBasedAuthorizationId: item?.akkuUserTimeBasedAuthorizationId,
            akkuUserTimeBasedAuthorizationIdList: item?.akkuUserTimeBasedAuthorizationIdList,
          });
        });

        if (mappingData?.userDeviceRestrictionDetailsKvDto) {
          storeData("deviceRestrictionUsersCount", mappingData?.userDeviceRestrictionDetailsKvDto?.assignedUserCount, true);
          reformedArray?.push({
            name: mappingData.userDeviceRestrictionDetailsKvDto?.name,
            description: mappingData?.description,
            type: devices,
            status: mappingData?.userDeviceRestrictionDetailsKvDto?.status,
            assigned: mappingData?.userDeviceRestrictionDetailsKvDto?.assignedTo,
            classification: "b", //device
          });
        } else {
          storeData("deviceRestrictionUsersCount", null, true);
        }

        mappingData?.ipRestrictionDashboardResponses?.forEach((element: any) => {
          let assigned: string;

          if (element?.userRestricted) {
            assigned = `User(${element?.count})`;
          } else if (element?.groupRestricted) {
            assigned = `Group(${element?.count})`;
          } else if (element?.organisationRestriction) {
            assigned = "All";
          } else {
            assigned = "-";
          }

          reformedArray?.push({
            ...element,
            classification: "a", // ip
            type: ip,
            assigned: assigned,
          });
        });
        reformedArray?.sort(customSortByIp);

        setAccessManagerList(reformedArray);
      }
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  useEffect(() => {
    getAccessManagerList();
  }, [toReload]);
  const handleDeleteRestrictionPopup = (record: any) => {
    if (hasDeletePermission) {
      setDeleteRestrictionModalOpen(true);
      setEditRecords(record);
    } else {
      setPermissionModal(true);
    }
  };
  const handleTypes = (e: string) => {
    if (e === "a") {
      return "IP Restriction";
    } else if (e === "b") {
      return "Device Restriction";
    } else if (e === "c") {
      return "Time Restriction";
    }
  };
  const handleClickRestrictionName = (record: any) => {
    if (accessManagerEdit) {
      if (record?.classification === "a") {
        getIpDetails(record);
      } else if (record?.classification === "c") {
        getTimeDetails(record, false, false);
      } else if (record?.classification === "b") {
        storeData("key", "5", false);
        navigate("/access-manager/device-based-restriction");
      }
    } else {
      setPermissionModal(true);
    }
  };

  const columns: TableColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => {
        return (
          <div onClick={() => handleClickRestrictionName(record)}>
            <div className="accessMangerTable-bg">
              <div className="accessMangerTable-name">
                <h2>{record.name}</h2>
                <p>{record.description}</p>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: any, record: any) => (
        <div className="accessMangerTable-bg">
          <i className="accessMangerTable-icon">
            <Tooltip title={handleTypes(record.classification)} placement="top">
              <img src={type} alt={type} />
            </Tooltip>
          </i>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: boolean, record: any) => (
        <div className="accessMangerTable-bg">
          <span className="accessMangerTable-switch">
            <Switch
              disabled={record?.classification === "b"}
              checked={status}
              onChange={(status) => {
                onChange(status, record);
              }}
            />
          </span>
        </div>
      ),
    },
    {
      title: "Assigned",
      dataIndex: "assigned",
      key: "assigned",
      render: (assigned: string) => (
        <div className="accessMangerTable-bg">
          <span className="accessMangerTable-assigned">{assigned !== "" ? <span className="active">{assigned}</span> : "-"}</span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: any) => (
        <Button disabled={record?.classification === "b"} className="border-0 btn-disabled">
          <span
            className="material-icons-outlined"
            onClick={() => {
              handleDeleteRestrictionPopup(record);
            }}
          >
            delete
          </span>
        </Button>
      ),
    },
  ];

  const splitIp = (ipInfo: string, type?: string) => {
    if (!ipInfo) {
      return null;
    }

    let ipPart;
    if (type === "24") {
      [ipPart] = ipInfo.split(".0/24");
    } else {
      [ipPart] = ipInfo.split(".0.0/16");
    }

    const completeIp = ipInfo.split("/");
    const rangePart = completeIp[1];

    return { ipPart, rangePart };
  };
  const getIpDetails = async (record: any) => {
    dispatch(setInformationFromIpGet(record));
    const payload: any = {
      realmId: realmId,
      name: record?.name,
    };
    try {
      const response: any = await getIPRestrictionDetails(payload);

      const status = response.status;
      dispatch(setParticularIpInfo(response?.data?.data));
      let ipRange;
      const rangePart = splitIp(response?.data?.data?.[0]?.ipInfo)?.rangePart;
      if (rangePart === "16") {
        ipRange = "range16";
      } else if (rangePart === "24") {
        ipRange = "range24";
      } else {
        ipRange = "exactIp";
      }
      if (status === 200) {
        const data = response?.data?.data;
        let assignTo: string;
        if (data.userGroupRestrictions[0]?.keycloakGroupId !== null && data.userGroupRestrictions[0]?.keycloakGroupId !== "") {
          assignTo = "groups";
        } else if (data.userGroupRestrictions[0]?.userEntityId !== null && data.userGroupRestrictions[0]?.userEntityId !== "") {
          assignTo = "users";
        } else {
          assignTo = "organization";
        }

        const convertData: any = {
          name: data?.name || "",
          description: data?.description || "",
          authorizationType: data?.authorizationType || "Whitelist",
          ipDetails: data.ipInfos.map((item: any, index: any) => {
            let ip = item.ipInfo;
            let ipRange = "exactIp";
            if (item.ipRange) {
              if (ip.endsWith(".0.0/16")) {
                ipRange = "range16";
                ip = ip.replace(".0.0/16", "");
              } else if (ip.endsWith(".0/24")) {
                ipRange = "range24";
                ip = ip.replace(".0/24", "");
              }
            }
            return {
              ipRange: ipRange,
              IPv4: ip,
              id: index,
              IPv6: item.ipInfoV6 || "",
            };
          }),
          assignTo: assignTo, // Set this value as needed
          active: data.isActive,
        };

        convertData.userGroupRestrictions = data?.userGroupRestrictions;
        if (convertData?.assignTo === "groups") {
          const ipGroupIds: any = response?.data?.data?.userGroupRestrictions
            ?.filter(({ keycloakGroupId }: any) => keycloakGroupId !== null && keycloakGroupId !== undefined)
            .map(({ keycloakGroupId }: any) => keycloakGroupId);
          convertData.groupIds = ipGroupIds;
          dispatch(setIpEditRecord(convertData));
          navigate("/access-manager/select-ip?isEdit=true&type=groups");
        } else if (convertData?.assignTo === "users") {
          const ipUserIds: any = response?.data?.data?.userGroupRestrictions
            .filter(({ userEntityId }: any) => userEntityId !== null && userEntityId !== undefined)
            .map(({ userEntityId }: any) => userEntityId);
          convertData.userId = ipUserIds;
          dispatch(setIpEditRecord(convertData));
          navigate("/access-manager/select-ip?isEdit=true&type=users");
        } else if (convertData?.assignTo === "organization") {
          convertData.akkuUserIpBasedAuthorizationId = response?.data?.data?.userGroupRestrictions?.akkuUserIpBasedAuthorizationId;
          dispatch(setIpEditRecord(convertData));
          navigate("/access-manager/select-ip?isEdit=true&type=organization");
        }
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const getTimeDetails = async (record: any, isNavigate: any, isActive: any) => {
    let navigationType: string = "";
    const queryParams: any = {
      akkuUserTimeBasedAuthorizationId: record?.akkuUserTimeBasedAuthorizationId,
    };
    try {
      const response: any = await getParticularTimeRestriction("", queryParams);
      if (response.status === 200) {
        const timeBasedRestrictionApiDetails = response?.data?.data?.timeBasedAuthorization;
        dispatch(setIpEditRecord(timeBasedRestrictionApiDetails));
        const assignedToLower = record?.assigned?.toLowerCase();
        storeData("timeRecords", true);
        if (!isNavigate) {
          if (assignedToLower?.includes("user")) {
            navigationType = "users";
          } else if (assignedToLower?.includes("group")) {
            navigationType = "groups";
          } else if (assignedToLower?.includes("all")) {
            navigationType = "organization";
          }
          navigate(`/access-manager/time-parameters?isEdit=true&type=${navigationType}`);
        } else {
          disableTimeParameter(response?.data, record, isActive);
        }
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const disableTimeParameter = async (response: any, record: any, isActive: any) => {
    let payload = {
      userTimeBasedAuthorizationDto: {
        name: record.name,
        description: record.description,
        isActive: isActive,
        realmId: realmId,
        akkuUserTimeBasedAuthorizationId: record.akkuUserTimeBasedAuthorizationId,
      },
      userTimeBasedAuthorizationRestrictionKvDto: response?.data?.timeBasedAuthorization?.userTimeBasedAuthorizationRestrictionKvDtoList,
    };
    const pathParams: any = {
      id: record?.akkuUserTimeBasedAuthorizationId,
    };
    try {
      const response: any = await updateTimeRestrictionApi(payload, {}, pathParams);
      const status = response.status;
      if (status === 200) {
        handleNotification("Time", isActive);
        setLoader(false);
        getAccessManagerList();
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const closePermissionModal = () => {
    setPermissionModal(false);
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full">
          <div className="header-content flex justify-between items-center">
            <div>
              <h2>Access Manager</h2>
              <p>Enables you to control how your applications and data is accessed.</p>
            </div>
            {accessManagerList?.length > 0 && (
              <div className="">
                <Tooltip title={"Create Restrictions"}>
                  <Button type="primary" className={`accessManger-addBtn bg-[#5441DA] ${getPermissionStyle(accessManagerCreate)}`} onClick={openCreateAccessManager}>
                    <span className="material-symbols-outlined">add </span>
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="accessManger mt-3">
            <div className="accessManger-contents" data-testid="accessManager-table">
              {accessManagerList?.length > 0 ? (
                <Table className="cursor-pointer" columns={columns} dataSource={accessManagerList} pagination={false} />
              ) : (
                <div className="header-content no_data">
                  <div>
                    <img src="/no_data.png" alt="nodata" />
                  </div>
                  <div>
                    <p>No rules defined for IP Restriction, Time Restriction, and Device Restriction.</p>
                  </div>

                  <button type="button" className={`c-new bg-[#5441DA] ${getPermissionStyle(accessManagerCreate)}`} onClick={openCreateAccessManager}>
                    Create New
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CreateAccessMangerModal />
      {loader && <Loader />}
      {permissionModal && <PermissionsModal open={permissionModal} close={closePermissionModal} />}
      {deleteRestrictionModalOpen && (
        <DeleteRestrictionModal
          editRecord={editRecords}
          deleteRestrictionModalOpen={deleteRestrictionModalOpen}
          setDeleteRestrictionModalOpen={setDeleteRestrictionModalOpen}
          setToReload={setToReload}
          toReload={toReload}
          setLoader={setLoader}
        />
      )}
      {warningModal && <DeviceRestrictionWarningModal modalOpen={warningModal} setModalOpen={closeWarningModal} contentMessage={ErrorMessages?.deviceRestrictionWarning} />}
    </div>
  );
}
