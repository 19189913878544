import { Button, Select, Input } from "antd";
import React, { useState } from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { createAppSchema, initAppConfiguration } from "../helper/connectorHelper";
import RestApiForm from "./RestApiForm";
import DBConnectorForm from "./DBConnectorForm";
import { setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { useDispatch } from "react-redux";

const ConnectorDetails = (props: any) => {
  const { enableTest } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const connectorType = [
    { label: "REST API", value: "REST_API" },
    { label: "Database", value: "Database" },
  ];

  const [initialValues, setInitialValues] = useState(initAppConfiguration);

  const handleSubmit = async (values: any) => {
    console.log(values);
  };
  const handleBackToUser = () => {
    navigate("/user");
  };
  const handleNext = () => {
    let step = {
      activeStep: 1,
      childStepper: "",
    };
    dispatch(setCurrentStep(step));
  };

  const handleOnChange = (connectorType: string, value: any) => {
    setInitialValues((prevAttributes) => ({
      ...prevAttributes, // spread previous state
      [connectorType]: value, // update the specific attribute based on connectorType
    }));
  };

  return (
    <div className="w-full px-10 pb-20 ">
      <p className="app-header pt-8">Connector Details</p>
      <div className="w-full mx-auto">
        <Formik initialValues={initialValues} validationSchema={createAppSchema} onSubmit={handleSubmit}>
          {({ values, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="w-full pt-10  app-connector flex-wrap ">
                  <div className="mb-10 w-[45%] input-container relative">
                    <p>Connector Name*</p>
                    <Field as={Input} type="text" className="form-type-box" name="connectorName" data-testid="usersN-input" />
                    <ErrorMessage name="connectorName" component="div" className="error-message" />
                  </div>
                  <div className="mb-10 w-[45%] input-container relative">
                    <p>Description*</p>
                    <Field as={Input} type="text" className="form-type-box" name="description" data-testid="usersDn-input" />
                    <ErrorMessage name="description" component="div" className="error-message" />
                  </div>
                  <div className="mb-10 w-[45%] input-container relative">
                    <p>Connector Type*</p>
                    <Field
                      as={Select}
                      suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                      className=" w-[100%] h-[56px]"
                      name="connectorType"
                      value={initialValues.connectorType}
                      filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={(value: any) => handleOnChange("connectorType", value)}
                    >
                      {connectorType?.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Field>
                    <ErrorMessage name="connectorType" component="div" className="error-message" />
                  </div>
                  <>{initialValues.connectorType === "REST_API" ? <RestApiForm /> : <DBConnectorForm initialValues={initialValues} setFieldValue={setFieldValue} />}</>

                  <div className="footer ">
                    <div className="modal-footer w-full mx-auto ">
                      <div className="w-full flex justify-end pr-5">
                        {!enableTest ? (
                          <>
                            <Button onClick={handleBackToUser} className=" back-btn">
                              Back
                            </Button>
                            <Button onClick={handleNext} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn ">
                              Next
                            </Button>
                          </>
                        ) : (
                          <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                            Test
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ConnectorDetails;
