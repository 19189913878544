import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../layouts/component/CustomButton";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../services/storage/Storage";
import { useNavigate } from "react-router-dom";

const DeleteLDAPConnection = (props: any) => {
  const { openModal, handleClose, setLoader } = props;
  const [open, isOpen] = useState<boolean>(false);
  const deleteLDAPConnection = useGetApiRequests("deleteLDAPConnection", "DELETE");

  let getComponentId = retrieveData("getComponentDetails", true);
  const realmName = retrieveData("realmName", true);
  const navigate = useNavigate();
  useEffect(() => {
    isOpen(openModal);
  }, [openModal]);

  const handleDeleteDirectory = async () => {
    const payload = {
      realmName: realmName,
    };
    const akkuClientId = getComponentId;
    setLoader(true);
    try {
      handleClose();
      const response: any = await deleteLDAPConnection("", payload, [akkuClientId]);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data;
        setLoader(false);
        triggerNotification("success", "", data?.message, "topRight");
        navigate("/user");
      }
    } catch (err: any) {
      setLoader(false);

      handleRequestError(err);
    }
  };
  const handleDelete = () => {
    handleDeleteDirectory();
  };
  const handleCloseModal = () => {
    handleClose();
    isOpen(false);
  };

  return (
    <Modal className="  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={images.notificationDelete} alt="notificationDone" className="delete-apps" />

          <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#101828]">Delete this directory connection will prevent users to login to approved apps.</p>
          <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#101828]">Are you sure you want to continue?</p>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={"No"} />
          <CustomButtonNext onClick={handleDelete} text={"Yes"} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteLDAPConnection;
