import { Input } from "antd";
import React from "react";

const AppsHeader = (props: any) => {
  const { handleSearch, searchTerm } = props;
  return (
    <div className="appHeader">
      <div className="appHeader-row">
        <div className="app-title">
          <h2>All Apps</h2>
        </div>
        <div className="search-box relative">
          <Input type="text" placeholder="Search in apps" className="search-box-input" value={searchTerm} onChange={handleSearch} />
          <span className="material-symbols-outlined absolute left-4 top-3">search</span>
        </div>
      </div>
    </div>
  );
};

export default AppsHeader;
