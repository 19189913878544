import { Button, Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearRolesResponse } from "../../../redux/slice/roles-permission/RolesAndPermissionSlice";

const RolePermissionHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddRole = () => {
    dispatch(clearRolesResponse());
    navigate("/role-management/configure-role");
  };
  return (
    <div className="header-content flex justify-between">
      <h2>Roles and Permissions</h2>
      <Tooltip title={"Add new role"}>
        <Button type="primary" className=" add-btn" onClick={handleAddRole}>
          <span className="material-symbols-outlined">add </span>
        </Button>
      </Tooltip>
    </div>
  );
};

export default RolePermissionHeader;
