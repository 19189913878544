import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonNext from "../../../../layouts/component/CustomButton";
import { images } from "../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import ImageView from "../../../../layouts/component/ImageView";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { useDispatch, useSelector } from "react-redux";
import { setAppRender } from "../../../../redux/slice/appsRenderSlice";
import "./DeleteApp.scss";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
const DeleteApps = (props: any) => {
  const deleteAppList = useGetApiRequests("deleteAppList", "DELETE");
  const { openModal, handleClose, currentAppDetails } = props;
  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);

  const [open, isOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    isOpen(openModal);
  }, [openModal]);
  const handleCloseModal = () => {
    handleClose();
  };
  const handleDelete = async () => {
    let akkuClientId = currentAppDetails?.akkuClientId;
    try {
      const response: any = await deleteAppList("", "", [akkuClientId]);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data;
        triggerNotification("success", "", data?.message, "topRight");
        handleCloseModal();
        dispatch(setAppRender(!reInitiateApps));
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  return (
    <Modal className="p-16  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={images.notificationDelete} alt="notificationDone" className="delete-apps" />
          <h5 className=" font-Inter text-[24px] pt-3 font-semibold"> Remove {currentAppDetails?.akkuMasterClient?.name}</h5>
          <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">
            Removing {currentAppDetails?.akkuMasterClient?.name} will revoke user access permissions. However, you can reconfigure it via "Available Apps."
          </p>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={"Cancel"} />
          <CustomButtonNext onClick={handleDelete} text={"Delete"} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteApps;
