import React, { useEffect, useState } from "react";
import { Radio, Select, Table, Button, Input } from "antd";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import { useLocation, useNavigate } from "react-router-dom";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { useDispatch, useSelector } from "react-redux";
import { setIpPayload, setPayloadForTimeAndIp } from "../../../../redux/slice/IPSlice";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../services/storage/Storage";
import Loader from "../../../../layouts/component/Loader";
import { getUsersList } from "../../../dashboard/user/user-dashboard/groups/modal/ListOfUserHelperFile";
import UsersSearchDropdown from "../../../dashboard/user/user-dashboard/groups/UserSearchModal";
import { getUserGroup } from "./IpRestrictionUtils";
import { validationSchemaForIp } from "./SelectIpValidationSchema";
import { columnsIp, groupColumnsIp } from "./SelectIpTableColumns";
import IpTimeSuccessModal from "../IpTimeSuccessModal";
import CloseConfirmationModal from "../Model/CloseConfirmationModal";
import GroupAndUserNecessaryModal from "../Model/GroupAndUserNecessaryModal";
import { setListOfRestrictionModal } from "../../../../redux/slice/Users/UserDashboardUpdateSlice";
import AccessManagerBackNavigationArrow from "../AccessManagerBackNavigationArrow";
import "./selectIP.scss";

const { Option } = Select;

const SelectIP = () => {
  const isActiveEdit = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const createIpRestrictionApi = useGetApiRequests("createIpBasedRestriction", "POST");
  const getUserList = useGetApiRequests("userManagement", "POST");
  const updateIpRestriction = useGetApiRequests("updateIpRestriction", "PUT");
  const getGroupsList = useGetApiRequests("getGroupsList", "GET");

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isEditFromUrl = params.get("isEdit");
  const type = params.get("type");
  const navigatedFrom = params.get("from");
  const editIpDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const ipFullDetails = useSelector((state: any) => state?.IpRestrictionSlice?.getParticularIpInfo);
  const savedPayloadForIp = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);
  const deviceRestrictionList: any = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);

  const [loader, setLoader] = useState<boolean>(false);
  const [authorizationType, setAuthorizationType] = useState("");
  const [rangeSelected, setRangeSelected] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [usersSearchList, setUsersSearchList] = useState<any>([]);
  const [selectedObjectList, setSelectedObjectList] = useState<any>([]);
  const [listOfUsers, setListOfUsers] = useState<any>([]);
  const [updatedUserList, setUpdatedUserList] = useState<any>([]);
  const [updatedUser, setUpdatedUserLists] = useState<any>([]);

  const [deletedUsers, setDeletedUsers] = useState<any>([]);
  const [addedUsers, setAddedUsers] = useState<any>([]);
  const [groupData, setGroupData] = useState<any>([]);
  const [userSearchGroupData, setUserSearchGroupData] = useState<any>([]);
  const realmId = retrieveData("realmId", true);
  const [editGroupRecords, setEditGroupRecords] = useState<any>([]);
  const [ipErrorMessage, setIpErrorMessage] = useState<any>("");
  const [assignToValue, setAssignToValue] = useState("");
  const [ipSuccessModal, setIpSuccessModal] = useState(false);
  const [ipSuccessMessage, setIpSuccessMessage] = useState<any>();
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [atLeastOneModal, setAtLeastOneModal] = useState(false);
  const [removedIp, setRemovedIp] = useState<any>([]);

  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    description: "",
    authorizationType: "Whitelist",
    ipDetails: [{ ipRange: "", IPv4: "", id: "", IPv6: "" }],
    assignTo: navigatedFrom ? "users" : "",
  });

  const pageSize = 10;
  const currentPage = 0;

  useEffect(() => {
    if (isEditFromUrl === "true") {
      setIsEdit(true);

      setInitialValues(editIpDetails);
      setAuthorizationType(editIpDetails?.authorizationType);
      setRangeSelected(editIpDetails?.ipRange);

      if (type === "groups") {
        getUserGroup(setLoader, getGroupsList, setGroupData, setUserSearchGroupData, editIpDetails?.groupIds, true, setEditGroupRecords);
      } else if (type === "users") {
        const editUserList = editIpDetails?.userId?.map((item: any) => ({
          key: item,
        }));
        getUsersList(currentPage, pageSize, "", false, setLoader, realmId, getUserList, setUsersSearchList, true, editUserList, "", setListOfUsers, true, setEditGroupRecords);
      }
    } else if (savedPayloadForIp && Object.keys(savedPayloadForIp)?.length > 0) {
      setInitialValues(savedPayloadForIp);
      setAuthorizationType(savedPayloadForIp?.authorizationType);
      setRangeSelected(savedPayloadForIp?.ipRange);
      setAssignToValue(savedPayloadForIp?.assignTo);
    }
  }, []);

  const handleCloseSuccessModalIp = () => {
    setIpSuccessModal(false);
  };
  const handleOpenCloseModal = () => {
    setOpenCloseModal(true);
  };
  const handleCloseConfirmationModal = () => {
    setOpenCloseModal(false);
  };

  const calculateIpInIpRange = (values: any) => {
    let newIpInfo = "";
    let oldIpInfo = "";

    const transformData = values.map((elm: any) => {
      if (elm.ipRange === "range16") {
        newIpInfo = `${elm.IPv4}.0.0/16`;
        oldIpInfo = `${elm.IPv4}.0.0/16`;
      } else if (elm.ipRange === "range24") {
        newIpInfo = `${elm.IPv4}.0/24`;
        oldIpInfo = `${elm.IPv4}.0/24`;
      } else {
        newIpInfo = `${elm.IPv4}`;
        oldIpInfo = `${elm.IPv4}`;
      }
      return {
        ipRange: elm.ipRange !== "exactIp",
        newIpInfo: newIpInfo,
        oldIpInfo: oldIpInfo,
        ipInfoV6: elm.IPv6.length !== 0 ? `${elm.IPv6}` : null,
      };
    });
    return transformData;
  };
  const calculateIpInIpRange1 = (values: any) => {
    let ipInfo = "";

    const transformData = values.map((elm: any) => {
      if (elm.ipRange === "range16") {
        ipInfo = `${elm.IPv4}.0.0/16`;
      } else if (elm.ipRange === "range24") {
        ipInfo = `${elm.IPv4}.0/24`;
      } else {
        ipInfo = `${elm.IPv4}`;
      }
      return {
        ipRange: elm.ipRange !== "exactIp",
        ipInfo: ipInfo,

        ipInfoV6: elm.IPv6?.length !== 0 ? `${elm.IPv6}` : null,
      };
    });
    return transformData;
  };
  const splitIp = (initIpDetails: any) => {
    const transformData = initIpDetails.map((item: any) => {
      let ipInfo = "";
      if (item.new !== true) {
        if (item.ipRange === "range16") {
          ipInfo = `${item.IPv4}.0.0/16`;
        } else if (item.ipRange === "range24") {
          ipInfo = `${item.IPv4}.0/24`;
        } else {
          ipInfo = `${item.IPv4}`;
        }
        return {
          ipRange: item.ipRange !== "exactIp",
          ipInfo: ipInfo,
          ipInfoV6: item.IPv6.length !== 0 ? `${item.IPv6}` : null,
        };
      }
    });
    return transformData;
  };
  function calculateIpInfoAndIpRange(values: any, initIpDetails: any) {
    let transformData: any;
    if (values.length !== 0 && values?.ipDetails?.length !== 0) {
      const transformData = values.ipDetails.map((item: any) => {
        const oldIp = values.oldIp.find((elm: any) => elm.id === item.id);

        // Determine the new and old IP information
        let newIpInfo = "";
        let oldIpInfo = "";
        if (item.new != true) {
          if (item.ipRange === "range16") {
            newIpInfo = `${item.IPv4}.0.0/16`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}.0.0/16` : "";
          } else if (item.ipRange === "range24") {
            newIpInfo = `${item.IPv4}.0/24`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}.0/24` : "";
          } else {
            newIpInfo = `${item.IPv4}`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}` : "";
          }

          // Construct the transformed object
          const obj = {
            ipRange: item.ipRange !== "exactIp",
            newIpInfo: newIpInfo,
            oldIpInfo: oldIpInfo,
            ipInfoV6: item.IPv6.length !== 0 ? `${item.IPv6}` : null,
          };

          return obj;
        }
        //

        if (item.new == true) {
          if (item.ipRange === "range16") {
            newIpInfo = `${item.IPv4}.0.0/16`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}.0.0/16` : "";
          } else if (item.ipRange === "range24") {
            newIpInfo = `${item.IPv4}.0/24`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}.0/24` : "";
          } else {
            newIpInfo = `${item.IPv4}`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}` : "";
          }

          // Construct the transformed object
          const obj = {
            ipRange: item.ipRange !== "exactIp",
            newIpInfo: newIpInfo,
            oldIpInfo: oldIpInfo,
            ipInfoV6: item.IPv6.length !== 0 ? `${item.IPv6}` : null,
            new: true,
          };

          return obj;
        }
      });
      return transformData;
    } else {
      transformData = splitIp(initIpDetails);
    }

    return transformData;
  }
  const filterRangeType = (elm: any) => {
    const filteredData = elm.map((item: any) => {
      let ipInfo = "";
      if (item.ipRange === "range16") {
        ipInfo = `${item.IPv4}.0.0/16`;
      } else if (item.ipRange === "range24") {
        ipInfo = `${item.IPv4}.0/24`;
      } else {
        ipInfo = `${item.IPv4}`;
      }
      return {
        ipInfo: ipInfo,
        ipInfoV6: item.IPv6.length !== 0 ? item.IPv6 : null,
        ipRange: item.ipRange !== "exactIp",
      };
    });
    return filteredData;
  };
  // to set old and new ip while edit
  const removeCommonIPv4 = (arr1: any, arr2: any) => {
    const ipv4Set1: any = new Set(arr1.map((item: any) => item.IPv4));
    const ipv4Set2 = new Set(arr2.map((item: any) => item.IPv4));

    const commonIPv4 = new Set([...ipv4Set1].filter((ip) => ipv4Set2.has(ip)));

    const oldIp = arr1.filter((item: any) => {
      const correspondingItem = arr2.find((i: any) => i.IPv4 === item.IPv4);
      return !commonIPv4.has(item.IPv4) || (correspondingItem && item.IPv6 !== correspondingItem.IPv6);
    });

    const newIp = arr2.filter((item: any) => {
      const correspondingItem = arr1.find((i: any) => i.IPv4 === item.IPv4);
      return !commonIPv4.has(item.IPv4) || (correspondingItem && item.IPv6 !== correspondingItem.IPv6);
    });

    return {
      oldIp: oldIp,
      ipDetails: newIp,
    };
  };

  const filterCommonIPv4 = (arr1: any, arr2: any) => {
    // Create a set of IPv4 values from arr1
    const ipv4Set = new Set(arr1.map((item: any) => item.IPv4));

    // Filter arr2 to include only those objects whose IPv4 is in the ipv4Set
    const commonIpDetails = arr2.filter((item: any) => ipv4Set.has(item.IPv4));

    return commonIpDetails;
  };
  const hasIsPresent = (ipDetails: any) => {
    return ipDetails.filter((item: any) => item !== null && item !== undefined && item?.new !== true && item.oldIp !== "") || [];
  };
  const calculateIpInIpRange2 = (values: any) => {
    let newIpInfo = "";

    const transformData = values.map((elm: any) => {
      if (elm.ipRange === "range16") {
        newIpInfo = `${elm.newIpInfo}.0.0/16`;
      } else if (elm.ipRange === "range24") {
        newIpInfo = `${elm.newIpInfo}.0/24`;
      } else {
        newIpInfo = `${elm.newIpInfo}`;
      }
      return {
        ipRange: elm.ipRange,
        ipInfo: newIpInfo,
        ipInfoV6: elm.ipInfoV6 && elm.ipInfoV6.trim() !== "" ? elm.ipInfoV6 : null,
      };
    });
    return transformData;
  };
  const calculateIpInIpRange3 = (values: any) => {
    let newIpInfo = "";

    const transformData = values.map((elm: any) => {
      if (elm.ipRange === "range16") {
        newIpInfo = `${elm.IPv4}.0.0/16`;
      } else if (elm.ipRange === "range24") {
        newIpInfo = `${elm.IPv4}.0/24`;
      } else {
        newIpInfo = `${elm.IPv4}`;
      }
      return {
        ipRange: elm.ipRange !== "exactIp",
        ipInfo: newIpInfo,
        ipInfoV6: elm.IPv6?.length !== 0 ? `${elm.IPv6}` : null,
      };
    });
    return transformData;
  };

  const editIpApi = async (values: any) => {
    const uncommonIp = removeCommonIPv4(editIpDetails.ipDetails, values.ipDetails);
    const allocatedUsers = listOfUsers.map((item: any) => item.value);
    const allocatedGroups = groupData.map((item: any) => item.key);
    const newObjects = values.ipDetails.filter((item: any) => item.new === true);
    const convertedData: any = filterRangeType(newObjects);
    if ((type === "users" && listOfUsers?.length === 0) || (type === "groups" && groupData?.length === 0)) {
      setAtLeastOneModal(true);
    } else {
      setLoader(true);
      const ipDetails = calculateIpInfoAndIpRange(uncommonIp, values.ipDetails);
      const oldIpDetails = calculateIpInIpRange1(editIpDetails.ipDetails);
      const val = filterCommonIPv4(editIpDetails.ipDetails, values.ipDetails);
      const unEditedValues = calculateIpInIpRange(val);
      const filteredDataUpdated = hasIsPresent(ipDetails);
      const addAndEditVal = calculateIpInIpRange3(val);

      dispatch(setPayloadForTimeAndIp(values));
      const combinedIds = initialValues?.userGroupRestrictions.flatMap((item: any) => item?.akkuUserIpBasedAuthorizationIds);
      const filteredData = filteredDataUpdated.filter((item: any) => !item.ipInfo && item.oldIpInfo !== "");
      const addAndEdit = calculateIpInIpRange2(filteredData);
      const conCoatData = [...filteredData, ...unEditedValues];
      const removeDuplicate = [...addAndEdit, ...addAndEditVal];
      const uniqueIpData = removeDuplicate.filter((item, index, self) => index === self.findIndex((t) => t.ipInfo === item.ipInfo));
      const seen = new Set();
      const filteredIpDetails: any = [];
      conCoatData.forEach((item: any) => {
        if (seen.has(item.newIpInfo)) {
          return;
        }
        seen.add(item.newIpInfo);
        filteredIpDetails.push(item);
      });
      const result = allocatedUsers.filter((item: any) => !updatedUser.includes(item));
      const resultGroup = updatedUser.filter((item: any) => !allocatedGroups.includes(item));
      const data = resultGroup.length != 0 ? resultGroup : allocatedGroups;

      const removeIp = removedIp.filter((item: any) => item !== "");

      let payload: any = {
        akkuUserIpBasedAuthorizationDto: {
          name: values.name,
          description: values.description,
          authorizationType: values.authorizationType,
          ipInfos: uncommonIp.oldIp.length !== 0 ? uniqueIpData : oldIpDetails,
          active: ipFullDetails.isActive,
          realmId: realmId,

          [result.length !== 0 ? "userEntityId" : "keycloakGroupId"]: result.length !== 0 ? result : data,
        },
        ipRestrictionDashboardDto: {
          realmId: realmId,
          authorizationType: values.authorizationType,
          status: ipFullDetails.isActive,
          userRestricted: type === "users",
          groupRestricted: type === "groups",
          organisationRestriction: type === "organization",
          akkuUserIpBasedAuthorizationIds: combinedIds,
        },
        removeIpAddress: removeIp,
        addIpRestriction: updatedUser,
        removeIpRestriction: deletedUsers,
        addIpAddress: convertedData,
        updateIpInfos: filteredIpDetails.length !== 0 ? filteredIpDetails : oldIpDetails,
      };

      if (ipDetails[0] == undefined) {
        payload.updateIpInfos = [];
      }
      if (ipDetails[0]?.new == true && filteredIpDetails?.length == 0) {
        payload.updateIpInfos = [];
      }

      if (type === "organization") {
        payload.akkuUserIpBasedAuthorizationDto.keycloakGroupId = [];
        payload.akkuUserIpBasedAuthorizationDto.ipInfos = [];
      }

      try {
        const response: any = await updateIpRestriction(payload);
        const status = response.status;
        if (status === 200) {
          setLoader(false);
          setIpSuccessModal(true);
          setIpSuccessMessage(response?.data?.message);
          setTimeout(() => {
            handleCloseSuccessModalIp();
            navigate("/access-manager");
          }, 3000);
        }
      } catch (err: any) {
        setTimeout(() => {
          setLoader(false);
          handleRequestError(err);
        }, 2000);
      }
    }
  };
  const isValidIP = (ip: any) => {
    let segments = ip?.split(".");
    for (let i = 0; i < segments?.length; i++) {
      let num = Number(segments[i]);
      if (isNaN(num) || num < 0 || num > 255) {
        return false;
      }
    }

    return true;
  };
  const handleNewIpSubmission = async (values: any, payload: any) => {
    if (navigatedFrom) {
      const ipRestrictedUser = deviceRestrictionList?.map((item: any) => item?.key);
      payload.userEntityId = ipRestrictedUser;
      payload.realmId = realmId;
    }
    try {
      const response: any = await createIpRestrictionApi(payload);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        setIpSuccessModal(true);
        setIpSuccessMessage(response?.data?.message);
        setTimeout(() => {
          handleCloseSuccessModalIp();
          navigate("/access-manager");
        }, 3000);
      }
    } catch (err: any) {
      setTimeout(() => {
        setLoader(false);
        handleRequestError(err);
      }, 2000);
    }
  };

  const handlePayloadDispatch = (payload: any, assignTo: string) => {
    dispatch(setIpPayload(payload));
    if (assignTo === "groups") {
      navigate("/access-manager/groups?parameter=ip");
    } else if (assignTo === "users") {
      navigate("/access-manager/assignUsers?parameter=ip");
    }
  };

  const onSubmit = async (values: any) => {
    const validIp = isValidIP(values?.IP);
    if (!validIp) {
      setIpErrorMessage("IP is invalid");
      return;
    }

    if (isEdit && validIp) {
      editIpApi(values);
      return;
    }

    setLoader(true);
    const ipDetails = calculateIpInfoAndIpRange([], values.ipDetails);
    dispatch(setPayloadForTimeAndIp(values));

    let payload: any = {
      name: values.name,
      description: values.description,
      authorizationType: values.authorizationType,
      ipInfos: ipDetails,
      active: true,
      realmId: realmId,
    };
    if (!navigatedFrom) {
      switch (values?.assignTo) {
        case "groups":
        case "users":
          handlePayloadDispatch(payload, values.assignTo);
          break;
        case "organization":
        default:
          await handleNewIpSubmission(values, payload);
          break;
      }
    } else {
      handleNewIpSubmission(values, payload);
    }
  };
  const returnToBack = () => {
    navigate(-1);
  };
  const returnToAccessManager = () => {
    navigate("/access-manager");
  };
  const returnToUserManagement = () => {
    dispatch(setListOfRestrictionModal(true));
    navigate("/user");
  };

  const chooseIpType = (value: any, setFieldValue: any) => {
    if (value === "Blacklist") {
      setFieldValue("assignTo", "organization");
      setAssignToValue("organization");
    } else {
      setFieldValue("assignTo", "");
      setAssignToValue("");
    }
    setFieldValue("authorizationType", value);
    setAuthorizationType(value);
  };
  const chooseAssigningTo = (value: any, setFieldValue: any) => {
    setFieldValue("assignTo", value);
    setAssignToValue(value);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleChangeUsers = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjectList];
    const missingIds = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfUsersDetails = [...listOfUsers];
    const needToDelete = listOfUsersDetails.filter((item) => !missingIds.includes(item.value));
    setUpdatedUserList(needToDelete);
    setUpdatedUserLists(value);
  };
  const handleChangeGroups = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjectList];
    const missingIdsForGroups = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfGroupDetails = [...groupData];
    const needToDelete = listOfGroupDetails.filter((item) => !missingIdsForGroups.includes(item.value));
    setUpdatedUserList(needToDelete);
    setUpdatedUserLists(value);
  };
  const handleAddUsers = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = usersSearchList.filter((item: any) => selectedValues.includes(item.value));
      setAddedUsers(selectedValues);
      setSelectedObjectList(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray.map((obj) => obj.value))).map((value) => {
        return mergedArray.find((obj) => obj.value === value);
      });
      setListOfUsers(uniqueMergedArray);
      setDropdownVisible(false);
    } else {
      setDropdownVisible(false);
      setListOfUsers(editGroupRecords);
    }
  };
  const handleAddGroups = () => {
    if (selectedValues?.length > 0) {
      const selectedObjects = userSearchGroupData?.filter((item: any) => selectedValues?.includes(item.value));
      setAddedUsers(selectedValues);
      setSelectedObjectList(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value))).map((value) => {
        return mergedArray.find((obj) => obj?.value === value);
      });
      setGroupData(uniqueMergedArray);
      setDropdownVisible(false);
    } else {
      setDropdownVisible(false);
      setGroupData(editGroupRecords);
    }
  };
  const dropdownRender = (menu: any) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={type === "groups" ? handleAddGroups : handleAddUsers} setDropdownVisible={setDropdownVisible} />
      </div>
    );
  };

  const handleUserLabelClick = (event: any, value: any) => {
    event.stopPropagation();
    const newValue: any = [...selectedValues];
    if (newValue.includes(value)) {
      newValue.splice(newValue.indexOf(value), 1);
    } else {
      newValue.push(value);
    }
    setSelectedValues(newValue);
  };
  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const unassignUser = () => {
    if (type === "groups") {
      const shallowGroupData = [...groupData];
      const reformedGroupList = shallowGroupData?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationGroupIds = ipFullDetails?.userGroupRestrictions?.filter((item: any) => selectedRowKeys.includes(item?.keycloakGroupId));
      const keycloakGroupIds = selectedAuthorizationGroupIds.map((item: any) => item.akkuUserIpBasedAuthorizationIds);
      const flatArray = keycloakGroupIds.flat();
      const mergeDeleteUserArray = [...deletedUsers, ...flatArray];
      setDeletedUsers(mergeDeleteUserArray);
      setGroupData(reformedGroupList);
    } else {
      const shallowListOfUsers = [...listOfUsers];
      const filteredReformedListNew = shallowListOfUsers?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const filteredReformedListNew1 = shallowListOfUsers?.filter((item: any) => selectedRowKeys?.includes(item.key));

      const userEntityIds = new Set(filteredReformedListNew1.map((item) => item.value));

      // Filter the first array based on the userEntityId values in the Set
      const filteredArray = initialValues.userGroupRestrictions.filter((item: any) => userEntityIds.has(item.userEntityId));
      const akkuUserIpBasedAuthorizationIds = filteredArray.map((user: any) => user.akkuUserIpBasedAuthorizationIds);

      // Flatten the array of arrays into a single array (if needed)
      const flattenedIds = akkuUserIpBasedAuthorizationIds.flat();

      setDeletedUsers(flattenedIds);
      setListOfUsers(filteredReformedListNew);
    }

    setSelectedRowKeys([]);
  };

  const handleCloseNecessaryModal = () => {
    setAtLeastOneModal(false);
  };
  const getIpSuffix = (ipRange: any) => {
    if (ipRange === "range16") {
      return ".0.0/16";
    } else if (ipRange === "range24") {
      return ".0/24";
    }
    return "";
  };

  const handleRemoveList = (index: any, remove: any, val: any) => {
    const removed = getIpSuffix(val.ipRange);
    remove(index);
    setRemovedIp((prev: any) => [...prev, `${val.IPv4 + removed}`]);
  };

  const getClassName = (index: any) => {
    return index === 0 ? "" : "lg:opacity-0";
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full">
          <ul className="flex breadcrumb">
            {!navigatedFrom ? (
              <li className="font-Inter pr-1 cursor-pointer" onClick={returnToAccessManager}>
                Access Manager /
              </li>
            ) : (
              <li className="font-Inter pr-1 cursor-pointer" onClick={returnToUserManagement}>
                User Management /
              </li>
            )}
            <li className="font-Inter pr-1 active">{!isEdit ? "Select IP" : "IP Restriction"}</li>
          </ul>
          <div className={`mb-14 mt-4 ${isActiveEdit ? "selectIpEdit-title" : ""}`}>
            <AccessManagerBackNavigationArrow title={!isEdit ? "Select IP" : "Edit - IP Restriction"} />
          </div>
          {!isEdit && (
            <div className={`mt-5 select-ip ${isEdit && "hidden"} `}>
              <Formik initialValues={initialValues} validationSchema={validationSchemaForIp} onSubmit={onSubmit} enableReinitialize={true}>
                {({ handleSubmit, setFieldValue, values, errors }) => {
                  return (
                    <Form
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <div className="  w-full flex pt-0 selectIP-form flex-wrap">
                        <div className=" w-[80%] flex pl-10 justify-between flex-wrap ">
                          <div className="mb-8 lg:mb-11 w-[100%] lg:w-[46%] relative">
                            <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Name*</p>
                            <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="name" maxLength={30} data-testid="selectIp-name" />
                            <ErrorMessage name="name" component="div" className="error-message" />
                          </div>
                          <div className="mb-8 lg:mb-11 w-[100%] lg:w-[46%] relative">
                            <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Description*</p>
                            <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="description" maxLength={100} data-testid="selectIp-description" />
                            <ErrorMessage name="description" component="div" className="error-message" />
                          </div>

                          <div className="mb-8 lg:mb-11 w-[100%] lg:w-[98%] relative">
                            <div className="md:w-[100%] lg:w-[46%]">
                              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Choose type for IP*</p>
                              <Radio.Group name="authorizationType" value={values?.authorizationType} className="flex pt-3" onChange={(e) => chooseIpType(e.target.value, setFieldValue)}>
                                <Radio value={"Whitelist"}>
                                  <div className="flex flex-col mr-5">
                                    <p className="user-type ">Whitelist IP</p>
                                  </div>
                                </Radio>
                                <Radio className="hidden" value={"Blacklist"}>
                                  <div className="flex  flex-col">
                                    <p className="user-type">Blacklist IP</p>
                                  </div>
                                </Radio>
                              </Radio.Group>
                            </div>
                            <ErrorMessage name="authorizationType" component="div" className="error-message" />
                          </div>

                          {/* new design */}
                          <FieldArray name="ipDetails">
                            {({ push, remove }) => (
                              <>
                                {values.ipDetails.map((field: any, index: any) => {
                                  let placeholder = "xxx.xxx.xxx.xxx";
                                  let maxLength = 15;
                                  let pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                                  if (field.ipRange === "range16") {
                                    placeholder = "xxx.xxx";
                                    maxLength = 7;
                                    pattern = "^\\d{1,3}\\.\\d{1,3}$";
                                  } else if (field.ipRange === "range24") {
                                    placeholder = "xxx.xxx.xxx";
                                    maxLength = 11;
                                    pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                                  }
                                  return (
                                    <div className="w-full relative mt-4" key={field.id}>
                                      <div className="w-full flex justify-between relative gap-2">
                                        <div className="lg:mb-4 lg:w-[32%] md:w-[50%] relative">
                                          <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>Range*</p>
                                          <Field
                                            as={Select}
                                            showSearch
                                            suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                                            className="w-[100%] h-[48px]"
                                            name={`ipDetails[${index}].ipRange`}
                                            value={field.ipRange}
                                            onChange={(value: any) => setFieldValue(`ipDetails[${index}].ipRange`, value)}
                                            filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Select"
                                          >
                                            <Select.Option value="exactIp">Exact IP</Select.Option>
                                            <Select.Option value="range16">Range 16</Select.Option>
                                            <Select.Option value="range24">Range 24</Select.Option>
                                          </Field>
                                          <ErrorMessage name={`ipDetails[${index}].ipRange`} component="div" className="error-message" />
                                        </div>
                                        <div className="lg:mb-4 lg:w-[32%] selectIP relative">
                                          {field.ipRange !== "" && field.ipRange !== "exactIp" && <div className="selectIP-highlighted">{field.ipRange === "range16" ? ".0.0/16" : ".0/24"}</div>}
                                          <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>IPv4*</p>
                                          <Field
                                            placeholder={placeholder}
                                            data-testid="ip-address"
                                            as={Input}
                                            type="text"
                                            className="h-[48px] text-[18px] font-medium"
                                            name={`ipDetails[${index}].IPv4`}
                                            maxLength={maxLength}
                                            pattern={pattern}
                                            disabled={field.ipRange === ""}
                                            onChange={(event: any) => {
                                              const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                                              setFieldValue(`ipDetails[${index}].IPv4`, valueWithoutSpaces);
                                            }}
                                          />
                                          <ErrorMessage name={`ipDetails[${index}].IPv4`} className="error-message" component="div" />
                                        </div>

                                        <div className="lg:mb-4 lg:w-[32%] selectIP relative">
                                          <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>IPv6</p>
                                          <Field
                                            as={Input}
                                            disabled={field.ipRange !== "exactIp"}
                                            type="text"
                                            placeholder="xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx"
                                            className="h-[48px] text-[18px] font-medium"
                                            name={`ipDetails[${index}].IPv6`}
                                            onChange={(event: any) => {
                                              const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                                              setFieldValue(`ipDetails[${index}].IPv6`, valueWithoutSpaces);
                                            }}
                                          />
                                          <ErrorMessage name={`ipDetails[${index}].IPv6`} className="error-message" component="div" />
                                        </div>
                                      </div>
                                      <div className="flex right-[-130px] absolute top-7 items-center justify-center">
                                        <span
                                          className={`${values.ipDetails.length === 1 ? "pointer-events-none" : ""} add-custom ip remove material-symbols-outlined cursor-pointer`}
                                          onClick={() => remove(index)}
                                        >
                                          remove
                                        </span>
                                        {index === values.ipDetails.length - 1 && (
                                          <span className="cursor-pointer material-symbols-outlined add-custom ip" onClick={() => push({ ipRange: "", id: index, IPv4: "", IPv6: "" })}>
                                            add
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </FieldArray>

                          {/* end */}
                          {!navigatedFrom && (
                            <div className="mb-8 lg:my-11 w-[100%] lg:w-[98%] relative">
                              <div className="md:w-[100%] lg:w-[46%]">
                                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Assign to*</p>
                                <Radio.Group value={assignToValue} className="flex pt-3" name="assignTo" onChange={(e: any) => chooseAssigningTo(e.target.value, setFieldValue)}>
                                  <Radio value={"organization"}>
                                    <div className="flex flex-col mr-5">
                                      <p className="user-type ">Organization</p>
                                    </div>
                                  </Radio>
                                  <Radio value="groups">
                                    {/* <Radio value="groups" disabled={values?.authorizationType === "Blacklist"}> */}
                                    <div className="flex  flex-col mr-5">
                                      <p className="user-type ">Groups</p>
                                    </div>
                                  </Radio>
                                  <Radio value="users">
                                    {/* <Radio value="users" disabled={values?.authorizationType === "Blacklist"}> */}
                                    <div className="flex  flex-col">
                                      <p className="user-type ">Users</p>
                                    </div>
                                  </Radio>
                                </Radio.Group>
                              </div>
                              <ErrorMessage name="assignTo" component="div" className="error-message" />
                            </div>
                          )}
                        </div>
                        <hr />
                        <div className="mr-4 flex justify-end w-full mt-2 pb-7 foot">
                          <CustomButtonBack text={"Cancel"} onClick={handleOpenCloseModal} />
                          <Button htmlType="submit" className="btn bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px]" type="primary" disabled={ipErrorMessage !== ""}>
                            {values?.assignTo === "organization" || navigatedFrom ? "Save" : "Next"}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
          {isEdit && (
            <div className="ip-container ">
              {isEdit && (
                <div className="mx-auto selectIpEdit">
                  <Formik initialValues={initialValues} validationSchema={validationSchemaForIp} onSubmit={onSubmit} enableReinitialize={true}>
                    {({ handleSubmit, setFieldValue, values }) => {
                      return (
                        <Form
                          onSubmit={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          <div className="w-full flex pt-0 flex-wrap relative">
                            <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-11 w-full">
                              <div className="relative">
                                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Name*</p>
                                <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="name" maxLength={30} />
                                <ErrorMessage name="name" component="div" className="error-message" />
                              </div>
                              <div className="relative">
                                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Description*</p>
                                <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="description" maxLength={100} />
                                <ErrorMessage name="description" component="div" className="error-message" />
                              </div>
                            </div>
                            <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-11 w-full mt-5 pt-5">
                              <div className="relative">
                                <div className="">
                                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Choose type for IP*</p>
                                  <Radio.Group
                                    disabled={type !== "organization"}
                                    name="authorizationType"
                                    value={authorizationType}
                                    className="flex pt-3"
                                    onChange={(e) => chooseIpType(e.target.value, setFieldValue)}
                                  >
                                    <Radio value={"Whitelist"}>
                                      <div className="flex flex-col mr-5">
                                        <p className="user-type ">Whitelist IP</p>
                                      </div>
                                    </Radio>
                                    <Radio className="hidden" value={"Blacklist"}>
                                      <div className="flex  flex-col">
                                        <p className="user-type ">Blacklist IP</p>
                                      </div>
                                    </Radio>
                                  </Radio.Group>
                                </div>
                                <ErrorMessage name="authorizationType" component="div" className="error-message" />
                              </div>
                            </div>
                            <div className="w-[70%]">
                              <FieldArray name="ipDetails">
                                {({ push, remove }) => {
                                  return (
                                    <>
                                      {values.ipDetails.map((field: any, index: any) => {
                                        let pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                                        let placeholder = "xxx.xxx.xxx.xxx";
                                        let maxLength = 15;
                                        if (field.ipRange === "range16") {
                                          maxLength = 7;
                                          placeholder = "xxx.xxx";
                                          pattern = "^\\d{1,3}\\.\\d{1,3}$";
                                        } else if (field.ipRange === "range24") {
                                          maxLength = 11;
                                          placeholder = "xxx.xxx.xxx";
                                          pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                                        }
                                        return (
                                          <div className="w-full relative mt-4" key={field.id}>
                                            <div className="w-full flex justify-between relative md:flex-wrap  gap-2">
                                              <div className="lg:mb-4 lg:w-[32%]  md:w-[100%] relative">
                                                <p className={`${getClassName(index)} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>Range*</p>
                                                <Field
                                                  showSearch
                                                  disabled={!field.new}
                                                  as={Select}
                                                  suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                                                  className="w-[100%] h-[48px]"
                                                  value={field.ipRange}
                                                  name={`ipDetails[${index}].ipRange`}
                                                  onChange={(value: any) => setFieldValue(`ipDetails[${index}].ipRange`, value)}
                                                  placeholder="Select"
                                                  filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                  <Select.Option value="exactIp">Exact IP</Select.Option>
                                                  <Select.Option value="range16">Range 16</Select.Option>
                                                  <Select.Option value="range24">Range 24</Select.Option>
                                                </Field>
                                                <ErrorMessage name={`ipDetails[${index}].ipRange`} component="div" className="error-message" />
                                              </div>
                                              <div className="lg:mb-4 lg:w-[32%] md:w-[100%] selectIP relative">
                                                {field.ipRange !== "" && field.ipRange !== "exactIp" && <div className="selectIP-highlighted">{field.ipRange === "range16" ? ".0.0/16" : ".0/24"}</div>}
                                                <p className={`${getClassName(index)} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>IPv4*</p>
                                                <Field
                                                  placeholder={placeholder}
                                                  as={Input}
                                                  data-testid="ip-address"
                                                  type="text"
                                                  name={`ipDetails[${index}].IPv4`}
                                                  className="h-[48px] text-[18px] font-medium"
                                                  maxLength={maxLength}
                                                  disabled={field.ipRange === ""}
                                                  pattern={pattern}
                                                  onChange={(event: any) => {
                                                    const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                                                    setFieldValue(`ipDetails[${index}].IPv4`, valueWithoutSpaces);
                                                  }}
                                                />
                                                <ErrorMessage name={`ipDetails[${index}].IPv4`} className="error-message" component="div" />
                                              </div>

                                              <div className="lg:mb-4 lg:w-[32%] md:w-[100%] selectIP relative">
                                                <p className={`${getClassName(index)} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>IPv6</p>
                                                <Field
                                                  as={Input}
                                                  placeholder="xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx"
                                                  type="text"
                                                  disabled={field.ipRange !== "exactIp"}
                                                  className="h-[48px] text-[18px] font-medium"
                                                  name={`ipDetails[${index}].IPv6`}
                                                  onChange={(event: any) => {
                                                    const valueWithoutSpace = event.target.value.replace(/\s+/g, "");
                                                    setFieldValue(`ipDetails[${index}].IPv6`, valueWithoutSpace);
                                                  }}
                                                />
                                                <ErrorMessage name={`ipDetails[${index}].IPv6`} className="error-message" component="div" />
                                              </div>
                                            </div>
                                            <div className="flex right-[-130px] absolute top-7 items-center justify-center">
                                              <span
                                                className={`${values.ipDetails.length === 1 && "pointer-events-none"} add-custom ip remove material-symbols-outlined cursor-pointer`}
                                                onClick={() => handleRemoveList(index, remove, values.ipDetails[index])}
                                              >
                                                remove
                                              </span>
                                              {index === values.ipDetails.length - 1 && (
                                                <span
                                                  className="cursor-pointer material-symbols-outlined add-custom ip"
                                                  onClick={() => push({ ipRange: "", id: index, IPv4: "", IPv6: "", new: true })}
                                                >
                                                  add
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  );
                                }}
                              </FieldArray>
                            </div>
                          </div>
                          <div className="mb-2 w-full selectIP relative mt-5 grid lg:grid-cols-1 2xl:grid-cols-3 gap-11">
                            <div></div> <div></div>
                            <Button htmlType="submit" className="btn bg-[#5441DA] w-full h-[55px] text-[20px] mt-5" type="primary" disabled={ipErrorMessage !== ""}>
                              Update
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              )}

              {isEdit && type !== "organization" && (
                <div className="assignedTable-container">
                  <h3>Assigned to </h3>
                  <div className="table-search">
                    <div className="w-[35%]">
                      {type === "groups" ? (
                        <Select
                          className="h-14 w-full mb-5 custom-dropdown"
                          mode="tags"
                          placeholder={"Search"}
                          onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
                          open={dropdownVisible}
                          tokenSeparators={[","]}
                          optionLabelProp="label"
                          onChange={handleChangeGroups}
                          filterOption={filterOption}
                          dropdownRender={dropdownRender}
                        >
                          {userSearchGroupData?.map((option: any) => (
                            <Option key={option?.value} value={option?.value} label={option?.name}>
                              <input
                                type="checkbox"
                                className="dropdown-list w-5"
                                name={option?.name}
                                id={option?.value}
                                checked={selectedValues.includes(option?.value)}
                                onChange={(e) => handleUserLabelClick(e, option?.value)}
                              />
                              <label htmlFor={option?.value} className="flex flex-col h-14 pointer-events-none justify-center">
                                {option?.name} <span className="absolute top-[32px] text-[#747577]">{option?.description}</span>
                              </label>
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Select
                          className="h-14 w-full mb-5 custom-dropdown"
                          mode="tags"
                          placeholder={"Search"}
                          onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
                          open={dropdownVisible}
                          tokenSeparators={[","]}
                          optionLabelProp="label"
                          onChange={handleChangeUsers}
                          filterOption={filterOption}
                          dropdownRender={dropdownRender}
                        >
                          {usersSearchList?.map((option: any) => (
                            <Option key={option?.value} value={option?.value} label={option?.name}>
                              <input
                                type="checkbox"
                                className="dropdown-list w-5"
                                name={option?.name}
                                id={option?.value}
                                checked={selectedValues.includes(option?.value)}
                                onChange={(e) => handleUserLabelClick(e, option?.value)}
                              />
                              <label htmlFor={option?.name} className="flex flex-col h-14 pointer-events-none justify-center">
                                {option?.name} <span className="absolute top-[32px] text-[#747577]">{option?.email}</span>
                              </label>
                            </Option>
                          ))}
                        </Select>
                      )}
                    </div>
                  </div>
                  {type !== "organization" && (
                    <div className="assignedTable">
                      <div className="flex device-restriction">
                        <p className="assignedTable-totalGroup">{`Total No. of ${type} ${type === "groups" ? groupData?.length : listOfUsers?.length} `}</p>
                        {selectedRowKeys && selectedRowKeys.length > 0 && (
                          <p className={`ml-[55px] users-clear `} onClick={unassignUser}>
                            {type === "groups" ? "Unassign Group(s)" : "Unassign user(s)"}
                          </p>
                        )}
                      </div>
                      <Table rowSelection={rowSelection} columns={type === "groups" ? groupColumnsIp : columnsIp} dataSource={type === "groups" ? groupData : listOfUsers} pagination={false} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {loader && <Loader />}
      {atLeastOneModal && <GroupAndUserNecessaryModal handleCloseNecessaryModal={handleCloseNecessaryModal} openNecessaryModal={atLeastOneModal} type={type === "groups" ? "group" : "user"} />}
      {openCloseModal && <CloseConfirmationModal closeModalOpen={openCloseModal} handleProceed={returnToBack} handleCancel={handleCloseConfirmationModal} />}
      {ipSuccessModal && <IpTimeSuccessModal open={ipSuccessModal} handleModalClose={handleCloseSuccessModalIp} responseMessage={ipSuccessMessage} />}
    </div>
  );
};

export default SelectIP;
