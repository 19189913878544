import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../layouts/component/CustomButton";
import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import { Modal } from "antd";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";

const DeleteRoles = (props: any) => {
  const deletePermission = useGetApiRequests("deletePermission", "DELETE");
  const { handleClose, openModal, roleId, setIsDeleted, isDeleted, setLoader } = props;
  const [open, isOpen] = useState<boolean>(false);

  const handleDelete = async () => {
    setLoader(true);
    const deleteRole = {
      roleId: roleId,
    };
    try {
      handleClose();
      const response: any = await deletePermission("", {}, deleteRole);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data;
        setLoader(false);
        triggerNotification("success", "", data?.message, "topRight");
        setIsDeleted(!isDeleted);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleCloseModal = () => {
    handleClose();
  };
  useEffect(() => {
    isOpen(openModal);
  }, [openModal]);
  return (
    <Modal className=" show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={images.notificationDelete} alt="notificationDone" className="delete-apps" />
          <h5 className="text-[18px] font-Inter pt-3 font-semibold"> Are you sure you want to delete this role? </h5>
        </div>
      </div>
      <div className="modal-footer pt-10">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={"No"} data-testid="No" />
          <CustomButtonNext onClick={handleDelete} text={"Yes"} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteRoles;
