import React, { useEffect } from "react";
import "./AppProvisionConfiguration.scss";
import ManageConnection from "./manage-connection/ManageConnection";
import ConnectorDetails from "./connector/ConnectorDetails";
import ProvisionMappingAttributes from "./mappers/ProvisionMappingAttributes";
import ProvisionGroupMapping from "./provision-group-mapping/ProvisionGroupMapping";
import MappingUserStatus from "./mapper-status/MappingUserStatus";
import ProvisionMapperNotification from "./mapper-notification/ProvisionMapperNotification";
import MappingScheduler from "./scheduler/MappingScheduler";
import ProvisionStepper from "./stepper/ProvisionStepper";
import { useSelector } from "react-redux";
const AppProvisionConfiguration = () => {
  const currentSteps = useSelector((state: any) => state?.AppDetailsSlice?.currentStep);
  useEffect(() => {
    console.log(currentSteps);
  }, []);

  return (
    <>
      <div className="akku-container" data-testid='connector-detailsId'>
        <div className="main-container app">
          <div className="w-full flex app-container">
            <div className="app-stepper bg-white">
              <ProvisionStepper />
            </div>
            <div className="app-form-container overflow-hidden">
              <>
                {currentSteps?.activeStep === 0 && <ConnectorDetails />}
                {currentSteps?.activeStep === 1 && <ManageConnection />}
                {currentSteps?.activeStep === 2 && <ProvisionMappingAttributes />}
                {currentSteps?.activeStep === 3 && <ProvisionGroupMapping />}
                {currentSteps?.activeStep === 4 && <MappingUserStatus />}
                {currentSteps?.activeStep === 5 && <ProvisionMapperNotification />}
                {currentSteps?.activeStep === 6 && <MappingScheduler />}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppProvisionConfiguration;
