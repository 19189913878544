import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Select, Tooltip } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ReactComponent as GreenCheckIcon } from "./../../../../../assets/images/icons/greencheck.svg";
import trashcan from "./../../../../../assets/images/icons/trashcan.svg";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import * as Yup from "yup";
import { validationErrorMessages } from "../../../../../constant/validationMessage";
import Loader from "../../../../../layouts/component/Loader";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import PhoneInput from "react-phone-input-2";
import { retrieveData } from "../../../../../services/storage/Storage";
import GroupAppList from "./GroupAppList";
import { getListOfApps } from "../../user-dashboard/groups/modal/ListOfUserHelperFile";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { useNavigate } from "react-router-dom";
import ConnectedApplicationEdit from "../../user-dashboard/groups/layout/ConnectedApplicationEdit";
import { images } from "../../../../../assets/images/ImagePaths";
import ImageView from "../../../../../layouts/component/ImageView";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import UserCreationNotificationModal from "../../user-dashboard/add-user/layouts/single-upload/directory/UserCreationNotificationModal";
import { ErrorMessages } from "../../../../../const/Messages";
import { getRolesList } from "../../user-dashboard/adduser/usertable/CustomTableUtils";
import CustomFieldBasic from "./CustomFieldBasic";
import DeviceTokenPhoneCheck from "../../../../../layouts/modal/DeviceTokenPhoneCheck/DeviceTokenPhoneCheck";
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(validationErrorMessages.firstName.required)
    .matches(/^[a-zA-Z\s]*$/, validationErrorMessages.firstName.letterAndSpace)
    .min(4, validationErrorMessages.firstName.minLength)
    .max(16, validationErrorMessages.firstName.maxLength),
  lastName: Yup.string()
    .required(validationErrorMessages.lastName.required)
    .matches(/^[a-zA-Z\s]*$/, validationErrorMessages.lastName.letterAndSpace)
    .min(1, validationErrorMessages.lastName.minLength)
    .max(16, validationErrorMessages.lastName.maxLength),
  phone: Yup.string().required("Mobile Number is required").min(8, "Mobile Number must be at least 8 characters").max(13, "Mobile Number cannot exceed 13 characters"),
  personalEmail: Yup.string().email("Invalid email").required("Email is required"),
  workEmail: Yup.string().email("Invalid email").required("Email is required"),
  customField: Yup.array().of(
    Yup.object().shape({
      value: Yup.string()
        .test("is-not-first", "Please fill this field", function (value, context: any) {
          const index = context?.options?.index;
          if (index === 0) return true;
          return value !== undefined && value !== "";
        })
        .min(3, "Please enter at least 3 characters"),
      name: Yup.string()
        .required("This field is required")
        .min(3, "Please enter at least 3 characters")
        .test("unique-name", "Field already exists", function (value: any) {
          const { path, options }: any = this;
          const fields: any = options.context.customField;
          const currentFieldIndex = parseInt(path.match(/\d+/)[0]);
          const isDuplicate = fields?.filter((field: any, index: any) => index !== currentFieldIndex).some((field: any) => field.name === value);

          return !isDuplicate;
        }),
    }),
  ),
});
const BasicDetails = (props: any) => {
  const { setUserEnabled, setDisableToggle, enable, handleCloseLoader } = props;
  const navigate = useNavigate();
  const updateBasicDetails = useGetApiRequests("basicDetails", "PUT");
  const realmId = retrieveData("realmId", true);
  const userName = retrieveData("userName", true);

  const [listOfApps, setListOfApps] = useState([]);
  const { isEdit, selectedRowObject } = props;
  const currentSelectedRow = retrieveData("currentSelectedRow", true);
  const getAppList = useGetApiRequests("getListOfAppsByRealmId", "GET");
  const [isFormDirty, setFormDirty] = useState(false);
  const getUserInfo = useGetApiRequests("getUserProfile", "GET");
  const [loader, setLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userDetails, setUserDetails] = useState<any>([]);
  const [individualApps, setIndividualApps] = useState<any>([]);
  const [checkedState, setCheckedState] = useState<{ [key: string]: boolean }>({});
  const [isActiveDeviceRestriction, setIsActiveDeviceRestriction] = useState(false);
  const [tempListApps, setTempListApps] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [disableFields, setDisableFields] = useState<boolean>(false);
  const [isActiveDeviceToken, setIsActiveDeviceToken] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [submittedFormValue, setSubmittedFormValue] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [restrictionList, setRestrictionList] = useState<any>([]);
  const [removedDeviceRestrictions, setRemovedDeviceRestrictions] = useState<any>([]);
  const [selectedIds, setSelectedIds] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({
    firstName: "",
    lastName: "",
    workEmail: "",
    userType: "User",
    phone: "",
    personalEmail: "",
    customField: [{ value: "", name: "Untitled", id: "untitled" }],
  });
  const [roleSelected, setRoleSelected] = useState("User");
  const getRoleList = useGetApiRequests("getRolesList", "GET");
  const [userTypeList, setUserTypeList] = useState<any>([]);
  useEffect(() => {
    init();
  }, [enable]);

  const init = () => {
    getRoles();
  };
  const getRoles = async () => {
    setLoader(true);
    const roleList: any = await getRolesList(realmId, getRoleList);
    const user = { label: "User", value: "User" };
    roleList?.push(user);
    setUserTypeList(roleList);
    getUserInformation(roleList);
  };
  const getAppData = async (el: any) => {
    try {
      const individualApps = await getListOfApps(realmId, setLoader, getAppList);
      const filteredAppList = individualApps.filter((obj: any) => !el.some((item: any) => item?.value === obj?.value));
      setIndividualApps(filteredAppList);
    } catch (error) {
      handleRequestError(error);
      setLoader(false);
    }
  };
  const checkDeviceToken = (dataArray: any): boolean => {
    //Check if any item in the data array has a name of 'deviceToken'
    return dataArray.some((item: any) => {
      if (item.name === "deviceToken") {
        setIsActiveDeviceToken(true);
      }
    });
  };

  const initUserInformation = (el: any) => {
    const newAttribute = el?.attributes;
    checkDeviceToken(newAttribute);
    const fieldArray = newAttribute.filter((item: any) => item?.custom);
    const userData: any = {};
    el?.attributes?.forEach((attribute: any) => {
      if (attribute.name === "personal_email_id" || attribute.name === "telephoneNumber") {
        userData[attribute.name] = attribute.value ?? "";
      }
    });
    setInitialValues({
      ...initialValues,
      firstName: el.firstName ?? "",
      lastName: el.lastName ?? "",
      workEmail: el.username ?? "",
      phone: userData.telephoneNumber ?? "",
      personalEmail: userData.personal_email_id ?? "",
      customField: fieldArray.length === 0 ? [{ value: "", name: "Untitled" }] : fieldArray,
    });
  };
  const handleToBack = () => {
    setModalOpen(true);
  };
  const getUserInformation = (roleList: any) => {
    const pathParams = {
      id: currentSelectedRow?.key,
    };
    getUserInfo("", {}, pathParams)
      .then((response: any) => {
        const data = response?.data?.data;
        // setRestrictionList(data);
        const rolesInInitialValue = roleList.find((item: any) => item?.value === data?.userRoleList?.[0]?.id);
        if (rolesInInitialValue && Object.keys(rolesInInitialValue).length > 0) {
          setRoleSelected(rolesInInitialValue?.key);
        }
        if (data?.userDetailDto?.username === userName) {
          setDisableFields(true);
          setDisableToggle(true);
        }
        setUserDetails(data);
        initUserInformation(data?.userDetailDto);
        setUserEnabled(data?.userDetailDto?.enabled);
        setLoader(false);
        handleCloseLoader();
        if (data?.userDeviceInfoDtoList?.length > 0) {
          const filterDeviceBased = data?.userDeviceInfoDtoList?.filter((item: any) => item?.serialNumber);
          if (filterDeviceBased?.length > 0) {
            setIsActiveDeviceRestriction(true);
            setRestrictionList(filterDeviceBased);
          }
        }

        if (data.akkuClientGroupMapDto?.userClientGroupDtoListStringMap) {
          const initialState: { [key: string]: boolean } = {};
          data.akkuClientGroupMapDto?.userClientGroupDtoListStringMap &&
            Object.keys(data.akkuClientGroupMapDto?.userClientGroupDtoListStringMap).forEach((groupName) => {
              initialState[groupName] = true;
            });
          setCheckedState(initialState);
        }
        const updatedUserClientDtoList = data?.userClientDtoList.map((item: any) => {
          return {
            ...item,
            label: item.app,
            checked: true,
            value: item.akkuClientId,
          };
        });
        setTempListApps(updatedUserClientDtoList);
        setListOfApps(updatedUserClientDtoList);
        getAppData(updatedUserClientDtoList);
      })
      .catch((err: any) => {
        setLoader(false);
        handleRequestError(err);
      });
  };
  const onChange = (value: string, setFieldValue: any) => {
    setRoleSelected(value);
    setFieldValue("userType", value);
  };
  const onSubmit = (values: any) => {
    setSubmittedFormValue(values);
    if (phoneNumber === "active" && isActiveDeviceToken) {
      handleOpenConfirmationModal();
    } else {
      handlePayload(values, false);
    }
  };

  const handleOnChangePhone = (value: any, setFieldValue: any) => {
    setPhoneNumber("active");
    setFieldValue("phone", value);
  };

  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };
  const closeConfirmationModal = () => {
    setIsActiveDeviceToken(false);
    setOpenConfirmationModal(false);
    handlePayload(submittedFormValue, false);
  };

  const handleProceed = () => {
    setIsActiveDeviceToken(false);
    setOpenConfirmationModal(false);
    handlePayload(submittedFormValue, true);
  };

  const handelSplit = (arrayList: any, replacementMap: any) => {
    const result = Object.entries(replacementMap).map(([key, value]) => ({
      name: key,
      value: value,
      custom: true,
    }));
    const combinerArray = result.concat(arrayList);
    const uniqueData = combinerArray.reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return uniqueData;
  };

  const handleDeleteUserDetailsObject = (userClientGroupDtoList: any, userDetailsValues: any, falseKeys: any) => {
    userClientGroupDtoList &&
      Object.keys(userClientGroupDtoList).forEach((groupName) => {
        if (!falseKeys?.includes(groupName)) {
          delete userDetailsValues.akkuClientGroupMapDto.userClientGroupDtoListStringMap[groupName];
        }
      });
    return userDetailsValues;
  };

  const handlePayload = (formValues: any, deviceToken: boolean) => {
    let userDetailsValues = { ...userDetails };
    // Update userDetailDto properties
    userDetailsValues.userDetailDto = {
      ...userDetailsValues.userDetailDto,
      firstName: formValues.firstName,
      username: formValues.workEmail,
      lastName: formValues.lastName,
    };

    // Filter out attributes with name "personal_email_id" or "clientAppId"
    userDetailsValues.userDetailDto.attributes = userDetailsValues?.userDetailDto?.attributes?.filter((item: any) => item?.name !== "personal_email_id" && item?.name !== "clientAppId");

    const transformedList = listOfApps.map((item: any) => ({
      name: "clientAppId",
      value: `${item?.value}`,
    }));

    userDetailsValues.userDetailDto?.attributes.forEach((item: any) => {
      const storeObject = item;
      if (storeObject.name === "telephoneNumber") {
        storeObject.value = formValues.phone;
      }
    });
    const replacementMap = formValues?.customField.reduce((acc: any, item: any) => {
      acc[item.name] = item.value;
      return acc;
    }, {});
    const attributes = userDetailsValues?.userDetailDto?.attributes;
    const customFields = attributes.filter((item: any) => !item.custom);
    const updatedArray: any = handelSplit(customFields, replacementMap);

    userDetailsValues.userDetailDto.attributes = [...updatedArray, ...transformedList, { name: "personal_email_id", value: formValues.personalEmail }];
    let userClientGroupDtoList = userDetailsValues?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap;
    const filteredData = userDetailsValues?.userDetailDto?.attributes?.filter((item: any) => item?.name !== "LDAP_ENTRY_DN" && item?.name !== "modifyTimestamp");
    userDetailsValues.userDetailDto.attributes = filteredData;

    // Update userDeviceInfoDto based on isActiveDeviceRestriction
    if (removedDeviceRestrictions?.length > 0) {
      userDetailsValues.userDeviceInfoDtoList = removedDeviceRestrictions;
    } else {
      delete userDetailsValues?.userDeviceInfoDtoList;
    }

    const falseKeys: any = [];

    Object.entries(checkedState).forEach(([key, value]) => {
      if (value === false) {
        falseKeys.push(key);
      }
    });

    if (falseKeys?.length > 0) {
      handleDeleteUserDetailsObject(userClientGroupDtoList, userDetailsValues, falseKeys);
    } else {
      userDetailsValues.akkuClientGroupMapDto.userClientGroupDtoListStringMap = null;
    }

    setLoader(true);

    if (roleSelected !== "User") {
      const idList = [];
      const roleId = {
        id: roleSelected,
      };
      idList.push(roleId);
      userDetailsValues.userRoleList = idList;
    } else {
      userDetailsValues.userRoleList = [];
    }

    const queryParams = {
      realmId: realmId,
    };

    if (deviceToken) {
      userDetailsValues.userDetailDto.attributes = userDetailsValues?.userDetailDto?.attributes?.filter((item: any) => item.name !== "deviceToken");
    }
    updateBasicDetails(userDetailsValues, queryParams)
      .then((response: any) => {
        handleSuccessMethod(response);
      })
      .catch((err: any) => {
        setLoader(false);
        handleCatchMethod(err);
      });
  };
  const handleSuccessMethod = (response: any) => {
    if (response?.status === 201 || response?.status === 200) {
      navigate("/user");
      setLoader(false);
      triggerNotification("success", "", response?.data?.message, "topRight");
    }
  };
  const handleCatchMethod = (err: any) => {
    if (err?.response?.status === 401 || err?.response?.status === 500) {
      handleRequestError(err);
    } else {
      triggerNotification("error", "", err?.response?.data?.message, "topRight");
    }
  };
  const deleteRestriction = (elements: any) => {
    setSelectedIds(elements);
    setDeleteModal(true);
  };
  const handleModalClose = () => {
    setSelectedIds(null);
    setDeleteModal(false);
  };
  const confirmDeleteRestriction = () => {
    if (selectedIds !== null) {
      // Remove the selected IDs from the restriction list
      const updatedRestrictionList = restrictionList?.filter((item: any) => item?.akkuUserDeviceInfoId !== selectedIds?.akkuUserDeviceInfoId);
      setRestrictionList(updatedRestrictionList);
      setRemovedDeviceRestrictions([...removedDeviceRestrictions, selectedIds]);
    }
    handleModalClose();
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} values={initialValues} onSubmit={onSubmit} enableReinitialize={true} isInitialValid={isFormDirty}>
        {({ handleSubmit, errors, setFieldValue, dirty, values }) => (
          <Form
            onSubmit={(e) => {
              setFormDirty(true);
              handleSubmit(e);
            }}
          >
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
              <div className="mb-2 relative">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">First Name*</p>
                <Field as={Input} disabled={disableFields} type="text" className="h-[56px] font-medium text-[18px]" name="firstName" maxLength={16} data-testid="fnameId" />
                <ErrorMessage name="firstName" component="div" className="error-message" />
              </div>
              <div className="mb-2 relative">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Last Name*</p>
                <Field as={Input} disabled={disableFields} type="text" className="h-[56px] font-medium text-[18px]" name="lastName" maxLength={16} data-testid="lnameId" />
                <ErrorMessage name="lastName" component="div" className="error-message" />
              </div>
              <div className="mb-2 relative">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Work Email ID *</p>
                <Field as={Input} disabled={disableFields} type="email" readOnly={isEdit || ""} className={`${isEdit ? "cursor-not-allowed" : ""} h-[56px] font-medium text-[18px]`} name="workEmail" />
                <ErrorMessage name="workEmail" component="div" className="error-message" />
              </div>
              <div className="mb-2">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium" data-testid="UTypeId">
                  User Type*
                </p>
                <Field
                  disabled={disableFields}
                  as={Select}
                  suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                  className=" w-[100%] h-[56px]"
                  name="userType"
                  value={roleSelected}
                  onChange={(value: string) => onChange(value, setFieldValue)}
                  filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {userTypeList?.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Field>
                <ErrorMessage name="userType" component="div" className="error-message" />
              </div>
              {selectedRowObject?.restrictions === "active" && (
                <div className="mb-2">
                  <p className="text-[#171717] text-[24px] font-inter font-semibold">Restrictions</p>
                  <div className=" w-[40%] -form flex items-center justify-between mt-2">
                    <Input disabled={true} value={"Device Restriction"} type="text" className="h-[46px] flex items-center font-medium text-[18px]" prefix={<GreenCheckIcon />} />
                    <Tooltip title={"Remove Restriction"}>
                      <span>
                        <img src={trashcan} alt="delete" className="cursor-pointer h-[33px]" onClick={deleteRestriction} />
                      </span>
                    </Tooltip>
                  </div>
                </div>
              )}
              <div className="mb-2 relative">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Personal Email ID</p>
                <Input
                  type="email"
                  className="h-[56px] font-medium"
                  name="personalEmail"
                  onChange={(e) => setFieldValue("personalEmail", e.target.value)}
                  value={values.personalEmail}
                  data-testid="emailId"
                  disabled={disableFields}
                />
                <ErrorMessage name="personalEmail" component="div" className="error-message" />
              </div>
              <div className="mb-2 relative">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium" data-testid="phoneId">
                  Mobile Number
                </p>
                <PhoneInput
                  disabled={disableFields}
                  inputClass="ant-input h-[56px] w-[100%]"
                  country={"us"}
                  value={values.phone}
                  onChange={(value: any) => handleOnChangePhone(value, setFieldValue)}
                />
                <ErrorMessage name="phone" component="div" className="error-message" />
              </div>
            </div>

            <div className="custom-field w-full mx-auto mb-10">
              <div className="flex w-full justify-between items-center ">
                <div className="text w-[75%] mt-5 ">
                  <p className="text-[20px] font-semibold font-Inter">Please enter the following details or add custom field</p>
                </div>
              </div>
            </div>

            <CustomFieldBasic errors={errors} values={values} setFieldValue={setFieldValue} userDetails={userDetails} setUserDetails={setUserDetails} />

            <div className="mt-6">
              <ConnectedApplicationEdit
                setListOfApps={setListOfApps}
                listOfApps={listOfApps}
                name="Individual apps"
                styleName="individual-apps"
                individualApps={individualApps}
                tempListApps={tempListApps}
              />
            </div>
            <div>
              {userDetails?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap && (
                <>
                  <h1 className="editUser-title mb-6 mt-6">Groups connected apps</h1>
                  <GroupAppList data={userDetails?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap} setCheckedState={setCheckedState} checkedState={checkedState} />
                </>
              )}
            </div>
            {/* <UserRestriction restrictionList={restrictionList} /> */}

            {isActiveDeviceRestriction && (
              <div>
                <p className="text-[#171717] text-[24px] font-inter font-semibold">Restrictions</p>
                <div className="w-[100%] -form flex flex-wrap items-center justify-between mt-2">
                  {restrictionList &&
                    restrictionList?.map((item: any) => (
                      <div className=" w-[50%] -form flex items-center justify-between mt-2">
                        <div className="flex items-center justify-between">
                          <Input value={item?.serialNumber} type="text" className="h-[46px] w-[450px] flex items-center font-medium text-[18px]" prefix={<GreenCheckIcon />} />
                          <Tooltip title={"Remove Restriction"}>
                            <span>
                              <img src={trashcan} alt="delete" className="cursor-pointer h-[33px]" onClick={() => deleteRestriction(item)} />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className={` ${isEdit ? "pb-4 relative bottom-0 left-0 mt-0 " : "pb-0 "} modal-footer mt-[5rem] p-8 pr-[30px] w-[100%] mx-auto`}>
              <div className="w-full flex justify-end">
                <CustomButtonBack onClick={handleToBack} text={"Cancel"} />
                <Button
                  data-testid="submit-button"
                  type="primary"
                  htmlType="submit"
                  className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
                >
                  Update
                </Button>
              </div>
            </div>
            {loader && <Loader />}
            <Modal className="p-10  show-notification  _suspended rounded-lg" maskClosable={false} centered open={deleteModal} footer={false} onCancel={handleModalClose}>
              <div className="modal-body pt-4 ">
                <div className="flex w-full justify-center items-center flex-col">
                  <ImageView src={images.notificationSuspend} alt="notificationDone" className="w-20 h-20" />
                  <h5 className="text-[24px] font-Inter pt-3 font-semibold">{""}</h5>
                  <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">Are you sure want to delete the restrictions ?</p>
                </div>
              </div>
              <div className="modal-footer pt-8">
                <div className="w-full flex justify-center">
                  <CustomButtonBack text={"No"} onClick={handleModalClose} />
                  <CustomButtonNext text={"Yes"} onClick={confirmDeleteRestriction} />
                </div>
              </div>
            </Modal>
            {modalOpen && <UserCreationNotificationModal type={"userBasicDetails"} modalOpen={modalOpen} setModalOpen={setModalOpen} responseMessage={ErrorMessages?.confirmationMessage} />}
          </Form>
        )}
      </Formik>
      <DeviceTokenPhoneCheck open={openConfirmationModal} handleCancel={closeConfirmationModal} handleProceed={handleProceed} />
    </>
  );
};
export default BasicDetails;
