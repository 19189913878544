import * as Yup from "yup";
const timeParameterSchema = Yup.object().shape({
  timeTo: Yup.string().required("End time is required"),
  timeFrom: Yup.string().required("Start time is required"),
  timezone: Yup.string().required("Timezone is required"),
  id: Yup.string().optional(),
});
export const validationSchemaForTimeRestriction = Yup.object().shape({
  name: Yup.string().required("Name is required").max(20, "Name cannot exceed 20 characters"),
  description: Yup.string().required("Description is required").max(50, "Description cannot exceed 50 characters"),
  assignTo: Yup.string().required("Please select any one"),
  timeParameter: Yup.array().of(timeParameterSchema),
});
