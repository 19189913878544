import * as Yup from "yup";


export   const createAppSchema = Yup.object().shape({
    connectorName: Yup.string().required("Connector name is required"),
    description: Yup.string().required("Description is required"),
    connectorType: Yup.string().required("Connector type is required"),
    connectorURL: Yup.string().required("Connector URL is required").url("Invalid URL"),
    userName: Yup.string().required("User name is required"),
    password: Yup.string().required("Password is required"),
    provisionURL: Yup.string().required("Provisioning URL is required").url("Invalid URL"),
    deprovisionURL: Yup.string().required("Deprovisioning URL is required").url("Invalid URL"),
  });
  export   const manageConnectionSchema = Yup.object().shape({
    manageConnectorName: Yup.string().required("Connector name is required"),
    manageDescription: Yup.string().required("Description is required"),
   
  });


   export  const initAppConfiguration = {
    connectorName: "",
    description: "",
    connectorType: "REST_API",
    connectorURL: "",
    userName: "",
    password: "",
    provisionURL: "",
    deprovisionURL: "",
  };

     export  const initAppConnection = {
    manageDescription: "",
    manageConnectorName: "",
    
  };

