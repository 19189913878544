import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { retrieveData, retrieveDeviceData } from "../../services/storage/Storage";
import useGetApiRequests from "../../services/axios/useApiRequests";
import UserLoginTheme from "../../pages/user-authentication/LoginTheme/LoginTheme";
import { images } from "../../assets/images/ImagePaths";
import { useNavigate } from "react-router-dom";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import { createValidationSchema } from "../../Utils/DynamicSchemaForPassword";
import PasswordStrengthIndicator from "../../Utils/PasswordStrengthIndicator";
import { PASSWORD_RESET_SUCCESS, PASSWORD_SET_SUCCESS } from "../../const/ModulePermissionConst";

function ResetPassword() {
  const realmName = retrieveData("realmName", true);

  const getPasswordPolicy = useGetApiRequests("getPasswordPolicy", "GET");
  const verifyPayload = useSelector((state: any) => state?.forgotUsernameMobileNumber?.verifyPayload);
  const sendChampVerificationReference = useSelector((state: any) => state?.forgotUsernameMobileNumber?.referenceNumber);

  const userDetails = useSelector((state: any) => state?.userDetails?.data);
  const fromSetPassword = retrieveData("fromSetPassword", true);
  const setPasswordApi = useGetApiRequests("setPassword", "PUT");
  const deviceUrl = retrieveDeviceData("device");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState<any>(null);
  const [responseSuccessMessage, setResponseSuccessMessage] = useState<any>(true);
  const [validSchema, setValidSchema] = useState<any>();
  const [passwordPolicy, setPasswordPolicy] = useState<any>(null);
  useEffect(() => {
    getPasswordPolicyList();
  }, []);

  const getPasswordPolicyList = async () => {
    setLoader(true);
    const pathParams = {
      id: realmName,
    };
    try {
      const response: any = await getPasswordPolicy("", {}, pathParams);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        const data = response?.data?.data;
        const validationSchema = createValidationSchema(data);
        setPasswordPolicy(data);
        setValidSchema(validationSchema);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validSchema,
    onSubmit: (values) => {
      setLoader(true);
      const officialEmailId = userDetails?.officialEmailId;
      const otp = retrieveData("otp", true);
      let userOtpValidateDto: { phoneNumber?: string; recoveryEmailId?: string; otp: any; realmName: any } = {
        otp,
        realmName,
      };

      if (verifyPayload?.isActive === "phone") {
        userOtpValidateDto.phoneNumber = verifyPayload?.phoneNumber;
      } else {
        userOtpValidateDto.recoveryEmailId = verifyPayload?.userName;
      }

      const payload: any = {
        userEmailId: officialEmailId,
        password: values.password,
        realmId: retrieveData("realmId", true),
        recoveryAttempt: !fromSetPassword,
        userOtpValidateDto,
      };
      if (sendChampVerificationReference) {
        payload.userOtpValidateDto.sendChampVerificationReference = sendChampVerificationReference;
      }

      setPasswordApi(payload)
        .then((response: any) => {
          if (response.data.status === "OK") {
            setDisableSubmit(true);
            const successMessage = fromSetPassword ? PASSWORD_SET_SUCCESS : PASSWORD_RESET_SUCCESS;
            setResponseSuccessMessage(successMessage);
            setResponseErrorMessage(null);
            setTimeout(() => {
              redirectToInitPage();
            }, 3000);
          } else {
            setResponseErrorMessage(response?.data?.message ? response?.data?.message : response.message);
          }
          setLoader(false);
        })
        .catch((err: any) => {
          setLoader(false);
          setResponseErrorMessage(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
        });
    },
  });
  const redirectToInitPage = () => {
    let url = window.location.href;
    const urlObj = new URL(url);
    const baseUrl = `${urlObj.protocol}//${urlObj.host}`;
    if (!deviceUrl) {
      window.location.href = baseUrl;
    } else {
      navigate("/device-logout?agentRestart=true");
    }
  };
  const handleCopyPaste = (event: any) => {
    setResponseErrorMessage(null);
    setResponseSuccessMessage(null);
    event.preventDefault();
    return false;
  };
  const onChangePassword = (value: any) => {
    formik.setFieldValue("password", value.target.value);
    setResponseErrorMessage(null);
    setResponseSuccessMessage(null);
  };
  const onChangeConfirmPassword = (value: any) => {
    formik.setFieldValue("confirmPassword", value.target.value);
    setResponseErrorMessage(null);
    setResponseSuccessMessage(null);
  };
  return (
    <div className="w-full">
      <div className="login-title">
        <h2 className="set-password-title">{fromSetPassword === true ? "Set Password" : "Reset Password"}</h2>
        {responseErrorMessage && <div className="error text-center">{responseErrorMessage}</div>}
        {responseSuccessMessage && <div className="set-password-success text-center _success_message">{responseSuccessMessage}</div>}
      </div>
      <div className="login-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="formGroup login-userName mb-3">
            <label className="label">Password</label>
            <Input.Password
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
              name="password"
              placeholder="Enter password"
              iconRender={(visible) =>
                visible ? (
                  <div className="w-6">
                    <img className="w-6" src={images.visible} alt="visible" />
                  </div>
                ) : (
                  <div className="w-6">
                    <img className="w-6" src={images.inVisible} alt="inVisible" />
                  </div>
                )
              }
              value={formik.values.password}
              onChange={(value) => onChangePassword(value)}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="formGroup login-userName">
            <label className="label">Re-Enter Password</label>
            <Input.Password
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
              name="confirmPassword"
              placeholder="Re-enter password"
              iconRender={(visible) =>
                visible ? (
                  <div className="w-6">
                    <img className="w-6" src={images.visible} alt="visible" />
                  </div>
                ) : (
                  <div className="w-6">
                    <img className="w-6" src={images.inVisible} alt="inVisible" />
                  </div>
                )
              }
              value={formik.values.confirmPassword}
              onChange={(value) => onChangeConfirmPassword(value)}
              onBlur={formik.handleBlur}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? <div className="error">{formik.errors.confirmPassword}</div> : null}
          </div>
          <div className="errorValidator-container">
            <PasswordStrengthIndicator password={formik.values.password} passwordPolicy={passwordPolicy} />
          </div>
          {!fromSetPassword && passwordPolicy?.notRecentlyUsed && (
            <div className="text-[14px] font-medium font-Inter italic text-[#5441da] pt-3 ">
              Note: <span className="text-[#3a3a3a]">Your password cannot be same as the last three passwords</span>
            </div>
          )}
          <div className="btn">
            <Button type="primary" className="bg-akku login-btn" htmlType="submit" loading={loader} disabled={Object.keys(formik?.errors).length !== 0 || disableSubmit}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserLoginTheme(ResetPassword);
