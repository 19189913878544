import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";

const FinalReviewUserData = (props: any) => {
  const { openModal, handleClose } = props;
  const [open, isOpen] = useState<boolean>(false);

  const jsonData: any = [
    { source: "givenName - Karthi", target: "FirstName - Karthi " },
    { source: "sn - Durai", target: "LastName -  Durai" },
  ];
  useEffect(() => {
    isOpen(openModal);
  }, [openModal]);

  const handleCloseModal = () => {
    handleClose();
  };

  const handleAddConnection = () => {
    handleClose();
  };
  return (
    <>
      <Modal className="modal-selector rounded-lg " maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
        <div className="modal-body  pt-4 ">
          <div className="modal-content pb-8">
            <p className="modal-title border-0 pb-3 pl-[30px]">Final Review - User Data</p>
            <p className="modal-sub-title pl-[30px]">Please review the mapping below and confirm changes if any </p>
          </div>

          <div className="flex w-full mx-auto flex-wrap">
            <div className="w-full flex py-8 ">
              <div className="w-[60%] flex pl-[20%]">
                <p className="content-header">SOURCE - LDAP</p>
              </div>
              <div className="w-[40%]">
                <p className="content-header">TARGET-AKKU</p>
              </div>
            </div>
            <div className="w-full modal-list ">
              {jsonData.map((elm: any, index: number) => (
                <div className="w-full flex " key={elm.source}>
                  <div className="w-[60%]  ">
                    <p className="content-description pl-[20%]">{elm.source}</p>
                  </div>
                  <div className="w-[40%]">
                    <p className="content-description">{elm.target}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-footer top pt-8">
          <div className="w-full flex justify-end">
            <CustomButtonBack onClick={handleCloseModal} text={"Back"} />
            <CustomButtonNext onClick={handleAddConnection} text={"Save"} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FinalReviewUserData;
