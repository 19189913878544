import React, { useEffect, useState } from "react";
import "./Card.scss";
import ImageView from "../../../../layouts/component/ImageView";
import { Switch, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { storeData } from "../../../../services/storage/Storage";
import DeleteApps from "../modal/DeleteApps";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { setAppConfigDetails } from "../../../../redux/slice/app-store/AppDetailsSlice";
import { getPermissionStyle, hasPermission } from "../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../const/RolePermissions";
import PermissionsModal from "../../../../layouts/permissionsModal/PermissionsModal";
import EmptyDataCard from "./EmptyDataCard";
import NewAppConfiguration from "../modal/new-app-configure/NewAppConfiguration";
const MyAppCard = (props: any) => {
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasEditAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "update");
  const hasCreateAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "create");
  const hasDeleteAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "delete");

  const { myAppDetails, masterData, available } = props;
  const clientDisplayConsole = useGetApiRequests("clientDisplayConsole", "PUT");
  const [appDetails, setAppDetails] = useState([]);
  const [myAllAppDetails, setMyAppDetails] = useState([]);
  const [currentAppDetails, setCurrentAppDetails] = useState([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.available) {
      setAppDetails(masterData);
    } else {
      setMyAppDetails(myAppDetails);
    }

  }, [props]);

  const ConfigureSingleApp = (item: any) => {
    if (hasCreateAppStorePermission) {
      dispatch(setAppConfigDetails(item));
      storeData("configureApp", "configure", true);
      navigate("/app-store/configure-app");
    } else {
      setPermissionsModal(true);
    }
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handelDelete = (item: any) => {
    if (hasDeleteAppStorePermission) {
      setCurrentAppDetails(item);
      setOpenModal(true);
    } else {
      setPermissionsModal(true);
    }
  };
  const handleToggle = (item: any, index: any, event: any) => {
    // Create a new array with updated item
    if (hasEditAppStorePermission) {
      const updatedItems: any = myAllAppDetails.map((detail: any, idx: any) => {
        if (idx === index) {
          // Find the item by index
          return {
            ...detail,
            client: {
              ...detail.client,
              alwaysDisplayInConsole: !detail.client.alwaysDisplayInConsole, // Toggle the value
            },
          };
        }
        return detail;
      });
      let queryParams = {
        "akku-client-id": item.akkuClientId,
        "is-display-in-console": event,
      };
      clientDisplayConsole("", queryParams)
        .then((response: any) => {
          triggerNotification("success", "", response?.data?.message, "topRight");
          setMyAppDetails(updatedItems);
        })
        .catch((err: any) => {
          handleRequestError(err);
        });
    } else {
      setPermissionsModal(true);
    }
  };
  const handelEditDetails = (item: any) => {
    if (hasEditAppStorePermission) {
      dispatch(setAppConfigDetails(item));
      storeData("configureApp", "edit", true);
      navigate("/app-store/configure-app");
    } else {
      setPermissionsModal(true);
    }
  };
  const handelCreateNewApp = () => {
    setOpenPopUp(true);
  };
  const handleModalClose = () => {
    setOpenPopUp(false);
  };
  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };
  return (
    <>
      <div className="w-full flex flex-wrap">
        {appDetails && available ? (
          appDetails.map((item: any, index: number) => (
            <div className="2xl:w-1/4  lg:w-1/3 sm:w-1/2" key={item.akkuMasterClientId}>
              <div className="mx-4  h-[270px] app-card  mb-8 relative cursor-pointer">
                <div className="card-img ">
                  <ImageView src={item.logoUrl} alt="my apps" className=" w-[60px] h-[60px] my-apps-icon" />
                </div>
                <div className="px-4 py-2">
                  <p className="text-[#1D1D24] text-[20px] font-Inter font-semibold">{item.name}</p>
                  <Tooltip title={item.description}>
                    <p className="text-[#444] text-[16px] font-Inter card-description">{item.description}</p>
                  </Tooltip>
                </div>
                <div className="card-action">
                  <p className={`configure font-Inter ${getPermissionStyle(hasCreateAppStorePermission, "permission-disabled-text")}`} onClick={() => ConfigureSingleApp(item)}>
                    Configure
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="2xl:w-1/4  lg:w-1/3 sm:w-1/2 hidden" onClick={handelCreateNewApp}>
              <div className="mx-4  h-[270px] app-card  mb-8">
                <div className=" h-full">
                  <EmptyDataCard />
                </div>
              </div>
            </div>
            {myAllAppDetails?.map((item: any, index: number) => (
              <div className="2xl:w-1/4  lg:w-1/3 sm:w-1/2" key={item?.akkuClientId}>
                <div className="mx-4  h-[270px] app-card relative  mb-8 cursor-pointer" key={item?.akkuMasterClient?.akkuMasterClientId}>
                  <div className="h-[100px] flex justify-center bg-[#F5F5FA] items-center rounded-t-lg rounded-tl-lg">
                    <ImageView src={item?.logoUrl ? imageBaseUrl + item?.logoUrl : item?.akkuMasterClient?.logoUrl} alt="my apps" className=" w-[60px] my-apps-icon h-[60px]" />
                  </div>
                  <div className="px-4 py-2">
                    <p className="text-[#1D1D24] text-[20px] font-Inter font-semibold">{item.client?.name}</p>
                    <Tooltip title={item.description}>
                      <p className="text-[#444] text-[16px] font-Inter card-description">{item.client?.description}</p>
                    </Tooltip>
                  </div>
                  <div className="px-4 py-2 flex justify-between items-center w-full absolute bottom-[5px] ">
                    <div className="action flex">
                      <p
                        className={`pr-4 edit text-[18px] font-Inter text-[#5441DA] cursor-pointer ${getPermissionStyle(hasEditAppStorePermission, "permission-disabled-text")}`}
                        onClick={() => {
                          handelEditDetails(item);
                        }}
                      >
                        Edit
                      </p>
                      <p
                        className={`text-[#5441DA] font-Inter edit text-[18px] cursor-pointer ${getPermissionStyle(hasDeleteAppStorePermission, "permission-disabled-text")}`}
                        onClick={() => {
                          handelDelete(item);
                        }}
                      >
                        Remove
                      </p>
                    </div>
                    <Switch
                      className={getPermissionStyle(hasEditAppStorePermission, "permission-disabled-switch")}
                      checked={item.client.alwaysDisplayInConsole}
                      onChange={(e) => handleToggle(item, index, e)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
      {openPopUp && <NewAppConfiguration openPopUp={openPopUp} handleModalClose={handleModalClose} />}
      {openModal && <DeleteApps openModal={openModal} handleClose={handleClose} currentAppDetails={currentAppDetails} />}
    </>
  );
};

export default MyAppCard;
