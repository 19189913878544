import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { retrieveData } from "../../../services/storage/Storage";
import generateColumns from "../RiskAssessmentHelperFile";
import { prerequisitesTable } from "../../../constant/prerequisites/prerequisites";
import { useNavigate } from "react-router-dom";

const RiskAssessmentTable = (props: any) => {
  const { tabKey, riskAssessmentData, parentLoader, totalCount, currentPage, sizeChanger, setCurrentPage, setSizeChanger } = props;
  const [selectedTabKey, setSelectedTabKey] = useState<string>("");
  const navigate = useNavigate();
  useEffect(() => {
    if (tabKey === "") {
      const tabKeyFromLocalStorage = retrieveData("riskAssessmentTabKey", true)?.toString();
      setSelectedTabKey(tabKeyFromLocalStorage);
    } else {
      setSelectedTabKey(tabKey);
    }
  }, [tabKey]);

  const columns = generateColumns({ selectedTabKey }, navigate);

  const handlePageChange = (page: any) => {
    setCurrentPage((page - 1) * sizeChanger);
  };
  const handlePageSizeChange = (current: any, newSize: any) => {
    setSizeChanger(newSize);
  };
  return (
    <div className="w-full p-8">
      <div className="text-[#212121] font-Inter text-[18px] font-medium italic mb-3">Total users count : {totalCount}</div>
      <Table
        loading={parentLoader}
        columns={columns}
        dataSource={riskAssessmentData}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: currentPage / sizeChanger + 1,
          pageSizeOptions: prerequisitesTable.pageSizeOptions,
          defaultPageSize: sizeChanger,
          pageSize: sizeChanger,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
        }}
        scroll={{ x: true }}
      />
    </div>
  );
};
export default RiskAssessmentTable;
