import axios from "axios";
import { retrieveData } from "../storage/Storage";

axios.defaults.baseURL = process.env.REACT_APP_BASEURL;

axios.interceptors.request.use(
  (config) => {
    const token: string = retrieveData("authToken", true);

    //   const isTokenAvailable: boolean = !!token;
    // Check if the URL requires the Authorization header
    //   const requiresAuthHeader: boolean = config.headers?.Authorization !== "exclude";

    if (config.headers?.Authorization !== "exclude") {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axios;
