import React, { useState } from "react";
import { Form, Formik } from "formik";
import EmailNotification from "../../../../dashboard/user/user-dashboard/add-user/layouts/modal/map-notification/EmailNotification";
import { Button } from "antd";
import SMSNotification from "../../../../dashboard/user/user-dashboard/add-user/layouts/modal/map-notification/SMSNotification";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";

const ProvisionMapperNotification = () => {
  const validationSchema = {};

  const handleSubmit = async (values: any) => {
    setInitialValues([]);
  };
  const [initialValues, setInitialValues] = useState([]);

  const handleUserAction = () => {
    setInitialValues([]);
  };

  const handleToBack = () => {
    setInitialValues([]);
  };
  return (
    <>
      <div className="w-full px-10 pb-5 ">
        <p className="app-header pt-8">Mapping - User status</p>
      </div>

      <div className="w-full mx-10 notification-container">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="w-full">
                  <div className="w-full">
                    <EmailNotification />
                  </div>
                  <div className="w-full mt-4">
                    <SMSNotification />
                  </div>
                </div>
                <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                  <div className="modal-footer w-[95%] mx-auto ">
                    <div className="w-full flex justify-end">
                      <CustomButtonBack onClick={handleToBack} text={"Back"} />

                      <Button type="primary" onClick={handleUserAction} className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ProvisionMapperNotification;
