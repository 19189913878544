import React from "react";

interface TotalAttemptScoreProps {
  attempt: number | undefined;
}

const TotalAttemptScore: React.FC<TotalAttemptScoreProps> = ({ attempt }) => {
  const formattedAttempt = attempt?.toString().padStart(2, "0") ?? "00";

  return (
    <>
      <div className="w-1/2 flex justify-center flex-col items-center">
        <h3 className="font-Inter font-semibold text-[38px] text-[#000] leading-[32px]  text-rwd26">{formattedAttempt}</h3>
        <p className="font-Inter text-[20px] font-medium text-[#444] pt-1 text-rwd16">Total Attempts</p>
      </div>
    </>
  );
};

export default TotalAttemptScore;
