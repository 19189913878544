import React from "react";
import ImageView from "../../../layouts/component/ImageView";
import { Tooltip } from "antd";

const AppList = (props: any) => {
  const { filterAppList } = props;
  return (
    <div className=" flex flex-wrap h-full">
      {filterAppList.map((item: any, index: number) => (
        <div key={item?.app}>
          <a href={item.baseUrl} target="_blank" rel="noreferrer" className="w-[25%] flex">
            <div className="w-full">
              <div className="app-item m-5 ">
                <div className="item">
                  <div className="item-header">
                    <a href={item.baseUrl} target="_blank" rel="noreferrer" className="w-20">
                      <ImageView src={item.logoUrl} alt={item.app} />
                    </a>
                  </div>
                  <div className="item-body">
                    <h4>
                      <a href={item.baseUrl} target="_blank" rel="noreferrer">
                        {item.app}
                      </a>
                    </h4>
                    <Tooltip title={item.description}>
                      <p>
                        <a href={item.baseUrl} target="_blank" rel="noreferrer">
                          {item.description}
                        </a>
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default AppList;
