import React from "react";
import SsoSessionSettingForm from "../../../layouts/dashboard-module/customer-table/modal/forms/SsoSessionSettingForm";

const EditSSOSettings = (props: any) => {
  return (
    <div className="w-full pt-5 edit-sso">
      <SsoSessionSettingForm active />
    </div>
  );
};

export default EditSSOSettings;
