import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  profileDetails: {},
}

const initialState: AuthState = {
    profileDetails: {},
};

const ProfileDetailsSlice: any = createSlice({
  name: "profileTabSlice",
  initialState,
  reducers: {
    setProfileDetails: (state, action: PayloadAction<boolean>) => {
      state.profileDetails = action.payload;
    },
 
     clearProfileDetails: (state) => {
      state.profileDetails = {};
    },
  },
});

export const { setProfileDetails, clearProfileDetails} = ProfileDetailsSlice.actions;
export default ProfileDetailsSlice.reducer;
