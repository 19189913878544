import React, { useEffect, useState } from "react";
import "./ProvisionStepper.scss";
import { Steps } from "antd";
import { useSelector } from "react-redux";

const ProvisionStepper = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const currentSteps = useSelector((state: any) => state?.AppDetailsSlice?.currentStep);
  useEffect(() => {
    console.log(currentSteps);
    setCurrentStep(1);
  }, [currentSteps]);
  return (
    <>
      <div className="w-full stepper-provision pl-8 ">
        <ul className="flex breadcrumb pt-10">
          <li className="flex">
            App Store
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
              <path d="M540-480 356-664l20-20 204 204-204 204-20-20 184-184Z" />
            </svg>
          </li>
          <li className="active">Provisioning</li>
        </ul>
        <div className="stepper-content">
          <h1>Provision- Jira</h1>
          <div className=" provision pt-10">
            <Steps
              direction="vertical"
              current={currentStep}
              items={[
                {
                  title: "Connector Details",
                },
                {
                  title: "Manage Connection",
                },
                {
                  title: (
                    <p className="flex items-center ">
                      Mapping <span className="material-symbols-outlined ml-1 mt-1">keyboard_arrow_up</span>
                    </p>
                  ),
                  description: (
                    <div className="child-stepper">
                      <p>Attributes</p>
                      <p>Groups</p>
                      <p>User status</p>
                      <p>Notification</p>
                      <p>Schedular</p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProvisionStepper;
