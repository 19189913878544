import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../../../layouts/component/CustomButton";
import { Modal } from "antd";
import { staticMessage } from "../../../../../../../../constant/StaticContent";
import "./FinalReviewMappers.scss";
import ImageView from "../../../../../../../../layouts/component/ImageView";
import { images } from "../../../../../../../../assets/images/ImagePaths";
import { retrieveData } from "../../../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../../../../../../../layouts/component/Loader";
import { handleRedirectMapAttribute } from "../../navigate-helper/LDAPNavigate";
import { setAddUserStepper } from "../../../../../../../../redux/slice/AddUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../../../../layouts/toast/ToastBar";
import { clearLDAPInitialValues, clearLDAPUpdateInitialValues, clearLDAPValues } from "../../../../../../../../redux/slice/LDAPSlice";
const MappersFinalReview = (props: any) => {
  const { finalReview, handleClose, transformPayload, formList, editAttribute, setCurrentTab } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const realmName = retrieveData("realmName", true);
  const addMappers = useGetApiRequests("addMappers", "POST");
  const [loader, setLoader] = useState<boolean>(false);
  const [createPayload, setCreatePayload] = useState<any>([]);
  const dispatch = useDispatch();
  const stepper = useSelector((state: any) => state?.addUserSlice?.steeper);
  useEffect(() => {
    setIsOpen(finalReview);
    let filteredData = transformPayload?.filter((obj: any) => obj.id == "");

    let modifiedData = filteredData?.map((obj: any) => {
      // Clone the object to avoid mutating the original data
      let newObj = { ...obj };
      // Delete the isCheck property
      delete newObj?.isCheck;
      delete newObj?.id;
      return newObj;
    });
    setCreatePayload(modifiedData);
  }, []);
  const handleClosed = () => {
    setIsOpen(false);
    handleClose();
  };
  const handleGroupMapping = async () => {
    if (createPayload.length !== 0) {
      setLoader(true);
      const pathParams = { realmName: realmName, mappers: "mappers" };
      try {
        for (const elm of createPayload) {
          const response: any = await addMappers([elm], {}, pathParams);
          const status = response?.status;
          if (status === 200) {
            handleDispatch();
            setLoader(false);
          }
        }
        triggerNotification("success", "Mappers Created Successfully", "", "topRight");
      } catch (err) {
        handleRequestError(err);
        setLoader(false);
      } finally {
        setLoader(false);
      }
    } else {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    if (editAttribute) {
      setCurrentTab(4);
      clearLDAPState();
    } else {
      const newStepper = handleRedirectMapAttribute(stepper);
      dispatch(setAddUserStepper(newStepper));
    }
  };
  const clearLDAPState = () => {
    dispatch(clearLDAPInitialValues());
    dispatch(clearLDAPUpdateInitialValues());
    dispatch(clearLDAPValues());
  };

  return (
    <Modal className=" common-modal final  " maskClosable={false} centered open={isOpen} footer={false} onCancel={handleClose}>
      {loader && <Loader />}
      <div className="common-header flex justify-between p-5">
        <div className="flex flex-col">
          <p className="title">{staticMessage.LDAPGroupMap.finalReview}</p>
          <p className="description">{staticMessage?.LDAPGroupMap?.finalReviewDescription}</p>
        </div>
        <div className="flex flex-col w-[190px]">
          <ImageView src={images?.ldapToAkku} className="w-full" />
        </div>
      </div>
      <div className="common-modal-body h-[550px] overflow-y-auto">
        <div className="content pt-8  w-full flex">
          <div className="w-1/2">
            <div className="flex">
              <p className="pl-28 content-header">SOURCE - LDAP</p>
            </div>
            {formList?.map((elm: any) => (
              <div className="w-full flex content-row" key={elm?.id}>
                <div className=" pt-6 pb-2">
                  <p className="text-[#1D1D24] font-Inter text-[20px] font-medium pl-28 ">{elm?.source}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="w-1/2">
            <div className="flex">
              <p className="pl-28 content-header">TARGET-AKKU</p>
            </div>
            {formList?.map((elm: any) => (
              <div className="w-full flex content-row" key={elm?.id}>
                <div className=" pt-6 pb-2">
                  <p className="text-[#1D1D24] font-Inter text-[20px] font-medium pl-28 ">{elm?.target}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="common-modal-footer  final-review">
        <CustomButtonBack text={createPayload?.length == 0 ? "Close" : "Cancel"} onClick={handleClosed} />
        <CustomButtonNext text={"Next"} onClick={handleGroupMapping} className="ml-10" />
      </div>
    </Modal>
  );
};

export default MappersFinalReview;
